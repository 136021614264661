import React from 'react';
import { connect } from 'react-redux';
import { Link, Route } from 'react-router-dom';
import agent from '../../agent';
import {
  ADD_LEAGUE_REQUEST,
  UPDATE_LEAGUE_REQUEST,
  ADMIN_SEASONS_LOADED,
  ADMIN_LEAGUES_LOADED,
  ADMIN_LEAGUES_UNLOADED,
} from '../../constants';
import NewLeagueModal from './new-league-modal';
import { leagueTypes } from '../../data/play-type';
import genders from '../../data/genders';
import { toastr } from 'react-redux-toastr';

export class LeaguesContainer extends React.Component {
  state = {
    leagues: undefined, // undefined so modals default parameter will be triggered
  };

  formSubmit = values => {
    const newLeague = {
      id: values.id,
      playType: values.playType.id,
      gender: values.gender.id,
      gameOrder: values.gameOrder,
      rating: values.rating,
      season: values.season.id,
      flight: values.flight,
      cost: values.flight ? parseFloat(values.cost) : undefined,
      chargeCaptain: values.chargeCaptain,
      eloRating: values.flight ? values.eloRating : false,
    };
    this.props.onLeagueCreate(newLeague);
  };

  getLeagueForEdit = leagueId => {
    agent.Leagues.getLeague(leagueId).then(leagueData => {
      const type = leagueTypes.find(type => type.id === leagueData.playType);
      const gender = genders.find(gender => gender.id === leagueData.gender);
      const league = {
        ...leagueData,
        playType: { id: leagueData.playType, label: type && type.name },
        gender: { id: leagueData.gender, name: gender && gender.name },
      };
      this.setState({ league });
    });
  };

  renderLeagues(leagues) {
    return (
      <table className="table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Play</th>
            <th>Games</th>
            <th>Rating</th>
            <th>Edit</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {leagues.map((league, idx) => {
            const leaguePlayType = leagueTypes.find(
              type => type.id === league.playType
            );
            return (
              <tr key={idx}>
                <td>{league.name}</td>
                <td>{leaguePlayType ? leaguePlayType.name : null}</td>
                <td>{league.gameOrder}</td>
                <td>{league.comboRating}</td>
                <td>
                  <span onClick={() => this.getLeagueForEdit(league.id)}>
                    <Link to={`${this.props.match.url}/${league.id}`}>
                      <i className="fa fa-pencil fa-fw" />
                    </Link>
                  </span>
                  {/* <i className="fa text-danger fa-trash fa-fw" /> */}
                </td>
                <td>
                  <Link to={`${this.props.match.url}/${league.id}/teams`}>
                    Teams
                  </Link>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }

  UNSAFE_componentWillMount() {
    this.props.onLoad();
  }

  render() {
    return (
      <div className="leagues">
        <div className="list-group">
          <div className="list-group-item">
            <h3>
              Leagues List
              <div
                className="float-right"
                onClick={() => {
                  this.setState({ league: undefined });
                }}>
                <Link
                  className="btn btn-primary"
                  to={`${this.props.match.url}/new`}>
                  <i className="fa-plus fa fa-fw" /> Add
                </Link>
              </div>
            </h3>
          </div>
          {this.props.leagues && this.props.leagues.length > 0 ? (
            this.renderLeagues(this.props.leagues)
          ) : (
            <div className="list-group-item">No Leagues.</div>
          )}
        </div>
        <Route
          strict
          exact
          path={`${this.props.match.path}/:id`}
          render={() => (
            <NewLeagueModal
              errors={this.props.errors}
              seasons={this.props.seasons}
              league={this.state.league}
              onSubmit={values => this.formSubmit(values)}
            />
          )}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  ...state.common,
  ...state.admin,
});

const mapDispatchToProps = dispatch => {
  return {
    onLoad: () => {
      dispatch({
        type: ADMIN_LEAGUES_LOADED,
        payload: agent.Admin.listLeagues(),
      });
      dispatch({
        type: ADMIN_SEASONS_LOADED,
        payload: agent.Seasons.listCurrentSeasons(),
      });
    },
    onUnload: () => {
      dispatch({
        type: ADMIN_LEAGUES_UNLOADED,
      });
    },
    onLeagueCreate: league => {
      dispatch({
        type: league.id ? UPDATE_LEAGUE_REQUEST : ADD_LEAGUE_REQUEST,
        payload: league.id
          ? agent.Leagues.update(league.id, league)
          : agent.Leagues.add(league),
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LeaguesContainer);
