export const GET_FLIGHT_DATA = 'GET_FLIGHT_DATA';

export const FLIGHT_LOAD = 'FLIGHT_LOAD';

export const GET_FLIGHT_MATCHES = 'GET_FLIGHT_MATCHES';

export const CREATE_FLIGHT_MATCHES = 'CREATE_FLIGHT_MATCHES';

export const GET_LEAGUES_DATA = 'GET_LEAGUES_DATA';

export const UPDATE_FLIGHT = 'UPDATE_FLIGHT';
