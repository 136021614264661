import React from 'react';
import { connect } from 'react-redux';

export class FourOhFourContainer extends React.Component {
  render() {
    return (
      <div className="">
        <h1>Page Not Found</h1>
        <div>Did you mistype?</div>
      </div>
    );
  }
}
export default connect(
  null,
  {}
)(FourOhFourContainer);
