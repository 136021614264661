import React from 'react';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import PasswordUpdateForm from './password-update-form';
import agent from '../../agent';
import ListError from '../../common/list-errors';
import { UPDATE_USER_PASSWORD } from '../../constants';
import { Redirect } from 'react-router-dom';
const mapStateToProps = state => ({
  ...state.profile,
  ...state.common,
});
const mapDispatchToProps = dispatch => ({
  updatePassword(values, userId) {
    return dispatch({
      type: UPDATE_USER_PASSWORD,
      payload: agent.Auth.updatePassword(values, userId),
    });
  },
});

export class SettingsContainer extends React.Component {
  state = {
    redirectTo: null,
  };
  handleSubmit = values => {
    this.setState({
      errors: null,
    });
    return this.props
      .updatePassword(values, this.props.userId)
      .then(this.onAfterUpdate)
      .catch(error => this.onUpdateError(error));
  };
  onAfterUpdate = () => {
    toastr.success(
      'Settings',
      'Your account settings have been updated successfully.'
    );
    // Stay on same route if userId was passed in
    const path = this.props.userId ? '' : '/';
    this.setState({
      redirectTo: path,
    });
  };
  onUpdateError(error) {
    this.setState({
      errors: error.response.body,
    });
  }
  render() {
    const errors = this.state ? this.state.errors : null;
    return this.state.redirectTo ? (
      <Redirect to={this.state.redirectTo} />
    ) : (
      <div>
        <h1>Settings</h1>
        <ListError errors={errors} />
        <PasswordUpdateForm
          initialValues={{}}
          onSubmit={this.handleSubmit}
          buttonText="Update Password"
        />
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SettingsContainer);
