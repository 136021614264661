import React from 'react';
import { Link } from 'react-router-dom';
import MobileStoreButton from 'react-mobile-store-button';
import { connect } from 'react-redux';
import { parse } from 'qs';
import agent from '../../agent';
import ListErrors from '../../common/list-errors';
import {
  PASSWORD_RESET_LOADED,
  PASSWORD_RESET_UNLOADED,
  PASSWORD_RESET,
  UPDATE_FIELD_AUTH,
} from '../../constants';

export class PasswordResetContainer extends React.Component {
  constructor() {
    super();
    this.state = { formSubmitted: false };
    this.changePassword = ev => this.props.onChangePassword(ev.target.value);
    this.changeConfirmPassword = ev =>
      this.props.onchangeConfirmPassword(ev.target.value);
    this.submitForm = (password, confirmPassword) => ev => {
      ev.preventDefault();
      this.props.onSubmit(this.props.token, password, confirmPassword);
      this.setState({ formSubmitted: true });
    };
  }
  componentDidMount() {
    this.props.onLoad();
  }

  componentWillUnmount() {
    this.props.onUnload();
  }

  render() {
    const { password = '', confirmPassword = '' } = this.props;
    return (
      <div className="auth-page">
        <div className="container page">
          {!this.props.inProgress &&
          (this.state.formSubmitted && !this.props.errors) ? (
            <p>
              <br />
              <div className="row">
                <div className="col-sm text-center">
                  <h1>
                    Success! You can now log in with your new credentials.
                  </h1>
                  <h1>Don't have the app? Download The App Today!</h1>
                </div>
              </div>
              <div className="row mt-5">
                <div className="col-sm text-center">
                  <MobileStoreButton
                    store="ios"
                    url={_clientFeatures.download_buttons.APPLE}
                  />
                </div>
                <div className="col-sm text-center">
                  <MobileStoreButton
                    store="android"
                    url={_clientFeatures.download_buttons.GOOGLE}
                  />
                </div>
              </div>
            </p>
          ) : (
            <div className="row">
              <div className="col-md-6 offset-md-3 col-xs-12">
                <div>
                  <ListErrors errors={this.props.errors} />
                  <form onSubmit={this.submitForm(password, confirmPassword)}>
                    <fieldset>
                      <fieldset className="form-group">
                        <input
                          className="form-control"
                          type="password"
                          placeholder="Password"
                          value={password}
                          onChange={this.changePassword}
                        />
                      </fieldset>
                      <fieldset className="form-group">
                        <input
                          className="form-control"
                          type="password"
                          placeholder="Confirm Password"
                          value={confirmPassword}
                          onChange={this.changeConfirmPassword}
                        />
                      </fieldset>
                      <button
                        className="btn btn-primary"
                        type="submit"
                        disabled={this.props.inProgress}>
                        Reset Password
                      </button>
                    </fieldset>
                  </form>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({ ...state.auth });
const mapDispatchToProps = (dispatch, state) => {
  return {
    onLoad: () => {
      const query = parse(state.location.search.substr(1));
      const token = query.token;
      dispatch({
        type: PASSWORD_RESET_LOADED,
        payload: {
          token,
        },
      });
    },
    onUnload: () => dispatch({ type: PASSWORD_RESET_UNLOADED }),
    onChangePassword: value =>
      dispatch({ type: UPDATE_FIELD_AUTH, key: 'password', value }),
    onchangeConfirmPassword: value =>
      dispatch({ type: UPDATE_FIELD_AUTH, key: 'confirmPassword', value }),
    onSubmit: (token, password, confirmPassword) =>
      dispatch({
        type: PASSWORD_RESET,
        payload: agent.Auth.resetPassword(token, password, confirmPassword),
      }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PasswordResetContainer);
