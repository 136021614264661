import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { localTime } from '../../../common/services/utils/helpers';
import PropTypes from 'prop-types';
import agent from '../../../agent';
import ConfirmationDialog from '../../../components/UI/ConfirmationDialog';
import { toastr } from 'react-redux-toastr';
import { eventTypes, eventStatus } from '../../../data/types';

class ConfirmedSocialPlayList extends Component {
  render() {
    const { events } = this.props;
    return (
      <div>
        <strong>Confirmed Events</strong>
        {events.length ? (
          <SocialPlayEventTable
            currentUser={this.props.currentUser}
            events={events}
            onConfirmationChange={this.props.onConfirmationChange}
          />
        ) : (
          <div className="alert alert-info mt-3">
            No Confirmed Social Play events yet.
          </div>
        )}
      </div>
    );
  }
}

class SocialPlayEventTable extends Component {
  static propTypes = {
    onConfirmationChange: PropTypes.func.isRequired,
    events: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        location: PropTypes.string.isRequired,
        dateTime: PropTypes.string.isRequired,
      })
    ).isRequired,
  };
  constructor() {
    super();
    this.state = {
      showConfirmation: false,
    };
  }
  cardItem = (event, title = 'Social Play') => {
    return (
      <div className="row d-flex justify-content-between">
        {event.status === eventStatus.OPEN ? (
          <span className="ml-1 ml-sm-3">
            <i className=" fa fa-lg fa-folder-open text-success" />
            <small className="pl-1">Open</small>
          </span>
        ) : (
          <span className="ml-1 ml-sm-3">
            {' '}
            <i className=" fa fa-lg fa-folder text-danger" />
            {event.venueEvent &&
            event.creator === this.props.currentUser._id ? (
              <small className="pl-1">Closed</small>
            ) : (
              <small className="pl-1">Confirmed</small>
            )}
          </span>
        )}
        <small className="mr-1 mr-sm-0 mt-1">{title}</small>
        <small className="mr-3 mr-sm-0 mt-1">
          Players&nbsp;
          {event.accepted}/{event.maxPlayers}
        </small>
        <small className="mr-1 mt-1">{event.playType}</small>
        <span className="mt-sm-0 mt-3 ml-1 ml-sm-0 mr-3">
          {localTime(event.start).format('ddd, MM/DD/YY h:mma')}
          <small className="ml-1 mr-1">at</small>
          {event.venue}
        </span>
        {this.eventDeleteable(event) ? (
          <div>
            <i
              className="mr-1 mt-1 fa fa-trash"
              onClick={e => {
                e.preventDefault();
                this.toggleConfirmationDialog();
                this.setState({ curEvent: event });
              }}
            />
          </div>
        ) : (
          <i className="mr-1 mt-1 fa fa-trash text-white" /> // Render icon that matches white bg so it keeps layout
        )}
        <span className="float-right mt-3 mt-sm-1">
          <i className="fa fa-chevron-right fa-fw" />
        </span>
      </div>
    );
  };
  cardView = events => {
    return events.map(event => {
      if (event.type === eventTypes.SOCIAL) {
        return (
          <Link
            to={`social-play/${event._id}`}
            key={event._id}
            className={'list-group-item'}>
            {this.cardItem(
              event,
              event.venueEvent && event.creator === this.props.currentUser._id
                ? 'Venue Event'
                : 'Social Play'
            )}
          </Link>
        );
      }
      return (
        <Link
          to={`teams/${event.team._id}/practices/${event._id}`}
          key={event._id}
          className={'list-group-item'}>
          {this.cardItem(event, 'Practice')}
        </Link>
      );
    });
  };
  eventDeleteable = event => event.creator === this.props.currentUser._id;
  toggleConfirmationDialog = () =>
    this.setState({ showConfirmation: !this.state.showConfirmation });
  handleSocialEventDelete = eventId => {
    const self = this;
    agent.Events.delete(eventId)
      .then(() => {
        self.props.onConfirmationChange();
      })
      .catch(() => {
        toastr.error(
          'SocialPlay',
          `There was an error removing this Social Play Event. Please try again. If the problem persists, please contact ${
            _clientFeatures.supportURL
          }.`
        );
      });
  };
  render() {
    return (
      <div className="list-group mt-3">
        {this.cardView(this.props.events)}
        {this.state.showConfirmation && (
          <ConfirmationDialog
            show={this.state.showConfirmation}
            title="Delete Social Play Event"
            message="Are you sure you want to delete the social play event? Confirmed players will also be removed"
            toggle={this.toggleConfirmationDialog}
            onSubmit={() => {
              this.handleSocialEventDelete(this.state.curEvent._id);
            }}
          />
        )}
      </div>
    );
  }
}

const stateToProps = state => ({
  currentUser: state.common.currentUser,
});

export default connect(stateToProps)(ConfirmedSocialPlayList);
