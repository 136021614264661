import React from 'react';
import { connect } from 'react-redux';
import { USERS_DISPLAY } from '../constants';
import MobileStoreButton from 'react-mobile-store-button';
import agent from '../agent';

export class DownloadsContainer extends React.Component {
  componentDidMount() {}

  render() {
    let content;
    content = (
      <div className="container">
        <div className="row">
          <div className="col-sm text-center font-weight-bold">
            <h1>Download The App Today!</h1>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-sm text-center">
            <MobileStoreButton
              store="ios"
              url={_clientFeatures.download_buttons.APPLE}
            />
          </div>
          <div className="col-sm text-center">
            <MobileStoreButton
              store="android"
              url={_clientFeatures.download_buttons.GOOGLE}
            />
          </div>
        </div>
      </div>
    );
    return content;
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onLoad: id =>
      id &&
      dispatch({
        type: USERS_DISPLAY,
        payload: agent.Auth.display(id),
      }),
  };
};

const mapStateToProps = state => ({
  ...state.auth,
  ...state.common,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DownloadsContainer);
