import {
  VENUES_LOAD,
  GET_VENUE_DATA,
  GET_VENUE_LIST,
  GET_VENUE_EVENTS,
  GET_VENUE_MEMBERS,
  GET_VENUE_FOLLOWERS,
  SET_PLAYER_PREFERENCE,
  UPDATE_VENUE_USER_LISTS,
} from './constants';
import agent from "../agent";
import {UPDATE_GAME} from "../challenge/constants";

export function venuesLoad(data) {
  return {
    type: VENUES_LOAD,
    payload: data,
  };
}

export function loadVenueData(data) {
  return {
    type: GET_VENUE_DATA,
    payload: data,
  };
}

export function loadVenuesList(data) {
  return {
    type: GET_VENUE_LIST,
    payload: data,
  };
}

export function loadVenueMembers(data) {
  return {
    type: GET_VENUE_MEMBERS,
    payload: data,
  };
}

export function loadVenueFollowers(data) {
  return {
    type: GET_VENUE_FOLLOWERS,
    payload: data,
  };
}

export function updateVenueLists(data) {
  return {
    type: UPDATE_VENUE_USER_LISTS,
    payload: data,
  };
}

export function loadVenueEvents(id) {
  return async dispatch => {
    const current = await agent.Venues.getEvents(id, { current: true });
    const past = await agent.Venues.getEvents(id, {
      past: true,
      types: 'challenge',
    });
    dispatch({
      type: GET_VENUE_EVENTS,
      payload: { current, past },
    });
  };
}

export function setPlayerPreferences(data) {
  return {
    type: SET_PLAYER_PREFERENCE,
    payload: data,
  };
}
