export const PLAYER = 'player';
export const CO_CAPTAIN = 'co_captain';
export const CAPTAIN = 'captain';
export const SUBSTITUTE = 'substitute';

export default [
  { id: 0, name: 'None' },
  { id: PLAYER, name: 'Player' },
  { id: CO_CAPTAIN, name: 'Co Captain' },
  { id: CAPTAIN, name: 'Captain' },
  { id: SUBSTITUTE, name: 'Substitute' },
];
