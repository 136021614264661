import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import PropTypes from 'prop-types';

export default class ConfirmationDialog extends React.Component {
  static propTypes = {
    show: PropTypes.bool.isRequired, // Should be the same state as the one used in the toggle function
    title: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    buttonLabels: PropTypes.object, // {ok: okLabel, cancel: cancelLabel}
    submitBtnText: PropTypes.string,
    onSubmit: PropTypes.func.isRequired,
    toggle: PropTypes.func.isRequired, // Uses the parents state to control opening/closing of the dialog
    onBeforeModalClose: PropTypes.func, // Optional hook to call before closing the dialog on cancel
    onBeforeConfirmed: PropTypes.func, // Optional hook to call before closing the dialog on submit
  };

  constructor(props) {
    super(props);
    this.state = {
      modal: this.props.show,
    };
  }

  onModalClose = () => {
    if (this.onBeforeModalClose) {
      this.onBeforeModalClose();
    }
  };

  onConfirmed = () => {
    if (this.onBeforeConfirmed) {
      this.onBeforeConfirmed();
    }
    this.props.toggle();
    this.props.onSubmit();
  };

  render() {
    const {
      title,
      message,
      pristine,
      submitting,
      buttonLabels = {},
    } = this.props;

    return (
      <Modal
        isOpen={this.state.modal}
        onClosed={this.onModalClose}
        className={this.props.className}>
        <ModalHeader toggle={this.props.toggle}>{title}</ModalHeader>
        <ModalBody>{message}</ModalBody>
        <ModalFooter>
          <a className="btn btn-link" onClick={this.props.toggle}>
            {buttonLabels['cancel'] || 'Cancel'}
          </a>
          <Button
            disabled={pristine || submitting}
            color="primary"
            onClick={() => {
              this.onConfirmed();
            }}>
            {buttonLabels['ok'] || 'Ok'}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}
