import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Switch, Route, NavLink, Redirect } from 'react-router-dom';
import Profile from './profile';
import Admin from './admin';
import Settings from './settings';
import Friends from './friends';
import Venues from './venues';
import Event from './event';
import Captain from './captain';
import { LOAD_SETTINGS } from '../constants';
import agent from '../agent';

export class AccountContainer extends Component {
  componentDidMount() {
    const id = this.props.currentUser && this.props.currentUser.id;
    this.props.onLoad(id);
  }
  render() {
    let { match, currentUser, settings } = this.props;
    console.log(currentUser);
    return (
      <div>
        <ul className="nav nav-tabs">
          {currentUser && (
            <li className="nav-item">
              <NavLink className="nav-link" to={`${match.url}/profile`}>
                Profile
              </NavLink>
            </li>
          )}
          {currentUser && (
            <li className="nav-item">
              <NavLink className="nav-link" to={`${match.url}/friends`}>
                Friends
              </NavLink>
            </li>
          )}
          {currentUser && (
            <li className="nav-item">
              <NavLink className="nav-link" to={`${match.url}/venues`}>
                Venues
              </NavLink>
            </li>
          )}
          {currentUser && (
            <li className="nav-item">
              <NavLink className="nav-link" to={`${match.url}/social-play`}>
                Event
              </NavLink>
            </li>
          )}
          {currentUser && currentUser.roles.indexOf('Venue') !== -1 && (
            <li className="nav-item">
              <NavLink className="nav-link" to={`${match.url}/manager`}>
                Admin
              </NavLink>
            </li>
          )}
          {/*<li className="nav-item">*/}
          {/*<NavLink className="nav-link" to={`${match.url}/settings`}>*/}
          {/*Settings*/}
          {/*</NavLink>*/}
          {/*</li>*/}
          {currentUser && currentUser.roles.indexOf('Captain') !== -1 && (
            <li className="nav-item">
              <NavLink className="nav-link" to={`${match.url}/captain`}>
                Captain
              </NavLink>
            </li>
          )}
        </ul>
        <Switch>
          {currentUser && (
            <Route
              strict
              exact
              path={`${match.path}/profile`}
              component={Profile}
            />
          )}
          {/*<Route*/}
          {/*strict*/}
          {/*exact*/}
          {/*path={`${match.path}/settings`}*/}
          {/*component={Settings}*/}
          {/*/>*/}
          {currentUser && (
            <Route
              strict
              exact
              path={`${match.path}/friends`}
              component={Friends}
            />
          )}
          {currentUser && (
            <Route
              strict
              exact
              path={`${match.path}/venues`}
              component={Venues}
            />
          )}
          {currentUser && (
            <Route
              strict
              exact
              path={`${match.path}/social-play`}
              component={Event}
            />
          )}
          {currentUser && currentUser.roles.indexOf('Captain') !== -1 && (
            <Route
              strict
              exact
              path={`${match.path}/captain`}
              render={() => <Captain playerId={currentUser.id} />}
            />
          )}
          {currentUser && currentUser.roles.indexOf('Venue') !== -1 && (
            <Route
              strict
              exact
              path={`${match.path}/manager`}
              component={Admin}
            />
          )}
          <Redirect to={`${match.path}/profile`} />
        </Switch>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  ...state.profile,
  ...state.common,
});

const mapDispatchToProps = dispatch => ({
  onLoad: id =>
    dispatch({ type: LOAD_SETTINGS, payload: agent.Users.getUserSettings(id) }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountContainer);
