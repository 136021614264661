const genders = [
  {
    id: 1,
    name: 'Male',
  },
  {
    id: 2,
    name: 'Female',
  },
  {
    id: 3,
    name: 'Mixed',
  },
  {
    id: 4,
    name: 'Coed',
  },
];

// USTALeague can have season that are Men only, Women only, or Mixed
export default genders;

// Users can be Male or Female but not Mixed
export const userGenders = genders.slice(0, 2);
