import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { MENU_TOGGLE } from '../../constants';
import UnauthenticatedUserMenu from './unauthenticated-user-menu';
import UserMenu from './user-menu';

class SidebarContainer extends React.Component {
  constructor() {
    super();
  }

  render() {
    const sidebarClasses = ['sidebar'];
    if (this.props.menuOpen) {
      sidebarClasses.push('sidebar--open');
    }
    return (
      <div className={sidebarClasses.join(' ')}>
        <button
          onClick={this.props.onMenuToggle}
          type="button"
          className="close"
          aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <div className="sidebar__content">
          <div className="sidebar__nav-section">
            {this.props.currentUser ? (
              <UserMenu
                isAdmin={this.props.isAdmin}
                isPlayer={this.props.isPlayer}
                isTeamCreator={this.props.isTeamCreator}
                isClubSuper={this.props.isClubSuper}
                isVenue={this.props.isVenue}
              />
            ) : (
              <UnauthenticatedUserMenu />
            )}
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  ...state.common,
});
const mapDispatchToProps = dispatch => ({
  onMenuToggle: () => {
    return dispatch({ type: MENU_TOGGLE });
  },
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SidebarContainer)
);
