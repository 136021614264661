import React from 'react';
import { connect } from 'react-redux';
import agent from '../../agent';
import {
  ADMIN_USERS_LOADED,
  ADMIN_USERS_UNLOADED,
  USERS_LIST,
  SET_USER_IN_ROLE,
  IMPERSONATE,
} from '../../constants';
import { debounce } from 'throttle-debounce';
import Checkbox from '../../common/checkbox';
import { Link } from 'react-router-dom';
import ConfirmationDialog from '../../components/UI/ConfirmationDialog';
import { toastr } from 'react-redux-toastr';
import { localTime } from '../../common/services/utils/helpers';

export class UsersContainer extends React.Component {
  state = {
    showConfirmation: false,
    showErr: false,
    errMsg: '',
    sortBy: 'name',
  };
  constructor() {
    super();
    // this.updateUserList = debounce(350, this.updateUserList);
    this.onCheckChange = (user, role) => ev => {
      this.props.setUserRole(user, role, ev.target.checked);
    };
  }

  onImpersonate = user => {
    this.props.onImpersonate(user);
  };
  changeFilter = ev => {
    this.updateUserList(ev.target.value.toLowerCase());
  };

  updateUserList(value) {
    const users = this.state.users;
    const filtered = users.filter(
      u =>
        u.displayName.toLowerCase().includes(value) ||
        u.email.toLowerCase().includes(value)
    );
    this.props.onChangeFilter(filtered);
  }
  sortUsers = sortBy => {
    if (sortBy) {
      this.setState({ sortBy: sortBy });
    }
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    // Set the initial fetch of users to state so we can always filter from the state
    // If we don't keep the original list of users the list will continue to get smaller even after erasing the text in the search bar
    if (!prevState.users && nextProps.users) {
      return { users: nextProps.users };
    }
    return null;
  }

  componentDidMount() {
    this.props.onLoad();
  }

  componentWillUnmount() {
    this.props.onUnload();
  }

  toggleShowError = () => this.setState({ showErr: !this.state.showErr });

  toggleConfirmationDialog = () =>
    this.setState({ showConfirmation: !this.state.showConfirmation });

  handleDeleteUser = userId =>
    agent.Admin.removeUser(userId)
      .then(() => {
        toastr.success('Delete Player', 'This user has been removed.');
        this.props.onLoad();
      })
      .catch(e => {
        if (e.status === 405) {
          this.setState({
            showErr: true,
            errMsg: e.response.body.Error.errors[0].errorMessage,
          });
        } else {
          toastr.error(
            'Delete Player',
            'An error occured while trying to remove this user.'
          );
        }
      });

  getSortedRoster(users, sortBy) {
    if (sortBy === 'name') {
      return []
        .concat(users)
        .sort((a, b) => a.displayName.localeCompare(b.displayName));
    } else {
      let allUsers = users.filter(user => user.created !== undefined);
      let noDate = [];
      for (let user of users) {
        if (!user.created) {
          noDate.push(user);
        }
      }
      allUsers.sort((a, b) => a.created.localeCompare(b.created));

      return allUsers.concat(noDate);
    }
  }
  // TODO-PBF: all the '.name' have been changed to displyName in this file
  render() {
    const { users } = this.props;

    const buttonLabels = { ok: 'Delete Player' };

    const roleHeaders = () => {
      return users[0].roles.map(role => (
        <th key={role.name} className="admin-user-table__role-name">
          {role.name}
        </th>
      ));
    };

    const outputDate = user => {
      if (user.created) {
        return (
          <span className="mt-1">
            {localTime(user.created).format('MM/DD/YY')}
          </span>
        );
      } else {
        return <span className="mt-1">N/A</span>;
      }
    };

    const userRows = users
      ? this.getSortedRoster(users, this.state.sortBy).map(user => (
          <tr key={user.id} className="admin-user-table__user">
            <td>
              <button
                title="Impersonate"
                onClick={() => this.onImpersonate(user)}
                className="btn btn-sm btn-outline-primary">
                <i className="fa fa-chevron-left" />
                <i className="fa fa-user" />
                <i className="fa fa-chevron-right" />
              </button>
            </td>
            <td>
              {user.displayName && user.displayName.length > 0 ? (
                <div>
                  {/*<Link*/}
                  {/*title={user.email}*/}
                  {/*to={`/admin/account/${user.id}/profile`}>*/}
                  {/*{user.displayName}*/}
                  {/*</Link>*/}
                  <div title={user.email}>{user.displayName}</div>
                </div>
              ) : (
                <div>
                  <Link
                    title="No Name"
                    to={`/admin/account/${user.id}/profile`}>
                    {user.email}
                  </Link>
                </div>
              )}
            </td>
            <td>{outputDate(user)}</td>
            {user.roles.map(role => (
              <td
                key={user.id + '-' + role.name}
                className="admin-user-table__user__role">
                <Checkbox
                  checked={role.partOf}
                  onCheckChange={this.onCheckChange(user, role)}
                />
              </td>
            ))}
            <td>
              <button
                title="Delete"
                onClick={e => {
                  e.preventDefault();
                  this.setState({ user: user });
                  this.toggleConfirmationDialog();
                }}
                className="btn btn-sm btn-outline-danger">
                <i className="fa fa-trash" />
              </button>
            </td>
          </tr>
        ))
      : null;
    return (
      <div className="">
        <div className="list-group">
          <div className="list-group-item">
            <div>
              <span className="h3">Users List</span>
              <div className="float-right">
                <input
                  type="search"
                  placeholder="Search"
                  className="form-control"
                  onChange={this.changeFilter}
                />
              </div>
              <div className="float-right">
                <small className="h6 float-right m-0 p-0">
                  Sort By:
                  <button
                    className="btn btn-secondary btn-sm mr-2"
                    onClick={() => {
                      this.sortUsers('name');
                    }}>
                    Name
                  </button>
                  /
                  <button
                    className="btn btn-secondary btn-sm mr-2"
                    onClick={() => {
                      this.sortUsers('dateJoined');
                    }}>
                    Date Joined
                  </button>
                </small>
              </div>
            </div>
          </div>

          {users && users.length ? (
            <table className="table">
              <thead>
                <tr>
                  <th />
                  <th>Name</th>
                  <th>Date Joined</th>
                  {roleHeaders()}
                  <th />
                </tr>
              </thead>
              <tbody>{userRows}</tbody>
            </table>
          ) : (
            <div className="list-group-item">No users</div>
          )}
        </div>
        {this.state.showConfirmation && (
          <ConfirmationDialog
            show={this.state.showConfirmation}
            title="Delete Player"
            buttonLabels={buttonLabels}
            message="Are you sure you want to delete this user?
            This will remove all record of the user, including any
            team participation, any win/loss, and any social play record"
            toggle={this.toggleConfirmationDialog}
            onSubmit={() => {
              this.handleDeleteUser(this.state.user.id);
            }}
          />
        )}
        {this.state.showErr && (
          <ConfirmationDialog
            show={this.state.showErr}
            title="Delete Not Allowed"
            message={this.state.errMsg}
            toggle={this.toggleShowError}
            onSubmit={() => {}}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  ...state.common,
  ...state.admin,
});

const mapDispatchToProps = (dispatch, state) => {
  return {
    onLoad: () => {
      dispatch({
        type: ADMIN_USERS_LOADED,
        payload: agent.Admin.listUsers(state.filter),
      });
    },
    onUnload: () => dispatch({ type: ADMIN_USERS_UNLOADED }),
    onChangeFilter: users =>
      dispatch({
        type: USERS_LIST,
        payload: users,
      }),
    onImpersonate: user =>
      dispatch({
        type: IMPERSONATE,
        payload: agent.Auth.impersonate(user),
      }),
    setUserRole: (user, role, addToRole) =>
      dispatch({
        type: SET_USER_IN_ROLE,
        payload: addToRole
          ? agent.Admin.addUserToRole(user, role)
          : agent.Admin.removeUserFromRole(user, role),
      }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UsersContainer);
