import React, { Component } from 'react';
import MultiToggle from 'react-multi-toggle';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { isNil } from 'lodash';

export default class Toggle extends Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
  };

  state = {
    value: this.props.value == null ? NaN : this.props.value,
    // Null && Undefined check (Just for good measure)
    disabled: isNil(this.props.disabled) ? false : this.props.disabled,
  };

  UNSAFE_componentWillReceiveProps(next) {
    if (this.props.value !== next.value) {
      this.setState({
        value: next.value,
      });
    }
    if (next.hasOwnProperty('disabled')) {
      this.setState({
        disabled: next.disabled,
      });
    }
  }

  handleChange = value => {
    if (value !== this.state.value) {
      this.props.onChange(value);
      this.setState({
        value,
      });
    }
  };

  render() {
    const toggleClassName = classNames(
      this.props.className,
      this.state.disabled && `toggle--soft-no`,
      `toggle-state--${this.state.value}`
    );

    return (
      <MultiToggle
        className={toggleClassName}
        options={this.props.options}
        selectedOption={this.state.value}
        onSelectOption={this.state.disabled ? () => {} : this.handleChange}
      />
    );
  }
}
