import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import bgs from './imgs/images';
import icons from './imgs/icons';
import { MENU_TOGGLE } from '../../constants';

class SubheaderContainer extends React.Component {
  constructor() {
    super();
  }

  render() {
    const bgImg =
      (this.props.headerImage &&
        bgs[this.props.headerImage] &&
        bgs[this.props.headerImage].src) ||
      bgs[0].src;
    return (
      <nav
        className="sub-nav"
        style={{
          backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.5) ), url(${bgImg})`,
        }}>
        <button
          onClick={this.props.onClickMenu}
          className="navbar-toggler"
          type="button"
          aria-label="Toggle navigation">
          <div className="navbar-toggler-icon" />
        </button>
      </nav>
    );
  }
}

const mapStateToProps = state => ({
  ...state.common,
  ...state.route,
});
const mapDispatchToProps = dispatch => ({
  onClickMenu: () => {
    return dispatch({ type: MENU_TOGGLE });
  },
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SubheaderContainer)
);
