const notificationsSettings = [
  {
    id: 0,
    name: 'Email and Push Notifications',
  },
  {
    id: 1,
    name: 'Push Notifications Only',
  },
  {
    id: 2,
    name: 'Email Only',
  },
  {
    id: 3,
    name: 'None',
  },
];

export default notificationsSettings;
