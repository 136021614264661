import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import agent from '../../agent';
import ListErrors from '../../common/list-errors';
import {
  UPDATE_FIELD_AUTH,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_EMAIL_NOT_FOUND,
  FORGOT_PASSWORD_PAGE_UNLOADED,
} from '../../constants';
import { toastr } from 'react-redux-toastr';

export class ForgotPasswordContainer extends React.Component {
  constructor() {
    super();
    this.state = { formSubmitted: false, redirect: null };
    this.changeEmail = ev => this.props.onChangeEmail(ev.target.value);
    this.submitForm = (email, password) => ev => {
      ev.preventDefault();
      this.props
        .onSubmit(email, password)
        .then(() => {
          this.setState({ formSubmitted: true });
        })
        .catch(e => {
          if (e.response && e.response.text) {
            console.log(e.response.statusCode);
            const errResp = JSON.parse(e.response.text);
            const errMsg =
              errResp && errResp['errors'] && errResp['errors'].message;
            toastr.error('Error', errMsg, {
              showCloseButton: true,
              removeOnHover: true,
              progressBar: false,
              timeOut: 30000,
            });
          }
          if (e.response.statusCode === 404) {
            this.props.history.push('/register');
          }
        });
    };
  }

  render() {
    const email = this.props.email || '';
    return (
      <div className="container page">
        <div className="row">
          <div className="col-md-6 offset-md-3 col-xs-12">
            {this.state.formSubmitted ? (
              <div className="alert alert-success">
                You will receive an email with instructions to reset your
                password.
              </div>
            ) : (
              <div>
                <ListErrors errors={this.props.errors} />
                <form onSubmit={this.submitForm(email)}>
                  <fieldset>
                    <fieldset className="form-group">
                      <input
                        className="form-control"
                        type="email"
                        placeholder="Email"
                        value={email}
                        required
                        onChange={this.changeEmail}
                      />
                    </fieldset>

                    <button
                      className="btn btn-primary"
                      type="submit"
                      disabled={this.props.inProgress}>
                      Reset Password
                    </button>
                  </fieldset>
                </form>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  ...state.auth,
  ...state.common,
});

const mapDispatchToProps = dispatch => ({
  onChangeEmail: value =>
    dispatch({ type: UPDATE_FIELD_AUTH, key: 'email', value }),
  onSubmit: email =>
    dispatch({
      type: FORGOT_PASSWORD,
      payload: agent.Auth.forgotPassword(email),
    }),
  onEmailNotFound: email =>
    dispatch({
      type: FORGOT_PASSWORD_EMAIL_NOT_FOUND,
      payload: email,
    }),
  onUnload: () => dispatch({ type: FORGOT_PASSWORD_PAGE_UNLOADED }),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ForgotPasswordContainer)
);
