export const GET_RANKED_LIST_SUCCESS = 'GET_RANKED_LIST_SUCCESS';
export const GET_RANKED_FILTERS_SUCCESS = 'GET_RANKED_FILTERS_SUCCESS';
export const START_LOADING = 'START_LOADING';

export const listTypes = {
  ALL: {
    title: 'All',
    key: 'ALL',
  },
  VENUES: {
    title: 'Venue',
    key: 'VENUES',
  },
  FLIGHTS: {
    title: 'Flight',
    key: 'FLIGHTS',
  },
  LEAGUES: {
    title: 'League',
    key: 'LEAGUES',
  },
};

export const ratingOptions = [
  { value: 'Any', label: 'Any' },
  { value: 2.5, label: '2.5' },
  { value: 3, label: '3' },
  { value: 3.5, label: '3.5' },
  { value: 4, label: '4' },
  { value: 4.5, label: '4.5' },
  { value: 5, label: '5' },
  { value: 5.5, label: '5.5' },
  { value: 6, label: '6' },
  { value: 6.5, label: '6.5' },
  { value: 7, label: '7' },
];

export const genderOptions = [
  { value: 'Any', label: 'Any' },
  { value: 1, label: 'Male' },
  { value: 2, label: 'Female' },
];

export const TABS = {
  SINGLES: {
    title: 'Singles',
    titleFull: 'Singles Ranking',
    key: 'SINGLES',
    sortType: 'eloSingles',
  },
  DOUBLES: {
    title: 'Doubles',
    titleFull: 'Doubles Ranking',
    key: 'DOUBLES',
    sortType: 'eloDoubles',
  },
};
