import React, { Component } from 'react';
import { connect } from 'react-redux';
import CheckRequiredPlayerProps from '../required-player-props';
import Toggle from '../../../components/UI/Toggle';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import agent from '../../../agent';
import { toastr } from 'react-redux-toastr';
import ConfirmationDialog from '../../../components/UI/ConfirmationDialog';
import { localTime } from '../../../common/services/utils/helpers';
import { inviteResponse, eventTypes, eventStatus } from '../../../data/types';

const ADDED_MSG =
  'You are in! When all players have confirmed you will be notified.';
const CONFIRMATION_MSG =
  'This event is confirmed! All players will be notified.';
const REMOVED_MSG = 'You are no longer part of this event.';
const ERR_MSG = `We could not add you to this event at this time. Please try again. If the problem persists, please contact ${
  _clientFeatures.supportURL
}.`;
const TOGGLE_OPTIONS = [
  {
    displayName: <i className="fa fa-times fa-fw fa-lg" />,
    value: false,
  },
  {
    displayName: <i className="fa fa-check fa-fw fa-lg" />,
    value: true,
  },
];

class OpenSocialPlayList extends Component {
  render() {
    const { events, currentUser } = this.props;
    return (
      <div>
        <strong>Open Events</strong>
        {currentUser.player && (
          <CheckRequiredPlayerProps player={currentUser.player} />
        )}
        {events.length ? (
          <SocialPlayEventTable
            onChangeAvailability={this.props.onChangeAvailability}
            events={events}
            currentUser={currentUser}
          />
        ) : (
          <div className="alert alert-info mt-3">No open events yet.</div>
        )}
      </div>
    );
  }
}

class SocialPlayEventTable extends Component {
  static propTypes = {
    onChangeAvailability: PropTypes.func.isRequired,
    events: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        location: PropTypes.string.isRequired,
        dateTime: PropTypes.string.isRequired,
      })
    ).isRequired,
  };
  constructor() {
    super();
    this.state = {
      showConfirmation: false,
    };
  }
  setSocialPlayStatus = (event, status) => {
    let eventId = event._id;
    if (event.creator === this.props.currentUser._id) {
      this.toggleConfirmationDialog();
      this.setState({ curEvent: event });
    } else {
      agent.Events.setAvailability(eventId, { status: status })
        .then(event => {
          if (status === inviteResponse.CONFIRMED) {
            if (event.status === eventStatus.OPEN) {
              toastr.success('SocialPlay', ADDED_MSG);
            } else {
              toastr.success('SocialPlay', CONFIRMATION_MSG);
            }
          } else {
            toastr.info('SocialPlay', REMOVED_MSG);
          }
          this.props.onChangeAvailability();
        })
        .catch(() => {
          toastr.error('SocialPlay', ERR_MSG);
          this.props.onChangeAvailability();
        });
    }
  };
  setPracticeStatus = (event, status) => {
    let eventId = event._id;
    agent.Events.setAvailability(eventId, { status: status })
      .then(() => {
        if (status === inviteResponse.ACCEPT) {
          if (event.isFinalized) {
            toastr.success('Practice', ADDED_MSG);
          } else {
            toastr.success('Practice', CONFIRMATION_MSG);
          }
        } else {
          toastr.info('Practice', REMOVED_MSG);
        }
        this.props.onChangeAvailability();
      })
      .catch(() => {
        toastr.error('Practice', ERR_MSG);
        this.props.onChangeAvailability();
      });
  };
  onChange = (event, available) => {
    if (event.type === eventTypes.SOCIAL) {
      this.setSocialPlayStatus(
        event,
        available ? inviteResponse.CONFIRMED : inviteResponse.DECLINE
      );
    } else {
      this.setPracticeStatus(
        event,
        available ? inviteResponse.ACCEPT : inviteResponse.DECLINE
      );
    }
  };
  disableToggle = event =>
    event.type !== eventTypes.SOCIAL &&
    event.userResponse === inviteResponse.ACCEPT;
  eventDeleteable = event => event.creator === this.props.currentUser._id;
  toggleConfirmationDialog = () =>
    this.setState({ showConfirmation: !this.state.showConfirmation });
  handleSocialEventDelete = eventId => {
    const self = this;
    agent.Events.delete(eventId)
      .then(() => {
        self.props.onChangeAvailability();
      })
      .catch(() => {
        toastr.error(
          'SocialPlay',
          `There was an error removing this Social Play Event. Please try again. If the problem persists, please contact ${
            _clientFeatures.supportURL
          }.`
        );
      });
  };
  cardItem = (event, title = 'Social Play') => {
    const minWidth = { minWidth: '70px' };
    return (
      <div className="row d-flex align-items-center">
        {event.creator === this.props.currentUser._id && event.venueEvent ? (
          <span className="ml-1 ml-sm-3 col-sm-2">
            <i className=" fa fa-lg fa-folder-open text-success" />
            <small className="pl-1">Open</small>
          </span>
        ) : (
          <div className="col-sm-2">
            <span onClick={e => e.preventDefault()}>
              <Toggle
                className={
                  event.userResponse &&
                  event.userResponse !== inviteResponse.ACCEPT &&
                  'toggle--soft-no'
                }
                value={
                  event.userResponse &&
                  (event.userResponse === inviteResponse.CONFIRMED ||
                    event.userResponse === inviteResponse.ACCEPT)
                }
                options={TOGGLE_OPTIONS}
                onChange={available => this.onChange(event, available)}
                disabled={this.disableToggle(event)}
              />
            </span>
          </div>
        )}
        <small className="mr-1 mr-sm-0 mt-1 col-sm-1" style={minWidth}>
          {title}
        </small>
        <small className="mr-1 mr-sm-0 mt-1 col-sm-2" style={minWidth}>
          Players&nbsp;
          {event.accepted}
          {event.maxPlayers != null ? `/${event.maxPlayers}` : ' '}
        </small>
        <span className="mt-sm-0 mt-3 ml-1 ml-sm-0 mr-3 col-sm-5">
          {localTime(event.start).format('ddd, MM/DD/YY h:mma')}
          <small className="ml-1 mr-1">at</small>
          {event.venue}
        </span>
        {this.eventDeleteable(event) ? (
          <div className="col-sm">
            <i
              className="mr-1 mt-1 float-md-right fa fa-trash"
              onClick={e => {
                e.preventDefault();
                this.toggleConfirmationDialog();
                this.setState({ curEvent: event });
              }}
            />
          </div>
        ) : (
          <i className="mr-1 mt-1 fa fa-trash text-white col-sm" /> // Render icon that matches white bg so it keeps layout
        )}
        <span className="float-right mt-3 mt-sm-1">
          <i className="fa fa-chevron-right fa-fw" />
        </span>
      </div>
    );
  };
  cardView = events => {
    return events.map(event => {
      if (event.type === eventTypes.SOCIAL) {
        return (
          <Link
            to={`social-play/${event._id}`}
            key={event._id}
            className={'list-group-item'}>
            {this.cardItem(
              event,
              event.venueEvent && event.creator === this.props.currentUser._id
                ? 'Venue Event'
                : 'Social Play'
            )}
          </Link>
        );
      }
      return event.userResponse === inviteResponse.ACCEPT ? (
        <Link
          to={`teams/${event.team._id}/practices/${event._id}`}
          key={event._id}
          className={'list-group-item'}>
          {this.cardItem(event, 'Practice')}
        </Link>
      ) : (
        <div className={'list-group-item'}>
          {this.cardItem(event, 'Practice')}
        </div>
      );
    });
  };
  render() {
    return (
      <div className="list-group mt-3 mb-3">
        {this.cardView(this.props.events)}
        {this.state.showConfirmation && (
          <ConfirmationDialog
            show={this.state.showConfirmation}
            title="Delete Social Play Event"
            message="Are you sure you want to delete the social play event? Confirmed players will also be removed"
            toggle={this.toggleConfirmationDialog}
            onSubmit={() => {
              this.handleSocialEventDelete(this.state.curEvent._id);
            }}
          />
        )}
      </div>
    );
  }
}

const stateToProps = state => ({
  currentUser: state.common.currentUser,
});

export default connect(stateToProps)(OpenSocialPlayList);
