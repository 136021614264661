import superagent from 'superagent';
import { API_ROOT } from '../../../constants';
// Private token cache
let _token = null;
let _iToken = null;
export const setToken = token => {
  _token = token;
};
export const setImpersonationToken = token => {
  _iToken = token;
};

const config = request => {
  if (_iToken) {
    request.set('authorization', `bearer ${_iToken}`);
  } else if (_token) {
    request.set('authorization', `bearer ${_token}`);
  }
  // TODO Add error handling here
  return request.then(res => res.body);
};

export const del = url => config(superagent.del(`${API_ROOT}${url}`));

export const get = (url, params) =>
  config(superagent.get(`${API_ROOT}${url}`).query(params));

export const put = (url, body) =>
  config(superagent.put(`${API_ROOT}${url}`, body));

export const post = (url, body) =>
  config(superagent.post(`${API_ROOT}${url}`, body));

export const putWithAvatar = (url, fields, files) => {
  let req = superagent.put(`${API_ROOT}${url}`);
  for (var each in fields) {
    if (fields[each]) {
      req.field(each, fields[each]);
    }
  }
  if (files) {
    if (files.length) {
      files.forEach(file => {
        req.attach('asset', file);
      });
    } else {
      req.attach(files.name, files);
    }
  }
  return config(req);
};

export const postWithCSV = (url, fields, files) => {
  let req = superagent.post(`${API_ROOT}${url}`);
  for (var each in fields) {
    if (fields[each]) {
      req.field(each, fields[each]);
    }
  }
  if (files) {
    if (files.length) {
      files.forEach(file => {
        req.attach('file', file);
      });
    } else {
      req.attach(files.name, files);
    }
  }
  return config(req);
};
