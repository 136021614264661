import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export default class ParticipationLabel extends React.Component {
  static defaultProps = {
    small: true,
  };
  static propTypes = {
    status: PropTypes.string,
    className: PropTypes.string,
    small: PropTypes.bool,
  };

  isChecked = status => {
    switch (status) {
      case 'accept':
      case 'confirmed':
        return true;
      default:
        return false;
    }
  };

  render() {
    const { className, status, small } = this.props;
    const label = this.isChecked(status)
      ? ' Yes'
      : status === 'decline'
      ? 'No'
      : 'No Response';
    return (
      <span
        className={classNames(className, {
          'text-secondary': status === 0,
          'text-success': this.isChecked(status),
          'text-warning': status === 2,
          'text-danger': status === 'decline',
        })}>
        {(this.isChecked(status) || status === 'decline') && (
          <i
            className={classNames({
              fa: true,
              'fa-fw': true,
              'fa-check': this.isChecked(status),
              'fa-times': status === 'decline',
            })}
          />
        )}
        {small ? <small>{label}</small> : <span>{label}</span>}
      </span>
    );
  }
}
