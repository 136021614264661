// import Preferences from '../../event/components/friends';
// export default Preferences;
import React from 'react';
import { connect } from 'react-redux';
import { loadVenues, loadSettings } from '../../common/action';
import agent from '../../agent';
import { toastr } from 'react-redux-toastr';
import { VenuesComponent } from './venues.component';
import { Redirect } from "react-router-dom";

const mapStateToProps = state => ({
  ...state.profile,
  ...state.common,
});

const mapDispatchToProps = dispatch => ({
  onLoadVenues: venues => dispatch(loadVenues(venues)),
  onLoadSettings: settings => dispatch(loadSettings(settings)),
});

export class PreferenceContainer extends React.Component {
  componentDidMount() {
    this.props.onLoadVenues(agent.Venues.getList());
  }

  getSettings = () => {
    this.props.onLoadSettings(agent.Users.getUserSettings());
  };

  onAddVenues = venueIds => {
    agent.Users.addVenues(venueIds)
      .then(() => {
        this.getSettings();
      })
      .then(() => {
        toastr.success('Success', 'Venue added.');
      })
      .catch(() => {
        toastr.error('Error', 'Could not add Venue.');
      });
  };

  onRemoveVenue = venueId => {
    agent.Users.removeVenue(venueId)
      .then(() => {
        this.getSettings();
      })
      .then(() => {
        toastr.success('Success', 'Venue removed.');
      })
      .catch(() => {
        toastr.error('Error', 'Could not remove Venue.');
      });
  };

  render() {
    return (
      <div>
        {this.props.settings ? (
          <div>
            <h1>Venue Preferences</h1>
            <VenuesComponent
              venues={this.props.venues}
              settings={this.props.settings}
              onAddVenues={this.onAddVenues}
              onRemoveVenue={this.onRemoveVenue}
              currentUser={this.props.currentUser}
            />
          </div>
        ) : (
          <Redirect to="/account/profile" />
        )}
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PreferenceContainer);
