import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import PropTypes from 'prop-types';

import InputUI from '../../components/UI/Input';
import ListErrors from '../../common/list-errors';
import validation from '../../data/validations';
import { userGenders } from '../../data/genders';
import SelectUI from '../../components/UI/Select/Select';

export class EditMemberModalContainer extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    show: PropTypes.bool.isRequired, // Should be the same state as the one used in the toggle function
    toggle: PropTypes.func.isRequired, // Uses the parents state to control opening/closing of the dialog
    inputRequired: PropTypes.bool,
  };

  getValidator = type => {
    return validation[type];
  };

  constructor(props) {
    super(props);
    this.state = {
      modal: this.props.show,
    };
  }

  handleSubmit = values => {
    let gender = values.gender || 0;
    // It's an object with an id.. we need the id
    if (gender && typeof gender.id === 'number') {
      gender = gender.id;
    }
    return this.props.onSubmit({
      ...values,
      gender,
    });
  };

  render() {
    const { invalid, pristine, submitting, errors, handleSubmit } = this.props;

    return (
      <Modal isOpen={this.state.modal} className={this.props.className}>
        <form onSubmit={handleSubmit(this.handleSubmit)}>
          <ModalHeader toggle={this.props.toggle}>
            {this.props.title}
          </ModalHeader>
          <ModalBody>
            <ListErrors errors={errors} />
            {this.props.message}
            <br />
            <br />
            <div className="col-sm">
              <Field
                name="name.first"
                type="text"
                component={InputUI}
                label="First Name"
              />
            </div>
            <div className="col-sm">
              <Field
                name="name.last"
                type="text"
                component={InputUI}
                label="Last Name"
              />
            </div>
            <div className="col-sm-6">
              <Field
                name="rating"
                type="number"
                min={_clientFeatures.rating.min}
                max="7"
                step="0.5"
                validate={this.getValidator('ustaRating')}
                component={InputUI}
                label={_clientFeatures.rating.label}
              />
            </div>
            <div className="col-sm-6">
              <Field
                name="gender"
                label="Gender"
                valueKey="id"
                labelKey="name"
                options={userGenders}
                component={SelectUI}
                clearable={false}
              />
            </div>
          </ModalBody>
          <ModalFooter>
            <a className="btn btn-link" onClick={this.props.toggle}>
              Cancel
            </a>
            <Button
              disabled={submitting || invalid || pristine}
              color="primary">
              Save
            </Button>
          </ModalFooter>
        </form>
      </Modal>
    );
  }
}

export default reduxForm({ form: 'editMemberForm' })(EditMemberModalContainer);
