export default [
  {}, // Added this so we can just use the id to access the array by ndx without having to do a search. 0 represents None
  { id: 1, name: 'New England' },
  { id: 2, name: 'Eastern' },
  { id: 3, name: 'Middle States' },
  { id: 4, name: 'Mid-Atlantic' },
  { id: 5, name: 'Southern' },
  { id: 6, name: 'Florida' },
  { id: 7, name: 'Caribbean' },
  { id: 8, name: 'Midwest' },
  { id: 9, name: 'Northern' },
  { id: 10, name: 'Missouri Valley' },
  { id: 11, name: 'Texas' },
  { id: 12, name: 'Southwest' },
  { id: 13, name: 'Pacific Northwest' },
  { id: 14, name: 'Northern California' },
  { id: 15, name: 'Southern California' },
  { id: 16, name: 'Hawaii Pacific' },
];
