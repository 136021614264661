import {
  AFTER_EVENT_CREATE,
  BEFORE_EVENT_CREATE,
  GET_EVENT,
  UPDATE_EVENT,
  SET_PLAYER_EVENT_STATUS,
} from './constants';

export function beforeEventCreate(data) {
  return {
    type: BEFORE_EVENT_CREATE,
    payload: data,
  };
}

export function afterEventCreate(data) {
  return {
    type: AFTER_EVENT_CREATE,
    payload: data,
  };
}

export function updateEvent(data) {
  return {
    type: UPDATE_EVENT,
    payload: data,
  };
}

export function getEvent(data) {
  return {
    type: GET_EVENT,
    payload: data,
  };
}

export function setPlayerEventStatus(data) {
  return {
    type: SET_PLAYER_EVENT_STATUS,
    payload: data,
  };
}
