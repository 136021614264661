import React from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { parse } from 'qs';
import {
  CONFIRM_TOKEN,
  CONFIRM_TOKEN_UNLOADED,
  UPDATE_FIELD_AUTH,
} from '../../constants';
import agent from '../../agent';

export class FlightPaymentContainer extends React.Component {
  constructor() {
    super();
  }
  UNSAFE_componentWillMount() {
    this.props.onLoad();
  }

  componentWillUnmount() {
    this.props.onUnload();
  }

  render() {
    let redirect;
    if(this.props && this.props.location) {
      const query = parse(this.props.location.search.substr(1));
      const team = query.team;
      if (team) {
        redirect = `/teams/${team}`;
      }
      else {
        redirect = `/teams/`;
      }
    }

    return (
      <div className="auth-page">
        <div className="container page">
          <div className="row">
            <div className="col-md-6 offset-md-3 col-xs-12" />
          </div>
        </div>
        {this.props.confirmed && <Redirect to={redirect} />}
      </div>
    );
  }
}

const mapStateToProps = state => ({ ...state.auth });
const mapDispatchToProps = (dispatch, state) => {
  return {
    onLoad: () => {
      const query = parse(state.location.search.substr(1));
      const token = query.token;
      const pending = query.pending;
      const team = query.team;
      const match = query.match;
      if (pending) {
        return dispatch({
          type: CONFIRM_TOKEN,
          payload: agent.Auth.confirmToken(token, team),
        });
      }
      if (match) {
        return dispatch({
          type: CONFIRM_TOKEN,
          payload: agent.Auth.confirmToken(token, team, match),
        });
      }
      return dispatch({
        type: UPDATE_FIELD_AUTH,
        key: 'token',
        value: token,
      });
    },
    onUnload: () => dispatch({ type: CONFIRM_TOKEN_UNLOADED }),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FlightPaymentContainer);
