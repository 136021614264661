import {
  LOCATION_CHANGE,
  IMPERSONATE,
  END_IMPERSONATION,
  SET_USER_IN_ROLE,
} from '../constants';
import {
  VENUES_LOAD,
  GET_VENUE_DATA,
  GET_VENUE_LIST,
  GET_VENUE_EVENTS,
  GET_VENUE_MEMBERS,
  GET_VENUE_FOLLOWERS,
  SET_PLAYER_PREFERENCE,
  UPDATE_VENUE_USER_LISTS,
} from './constants';

export default function(state = {}, action) {
  switch (action.type) {
    case IMPERSONATE:
    case END_IMPERSONATION:
      return {};
    case LOCATION_CHANGE:
      if (state.errors || state.redirectTo || state.team) {
        return {
          ...state,
          errors: null,
          redirectTo: null,
        };
      }
      return state;
    case VENUES_LOAD:
      return {
        ...state,
        venues: action.payload,
      };
    case GET_VENUE_DATA:
      return {
        ...state,
        venue: action.payload,
      };
    case GET_VENUE_LIST:
      // Specifically for getting venue options when editing a team.
      // VENUES_LOAD is for the user list of venues, which is different based on the user's role.
      return {
        ...state,
        venuesList: action.payload,
      };
    case GET_VENUE_EVENTS:
      const {
        payload: { current, past },
      } = action;
      return {
        ...state,
        events: current,
        pastEvents: past,
      };
    case GET_VENUE_MEMBERS:
      return {
        ...state,
        members: action.payload,
      };
    case GET_VENUE_FOLLOWERS:
      return {
        ...state,
        followers: action.payload,
      };
    case UPDATE_VENUE_USER_LISTS:
      if (action.error) {
        return {
          ...state,
          errors: action.payload,
        };
      }
      const members = action.payload.members;
      const followers = action.payload.followers;
      return {
        ...state,
        members: members,
        followers: followers,
      };
    case SET_PLAYER_PREFERENCE:
      return {
        ...state,
        team: {
          ...state.team,
          userTeamSettings:
            action.payload.id === state.team.userTeamSettings.id
              ? action.payload
              : state.team.userTeamSettings,
          players: state.team.players.map(player =>
            player.id === action.payload.id ? action.payload : player
          ),
        },
      };
    case SET_USER_IN_ROLE:
      return {
        ...state,
        members:
          state.members &&
          state.members.map(member =>
            member._id === action.payload._id ? action.payload : member
          ),
        errors: action.error ? action.payload : null,
      };
    default:
      return state;
  }
}
