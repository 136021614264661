import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import PropTypes from 'prop-types';

import InputUI from '../../components/UI/Input';
import ListErrors from '../../common/list-errors';
import validation from '../../data/validations';
import Dropzone from 'react-dropzone';

export class ImportMemberModalContainer extends React.Component {
  static propTypes = {
    show: PropTypes.bool.isRequired, // Should be the same state as the one used in the toggle function
    onImportList: PropTypes.func.isRequired,
    toggle: PropTypes.func.isRequired, // Uses the parents state to control opening/closing of the dialog
    inputRequired: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = {
      modal: this.props.show,
    };
  }

  getDisplayValue = value => {
    return value ? value : 'N/A';
  };

  getDisplayGender = value => {
    return value ? (value[0].toLowerCase() === 'f' ? 'Female' : 'Male') : 'N/A';
  };

  render() {
    const { preview } = this.props;
    const showPreview = preview && preview.length > 0;

    const userRows = showPreview
      ? preview.map(user => (
          <tr key={user.email} className="admin-user-table__user">
            <td>
              <div>{this.getDisplayValue(user.email)}</div>
            </td>
            <td>
              <div>{this.getDisplayValue(user.first)}</div>
            </td>
            <td>
              <div>{this.getDisplayValue(user.last)}</div>
            </td>
            <td>
              <div>{this.getDisplayGender(user.gender)}</div>
            </td>
            <td>
              <div>{this.getDisplayValue(user.rating)}</div>
            </td>
          </tr>
        ))
      : null;

    return (
      <Modal isOpen={this.state.modal} className={this.props.className}>
        <ModalHeader toggle={this.props.toggle}>Import Members</ModalHeader>
        <ModalBody>
          {showPreview ? (
            <div>
              Please review the following.
              <br />
              If you need to make any corrections, please submit a new csv file.
              <br />
              <br />
            </div>
          ) : (
            <div>
              Please include the following headers (lowercase):
              <br />
              email, first, last, gender, and rating.
              <br />
              <br />
            </div>
          )}
          <Dropzone
            className="dropzone"
            activeClassName="dropzone--active"
            rejectClassName="dropzone--reject"
            accept={'text/csv'}
            onDrop={this.props.onPreviewList}>
            <div>Drag csv file here, or click to choose file.</div>
          </Dropzone>
          {showPreview && (
            <table className="table">
              <thead>
                <tr>
                  <th>Email</th>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Gender</th>
                  <th>Rating</th>
                </tr>
              </thead>
              <tbody>{userRows}</tbody>
            </table>
          )}
        </ModalBody>
        <ModalFooter>
          <a className="btn btn-link" onClick={this.props.toggle}>
            Cancel
          </a>
          {showPreview && (
            <Button color="primary" onClick={this.props.onImportList}>
              Import
            </Button>
          )}
        </ModalFooter>
      </Modal>
    );
  }
}

export default ImportMemberModalContainer;
