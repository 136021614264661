import {
  GET_FLIGHT_DATA,
  FLIGHT_LOAD,
  GET_FLIGHT_MATCHES,
  GET_LEAGUES_DATA,
  UPDATE_FLIGHT,
} from './constants';
import { END_IMPERSONATION, IMPERSONATE, LOCATION_CHANGE } from '../constants';

export default function(state = {}, action) {
  switch (action.type) {
    case IMPERSONATE:
    case END_IMPERSONATION:
      return {};
    case LOCATION_CHANGE:
      if (state.errors || state.redirectTo || state.team) {
        return {
          ...state,
          errors: null,
          redirectTo: null,
        };
      }
      return state;
    case FLIGHT_LOAD:
      return {
        ...state,
        flights: action.payload,
      };
    case GET_FLIGHT_DATA:
      return {
        ...state,
        flight: action.payload,
      };
    case GET_FLIGHT_MATCHES:
      return {
        ...state,
        matches: action.payload,
      };
    case GET_LEAGUES_DATA:
      return {
        ...state,
        leagues: action.payload,
      };
    case UPDATE_FLIGHT: {
      return {
        ...state,
        errors: action.error ? action.payload : null,
        flights: action.error ? action.payload : null,
        redirectTo: action.error ? null : `/flights/${action.payload.id}`,
      };
    }
    default:
      return state;
  }
}
