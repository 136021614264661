import React from 'react';
import classNames from 'classnames';

const InputUI = props => {
  const {
    label,
    // input props not forwarded by `input`
    type = 'text',
    autoComplete,
    min,
    max,
    step,
    minLength,
    maxLength,
    placeholder,
    required,
    disabled,
    // From redux form
    input,
    meta: { touched, error, warning },
  } = props;

  return (
    <div
      className={classNames('form-group', {
        'is-invalid': touched && error,
      })}>
      <label>{label}</label>
      <input
        {...input}
        disabled={disabled}
        autoComplete={autoComplete}
        type={type}
        min={min}
        max={max}
        minLength={minLength}
        maxLength={maxLength}
        placeholder={placeholder}
        required={required}
        step={step}
        className={classNames('form-control', {
          'is-invalid': touched && error,
        })}
      />
      {touched &&
        ((error && <small className="text-danger my-1">{error}</small>) ||
          (warning && <small className="text-warning my-1 font-weight-bold">{warning}</small>))}
    </div>
  );
};

export default InputUI;
