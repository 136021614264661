import React from 'react';
import { Link } from 'react-router-dom';

export class VenueList extends React.Component {
  render() {
    const { venues } = this.props;
    return (
      <table className="table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Address</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {venues &&
            venues.map((venue, idx) => (
              <tr key={idx}>
                <td>
                  <Link to={`/venues/${venue.id}`}>{venue.name}</Link>
                </td>
                <td>{venue.displayAddress}</td>
                <td>
                  {/*{team.isEditable && (*/}
                  <Link to={`/venues/${venue.id}`} className="pull-right">
                    Edit
                  </Link>
                  {/*)}*/}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    );
  }
}

export default VenueList;
