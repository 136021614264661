import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

class RequiredPlayerProps extends Component {
  static propTypes = {
    player: PropTypes.shape({
      ustaRating: PropTypes.number,
      gender: PropTypes.number,
    }).isRequired,
  };

  render() {
    const { ustaRating, gender } = this.props.player;
    if (ustaRating && gender) {
      return null;
    }

    return (
      <div className="alert alert-warning my-3" role="alert">
        <h4>Missing required profile information</h4>
        <p>The following fields are required for Social Play:</p>
        <ul className="list-unstyled">
          {!ustaRating && (
            <li>
              <strong>{_clientFeatures.rating.label}</strong>
              <p>* Used to pair players with similar ratings</p>
            </li>
          )}
          {!gender && (
            <li>
              <strong>Gender</strong>
              <p>
                * Used for placing players in Singles, Doubles, or Mixed matches
              </p>
            </li>
          )}
        </ul>
        <hr />
        Go to your{' '}
        <Link className="alert-link" to="/account/profile">
          Profile
        </Link>{' '}
        to update these settings
      </div>
    );
  }
}

export default RequiredPlayerProps;
