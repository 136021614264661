import React from 'react';
import { Field, getFormValues, reduxForm } from 'redux-form';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import PropTypes from 'prop-types';

import InputUI from '../../../components/UI/Input';
import ListErrors from '../../../common/list-errors';
import validation from '../../../data/validations';
import Select from '../../../components/UI/Select/Select';
import { compose } from 'redux';
import { connect } from 'react-redux';

export class SocialPlayModalContainer extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    show: PropTypes.bool.isRequired, // Should be the same state as the one used in the toggle function
    toggle: PropTypes.func.isRequired, // Uses the parents state to control opening/closing of the dialog
    inputRequired: PropTypes.bool,
    replacement: PropTypes.bool.isRequired, // Determines if this dialog is trying to add or replace a player.
    validatedEmails: PropTypes.array,
    inviteList: PropTypes.array, // A List of user's who can be invited to this event.
    warningMsg: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = {
      modal: this.props.show,
    };
  }

  validateEmail = value => {
    const { inputRequired } = this.props;
    if (inputRequired && !value) {
      return 'invalid';
    }
    if (value) {
      const email = value.email ? value.email : value;
      return validation.email(email);
    }
  };

  provideWarning = value => {
    const { validatedEmails, warningMsg } = this.props;
    if (value && validatedEmails && validatedEmails.length > 0) {
      const selectedEmail = value.email ? value.email : value;
      if (!validatedEmails.find(email => email === selectedEmail)) {
        return warningMsg
          ? warningMsg
          : 'This email does not match any in our records';
      }
    }
    return null;
  };

  onSubmit = values => {
    const email =
      values.playerEmail && values.playerEmail.email
        ? values.playerEmail.email
        : values.playerEmail;
    this.props.onSubmit({
      ...values,
      playerEmail: email,
    });
  };

  render() {
    const {
      replacement,
      inputRequired,
      venueEvent,
      invalid,
      submitting,
      errors,
      handleSubmit,
      inviteList,
    } = this.props;

    return (
      <Modal isOpen={this.state.modal} className={this.props.className}>
        <form onSubmit={handleSubmit(this.onSubmit)}>
          <ModalHeader toggle={this.props.toggle}>
            {this.props.title}
          </ModalHeader>
          <ModalBody>
            <ListErrors errors={errors} />
            {this.props.message}
            <br />
            <br />
            <Field
              name="playerEmail"
              validate={[this.validateEmail]}
              warn={[this.provideWarning]}
              component={Select}
              label={`${replacement ? 'Replacement' : 'Add'} ${
                venueEvent ? 'Member' : 'Player'
              }'s Email Address or Select by Name ${
                inputRequired ? '(required)' : '(optional)'
              }`}
              options={inviteList}
              labelKey="displayName"
              valueKey="email"
              placeholder={`${replacement ? 'Replace' : 'Add'} ${
                venueEvent ? 'Member' : 'Player'
              }...`}
              multi={false}
              creatable={true}
            />
            {/*{inviteList && inviteList.length > 0 && (*/}
            {/*  <div>*/}
            {/*    <Field*/}
            {/*      name="playerEmail"*/}
            {/*      label={inviteMessage ? inviteMessage : 'Add User'}*/}
            {/*      component={Select}*/}
            {/*      options={inviteList}*/}
            {/*      labelKey="displayName"*/}
            {/*      valueKey="email"*/}
            {/*      multi={false}*/}
            {/*      creatable={true}*/}
            {/*    />*/}
            {/*  </div>*/}
            {/*)}*/}
            <div>
              <br />
              <strong>Note:</strong>
              &nbsp;
              {this.props.notes ? (
                <small>{this.props.note}</small>
              ) : this.props.replacement ? (
                <small>
                  Make sure you have checked with the replacement that they are
                  available. Suggesting a replacement here will automatically
                  confirm them to this Social Play Event.
                </small>
              ) : (
                <small>
                  Make sure you have checked with the new player that they are
                  available. Adding a player here will automatically confirm
                  them to this Social Play Event.
                </small>
              )}
            </div>
          </ModalBody>
          <ModalFooter>
            <a className="btn btn-link" onClick={this.props.toggle}>
              Cancel
            </a>
            <Button disabled={submitting || invalid} color="primary">
              Ok
            </Button>
          </ModalFooter>
        </form>
      </Modal>
    );
  }
}
const socialPlayFormSelector = getFormValues('socialPlayForm');
export default compose(
  connect(state => ({
    formValues: socialPlayFormSelector(state),
  })),
  reduxForm({
    form: 'socialPlayForm',
  })
)(SocialPlayModalContainer);
