import React from 'react';
import { connect } from 'react-redux';
import { Link, Route } from 'react-router-dom';
import agent from '../../agent';
import {
  ADD_FLIGHT_REQUEST,
  UPDATE_FLIGHT_REQUEST,
  ADMIN_CURRENT_LEAGUES_LOADED,
  ADMIN_FLIGHTS_LOADED,
  ADMIN_FLIGHTS_UNLOADED,
} from '../../constants';
import NewFlightModal from './new-flight-modal';
import ConfirmationDialog from '../../components/UI/ConfirmationDialog';
import { toastr } from 'react-redux-toastr';

export class FlightsContainer extends React.Component {
  state = {
    flights: undefined, // undefined so modals default parameter will be triggered
    showConfirmation: false,
  };

  formSubmit = values => {
    const newFlight = {
      id: values._id,
      name: values.name,
      league: values.league.id,
      teams: values.teams,
      manual: !values.manual,
      allowDuplicates: !values.manual ? values.allowDuplicates : false,
    };
    this.props.onFlightCreate(newFlight);
  };

  getFlightForEdit = flightId => {
    agent.Flights.getFlight(flightId)
      .then(flightData => {
        const flight = {
          ...flightData,
        };
        this.setState({ flight });
      })
      .catch(e => {
        if (e.response && e.response.text) {
          const errResp = JSON.parse(e.response.text);
          const errMsg =
            errResp && errResp['errors'] && errResp['errors'].message;
          toastr.error('Team Error', errMsg, {
            showCloseButton: true,
            removeOnHover: true,
            progressBar: false,
            timeOut: 30000,
          });
        }
      });
  };

  toggleConfirmationDialog = () =>
    this.setState({ showConfirmation: !this.state.showConfirmation });

  getLeaguesForFlights = () => {
    const { currentLeagues } = this.props;
    if (currentLeagues) {
      return currentLeagues.filter(league => league.flight);
    }
    return [];
  };

  handleDeleteFlight = flightId => {
    agent.Flights.deleteFlight(flightId)
      .then(() => {
        toastr.success('Delete Flight', 'This flight has been removed.');
        this.props.onLoad();
      })
      .catch(() =>
        toastr.error(
          'Delete Flight',
          'An error occurred while trying to remove this flight.'
        )
      );
  };

  renderFlights(flights) {
    return (
      <table className="table">
        <thead>
          <tr>
            <th>Name</th>
            <th>League</th>
            <th>Teams</th>
            <th>Matches</th>
            <th>Edit</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {flights.map((flight, idx) => {
            return (
              <tr key={idx}>
                <td>
                  <Link to={`/flights/${flight.id}`}>{flight.name}</Link>
                </td>
                <td>{flight.league.name}</td>
                <td>{flight.teams.length}</td>
                <td>{flight.matches.length}</td>
                <td>
                  <span onClick={() => this.getFlightForEdit(flight.id)}>
                    <Link to={`${this.props.match.url}/${flight.id}`}>
                      <i className="fa fa-pencil fa-fw" />
                    </Link>
                  </span>
                  {/* <i className="fa text-danger fa-trash fa-fw" /> */}
                </td>
                <td>
                  <button
                    title="Delete"
                    onClick={e => {
                      e.preventDefault();
                      this.setState({ flight: flight.id });
                      this.toggleConfirmationDialog();
                    }}
                    className="btn btn-sm btn-outline-danger">
                    <i className="fa fa-trash" />
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }

  UNSAFE_componentWillMount() {
    this.props.onLoad();
    agent.Teams.getAll({ current: true }).then(teams => {
      this.setState({ currentTeams: teams });
    });
  }

  render() {
    return (
      <div className="flights">
        <div className="list-group">
          <div className="list-group-item">
            <h3>
              Flights List
              <div
                className="float-right"
                onClick={() => {
                  this.setState({ flight: undefined });
                }}>
                <Link
                  className="btn btn-primary"
                  to={`${this.props.match.url}/new`}>
                  <i className="fa-plus fa fa-fw" /> Add
                </Link>
              </div>
            </h3>
          </div>
          {this.props.flights && this.props.flights.length > 0 ? (
            this.renderFlights(this.props.flights)
          ) : (
            <div className="list-group-item">No Flights.</div>
          )}
        </div>
        <Route
          strict
          exact
          path={`${this.props.match.path}/:id`}
          render={() => (
            <NewFlightModal
              errors={this.props.errors}
              leagues={this.getLeaguesForFlights()}
              flight={this.state.flight}
              currentTeams={this.state.currentTeams}
              onSubmit={values => this.formSubmit(values)}
            />
          )}
        />
        {this.state.showConfirmation && (
          <ConfirmationDialog
            show={this.state.showConfirmation}
            title="Delete Flight"
            buttonLabels={{ ok: 'Delete Flight' }}
            message="Are you sure you want to delete this flight?
            This will remove all records of the flight, including any
            matches and standings"
            toggle={this.toggleConfirmationDialog}
            onSubmit={() => {
              this.handleDeleteFlight(this.state.flight);
            }}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  ...state.common,
  ...state.admin,
});

const mapDispatchToProps = dispatch => {
  return {
    onLoad: () => {
      dispatch({
        type: ADMIN_FLIGHTS_LOADED,
        payload: agent.Admin.listFlights(),
      });
      dispatch({
        type: ADMIN_CURRENT_LEAGUES_LOADED,
        payload: agent.Leagues.getCurrent(),
      });
    },
    onUnload: () => {
      dispatch({
        type: ADMIN_FLIGHTS_UNLOADED,
      });
    },
    onFlightCreate: flight => {
      dispatch({
        type: flight.id ? UPDATE_FLIGHT_REQUEST : ADD_FLIGHT_REQUEST,
        payload: flight.id
          ? agent.Flights.update(flight.id, flight).catch(e => {
              if (e.response && e.response.text) {
                const errResp = JSON.parse(e.response.text);
                const errMsg =
                  errResp && errResp['errors'] && errResp['errors'].message;
                toastr.error('Team Error', errMsg, {
                  showCloseButton: true,
                  removeOnHover: true,
                  progressBar: false,
                  timeOut: 30000,
                });
              }
            })
          : agent.Flights.add(flight).catch(e => {
              if (e.response && e.response.text) {
                const errResp = JSON.parse(e.response.text);
                const errMsg =
                  errResp && errResp['errors'] && errResp['errors'].message;
                toastr.error('Team Error', errMsg, {
                  showCloseButton: true,
                  removeOnHover: true,
                  progressBar: false,
                  timeOut: 30000,
                });
              }
            }),
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FlightsContainer);
