import Select from './Select';
import _find from 'lodash/find';
import _filter from 'lodash/filter';
import _reduce from 'lodash/reduce';
import _get from 'lodash/get';

/**
 * Check if props contains a boolean attribute. Returns true if props
 * contains the attr and the value is null | undefined, or the value is
 * explicitly set to `true`
 * @param {{}} props
 * @param {string} prop
 * @return {boolean}
 */
function hasBooleanProp(props, prop) {
  return props[prop] === true || (prop in props && props[prop] == null);
}

/**
 * Get an option or array of options with a `valueKey` property (bit)
 * that is "on" in the given value (bitmask)
 * @param {number?} value the bitmask
 * @param {{ [valueKey: string]: number }[]} options
 * @param {boolean} multi
 * @param {string} valueKey
 */
function valueToOptions(value, options, multi, valueKey) {
  return value && typeof value === 'number'
    ? multi
      ? // Mutli selects take an array of options and as long as
        // the bit is in the bitmask we consider it a match
        _filter(options, option => _get(option, valueKey) & value)
      : // "Single" selects don't like arrays. Also, if the intent is to
        // select a single item then we don't need to do the bitmask check
        // as it doesn't make sense to have more than one bit "on",
        // just check for strict equality
        _find(options, option => _get(option, valueKey) === value)
    : value;
}

/**
 * Creates a bitmask from the selected value, or values, by summing
 * the values of the `valueKey` property
 * @param {{ [valueKey: string]: number }|{ [valueKey: string]: number }[]} value
 * @param {string} valueKey
 * @return {number|*}
 */
function reduceSelection(value, valueKey) {
  return Array.isArray(value)
    ? _reduce(value, (n, item) => n + _get(item, valueKey), 0)
    : _get(value, valueKey, value);
}

export const MaskedSelect = props => {
  const {
    input: { value, onChange, onBlur },
    valueKey = 'value',
    options,
  } = props;

  const multi = hasBooleanProp(props, 'multi');

  const input = Object.assign({}, props.input, {
    value: valueToOptions(value, options, multi, valueKey),
    onBlur(value, ...rest) {
      return onBlur(reduceSelection(value, valueKey), ...rest);
    },
    onChange(value, ...rest) {
      return onChange(reduceSelection(value, valueKey), ...rest);
    },
  });

  return Select({
    ...props,
    input,
  });
};

export default MaskedSelect;
