import React from 'react';
import { connect } from 'react-redux';
import agent from '../agent';
import Loader from '../components/UI/Loader';
import VenueDetail from './components/venue-detail.component';
import { loadVenueEvents, setPlayerPreferences } from './action';
import { PLAYER } from '../data/team-member-type';

class VenueDetailContainer extends React.Component {
  state = {
    showSyncConfirmation: false,
    showPreviewConfirmation: false,
  };

  componentDidMount() {
    this.props.onVenueEventsLoad(this.props.match.params.id);
  }

  setEventStatus(eventId, status) {
    const user = this.props.currentUser;
    if (user && eventId) {
      // TODO: setEventAvailablity
    }
  }

  isCurrentPlayerCaptainOrCoCaptain(team) {
    return team.userTeamSettings.type > PLAYER && team.isEditable;
  }

  toggleSyncConfirmationDialog = () =>
    this.setState({ showSyncConfirmation: !this.state.showSyncConfirmation });

  togglePreviewConfirmationDialog = () =>
    this.setState({
      showPreviewConfirmation: !this.state.showPreviewConfirmation,
    });

  render() {
    const {
      team,
      roles,
      match,
      venue,
      events,
      pastEvents,
      currentUser,
    } = this.props;
    const isCaptain = team
      ? this.isCurrentPlayerCaptainOrCoCaptain(team) ||
        roles.includes('Captain')
      : false;
    const isAdmin = roles.includes('Admin');
    const isVenue = roles.includes('Venue');
    const renderVenueDetail =
      match.params.id && venue && match.params.id === venue.id;
    return (
      <div>
        {renderVenueDetail ? (
          <VenueDetail
            isCaptain={isCaptain}
            isAdmin={isAdmin}
            isVenue={isVenue}
            match={match}
            editPage={`${match.url}/edit`}
            sync={() => this.toggleSyncConfirmationDialog(team.id)}
            preview={() => this.togglePreviewConfirmationDialog(team.id)}
            venue={venue}
            events={events ? events : ''}
            pastEvents={pastEvents ? pastEvents : ''}
            eventStatus={(eventId, status) => this.setEventStatus(...arguments)}
            user={currentUser}
            // onPlayerPreferenceChange={this.handlePlayerPreferenceChange}
            onChange={this.getVenueData}
          />
        ) : (
          <Loader />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  ...state.venues,
  ...state.common,
});

const mapDispatchToProps = dispatch => ({
  onVenueEventsLoad: id => dispatch(loadVenueEvents(id)),
  onSetPlayerPreference: events => dispatch(setPlayerPreferences(events)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VenueDetailContainer);
