import React from 'react';
import { connect } from 'react-redux';
import { withRouter, Switch, Route } from 'react-router-dom';
import AuthRoute from '../common/auth-route';
import Venues from './venue-list';
import Venue from './venue-detail';
import VenueDashboard from './components/dashboard/dashboard';
import VenueDirectory from './components/venue-directory';
import VenueMembersIndex from './components/index';
import VenueTeams from './components/venue-team-list';
import VenueConnect from '../venues/components/connect/connect';
import NewEvent from './events/event-create';
import Event from './events/event-detail';
import Challenge from '../challenge/index';
import { MainTitleContainer } from './components/shared/main-title/index';
import SocialPlayEvent from '../social-play/components/event/event';

import ROLES from '../data/roles';

export class VenuesContainer extends React.Component {
  get isAdmin() {
    return this.hasRole('Admin') || this.props.impersonator;
  }
  get isPlayer() {
    return this.hasRole('Player');
  }

  get isTeamCreator() {
    return this.hasRole('Captain');
  }

  hasRole(roleName) {
    return this.props.roles ? this.props.roles.indexOf(roleName) >= 0 : false;
  }

  render() {
    const { location, venue } = this.props;
    const adminRoles = [ROLES.VENUE, ROLES.ADMINISTRATOR];
    const routes = [
      <AuthRoute
        roles={adminRoles}
        exact
        path="/venues/:id/:eventType/new"
        component={NewEvent}
      />,
      <Route
        exact
        path="/venues/:id/social/:eventId"
        component={SocialPlayEvent}
      />,
      <Route path="/venues/:id/challenge/:eventId" component={Challenge} />,
      <Route path="/venues/:id/:eventType/:eventId" component={Event} />,
      <AuthRoute
        roles={adminRoles}
        path="/venues/:id/events"
        component={Venue}
      />,
      <AuthRoute
        roles={adminRoles}
        strict
        path="/venues/:id/communicate"
        component={VenueConnect}
      />,
      <AuthRoute
        roles={adminRoles}
        strict
        path="/venues/:id/directory"
        component={VenueDirectory}
      />,
      <AuthRoute
        roles={adminRoles}
        strict
        path="/venues/:id/members"
        component={VenueMembersIndex}
      />,
      <AuthRoute
        roles={adminRoles}
        strict
        path="/venues/:id/teams"
        component={VenueTeams}
      />,
      <AuthRoute
        roles={adminRoles}
        strict
        path="/venues/:id"
        component={VenueDashboard}
      />,
      <AuthRoute roles={adminRoles} path="/venues" component={Venues} />,
    ];

    return (
      <div>
        <MainTitleContainer selectedVenue={venue} location={location} />
        <Switch>
          {routes.map(
            (route, index) =>
              route &&
              React.cloneElement(route, {
                key: route.props.path || index,
              })
          )}
        </Switch>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  ...state.common,
  ...state.venues,
});

const mapDispatchToProps = () => {
  return {
    onLoad: () => {},
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(VenuesContainer)
);
