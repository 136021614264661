import {
  LOCATION_CHANGE,
  IMPERSONATE,
  END_IMPERSONATION,
} from '../../constants';
import {
  BEFORE_PRACTICE_CREATE,
  AFTER_PRACTICE_CREATE,
  GET_PRACTICE_BY_SLUG,
  UPDATE_PRACTICE,
  SET_PLAYER_PRACTICE_STATUS,
} from './constants';

export default function(state = {}, action) {
  switch (action.type) {
    case IMPERSONATE:
    case END_IMPERSONATION:
      return {};
    case LOCATION_CHANGE:
      if (state.errors || state.redirectTo || state.practice) {
        return {
          ...state,
          errors: null,
          redirectTo: null,
          practice: null,
        };
      }
      return state;
    case UPDATE_PRACTICE:
      return {
        ...state,
        practice: action.payload,
      };
    case BEFORE_PRACTICE_CREATE:
      return {
        ...state,
        teams: action.payload,
      };
    case AFTER_PRACTICE_CREATE:
      return {
        ...state,
        errors: action.error ? action.payload : null,
        redirectTo: action.error ? null : `/teams/${action.payload.team.slug}`,
      };
    case GET_PRACTICE_BY_SLUG:
      return {
        ...state,
        practice: action.payload,
      };
    case SET_PLAYER_PRACTICE_STATUS:
      return {
        ...state,
        errors: action.error ? action.payload : null,
        practice: action.error ? state.practice : action.payload,
      };
    default:
      return state;
  }
}
