import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import SelectUI from '../../components/UI/Select';
import InputUI from '../../components/UI/Input';
import ButtonUI from '../../components/UI/Button';
import ListErrors from '../../common/list-errors';
import validation from '../../data/validations';
import { userGenders } from '../../data/genders';
import notificationsSettings from '../../data/notifications';
import { Prompt } from 'react-router-dom';

const CURRENT_YEAR = new Date().getFullYear();
const MANY_YEARS_AGO = CURRENT_YEAR - 100;

const birthYearValidators = [
  validation.minValue(MANY_YEARS_AGO),
  validation.maxValue(CURRENT_YEAR),
];

const zipMin = validation.minLength(5);
const zipMax = validation.maxLength(10); // zip+dash+plusFour

class UserPreferenceForm extends Component {
  getValidator = type => {
    if (this.props.impersonated) {
      return () => undefined;
    }
    return validation[type];
  };

  getNotificationsList() {
    return notificationsSettings;
  }

  handleSubmit = values => {
    let gender = values.gender || 0;
    let notifications = values.notifications || 0;
    // It's an object with an id.. we need the id
    if (gender && typeof gender.id === 'number') {
      gender = gender.id;
    }
    if (notifications && typeof notifications.id === 'number') {
      notifications = notifications.id;
    }
    return this.props.onSubmit({
      ...values,
      gender,
      notifications,
    });
  };
  render() {
    const {
      errors,
      handleSubmit,
      submitting,
      pristine,
      buttonText,
    } = this.props;

    return (
      <div className="row">
        <div className="col-sm">
          <ListErrors errors={errors} />
          <form onSubmit={handleSubmit(this.handleSubmit)}>
            {/* Name aliases used for scraping */}
            {this.props.impersonated && (
              <div>
                <h5>
                  Please fill out the USTA names exactly as seen on the USTA
                  website:{' '}
                </h5>
                <div className="row">
                  <div className="col-sm">
                    <Field
                      name="name.ustaFirst"
                      type="text"
                      component={InputUI}
                      label="USTA First Name"
                    />
                  </div>
                  <div className="col-sm">
                    <Field
                      name="name.ustaLast"
                      type="text"
                      component={InputUI}
                      label="USTA Last Name"
                    />
                  </div>
                </div>
                <br />
              </div>
            )}
            <div className="row">
              <div className="col-sm">
                <Field
                  name="name.first"
                  type="text"
                  component={InputUI}
                  label="First Name"
                />
              </div>
              <div className="col-sm">
                <Field
                  name="name.last"
                  type="text"
                  component={InputUI}
                  label="Last Name"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm">
                <Field
                  name="phone"
                  type="tel"
                  minLength="10"
                  maxLength="12"
                  placeholder="eg. 555-555-5555"
                  component={InputUI}
                  label="Cell Phone Number"
                  validate={[
                    this.getValidator('required'),
                    this.getValidator('phone'),
                  ]}
                />
              </div>
              <div className="col-sm">
                <Field
                  label="Birth Year"
                  name="birthYear"
                  type="number"
                  max={CURRENT_YEAR}
                  min={this.props.impersonated ? '0' : MANY_YEARS_AGO}
                  placeholder="eg. 1986"
                  validate={
                    this.props.impersonated
                      ? this.getValidator()
                      : birthYearValidators
                  }
                  component={InputUI}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6">
                <Field
                  name="ustaId"
                  type="number"
                  component={InputUI}
                  label="USTA Id Number"
                  validate={this.getValidator('number')}
                />
              </div>
              <div className="col-sm-6">
                <Field
                  name="rating"
                  type="number"
                  min={this.props.impersonated ? '0' : _clientFeatures.rating.min} // dont enforce a min if impersonating
                  max="7"
                  step="0.5"
                  validate={this.getValidator('ustaRating')}
                  component={InputUI}
                  label={_clientFeatures.rating.label}
                />
              </div>
              <div className="col-sm-6">
                <Field
                  name="gender"
                  label="Gender"
                  valueKey="id"
                  labelKey="name"
                  options={userGenders}
                  component={SelectUI}
                  clearable={false}
                />
              </div>
              <div className="col-sm-6">
                <Field
                  name="notifications"
                  label="Notifications"
                  valueKey="id"
                  labelKey="name"
                  options={this.getNotificationsList()}
                  component={SelectUI}
                  clearable={false}
                />
              </div>
              <div className="col-sm-6">
                <Field
                  name="postal"
                  autoComplete="postal-code"
                  component={InputUI}
                  validate={[zipMin, zipMax]}
                  label="Your Zip Code"
                />
              </div>
            </div>
            <div className="clearfix">
              <Field
                disabled={pristine || submitting}
                text={buttonText}
                component={ButtonUI}
                name="userPreferenceSubmit"
              />
            </div>
          </form>
        </div>
        <Prompt
          when={!pristine}
          message="You have unsaved changes. Are you sure you want to leave?"
        />
      </div>
    );
  }
}

export default reduxForm({
  form: 'userPreferenceForm',
  enableReinitialize: true,
})(UserPreferenceForm);
