import {
  get,
  post,
  put,
  del,
  postWithCSV,
  putWithAvatar,
} from './common/services/requests/requests';
import { toastr } from 'react-redux-toastr';
const STAY_OPEN = { timeOut: 0 };
const CONFLICT = 409;

const Admin = {
  // users
  listUsers: filter =>
    get('/users', {
      q: filter,
    }),
  removeUser: id => del(`/users/${id}`),
  addUserToRole: (user, role) => post(`/users/${user._id}/role/${role.name}`),
  removeUserFromRole: (user, role) =>
    del(`/users/${user._id}/role/${role.name}`),
  removeVenue: id => del(`/venues/${id}`),
  // leagues
  listLeagues: () => get('/leagues'),
  // flights
  listFlights: () => get('/flights'),
  // seasons
  listSeasons: () => get('/seasons'),
  // teams
  listTeams: (id, filter) =>
    id
      ? get(`/teams/leagues/${id}`, {
          q: filter,
        })
      : get(`/teams`),
  // venues
  listVenues: query => get('/venues', query),
  listFilterVenues: query => get('/venues', query),
  venueAction: (token, type) => post('/venues/action', { token, type }),
};

const Seasons = {
  listSeasons: Admin.listSeasons,
  listCurrentSeasons: () => get('/seasons/current'),
  getSeason: id => get(`/seasons/${id}`),
  update: (id, season) => put(`/seasons/${id}`, season),
  add: season => post('/seasons', season),
};

const Leagues = {
  getList: Admin.listLeagues,
  getCurrent: () => get('/leagues/current'),
  getLeague: id => get(`/leagues/${id}`),
  update: (id, league) => put(`/leagues/${id}`, league),
  add: league => post('/leagues', league),
};

const Flights = {
  getList: () => Admin.listFlights,
  getFlight: id => get(`/flights/${id}`),
  deleteFlight: id => del(`/flights/${id}`),
  update: (id, flight) => put(`/flights/${id}`, flight),
  add: flight => post('/flights', flight),
  getFlightMatches: id => get(`/flights/${id}/matches`),
  createFlightMatches: id => post(`/flights/${id}/createMatches`),
  deleteFlightMatches: id => post(`/flights/${id}/matches/deleteMatches`),
  createFlightMatch: (id, body) => post(`/flights/${id}/create`, body),
  swapMatches: (id, date1, date2) =>
    post(`/flights/${id}/matches/swapMatches?date1=${date1}&date2=${date2}`),
};

const Venues = {
  getList: query => Admin.listVenues(query),
  getVenue: id => get(`/venues/${id}`),
  getEvents: (id, query) => get(`/venues/${id}/events`, query),
  getMembers: id => get(`/venues/${id}/members`),
  getFollowers: id => get(`/venues/${id}/followers`),
  filterMembers: (id, body) => put(`/venues/${id}/members`, body),
  updateMemberStatus: (venueId, userId, body) =>
    put(`/venues/${venueId}/members/${userId}`, body),
  updateMemberProfile: (venueId, userId, body) =>
    put(`/venues/${venueId}/members/${userId}/profile`, body),
  addMember: (venueId, body) => post(`/venues/${venueId}/members`, body),
  removeMember: (venueId, userId) =>
    del(`/venues/${venueId}/members/${userId}`),
  update: (id, venue) => put(`/venues/${id}`, venue),
  add: venue => post(`/venues`, venue),
  importList: (venueId, type, csv) =>
    postWithCSV(`/venues/${venueId}/members/import?type=${type}`, {}, csv),
};

const Events = {
  getCurrent: query => get('/events/current', query),
  getCurrentByType: type => get(`/events/current?types=${type}`),
  get: (id, query) => get(`/events/${id}`, query),
  delete: id => del(`/events/${id}`),
  add: event => post('/events', event),
  sendReminder: id => post(`/events/${id}/reminder`),
  update: (id, event) => put(`/events/${id}`, event),
  setAvailability: (id, body) => post(`/events/${id}/availability`, body),
  updateGame: (matchid, gameid, body) =>
    put(`/matches/${matchid}/games/${gameid}`, body),
  updateScorecard: (matchid, body) => put(`/matches/${matchid}/games`, body),
  addAlternate: (matchid, body) =>
    post(`/matches/${matchid}/games/alternate`, body),
  removeGame: (matchid, gameid) => del(`/matches/${matchid}/games/${gameid}`),
  confirmScore: (flightId, matchId, teamId) =>
    put(`/flights/${flightId}/matches/${matchId}/${teamId}/confirmScores`),
  resendInvitations: id => put(`/events/${id}/resendInvitations`),
};

const Challenge = {
  add: body => post('/challenge', body),
  get: id => get(`/challenge/${id}`),
  update: (id, body) => put(`/challenge/${id}`, body),
  updateGame: (id, gameId, body) =>
    put(`/challenge/${id}/games/${gameId}`, body),
  confirmScores: id => post(`/challenge/${id}/confirmScores`),
  delete: id => del(`/challenge/${id}`),
};

const Invites = {
  add: body => post(`/invites`, body),
  remove: id => del(`/invites/${id}`),
};

const Auth = {
  current: () => get('/users/self'),
  getUser: userId => get(`/Users/${userId}`),
  display: id => null, // TODO-PBF: get(`/Users/${id}/display`),
  login: (email, password) => post('/users/login', { email, password }),
  user_token: tokenId => get(`/Account/token/${tokenId}`),
  register: body => post('/users', body),
  impersonate: user => post(`/users/impersonate/${user.id}`),
  forgotPassword: email => post('/users/forgot-password', { email }),
  resetPassword: (token, password, confirmPassword) =>
    post('/users/password-reset', {
      token,
      password,
      confirmPassword,
    }),
  updatePassword: (updatePasswordViewModel, userId) =>
    userId
      ? post(`/Account/${userId}/update-password`, updatePasswordViewModel)
      : post('/Account/update-password', updatePasswordViewModel),
  preFilledUser: token => get(`/users/pre-filled?token=${token}`),
  confirmEmail: (token, playerObj) =>
    post('/users/confirm-email', { token, playerObj }),
  confirmToken: (token, team, match) =>
    post('/users/confirm-token', { token, team, match }),
  resendConfirmEmail: email => get(`/Account/confirm-email?email=${email}`),
  eventResponse: (token, resp) => post('/events/response', { token, resp }),
};

const Clubs = {
  getClubMembers: clubId => get(`/clubs/${clubId}/players`),
  getClubTeams: clubId => get(`/clubs/${clubId}/teams`),
  getAll: () => get('/clubs'),
  inviteSocialUsers: (id, emails) =>
    post(`/clubs/${id}/socialPlayUserInvite`, emails),
  removeSlots: (clubId, matchId) =>
    post('/clubs/' + clubId + '/schedule/slots/' + matchId + '/cancel'),
};

const Users = {
  getUserProfile: playerId => get(`/users/profile/${playerId}`),
  uploadAvatar: file => putWithAvatar('/users/self/avatar', {}, file),
  updateInfo: user => put(`/users/self`, user),
  getSettings: playerId => get(`/users/${playerId}/settings`), // Not sure this is required
  getUserSettings: () => get('/users/settings'),
  updateSettings: settings => put(`/users/settings`, settings),
  setVenueAdminSettings: settings => put(`/users/settings/venue`, settings),
  getFriendGroups: () => get('/users/settings/groups'),
  getFriendGroup: group => get(`/users/settings/groups/${group}`),
  addGroup: body => post('/users/settings/groups', body),
  addFriendToGroup: body => put('/users/settings/groups', body),
  removeGroup: group => del(`/users/settings/groups/${group}`),
  removeFriendFromGroup: (group, friendId) =>
    del(`/users/settings/groups/${group}/friends/${friendId}`),
  getAllMembers: query => get(`/users/settings/venue/members`, query),
  addVenues: body => post(`/users/settings/venues`, body),
  removeVenue: venue => del(`/users/settings/venues/${venue}`),
  getRankedList: body => post(`/users/elo/rankedList`, body),
  getRankedListByVenue: (id, body) => post(`/users/elo/rankedList/venue/${id}`, body),
  getRankedListByLeague: (id, body) => post(`/users/elo/rankedList/league/${id}`, body),
  getRankedListByFlight: (id, body) => post(`/users/elo/rankedList/flight/${id}`, body),
};

const Mail = {
  send: body => post('/mail', body),
};

const Teams = {
  getAll: query => get(`/teams`, query),
  getTeamsByLeague: leagueId => get(`/teams/leagues/${leagueId}`),
  getTeam: teamId => get(`/teams/${teamId}`),
  getTeamMatches: teamId => get(`/teams/${teamId}/matches`),
  getTeamPractices: teamId => get(`/teams/${teamId}/practices`),
  requestScrape: (teamId, update) =>
    post(`/teams/${teamId}/scrape?type=${update}`),
  updateTeam: (teamId, body) => put(`/teams/${teamId}/updateAll`, body),
  updateTeamPlayer: (teamId, playerId, body) =>
    put(`/teams/${teamId}/players/${playerId}`, body),
  add: team => post('/teams', team),
  deleteTeam: teamId => del(`/teams/${teamId}`),
  requestTeam: body => post('/teams/request', body),
};
const Practice = {
  addPractice: body => post(`/practices`, body),
  getInviteableTeams: practiceId => get(`/practices/${practiceId}/teams`),
  update: (practiceId, body) => put(`/practices/${practiceId}`, body),
  fillPractice: (practiceId, body) =>
    post(`/practices/${practiceId}/fill`, body),
};

const Matches = {
  notify: (id, body) => put(`/matches/${id}/notify`, body),
  addMatch: body => post(`/matches`, body),
  requestAvail: (id, body) => put(`/matches/${id}/requestAvail`, body),
};

const Payments = {
  getToken: body => post(`/payments/token`, body),
  transaction: body => post(`/payments/transaction`, body),
};

export default {
  Admin,
  Auth,
  Clubs,
  Events,
  Leagues,
  Flights,
  Mail,
  Practice,
  Seasons,
  SocialPlay: Events,
  Teams,
  Users,
  Matches,
  Venues,
  Invites,
  Payments,
  Challenge,
};
