import {
  TEAM_LOAD,
  BEFORE_TEAM_CREATE,
  GET_TEAM_DATA,
  GET_TEAM_MATCHES,
  GET_TEAM_PRACTICES,
  GET_PLAYER_TEAM_DATA,
  GET_LEAGUES_DATA,
  UPDATE_TEAM,
  ADD_TEAM_REQUEST,
  UPDATE_TEAM_PLAYER,
  SET_PLAYER_PREFERENCE,
  TEAM_REQUESTED,
  GET_SEASONS_DATA,
  GET_SEASON_DATA,
  GET_VENUES_DATA,
} from './constants';

export function teamLoad(data, context) {
  const type = context ? context : TEAM_LOAD;
  return {
    type: type,
    payload: data,
    context: context,
  };
}

export function afterTeamRequest(data) {
  return {
    type: TEAM_REQUESTED,
    payload: data,
  };
}

export function addTeamRequest(data) {
  return {
    type: ADD_TEAM_REQUEST,
    payload: data,
  };
}

export function newTeamRequest(data) {
  return {
    type: BEFORE_TEAM_CREATE,
    payload: data,
  };
}

export function loadSeasonsData(data) {
  return {
    type: GET_SEASONS_DATA,
    payload: data,
  };
}

export function loadSeasonData(data) {
  return {
    type: GET_SEASON_DATA,
    payload: data,
  };
}

export function loadVenuesData(data) {
  return {
    type: GET_VENUES_DATA,
    payload: data,
  };
}

export function loadLeaguesData(data) {
  return {
    type: GET_LEAGUES_DATA,
    payload: data,
  };
}

export function loadPlayerTeamData(data) {
  return {
    type: GET_PLAYER_TEAM_DATA,
    payload: data,
  };
}

export function loadTeamData(data) {
  return {
    type: GET_TEAM_DATA,
    payload: data,
  };
}

export function loadTeamMatches(data) {
  return {
    type: GET_TEAM_MATCHES,
    payload: data,
  };
}

export function loadTeamPractices(data) {
  return {
    type: GET_TEAM_PRACTICES,
    payload: data,
  };
}

export function updateTeamRequest(data) {
  return {
    type: UPDATE_TEAM,
    payload: data,
  };
}

export function updateTeamPlayer(data) {
  return {
    type: UPDATE_TEAM_PLAYER,
    payload: data,
  };
}

export function setPlayerPreferences(data) {
  return {
    type: SET_PLAYER_PREFERENCE,
    payload: data,
  };
}
