import {
  LOAD_FRIENDS_GROUPS,
  LOAD_FRIENDS_GROUP,
  LOAD_VENUES_LIST,
  LOAD_SETTINGS,
  LOAD_ALL_VENUE_MEMBERS,
  LOAD_MEMBERS,
} from '../constants';

export function loadGroups(data) {
  return {
    type: LOAD_FRIENDS_GROUPS,
    payload: data,
  };
}

export function loadGroup(data) {
  return {
    type: LOAD_FRIENDS_GROUP,
    payload: data,
  };
}

export function loadAllMembers(data) {
  return {
    type: LOAD_ALL_VENUE_MEMBERS,
    payload: data,
  };
}

export function loadVenues(data) {
  return {
    type: LOAD_VENUES_LIST,
    payload: data,
  };
}

export function loadSettings(data) {
  return {
    type: LOAD_SETTINGS,
    payload: data,
  };
}

export function loadMembers(data) {
  return {
    type: LOAD_MEMBERS,
    payload: data,
  };
}
