import React from 'react';

export const GET_EVENT = 'GET_EVENT';
export const UPDATE_EVENT = 'UPDATE_EVENT';
export const UPDATE_GAME = 'UPDATE_GAME';
export const CONFIRM_SCORES = 'CONFIRM_SCORES';
export const SET_PLAYER_EVENT_STATUS = 'SET_PLAYER_EVENT_STATUS';

export const WIN_LOSS = [
  {
    id: '1',
    value: 'Home',
  },
  {
    id: '2',
    value: 'Away',
  },
];

export const CONF_TOGGLE = [
  {
    displayName: <i className="fa fa-check fa-fw fa-lg" />,
    value: 'confirmed',
  },
  {
    displayName: <i className="fa fa-times fa-fw fa-lg" />,
    value: 'decline',
  },
];
