import React from 'react';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import VenueConnect from './connect-form';
import { toastr } from 'react-redux-toastr';
import agent from '../../../agent';

const COMPONENT_TITLE = 'Venue Communication';

export class VenueConnectContainer extends React.Component {
  state = {
    selClubId: null,
    redirectTo: null,
  };
  cleanUp = [];

  UNSAFE_componentWillMount() {
    this.props.onLoad();
  }

  componentWillUnmount() {
    this.cleanUp.forEach(sub => sub.unsubscribe());
    this.cleanUp.length = 0;
  }

  onSubmit(values) {
    const body = {
      bcc: values.bcc,
      subject: values.txtSubject,
      body: values.txtBody,
    };
    agent.Mail.send(body)
      .then(() => {
        toastr.success(COMPONENT_TITLE, 'Sent email');
        this.setState({ redirectTo: `/venues/${this.props.match.params.id}` });
      })
      .catch(() => {
        toastr.error(COMPONENT_TITLE, 'Error sending email');
      });
  }

  render() {
    const { venue } = this.props;
    return (
      <div className="venue_connect">
        {this.state.redirectTo ? <Redirect to={this.state.redirectTo} /> : null}
        <VenueConnect
          venue={venue}
          onSubmit={values => this.onSubmit(values)}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  ...state.common,
  ...state.venues,
});

const mapDispatchToProps = dispatch => ({
  onLoad: () =>
    dispatch({
      type: 'CLUBS_HEADER_LOAD',
      payload: COMPONENT_TITLE,
    }),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(VenueConnectContainer)
);
