import React from 'react';
import { Redirect } from 'react-router-dom';
import {Field, getFormValues, reduxForm} from 'redux-form';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';

import InputUI from '../../components/UI/Input';
import DropdownUI from '../../components/UI/Select';
import TextAreaUI from '../../components/UI/TextArea';

import ListErrors from '../../common/list-errors';
import validation from '../../data/validations';
import usStates from '../../data/us-states';
import { connect } from 'react-redux';
import { compose } from 'redux';
import TimePickerUI from '../../components/UI/DateTimePicker';

const durationMin = validation.minValue(30);
const durationMax = validation.maxValue(300);

const zipMin = validation.minLength(5);
const zipMax = validation.maxLength(10); // zip+dash+plusFour

const VENUE_DEFAULT = {
  id: null,
  address: {
    country: null,
    locality: null,
    postal: null,
    region: null,
    street: null,
  },
  address2: null,
  city: null,
  state: { abbreviation: null },
  zip: null,
  matchDurationMinutes: null,
  name: null,
  description: null,
  managerEmail: null,
  public: null,
};

export class NewVenueModalContainer extends React.Component {
  state = {
    modal: true,
    paying: false,
  };

  static defaultProps = {
    venue: VENUE_DEFAULT,
  };

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  onModalClose = () => {
    this.setState({ redirect: true });
  };

  onSubmit = values => {
    values.id = this.props.venue && this.props.venue.id;
    if (!values.paying) {
      values.upgrades = {
        social_play: false,
      };
      values.paymentEnd = undefined;
    }
    this.props.onSubmit(values);
    this.setState({ redirect: true });
  };
  // I changed it to be a dropdown that can create new options. It used to just be a string. So now we need to validate each individual email
  emailValidators = values => {
    if (values) {
      for (let val of values) {
        const invalid = validation.email(val.email);
        if (invalid) {
          return invalid;
        }
      }
    }
    return undefined;
  };

  render() {
    const {
      errors,
      invalid,
      submitting,
      pristine,
      handleSubmit,
      venue = VENUE_DEFAULT,
      formValues,
    } = this.props;
    const isEdit = venue.id !== null;
    const payingVenue = formValues && formValues.paying;

    return this.state.redirect ? (
      <Redirect to="." />
    ) : (
      <Modal
        isOpen={this.state.modal}
        onClosed={this.onModalClose}
        className={this.props.className}>
        <form onSubmit={handleSubmit(this.onSubmit)}>
          <ModalHeader toggle={this.toggle}>
            {isEdit ? 'Edit' : 'Create New'} Venue
          </ModalHeader>
          <ModalBody>
            <ListErrors errors={errors} />

            <Field
              name="name"
              component={InputUI}
              validate={[validation.required]}
              label="Name"
            />
            <Field
              name="description"
              component={TextAreaUI}
              label="Description"
            />
            <Field
              name="address.street"
              component={InputUI}
              validate={[validation.required]}
              label="Address"
            />
            {/*<Field*/}
            {/*name="address2"*/}
            {/*component={InputUI}*/}
            {/*label="Address Line 2 (optional)"*/}
            {/*/>*/}
            <Field
              name="address.locality"
              component={InputUI}
              validate={[validation.required]}
              label="City"
            />
            <Field
              name="state"
              component={DropdownUI}
              validate={[validation.required]}
              label="State"
              options={usStates}
              labelKey={isEdit ? 'abbreviation' : 'name'}
              valueKey="abbreviation"
            />

            <Field
              name="address.postal"
              autoComplete="postal-code"
              component={InputUI}
              validate={[validation.required, zipMin, zipMax]}
              label="Zip"
            />
            <Field
              name="matchDuration"
              type="number"
              component={InputUI}
              validate={[validation.required, durationMin, durationMax]}
              label="Match Duration (minutes)"
            />
            {isEdit && (
              <Field
                name="timeZone"
                type="Time Zone"
                component={InputUI}
                label="Time Zone"
              />
            )}
            <Field
              name="admins"
              label="Add Manager"
              component={DropdownUI}
              labelKey="email"
              valueKey="email"
              multi={true}
              creatable={true}
              validate={[this.emailValidators]}
            />
            <div className="form-check">
              <label className="form-check-label">
                <Field
                  type="checkbox"
                  name="public"
                  component={field => (
                    <input
                      {...field.input}
                      type="checkbox"
                      className="form-check-input"
                    />
                  )}
                />
                Public
              </label>
            </div>
            <div className="form-check">
              <label className="form-check-label">
                <Field
                  type="checkbox"
                  name="paying"
                  component={field => (
                    <input
                      {...field.input}
                      type="checkbox"
                      className="form-check-input"
                    />
                  )}
                />
                Paying Venue
              </label>
            </div>
            {payingVenue && (
              <div>
                <div className="form-check">
                  <label className="form-check-label">
                    <Field
                      type="checkbox"
                      name="upgrades.social_play"
                      component={field => (
                        <input
                          {...field.input}
                          type="checkbox"
                          className="form-check-input"
                        />
                      )}
                    />
                    Add Socialite Role For Members
                  </label>
                </div>
                <Field
                  name="paymentEnd"
                  showTime={false}
                  showDate={true}
                  component={TimePickerUI}
                  label="Payment Ends On:"
                />
              </div>
            )}
            {/*<div className="form-check">
              <label className="form-check-label">
                <Field
                  type="checkbox"
                  name="upgrades.captain"
                  component={field => (
                    <input
                      {...field.input}
                      type="checkbox"
                      className="form-check-input"
                    />
                  )}
                />
                Captain
              </label>
            </div>*/}
          </ModalBody>
          <ModalFooter>
            <a className="btn btn-link" onClick={this.toggle}>
              Cancel
            </a>
            <Button
              type="submit"
              disabled={submitting || invalid || (!isEdit && pristine)}
              color="primary">
              {isEdit ? 'Update' : 'Create'} Venue
            </Button>
          </ModalFooter>
        </form>
      </Modal>
    );
  }
}

const venueFormSelector = getFormValues('newVenueForm');
export default compose(
  connect((state, props) => ({
    initialValues: {
      ...props.venue,
      public: props.venue ? props.venue.public : true,
    },
    formValues: venueFormSelector(state),
  })),
  reduxForm({
    form: 'newVenueForm',
    enableReinitialize: true,
  })
)(NewVenueModalContainer);
