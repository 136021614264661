export const TEAM_LOAD = 'TEAM_LOAD';
export const TEAM_PAST_LOAD = 'TEAM_PAST_LOAD';
export const VENUE_TEAM_LOAD = 'VENUE_TEAM_LOAD';
export const VENUE_TEAM_PAST_LOAD = 'VENUE_TEAM_PAST_LOAD';
export const ADD_TEAM_REQUEST = 'ADD_TEAM_REQUEST';
export const GET_LEAGUES_DATA = 'GET_LEAGUES_DATA';
export const GET_TEAM_DATA = 'GET_TEAM_DATA';
export const GET_TEAM_MATCHES = 'GET_TEAM_MATCHES';
export const GET_TEAM_PRACTICES = 'GET_TEAM_PRACTICES';
export const GET_PLAYER_TEAM_DATA = 'GET_PLAYER_TEAM_DATA';
export const UPDATE_TEAM = 'UPDATE_TEAM';
export const BEFORE_TEAM_CREATE = 'BEFORE_TEAM_CREATE';
export const GET_SEASONS_DATA = 'GET_SEASONS_DATA';
export const GET_SEASON_DATA = 'GET_SEASON_DATA';
export const GET_VENUES_DATA = 'GET_VENUES_DATA';
export const TEAM_REQUESTED = 'TEAM_REQUESTED';
export const UPDATE_TEAM_PLAYER = 'UPDATE_TEAM_PLAYER';
export const SET_PLAYER_PREFERENCE = 'SET_PLAYER_PREFERENCE';
export const CREATE_FLIGHT_MATCH = 'CREATE_FLIGHT_MATCH';
