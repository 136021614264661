import React from 'react';
import classNames from 'classnames';
import AddFriendModal from './add-friend-modal';
import AddGroupModal from './add-group-modal';
import ConfirmationDialog from '../../components/UI/ConfirmationDialog';

const DEFAULT_GROUP = 'All Friends';

export class FriendsComponent extends React.Component {
  state = {
    showAddGroup: false,
    showRemoveGroup: false,
    showAddFriend: false,
    showRemoveFriend: false,
    selectedUser: '',
    currentGroup: DEFAULT_GROUP,
  };

  handleGroupChange = value => {
    this.setState({
      currentGroup: value,
    });
    this.props.onGetGroup(value);
  };

  getSortedUsers = friends => {
    return [].concat(friends).sort((a, b) => {
      return a.displayName.localeCompare(b.displayName);
    });
  };

  toggleAddGroupDialog = () => {
    this.setState({
      showAddGroup: !this.state.showAddGroup,
    });
  };

  toggleRemoveGroupDialog = () => {
    this.setState({
      showRemoveGroup: !this.state.showRemoveGroup,
    });
  };

  toggleAddFriendDialog = user => {
    this.setState({
      showAddFriend: !this.state.showAddFriend,
      selectedUser: user,
    });
  };

  toggleRemoveFriendDialog = user => {
    this.setState({
      showRemoveFriend: !this.state.showRemoveFriend,
      selectedUser: user,
    });
  };

  setCurrentGroupToDefault = () => {
    this.setState({
      currentGroup: DEFAULT_GROUP,
    });
  };

  render() {
    const {
      showAddGroup,
      showRemoveGroup,
      showAddFriend,
      showRemoveFriend,
      selectedUser,
      currentGroup,
    } = this.state;
    const { groups, friends, membersList } = this.props;

    const groupHeaders = () => {
      if (groups && groups.length) {
        return groups.map(group => (
          <button
            onClick={() => this.handleGroupChange(group.name)}
            className={classNames({
              'btn btn-primary': true,
              'active focus': this.state.currentGroup === group.name,
            })}>
            {group.name}
          </button>
        ));
      }
    };

    const friendRows = friends
      ? this.getSortedUsers(friends).map(friend => (
          <tr key={friend._id} className="admin-user-table__user">
            <td>
              <div>
                <div title={friend.email}>{friend.displayName}</div>
              </div>
            </td>
            <td>
              <div>{friend.email}</div>
            </td>
            <td>
              <div className="float-right">
                <button
                  title="Remove"
                  onClick={e => {
                    e.preventDefault();
                    this.toggleRemoveFriendDialog(friend);
                  }}
                  className="btn btn-sm btn-outline-danger">
                  <i className="fa fa-trash" />
                </button>
              </div>
            </td>
          </tr>
        ))
      : null;

    return (
      <div className="">
        <h1>
          Friends Preferences
          <div className="float-md-right">
            <button
              title="Add Circle"
              onClick={e => {
                e.preventDefault();
                this.toggleAddGroupDialog();
              }}
              className="btn btn-sm btn-outline-primary mr-3">
              <i className="fa fa-plus fa-fw" />
              Add Circle
            </button>
          </div>
        </h1>
        <div className="list-group">
          <div className="lineup-header">
            <div>
              <small className="text-muted mr-2">Friend Circles</small>
              <div className="btn-group btn-group-sm" role="group">
                {groupHeaders()}
              </div>
            </div>
            <div className="float-md-right">
              {currentGroup !== DEFAULT_GROUP && (
                <button
                  title="Remove Group"
                  onClick={e => {
                    e.preventDefault();
                    this.toggleRemoveGroupDialog(currentGroup);
                  }}
                  className="btn btn-sm btn-outline-danger">
                  <i className="fa fa-trash" />
                </button>
              )}
              <button
                title="Add Friend"
                onClick={e => {
                  e.preventDefault();
                  this.toggleAddFriendDialog();
                }}
                className="btn btn-sm btn-outline-primary ml-3">
                <i className="fa fa-plus fa-fw" />
                Add Friend
              </button>
            </div>
          </div>
        </div>
        {friends && friends.length ? (
          <table className="table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th />
              </tr>
            </thead>
            <tbody>{friendRows}</tbody>
          </table>
        ) : (
          <div className="list-group-item">No Friends</div>
        )}
        {showAddGroup && (
          <AddGroupModal
            title={'Add Circle'}
            message={'Add a new group (circle) to add pl ayers to.'}
            show={showAddGroup}
            toggle={this.toggleAddGroupDialog}
            onSubmit={values => {
              this.toggleAddGroupDialog();
              this.props.onAddGroup({
                name: values.name,
              });
              this.setCurrentGroupToDefault();
            }}
          />
        )}
        {showRemoveGroup && (
          <ConfirmationDialog
            show={showRemoveGroup}
            title={'Remove Group'}
            buttonLabels={{ ok: 'Remove' }}
            message={`Are you sure you want to remove the group: '${currentGroup}'?\nYour friends will remain in 'All Friends'`}
            toggle={this.toggleRemoveGroupDialog}
            onSubmit={() => {
              this.props
                .onRemoveGroup({
                  name: currentGroup,
                })
                .then(() => {
                  this.setCurrentGroupToDefault();
                });
            }}
          />
        )}
        {showAddFriend && (
          <AddFriendModal
            title={'Add Friend'}
            message={`Add friend to the group '${currentGroup}'.`}
            show={showAddFriend}
            toggle={this.toggleAddFriendDialog}
            inputRequired={true}
            membersList={membersList}
            onSubmit={values => {
              this.toggleAddFriendDialog();
              this.props.onAddFriend({
                friends: values.emails,
                groups: [currentGroup],
              });
            }}
          />
        )}
        {showRemoveFriend && (
          <ConfirmationDialog
            show={showRemoveFriend}
            title={'Remove Friend'}
            buttonLabels={{ ok: 'Remove' }}
            message={`Are you sure you want to remove ${
              selectedUser.displayName
            } from the group: '${currentGroup}'?`}
            toggle={this.toggleRemoveFriendDialog}
            onSubmit={() => {
              this.props.onRemoveFriend({
                group: currentGroup,
                id: selectedUser._id,
              });
            }}
          />
        )}
      </div>
    );
  }
}
