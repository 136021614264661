import React from 'react';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import UserPreferenceForm from './user-preference-form';
import Dropzone from 'react-dropzone';
import agent from '../../agent';
import {
  UPDATE_PLAYER_INFO,
  UPLOAD_AVATAR,
  LOAD_SETTINGS,
  UPDATE_USER_PROFILE,
  UPDATE_USER,
} from '../../constants';
import { Redirect } from 'react-router-dom';
// NOTE: this component will update the profile of a passed in user. If a user is not passed in, it will default back to the currently logged in user

const maxFileSize = 1000 * 1000;
const mapStateToProps = (state, props) => {
  // If no user was passed in as props use the currently logged in user
  const user = props.user ? props.user : state.common.currentUser;
  const userSettings = props.settings ? props.settings : state.common.settings;
  return {
    isImpersonating: !!state.common.impersonator,
    player: user, // TODO: change this to user in the reducer
    settings: userSettings,
    title: props.user ? `${user.displayName}'s Profile` : 'My Profile',
    redirectTo: props.user ? '' : '/',
    updateType: props.user ? UPDATE_USER_PROFILE : UPDATE_PLAYER_INFO,
  };
};

const mapDispatchToProps = dispatch => ({
  onAvatarUp(file) {
    return dispatch({
      type: UPLOAD_AVATAR,
      payload: agent.Users.uploadAvatar(file),
    });
  },
  updatePlayerInfo(player, dispatchType) {
    return dispatch({
      type: dispatchType,
      payload: agent.Users.updateInfo(player),
    });
  },
  updatePlayerSettings(player) {
    if (player.notifications !== undefined) {
      const notifications = player.notifications;
      const appPrefs = {
        notifications: notifications === 0 || notifications === 1,
        email: notifications === 0 || notifications === 2,
      };
      agent.Users.updateSettings({ appPrefs }).then(results => {
        return dispatch({
          type: LOAD_SETTINGS,
          payload: results,
        });
      });
    }
  },
  updateCurrentPlayer() {
    agent.Auth.current().then(results => {
      return dispatch({
        type: UPDATE_USER,
        payload: results,
      });
    });
  },
});

export class ProfileContainer extends React.Component {
  state = {
    redirectTo: null,
    imageError: false,
  };
  handleSubmit = player => {
    return this.props
      .updatePlayerInfo(player, this.props.updateType)
      .then(this.props.updatePlayerSettings(player))
      .then(this.props.updateCurrentPlayer())
      .then(this.onAfterUpdate);
  };
  onAvatarUp = (drop, reject) => {
    if (drop.length === 1) {
      return this.props.onAvatarUp(drop).then(this.onAfterUpdate);
    }
    if (reject && reject.length > 0) {
      if (reject[0].size > maxFileSize) {
        return toastr.error(
          'Profile',
          'Please select an Avatar image smaller than 1MB'
        );
      }
    }
    return toastr.error(
      'Profile',
      'There was an error uploading your avatar. Please try again.'
    );
  };
  onAfterUpdate = () => {
    toastr.success(
      'Profile',
      'Your profile information has been updated successfully'
    );
    this.props.updateCurrentPlayer();
  };
  getInitialValues = () => {
    if (this.props.settings) {
      const appPrefs = this.props.settings.appPrefs;
      // Note: None = 3, Email Only = 2, Push Only = 1, Both = 0
      let notifications = 3;
      if (appPrefs.notifications) {
        if (appPrefs.email) {
          notifications = 0;
        } else {
          notifications = 1;
        }
      } else if (appPrefs.email) {
        notifications = 2;
      }
      const values = {
        ...this.props.player,
        notifications: notifications,
      };
      return values;
    }
    return this.props.player;
  };
  onError = () => {
    this.setState({
      imageError: true
    });
  };
  render() {
    const { imageError } = this.state;
    return this.state.redirectTo ? (
      <Redirect to={this.state.redirectTo} />
    ) : (
      <div>
        <h1>{this.props.title}</h1>

        <Dropzone
          className="dropzone"
          activeClassName="dropzone--active"
          rejectClassName="dropzone--reject"
          accept={'image/*'}
          maxSize={maxFileSize}
          onDrop={this.onAvatarUp}>
          {this.props.player.avatar && !imageError && (
            <img className="avatar-image" src={this.props.player.avatar} onError = {this.onError} />
          )}
          <div>Drag new avatar image here, or click to choose file.</div>
        </Dropzone>
        <UserPreferenceForm
          impersonated={this.props.isImpersonating}
          initialValues={this.getInitialValues()}
          onSubmit={this.handleSubmit}
          buttonText="Update Preferences"
        />
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileContainer);
