import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

const AuthRoute = ({
  roles,
  role,
  userRoles,
  isAuthenticated,
  component: Component,
  ...rest
}) => {
  let isAuthorizedForRoute;
  if (isAuthenticated) {
    // List of allowed roles, acts as an OR
    if (roles) {
      isAuthorizedForRoute = userRoles
        ? userRoles.some(role => roles.includes(role))
        : false;
    } else {
      isAuthorizedForRoute = userRoles ? userRoles.indexOf(role) !== -1 : false;
    }
  }

  return (
    <Route
      {...rest}
      render={props =>
        isAuthorizedForRoute ? (
          <Component {...props} />
        ) : isAuthenticated ? (
          <Redirect to="/" />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: {
                from: props.location,
              },
            }}
          />
        )
      }
    />
  );
};

const mapStateToProps = state => ({
  userRoles: state.common.roles,
  isAuthenticated: !!state.common.token,
});

export default connect(mapStateToProps)(AuthRoute);
