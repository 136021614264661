import {
  START_LOADING,
  GET_RANKED_LIST_SUCCESS,
  GET_RANKED_FILTERS_SUCCESS,
} from '../../constants';

export default function(state = {}, action) {
  const { payload, type } = action;
  switch (type) {
    case START_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_RANKED_LIST_SUCCESS: {
      const { users } = payload;
      return {
        ...state,
        rankedList: users,
        loading: false,
      };
    }
    case GET_RANKED_FILTERS_SUCCESS: {
      const { venues, flights, leagues } = payload;
      return {
        ...state,
        venues,
        flights,
        leagues,
      };
    }
    default:
      return state;
  }
}
