import React from 'react';
import { Field, getFormValues, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import ButtonUI from '../../../components/UI/Button/index';
import { toastr } from 'react-redux-toastr';
import ListErrors from '../../../common/list-errors/index';
import { eventTypes, eventTypesArray } from '../../../data/types';
import { MaskedSelect } from '../../../components/UI/Select';
import {
  getRatingSum,
  playTypes,
} from '../../../social-play/service/social-play';
import _memoize from 'lodash/memoize';
import validators from '../../../data/validations';
import { SINGLES } from '../../../data/play-type';
import { compose } from 'redux';
import VenueEventFields from '../../../components/Events/Venue';
import { ChallengeEventFields } from '../../../components/Events/Challenge';

const isRequired = _memoize(message => value => {
  return validators.required(value, message);
});

class AddEventForm extends React.Component {
  state = {
    type: '',
    playType: SINGLES,
    filterByRating: false,
  };

  componentDidMount() {
    const rating = this.props.currentUser && this.props.currentUser.rating;
    this.setState({ rating: rating });
  }

  onSubmit = values => {
    const isSocial = this.state.type === eventTypes.SOCIAL;
    if (isSocial) {
      let playTypes = {
        mixed: false,
        singles: false,
        doubles: false,
      };
      playTypes[values.playType] = true;
      values['playTypes'] = playTypes;
      if (values.playType === playTypes.MIXED) {
        values['maxRating'] = values.combinedRating;
      } else {
        this.getRating(values);
      }
    } else if (this.state.filterByRating) {
      this.getRating(values);
    }
    if (values.startTime && values.eventDate) {
      return this.props.handleSubmit();
    } else {
      toastr.error('Team Practices', 'Please Select Date, Time and Team');
    }
  };

  getRating = values => {
    const rating = this.state.rating;
    values['maxRating'] = rating;
    if (values.halfOver) {
      values['maxRating'] = getRatingSum(rating, 0.5);
      values['minRating'] = rating;
    }
    if (values.halfUnder) {
      values['minRating'] = getRatingSum(rating, -0.5);
    }
  };

  getEventName = type => {
    const result = eventTypesArray.find(e => e.id === type);
    return result ? result.name : '';
  };

  render() {
    const {
      errors,
      handleSubmit,
      submitting,
      pristine,
      invalid,
      formValues,
      groups,
      members,
    } = this.props;
    const { type } = this.state;
    const venueEvents = eventTypesArray.filter(event =>
      event.display.includes('venue')
    );

    const isChallengeType = !!(type && type === eventTypes.CHALLENGE);

    return (
      <div className="row justify-content-center">
        <div className="col-md-6">
          {type ? (
            <div>
              <h4 className="text-secondary d-flex justify-content-center">
                New {this.getEventName(type)} Event
              </h4>
              <hr />
            </div>
          ) : (
            <div>
              <h4 className="text-secondary d-flex justify-content-center">
                New Event
              </h4>
              <hr />
            </div>
          )}
          <ListErrors errors={errors} />
          <form onSubmit={handleSubmit(this.onSubmit)}>
            <Field
              name="type"
              label="Event Type"
              component={MaskedSelect}
              options={venueEvents}
              validate={[isRequired('Event type is required')]}
              valueKey="id"
              labelKey="name"
              placeholder="Select Event Type..."
              onChange={(_, type) => {
                this.type = type;
                this.setState({
                  type: type,
                });
              }}
            />
            {isChallengeType && <ChallengeEventFields playerOpts={members} />}
            {type && !isChallengeType && (
              <VenueEventFields
                formValues={formValues}
                type={type}
                groups={groups}
              />
            )}
            <div className="clearfix">
              <Field
                disabled={pristine || invalid || submitting}
                name="practiceFormBtn"
                text="Create Event"
                type="submit"
                component={ButtonUI}
              />
            </div>
          </form>
        </div>
      </div>
    );
  }
}

const eventFormSelector = getFormValues('eventForm');
export default compose(
  connect(state => ({
    currentUser: state.common.currentUser,
    roles: state.common.roles,
    formValues: eventFormSelector(state),
  })),
  reduxForm({
    form: 'eventForm',
  })
)(AddEventForm);
