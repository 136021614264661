import React from 'react';
import { connect } from 'react-redux';
import { Link, Route } from 'react-router-dom';
import agent from '../../agent';
import {
  ADD_VENUE_REQUEST,
  ADMIN_SEASONS_LOADED,
  ADMIN_VENUES_LOADED,
  ADMIN_VENUES_UNLOADED,
  UPDATE_VENUE_REQUEST,
} from '../../constants';
import NewVenueModal from './new-venue-modal';
import ConfirmationDialog from '../../components/UI/ConfirmationDialog';
import { toastr } from 'react-redux-toastr';
import { localTime } from '../../common/services/utils/helpers';

export class VenuesContainer extends React.Component {
  state = {
    showConfirmation: false,
    venue: undefined, // Using undefined here and in the setState below so that the default parameters will be used in the modal
  };

  formSubmit = values => {
    const newVenue = {
      ...values,
      admins: values.admins && values.admins.map(a => a.email),
      address: {
        ...values.address,
        region: values.state.abbreviation,
      },
    };
    this.props.onVenueCreate(newVenue);
  };

  toggleShowError = () => this.setState({ showErr: !this.state.showErr });

  toggleConfirmationDialog = () =>
    this.setState({ showConfirmation: !this.state.showConfirmation });

  handleDeleteVenue = venueId =>
    agent.Admin.removeVenue(venueId)
      .then(() => {
        toastr.success('Delete Venue', 'This admin has been removed.');
        this.props.onLoad();
      })
      .catch(e => {
        if (e.status === 405) {
          this.setState({
            showErr: true,
            errMsg: e.response.body.Error.errors[0].errorMessage,
          });
        } else {
          toastr.error(
            'Delete Venue',
            'An error occured while trying to remove this admin.'
          );
        }
      });

  getVenueForEdit = venueId => {
    agent.Venues.getVenue(venueId).then(venueData => {
      const venue = {
        ...venueData,
        paymentEnd:
          venueData && venueData.paymentEnd
            ? localTime(venueData.paymentEnd).format('MM/DD/YYYY')
            : undefined,
        state: { abbreviation: venueData.address.region },
      };
      this.setState({ venue });
    });
  };

  renderVenueAddress = venue => {
    return venue.displayAddress ? (
      <address>{venue.displayAddress}</address>
    ) : (
      ''
    );
  };

  renderVenues(venues) {
    return (
      <table className="table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Address</th>
            <th>Match Duration</th>
            <th>Edit</th>
            <th />
            {/* Blank th is for the Delete*/}
          </tr>
        </thead>
        <tbody>
          {venues.map((venue, idx) => {
            return (
              <tr key={idx}>
                <td>{venue.name}</td>
                <td>{this.renderVenueAddress(venue)}</td>
                <td>
                  {venue.matchDuration ? venue.matchDuration + ' min' : 'N/A'}
                </td>
                <td>
                  <span onClick={() => this.getVenueForEdit(venue.id)}>
                    <Link to={`${this.props.match.url}/${venue.id}`}>
                      <i className="fa-pencil fa fa-fw" />
                    </Link>
                  </span>
                  {/* <i className="fa text-danger fa-trash fa-fw" /> */}
                </td>
                <td>
                  <button
                    title="Delete"
                    onClick={e => {
                      e.preventDefault();
                      this.setState({ venue });
                      this.toggleConfirmationDialog();
                    }}
                    className="btn btn-sm btn-outline-danger">
                    <i className="fa fa-trash" />
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }

  UNSAFE_componentWillMount() {
    this.props.onLoad();
  }

  render() {
    const buttonLabels = { ok: 'Delete Venue' };
    return (
      <div className="clubs">
        <div className="list-group">
          <div className="list-group-item">
            <h3>
              Venues List
              <div
                className="float-right"
                onClick={() => this.setState({ venue: undefined })}>
                <Link
                  className="btn btn-primary"
                  to={`${this.props.match.url}/new`}>
                  <i className="fa-plus fa fa-fw" /> Add
                </Link>
              </div>
            </h3>
          </div>
          {this.props.venues && this.props.venues.length > 0 ? (
            this.renderVenues(this.props.venues)
          ) : (
            <div className="list-group-item">No Venues.</div>
          )}
        </div>
        <Route
          strict
          exact
          path={`${this.props.match.path}/:id`}
          render={() => (
            <NewVenueModal
              errors={this.props.errors}
              venue={this.state.venue}
              onSubmit={values => this.formSubmit(values)}
            />
          )}
        />
        {this.state.showConfirmation && (
          <ConfirmationDialog
            show={this.state.showConfirmation}
            title="Delete Venue"
            buttonLabels={buttonLabels}
            message="Are you sure you want to delete this venue?
            This will remove all record of the venue, including any
            events"
            toggle={this.toggleConfirmationDialog}
            onSubmit={() => {
              this.handleDeleteVenue(this.state.venue.id);
            }}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  ...state.common,
  ...state.admin,
});

const mapDispatchToProps = dispatch => {
  return {
    onLoad: () => {
      dispatch({
        type: ADMIN_VENUES_LOADED,
        payload: agent.Admin.listVenues({ show: 'all' }),
      });
      dispatch({
        type: ADMIN_SEASONS_LOADED,
        payload: agent.Seasons.listSeasons(),
      });
    },
    onUnload: () => {
      dispatch({
        type: ADMIN_VENUES_UNLOADED,
      });
    },
    onVenueCreate: venue => {
      dispatch({
        type: venue.id ? UPDATE_VENUE_REQUEST : ADD_VENUE_REQUEST,
        payload: venue.id
          ? agent.Venues.update(venue.id, venue)
          : agent.Venues.add(venue),
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VenuesContainer);
