import React from 'react';
import agent from '../agent';
import { connect } from 'react-redux';
import { FIRST_TIME_SETUP, USERS_DISPLAY } from '../constants';
import RegistrationForm from '../auth/register/registration-form';
import { toastr } from 'react-redux-toastr';
import { Redirect } from 'react-router-dom';
import { get as _get } from 'lodash';
const mapStateToProps = state => ({
  ...state.auth,
  ...state.common,
});

const mapDispatchToProps = dispatch => ({
  onSubmit: body =>
    dispatch({
      type: FIRST_TIME_SETUP,
      payload: agent.Users.updatePlayerInfo(body),
    }),
  onProfileUpdated: id =>
    dispatch({
      type: USERS_DISPLAY,
      payload: agent.Auth.display(id),
    }),
});

class FirstTimeSetup extends React.Component {
  state = {
    errors: null,
  };

  componentDidMount() {
    const playerId = _get(this.props, 'currentUser.player.id');
    Promise.all([
      agent.Venues.getList(),
      agent.SocialPlay.getPlayerPreferences(playerId),
    ]).then(values =>
      this.setState({
        clubs: values[0],
        prefs: values[1],
      })
    );
  }

  submitForm = values => {
    const playerObj = {
      password: values.password,
      id: this.props.currentUser.player.id,
      email: this.props.currentUser.email,
      firstName: values.firstName,
      lastName: values.lastName,
      ustaRating: parseFloat(values.ustaRating),
      gender: isNaN(values.gender) ? values.gender.id : values.gender,
      birthYear: parseInt(values.birthYear ? values.birthYear : 0),
      cellPhoneNumber: values.cellPhoneNumber,
      clubs: values.clubs.map(club => ({
        id: club.id,
        name: club.name,
      })),
    };
    return this.props
      .onSubmit(playerObj)
      .then(() => this.props.onProfileUpdated(this.props.currentUser.id))
      .then(
        () => {
          toastr.success(
            'Profile',
            'Your profile has been updated successfully!'
          );
          this.setState({
            redirect: '/',
          });
        },
        error => {
          this.setState({
            error,
          });
        }
      );
  };

  render() {
    const { currentUser } = this.props;
    const { prefs } = this.state;

    let content = <div>Loading...</div>;
    if (currentUser && prefs) {
      const initialValues = {
        email: currentUser.email,
        firstName: currentUser.player.firstName,
        lastName: currentUser.player.lastName,
        gender: currentUser.player.gender,
        ustaRating: currentUser.player.ustaRating
          ? currentUser.player.ustaRating
          : undefined,
        cellPhoneNumber: currentUser.player.cellPhoneNumber,
        birthYear: currentUser.player.birthYear
          ? currentUser.player.birthYear
          : undefined,
        clubs: prefs.clubs,
      };
      content = (
        <div className="auth-page">
          <div className="container page">
            <div className="alert alert-success">
              <h4 className="alert-heading">
                Welcome to {_clientFeatures.appName}
              </h4>
              Please fill out the information below.
            </div>
            <RegistrationForm
              initialValues={initialValues}
              errors={this.props.errors || this.state.error}
              isSignUp={false}
              setPassword={this.props.setPassword}
              clubs={this.state.clubs}
              onSubmit={this.submitForm}
            />
            {this.state.redirect && <Redirect to={this.state.redirect} />}
          </div>
        </div>
      );
    }

    return content;
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FirstTimeSetup);
