import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, Switch, Route, withRouter, Redirect } from 'react-router-dom';
import OpenSocialPlayList from './components/list/open';
import ConfirmedSocialPlayList from './components/list/confirmed';
import SocialPlayRequest from './components/request-play';
import Loader from '../components/UI/Loader';
import agent from '../agent';
import SocialPlayEvent from './components/event/event';

const headingClass =
  'header-text text-secondary d-flex align-items-center justify-content-between';
const requestBtnClass = 'btn btn-primary btn-sm';
const upgradeBtnClass = 'btn btn-outline-primary btn-sm';

export class SocialPlay extends Component {
  state = {
    loading: true,
    open: [],
    confirmed: [],
  };

  componentDidMount() {
    this._getGames();
  }

  get isSocialPlayMember() {
    if (this.props.currentUser) {
      return this.props.roles.indexOf('Socialite') !== -1;
    }
  }

  // userPracticeStatus(practiceId, status) {
  //   var user = this.props.currentUser;
  //   if (user && practiceId) {
  //     agent.Practices.setPlayerStatus(practiceId, user.id, status);
  //   }
  // }
  _handleChangeAvailability = () => {
    this._getGames();
  };

  _handleConfirmationChange = () => {
    this._getGames();
  };

  _getGames = async () => {
    this.setState({
      loading: true,
    });
    const events = await agent.Events.getCurrentByType('social');
    console.log(events);
    this.setState({
      loading: false,
      open: events.filter(e => e.status === 'open'),
      confirmed: events.filter(e => e.status === 'closed'),
    });
  };

  render() {
    const { isSocialPlayMember, state } = this;
    const { match } = this.props;
    console.log(this.props);
    if (!this.props.currentUser) {
      return <Redirect to={'/login'} />;
    } else {
      return (
        <Switch>
          <Route
            exact
            path={match.path}
            render={props => (
              <div>
                <div className="social-header-area">
                  <h2 className={headingClass}>
                    Social Play
                    {isSocialPlayMember ? (
                      <div>
                        <Link to="social-play/new" className={requestBtnClass}>
                          Request
                        </Link>
                        <Link
                          to="account/social-play"
                          className="btn btn-light btn-sm ml-1">
                          <i className="fa fa-gear" />
                        </Link>
                      </div>
                    ) : (
                      <div>
                        <Link
                          to="plans?upgrade=social"
                          className={upgradeBtnClass}>
                          Upgrade Your Account to Request Social Play
                        </Link>
                        <Link
                          to="account/social-play"
                          className="btn btn-light btn-sm ml-1">
                          <i className="fa fa-gear" />
                        </Link>
                      </div>
                    )}
                  </h2>
                </div>
                {state.loading ? (
                  <Loader />
                ) : (
                  <div>
                    <OpenSocialPlayList
                      {...props}
                      events={state.open}
                      onChangeAvailability={this._handleChangeAvailability}
                    />
                    <ConfirmedSocialPlayList
                      {...props}
                      events={state.confirmed}
                      onConfirmationChange={this._handleConfirmationChange}
                    />
                  </div>
                )}
              </div>
            )}
          />
          {isSocialPlayMember && (
            <Route
              exact
              path={`${match.path}/new`}
              render={() => <SocialPlayRequest onRequest={this._getGames} />}
            />
          )}
          <Route
            path={`${match.url}/:eventId`}
            render={() => <SocialPlayEvent onChange={this._getGames} />}
          />
          {/*<Redirect to={match.path} />*/}
        </Switch>
      );
    }
  }
}

const stateToProps = state => ({
  ...state.common,
});

export default withRouter(connect(stateToProps)(SocialPlay));
