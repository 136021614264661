export const ADMINISTRATOR = 'Admin';
export const PLAYER = 'Player';
export const CAPTAIN = 'Captain';
export const SOCIALITE = 'Socialite';
export const VENUE = 'Venue';
export const PRO = 'Pro';

export default {
  ADMINISTRATOR,
  PLAYER,
  CAPTAIN,
  SOCIALITE,
  VENUE,
  PRO,
};
