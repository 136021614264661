import agent from '../agent';

export const LOAD_PREFERENCES = 'event/LOAD_PREFERENCES';
export const UPDATE_PREFERENCES = 'event/UPDATE_PREFERENCES';
export const UPDATE_EVENT = 'event/UPDATE_EVENT';
export const LOAD_OPEN_EVENTS = 'event/LOAD_OPEN_EVENTS';
export const LOAD_CONFIRMED_EVENTS = 'event/LOAD_CONFIRMED_EVENTS';

export function updateEvent(id, event) {
  return {
    type: UPDATE_EVENT,
    payload: agent.Events.update(id, event),
  };
}

export function loadPreferences(playerId) {
  return {
    type: LOAD_PREFERENCES,
    payload: agent.SocialPlay.getPlayerPreferences(playerId),
  };
}

export function updatePreferences(preferences) {
  return {
    type: UPDATE_PREFERENCES,
    payload: agent.SocialPlay.updatePlayerPreferences(
      preferences.id,
      preferences
    ),
  };
}

export function loadOpenEvents() {
  return {
    type: LOAD_OPEN_EVENTS,
    payload: agent.SocialPlay.getOpen(),
  };
}

export function loadConfirmedEvents() {
  return {
    type: LOAD_CONFIRMED_EVENTS,
    payload: agent.SocialPlay.getConfirmed(),
  };
}
