import React from 'react';
import { connect } from 'react-redux';
import { Link, Route } from 'react-router-dom';
import agent from '../../agent';
import {
  ADMIN_SEASONS_LOADED,
  ADMIN_SEASONS_UNLOADED,
  ADD_SEASON_REQUEST,
  UPDATE_SEASON_REQUEST,
} from '../../constants';
import ustaSection from '../../data/usta-section';
import moment from 'moment';
import NewSeason from './new-season-modal';
import { toastr } from 'react-redux-toastr';

export class SeasonsContainer extends React.Component {
  state = {
    season: undefined, // Setting season to undefined here and in the setState below so the modals default parameter is triggered
  };

  formatDate(epoch) {
    return moment(new Date(epoch)).format('ll');
  }

  UNSAFE_componentWillMount() {
    this.props.onLoad();
  }

  formSubmit = values => {
    const newSeason = {
      ...values,
      section: values.section.id,
      registrationStart: formatSeasonDate(values.registrationStart),
      registrationEnd: formatSeasonDate(values.registrationEnd),
      seasonStart: formatSeasonDate(values.seasonStart),
      seasonEnd: formatSeasonDate(values.seasonEnd),
    };
    this.props.onSeasonCreate(newSeason).catch(e => {
      console.error(e);
      toastr.error('Seasons', 'There was an error creating the season');
    });
    function formatSeasonDate(date) {
      return moment(date)
        .toDate()
        .getTime();
    }
  };

  getSeasonForEdit = seasonId => {
    agent.Seasons.getSeason(seasonId).then(seasonData => {
      const season = {
        ...seasonData,
        section: ustaSection[seasonData.section],
        registrationStart: formatSeasonDate(seasonData.registrationStart),
        registrationEnd: formatSeasonDate(seasonData.registrationEnd),
        seasonStart: formatSeasonDate(seasonData.seasonStart),
        seasonEnd: formatSeasonDate(seasonData.seasonEnd),
      };
      this.setState({ season });
    });

    function formatSeasonDate(date) {
      return moment(new Date(date), 'MM/DD/YYYY');
    }
  };

  renderSeasons(seasons) {
    return (
      <table className="table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Section</th>
            <th>Reg</th>
            <th>Season</th>
            <th># Leagues</th>
            <th>Edit</th>
          </tr>
        </thead>
        <tbody>
          {seasons.map((season, idx) => {
            const seasonSection = ustaSection[season.section];
            return (
              <tr key={idx}>
                <td>{season.name}</td>
                <td>{seasonSection ? seasonSection.name : null}</td>
                <td>
                  {`${this.formatDate(
                    season.registrationStart
                  )} - ${this.formatDate(season.registrationEnd)}`}
                </td>
                <td>
                  {`${this.formatDate(season.seasonStart)} - ${this.formatDate(
                    season.seasonEnd
                  )}`}
                </td>
                <td>{season.leaguesCount}</td>
                <td>
                  <span onClick={() => this.getSeasonForEdit(season.id)}>
                    <Link to={`${this.props.match.url}/${season.id}`}>
                      <i className="fa fa-pencil fa-fw" />
                    </Link>
                  </span>
                  {/* <i className="fa text-danger fa-trash fa-fw" /> */}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }

  render() {
    return (
      <div className="seasons">
        <div className="list-group">
          <div className="list-group-item">
            <div>
              <span className="h3">Seasons List</span>
              <div
                className="float-right"
                onClick={() => {
                  this.setState({ season: undefined });
                }}>
                <Link
                  className="btn btn-primary"
                  to={`${this.props.match.url}/new`}>
                  <i className="fa-plus fa fa-fw" /> Add
                </Link>
              </div>
            </div>
          </div>
          {this.props.seasons && this.props.seasons.length > 0 ? (
            this.renderSeasons(this.props.seasons)
          ) : (
            <div className="list-group-item">No seasons.</div>
          )}
        </div>
        <Route
          strict
          exact
          path={`${this.props.match.path}/:id`}
          render={() => (
            <NewSeason
              errors={this.props.errors}
              season={this.state.season}
              onSubmit={values => this.formSubmit(values)}
            />
          )}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  ...state.common,
  ...state.admin,
});

const mapDispatchToProps = dispatch => {
  return {
    onLoad: () => {
      dispatch({
        type: ADMIN_SEASONS_LOADED,
        payload: agent.Seasons.listSeasons(),
      });
    },
    onUnload: () => {
      dispatch({
        type: ADMIN_SEASONS_UNLOADED,
      });
    },
    onSeasonCreate: season => {
      return dispatch({
        type: season.id ? UPDATE_SEASON_REQUEST : ADD_SEASON_REQUEST,
        payload: season.id
          ? agent.Seasons.update(season.id, season)
          : agent.Seasons.add(season),
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SeasonsContainer);
