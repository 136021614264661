import React from 'react';
import { Link } from 'react-router-dom';

export class TeamList extends React.Component {
  render() {
    const { teams } = this.props;
    return (
      <table className="table">
        <thead>
          <tr>
            <th>
              <span className="col-md-5"> Team Name </span>
            </th>
            <th>
              <span className="col-md-5">League </span>
            </th>
            <th />
          </tr>
        </thead>
        <tbody>
          {teams &&
            teams.map((team, idx) => (
              <tr key={idx}>
                <td>
                  <span className="col-md-5">
                    <Link to={`/teams/${team._id}`}>{team.name}</Link>
                  </span>
                </td>
                <td>
                  <span className="col-md-5">{team.league.name}</span>
                </td>
                <td>
                  {team.isEditable && (
                    <span className="col-md-2">
                      <Link
                        to={`/teams/${team.name ? team.name + '/edit' : ''}`}
                        className="pull-right">
                        Edit
                      </Link>
                    </span>
                  )}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    );
  }
}

export default TeamList;
