import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import ButtonUI from '../../components/UI/Button';
import ListErrors from '../../common/list-errors';

class VenueSettingsForm extends Component {
  render() {
    const {
      errors,
      handleSubmit,
      submitting,
      pristine,
      buttonText,
    } = this.props;

    return (
      <div className="row">
        <div className="col-sm">
          <ListErrors errors={errors} />
          <form
            onSubmit={handleSubmit(data => {
              this.props.onSubmit({ ...data });
            })}>
            {/* TODO: maybe have the viewModel return an array with an object of name and label so we can do a loop here */}
            <div className="row">
              <div className="col-sm-6">
                <label className="form-check-label">
                  <Field
                    type="checkbox"
                    name="event_request_notification"
                    component={field => (
                      <input
                        {...field.input}
                        type="checkbox"
                        className="form-check-input"
                      />
                    )}
                  />
                  Receive notifications when a social play event is created at
                  your venues.
                </label>
              </div>
            </div>
            <div className="clearfix">
              <Field
                disabled={pristine || submitting}
                text={buttonText}
                component={ButtonUI}
                name="userPreferenceSubmit"
              />
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default reduxForm({
  form: 'venueSettingsForm',
  enableReinitialize: true,
})(VenueSettingsForm);
