import React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import eventsBg from '../../assets/imgs/schedule_square.png';
import directoryBg from '../../assets/imgs/connect_square.png';
import connectBg from '../../assets/imgs/socialplay_square.png';
import notifyBg from '../../assets/imgs/setup_square.png';
import { loadVenueData } from '../../action';
import agent from '../../../agent';

export class VenueDashboardContainer extends React.Component {
  componentDidMount() {
    this.props.onVenueLoad(agent.Venues.getVenue(this.props.match.params.id));
  }

  cleanUp = [];

  componentWillUnmount() {
    this.cleanUp.forEach(sub => sub.unsubscribe());
    this.cleanUp.length = 0;
  }

  render() {
    const { match, venue } = this.props;
    return (
      <div className="venue_dashboard">
        <div className="venue_home">
          {venue && venue.paying ? (
            <div className="venue_home__section venue_home__section--events">
              <img src={eventsBg} />
              <Link to={`/venues/${match.params.id}/events`}>Events</Link>
            </div>
          ) : (
            <div className="venue_home_disabled__section venue_home_disabled__section--events">
              <img src={eventsBg} />
              <Link
                to={`/venues/${match.params.id}/events`}
                className="disabled-link">
                Events
              </Link>
            </div>
          )}
          <div className="venue_home__section venue_home__section--directory">
            <img src={directoryBg} />
            <Link to={`/venues/${match.params.id}/directory`}>Directory</Link>
          </div>
          <div className="venue_home__section venue_home__section--connect">
            <img src={connectBg} />
            <Link to={`/venues/${match.params.id}/communicate`}>
              Communicate
            </Link>
          </div>
          <div className="venue_home_disabled__section venue_home_disabled__section--notify">
            <img src={notifyBg} />
            <Link to={`/venues/${match.params.id}`} className="disabled-link">
              Notifications
            </Link>
          </div>
        </div>
        {/*<div>*/}
        {/*<Link to="/venues/:id/invite" className="btn btn-sm btn-outline-primary">*/}
        {/*<i className="fa fa-fw fa-user-plus mr-1" />*/}
        {/*Invite Social Players*/}
        {/*</Link>*/}
        {/*</div>*/}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  ...state.common,
  ...state.venues,
});

const mapDispatchToProps = dispatch => ({
  onVenueLoad: events => dispatch(loadVenueData(events)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(VenueDashboardContainer)
);
