import React from 'react';
export default class Footer extends React.Component {
  render() {
    return (
      <footer>
        &copy; {new Date().getFullYear()} SportFolder, Inc. All Rights Reserved
        <br />
        U.S. Patent No 9,744,424 B1
      </footer>
    );
  }
}
