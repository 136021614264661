import React from 'react';
import { Redirect } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';

import InputUI from '../../components/UI/Input';
import TimePickerUI from '../../components/UI/DateTimePicker';
import SelectUI from '../../components/UI/Select';
import ListErrors from '../../common/list-errors';
import validation from '../../data/validations';
import sections from '../../data/usta-section';
import { connect } from 'react-redux';
import { compose } from 'redux';

const SEASON_DEFAULT = {
  id: null,
  name: null,
  section: null,
  registrationStart: null,
  registrationEnd: null,
  seasonStart: null,
  seasonEnd: null,
};

export class NewSeasonModalContainer extends React.Component {
  state = {
    modal: true,
  };

  static defaultProps = {
    club: SEASON_DEFAULT,
  };

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  onModalClose = () => {
    this.setState({ redirect: true });
  };

  onSubmit = values => {
    // TODO: might be better to have the default outside of the dialog...
    values.id = this.props.season && this.props.season.id;
    this.props.onSubmit(values);
    this.setState({ redirect: true });
  };

  render() {
    const {
      handleSubmit,
      errors,
      pristine,
      submitting,
      season = SEASON_DEFAULT,
    } = this.props;
    const isEdit = season.id !== null;

    return this.state.redirect ? (
      <Redirect to="." />
    ) : (
      <Modal
        isOpen={this.state.modal}
        onClosed={this.onModalClose}
        className={this.props.className}>
        <form onSubmit={handleSubmit(this.onSubmit)}>
          <ModalHeader toggle={this.toggle}>
            {isEdit ? 'Edit' : 'Create New'} Season
          </ModalHeader>
          <ModalBody>
            <ListErrors errors={errors} />

            <Field
              name="name"
              component={InputUI}
              validate={[validation.required]}
              label="Name"
            />
            <Field
              name="section"
              validate={validation.required}
              component={SelectUI}
              options={sections}
              valueKey="id"
              labelKey="name"
              label="USTA Section"
            />
            <Field
              name="registrationStart"
              validate={validation.required}
              showTime={false}
              showDate={true}
              component={TimePickerUI}
              label="Registration Start Date"
            />
            <Field
              name="registrationEnd"
              validate={validation.required}
              showTime={false}
              showDate={true}
              component={TimePickerUI}
              label="Registration End Date"
            />
            <Field
              name="seasonStart"
              validate={validation.required}
              showTime={false}
              showDate={true}
              component={TimePickerUI}
              label="Season Start Date"
            />
            <Field
              name="seasonEnd"
              validate={validation.required}
              showTime={false}
              showDate={true}
              component={TimePickerUI}
              label="Season End Date"
            />
          </ModalBody>
          <ModalFooter>
            <a className="btn btn-link" onClick={this.toggle}>
              Cancel
            </a>
            <Button disabled={pristine || submitting || errors} color="primary">
              {isEdit ? 'Update' : 'Create'} Season
            </Button>
          </ModalFooter>
        </form>
      </Modal>
    );
  }
}

export default compose(
  connect((state, props) => ({
    initialValues: props.season,
  })),
  reduxForm({
    form: 'newSeasonForm',
    enableReinitialize: true,
  })
)(NewSeasonModalContainer);
