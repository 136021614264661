import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Switch, Route, NavLink, Redirect } from 'react-router-dom';
import { USER_PROFILE_LOADED, LOAD_SETTINGS } from '../../../constants';
// TODO: import and export them to the same directory instead?
import Profile from '../../../account/profile';
import Settings from '../../../account/settings';
import SocialPlay from '../../../account/friends';
import Captain from '../../../account/captain';
import agent from '../../../agent';

export class UserAccountContainer extends Component {
  componentDidMount() {
    this.props.onLoad(this.props.match.params.id).then(() => {
      this.props.onLoadSettings(this.props.user.player.id);
    });
  }
  render() {
    const { user, match, isCaptain } = this.props;
    // const { user } = this.state;
    return user ? (
      <div>
        <ul className="nav nav-tabs">
          {user.player && (
            <li className="nav-item">
              <NavLink className="nav-link" to={`${match.url}/profile`}>
                Profile
              </NavLink>
            </li>
          )}
          <li className="nav-item">
            <NavLink className="nav-link" to={`${match.url}/settings`}>
              Settings
            </NavLink>
          </li>
          {user.player && (
            <li className="nav-item">
              <NavLink className="nav-link" to={`${match.url}/social-play`}>
                Social Play
              </NavLink>
            </li>
          )}
          {isCaptain && (
            <li className="nav-item">
              <NavLink className="nav-link" to={`${match.url}/captain`}>
                Captain
              </NavLink>
            </li>
          )}
        </ul>
        <Switch>
          {user && (
            <Route
              strict
              exact
              path={`${match.path}/profile`}
              render={() => <Profile user={user} />}
            />
          )}
          <Route
            strict
            exact
            path={`${match.path}/settings`}
            render={() => <Settings userId={user.id} />}
          />
          {user && (
            <Route
              strict
              exact
              path={`${match.path}/social-play`}
              render={() => <SocialPlay player={user.player} />}
            />
          )}
          {isCaptain && (
            <Route
              strict
              exact
              path={`${match.path}/captain`}
              render={() => <Captain playerId={user.player.id} />}
            />
          )}
          <Redirect to={`${match.url}/social-play`} />
        </Switch>
      </div>
    ) : (
      <div />
    );
  }
}

const mapStateToProps = state => {
  const isCaptain = !!(
    state.common.settings && state.common.settings.captainSettings
  );
  const profile = state.admin.userProfile
    ? { ...state.admin.userProfile }
    : null;
  return {
    user: profile,
    isCaptain,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onLoad: userId =>
      agent.Auth.getUser(userId).then(user => {
        dispatch({
          type: USER_PROFILE_LOADED,
          payload: user,
        });
      }),
    onLoadSettings: playerId =>
      dispatch({
        type: LOAD_SETTINGS,
        payload: agent.Users.getSettings(playerId),
      }),
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(UserAccountContainer)
);
