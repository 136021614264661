import {
  GET_EVENT,
  UPDATE_EVENT,
  UPDATE_GAME,
  CONFIRM_SCORES,
  SET_PLAYER_EVENT_STATUS,
} from '../../constants';
import agent from '../../../agent';

export function getEvent(id, isAdmin) {
  return async dispatch => {
    const event = await agent.Challenge.get(id);
    let playerOpts = [];
    if (isAdmin) {
      const { venue } = event;
      playerOpts = await agent.Venues.getMembers(venue);
    }
    dispatch({
      type: GET_EVENT,
      payload: {
        event,
        playerOpts,
      }
    });
  };
}

export function updateEvent(id, body) {
  return async dispatch => {
    const payload = await agent.Challenge.update(id, body);
    dispatch({
      type: UPDATE_EVENT,
      payload,
    });
  };
}

export function updateGame(id, gameId, body) {
  return async dispatch => {
    await agent.Challenge.updateGame(id, gameId, body);
    const payload = await agent.Challenge.get(id);
    dispatch({
      type: UPDATE_GAME,
      payload,
    });
  };
}

export function confirmScores(id) {
  return async dispatch => {
    await agent.Challenge.confirmScores(id);
    const payload = await agent.Challenge.get(id);
    dispatch({
      type: CONFIRM_SCORES,
      payload,
    });
  };
}

export function setPlayerEventStatus(id, body) {
  return async dispatch => {
    const payload = await agent.Events.setAvailability(id, body);
    dispatch({
      type: SET_PLAYER_EVENT_STATUS,
      payload,
    });
  };
}
