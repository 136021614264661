import React from 'react';
import { MailDialog } from '../../components/UI/MailDialog';

class ListErrors extends React.Component {
  state = {
    showEmail: false,
  };
  toggleEmailDialog = () => this.setState({ showEmail: !this.state.showEmail });
  render() {
    const errors = this.props.errors;
    if (errors) {
      return (
        <div className="alert alert-danger">
          {errors.length > 1 && <strong>Error!</strong>}
          <ul className="error-messages">
            {errors.map ? (
              errors.map((value, key) => {
                return (
                  <li key={key}>
                    {errors.length === 1 && <strong>Error! </strong>}
                    {value.errorMessage || value.description || value}
                  </li>
                );
              })
            ) : (
              <li>
                Something went wrong! If the problem persists, contact{' '}
                <span className="alert-link" onClick={this.toggleEmailDialog}>
                  Support
                </span>
              </li>
            )}
          </ul>
          {this.state.showEmail && (
            <MailDialog
              to={_clientFeatures.supportURL}
              show={this.state.showEmail}
              toggle={this.toggleEmailDialog}
            />
          )}
        </div>
      );
    } else {
      return null;
    }
  }
}

export default ListErrors;
