import { ASYNC_START, ASYNC_END, CLUBS_LOADED } from '../constants';
import { get as _get } from 'lodash';

export default (state = {}, action) => {
  switch (action.type) {
    case 'CLUBS_HEADER_LOAD':
      return {
        ...state,
        clubs_header: action.payload,
      };
    case 'CLUBS_SELECT_CLUB':
      return {
        ...state,
        club: action.payload,
      };
    case CLUBS_LOADED:
      return {
        ...state,
        clubs: action.error ? null : action.payload,
        club: action.error ? null : _get(action.payload, '[0]', {}),
        errors: action.error ? action.payload : null,
      };
    case ASYNC_START:
      if (action.subtype === CLUBS_LOADED) {
        return {
          ...state,
          inProgress: true,
        };
      }
      break;
    case ASYNC_END:
      return {
        ...state,
        inProgress: false,
      };
    default:
      return state;
  }

  return state;
};
