import React from 'react';
import { Link } from 'react-router-dom';
import { localTime } from '../../../common/services/utils/helpers';
import ConfirmationDialog from '../../../components/UI/ConfirmationDialog';
import agent from '../../../agent';
import { loadVenueEvents } from '../../action';
import { connect } from 'react-redux';
import { eventTypes, eventStatus, eventTypesArray } from '../../../data/types';

class VenueEventList extends React.Component {
  state = {
    showConfirmation: false,
  };

  getEventType = type => {
    const event = eventTypesArray.find(e => e.id === type);
    return event ? event.name : 'Venue';
  };

  toggleConfirmationDialog = () =>
    this.setState({ showConfirmation: !this.state.showConfirmation });

  handleEventDelete = event => {
    const {
      match: { params },
    } = this.props;
    const { id, type } = event;
    if (type === eventTypes.CHALLENGE) {
      agent.Challenge.delete(id).then(() => {
        this.props.onEventsLoad(params.id);
      });
    } else {
      agent.Events.delete(id).then(() => {
        this.props.onEventsLoad(params.id);
      });
    }
  };

  render() {
    const { events, venue } = this.props;
    const { showConfirmation, event } = this.state;
    // TODO - determine if this is enough information to determine editability
    const buttonLabels = { ok: 'Delete Event' };
    const cardView = events => {
      return (
        <div className="mt-3">
          {events.map((event, idx) => (
            <Link
              to={`/venues/${venue.id}/${event.type}/${event.id}`}
              key={idx}
              className={'list-group-item'}>
              <div className="row align-items-center">
                {event.status === eventStatus.OPEN ? (
                  <div
                    className={
                      'col-sm-2 event-header event-header--' + event.type
                    }>
                    <i className=" fa fa-lg fa-folder-open text-success" />
                    <small className="pl-1">Open</small>
                  </div>
                ) : (
                  <div
                    className={
                      'col-sm-2 event-header event-header--' + event.type
                    }>
                    <i className=" fa fa-lg fa-folder text-danger" />
                    <small className="pl-1">
                      {event.type === eventTypes.DROPIN ? 'Filled' : 'Closed'}
                    </small>
                  </div>
                )}
                <div className="col-sm-2">
                  <small className="mt-1 mr-3">
                    {this.getEventType(event.type)}
                  </small>
                </div>
                <div className="col-sm-2">
                  <small className="mt-1 mr-3">
                    Space Available:{' '}
                    {event.type === eventTypes.CHALLENGE ? 0 : event.available}
                  </small>
                </div>
                {event.type === eventTypes.DROPIN ? (
                  <div className="col-sm-2">
                    <small className="mt-1 mr-3">
                      Interested: {event.accepted}
                    </small>
                  </div>
                ) : (
                  <div className="col-sm-2">
                    <small className="mt-1 mr-3">
                      Confirmed: {event.accepted}
                    </small>
                  </div>
                )}
                <div className="col-sm-3">
                  <small className="mt-1 mr-3">
                    {localTime(event.start).format('llll')}
                  </small>
                </div>
                <div className="col-sm">
                  <i
                    className="fa fa-trash float-md-right text-danger"
                    onClick={e => {
                      e.preventDefault();
                      this.setState({ event: event });
                      this.toggleConfirmationDialog();
                    }}
                  />
                </div>
                <span className="float-right mt-3 mt-sm-1">
                  <i className="fa fa-chevron-right fa-fw" />
                </span>
              </div>
            </Link>
          ))}
          {showConfirmation && (
            <ConfirmationDialog
              show={showConfirmation}
              title="Delete Event"
              buttonLabels={buttonLabels}
              message={`Are you sure you want to delete this ${this.getEventType(
                event.type
              )} event?`}
              toggle={this.toggleConfirmationDialog}
              onSubmit={() => {
                this.handleEventDelete(event);
              }}
            />
          )}
        </div>
      );
    };

    return events && events.map && events.length > 0 ? (
      <div className="list-group">{cardView(events)}</div>
    ) : (
      <div>No Events scheduled.</div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  onEventsLoad: id => dispatch(loadVenueEvents(id)),
});

export default connect(
  null,
  mapDispatchToProps
)(VenueEventList);
