import React from 'react';
import { connect } from 'react-redux';
import { Switch, Route, Redirect, withRouter, NavLink } from 'react-router-dom';
import Users from './users';
import Leagues from './leagues';
import Flights from './flights';
import Seasons from './seasons';
import Venues from './venues';
import Teams from './teams';

export class AdminContainer extends React.Component {
  render() {
    let match = this.props.match;
    return (
      <div className="main-outline">
        <div className="admin">
          <ul className="nav nav-tabs mb-1">
            <li className="nav-item">
              <NavLink className="nav-link" to={`${match.url}/users`}>
                Users
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to={`${match.url}/venues`}>
                Venues
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to={`${match.url}/flights`}>
                Flights
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to={`${match.url}/leagues`}>
                Leagues
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to={`${match.url}/seasons`}>
                Seasons
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to={`${match.url}/teams`}>
                Teams
              </NavLink>
            </li>
          </ul>
          <Switch>
            <Route strict path={`${match.path}/users`} component={Users} />
            <Route strict path={`${match.path}/venues`} component={Venues} />
            <Route
              strict
              path={`${match.path}/leagues/:id/teams`}
              component={Teams}
            />
            <Route strict path={`${match.path}/seasons`} component={Seasons} />
            <Route strict path={`${match.path}/leagues`} component={Leagues} />
            <Route strict path={`${match.path}/flights`} component={Flights} />
            <Route strict path={`${match.path}/teams`} component={Teams} />
            <Redirect to={`${match.path}/users`} />
          </Switch>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  ...state.common,
});

const mapDispatchToProps = () => {
  return {};
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AdminContainer)
);
