import classnames from 'classnames';
import React from 'react';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import AuthRoute from '../common/auth-route';
import { connect } from 'react-redux';
import ReduxToastr from 'react-redux-toastr';
import { toastr } from 'react-redux-toastr';
import { isUndefined } from 'lodash';

import uaParser from 'ua-parser-js';

import ROLES from '../data/roles';
import { APP_LOAD, MENU_TOGGLE } from '../constants';

// pages
import Home from '../home';
import Downloads from '../downloads';
import Header from './header';
import SubHeader from './subheader';
import Footer from './footer';
import Sidebar from './sidebar';
import Login from '../auth/login';
import Register from '../auth/register';
import FirstTimeSetup from '../account/first-time-setup';
import ForgotPassword from '../auth/forgot-password';
import PasswordReset from '../auth/password-reset';
import ConfirmEmail from '../auth/confirm-email';
import EventResponse from '../auth/event-response';
import VenueAction from '../auth/venue-action';
import Teams from '../teams';
import Venues from '../venues/venues.layout';
import SocialPlay from '../social-play/';
import Account from '../account';
import Admin from '../admin';
import UserAccountContainer from '../admin/users/account';
import FourOhFour from '../404';
import UserAccountToken from '../account/token';
import Agenda from '../agenda/index';
import Flights from '../flights';
import TeamLink from '../auth/team-link';
import Ranking from '../ranking';

import {
  setToken,
  setImpersonationToken,
} from '../common/services/requests/requests';
import agent from '../agent';
/**
 * @todo Not sure where best to put this.. but it allows us to
 * use a moment's instance in a xhr post {put|patch|etc.} body
 * without needing to call `.format()` first, as this serializes
 * the date with a utc offset ( the DB likes this )
 */
import moment from 'moment';

// When serializing a moment instance to JSON, use an ISO8601
// string that reflects the moment's utc offset
moment.fn.toJSON = function() {
  return this.format();
};

class ScrollToTop extends React.Component {
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      // TODO: is there a better way to get the scroll content element?
      const el = document.querySelector('.wrapper-content');
      if (el && el.scrollTo) {
        el.scrollTo(0, 0);
      }
    }
  }

  render() {
    return this.props.children || null;
  }
}

class App extends React.Component {
  componentDidCatch(err) {
    // This will catch any unhandled exceptions from the components hierarchy
    this.handleUncaughtExceptions(err);
  }
  handleUncaughtExceptions = e => {
    toastr.error(
      'Server Error',
      `Error loading page: ${e.message ||
        (e.reason &&
          e.reason
            .message)} || Please try again. If the problem persists, please contact ${
        _clientFeatures.supportURL
      }.`,
      { timeOut: 0 }
    );
    console.error(e);
  };
  get isAdmin() {
    return this.hasRole(ROLES.ADMINISTRATOR);
  }

  get isPlayer() {
    return this.hasRole(ROLES.PLAYER);
  }

  get isTeamCreator() {
    return this.isAdmin; // The sidebar team create button is now only for admins
  }

  get isClubSuper() {
    return this.hasRole(ROLES.VENUE);
  }

  get isVenue() {
    return this.hasRole(ROLES.VENUE);
  }

  hasRole(roleName) {
    return this.props.roles ? this.props.roles.indexOf(roleName) >= 0 : false;
  }

  componentDidMount() {
    const token = localStorage.getItem('jwt');
    const impersonationToken = localStorage.getItem('i_jwt');
    if (token) {
      setToken(token);
    }
    if (impersonationToken) {
      setImpersonationToken(token);
    }
    let isMobile = !isUndefined(new uaParser.UAParser().getDevice().type);

    this.props.onLoad(token ? agent.Auth.current() : null, token, isMobile);
  }

  render() {
    const { isAdmin, isPlayer, isTeamCreator, isClubSuper, isVenue } = this;

    const mainClass = ['wrapper-content'];
    if (this.props.menuOpen) {
      mainClass.push('wrapper-content--open');
    }

    let routes = null;
    if (this.props.appLoaded) {
      routes = (
        <Switch>
          <Route path="/" component={Home} exact />
          <Route path="/downloads" component={Downloads} exact />
          <Route path="/user-token/:token" component={UserAccountToken} exact />
          <Route path="/confirm-email" component={ConfirmEmail} />
          <Route path="/login" component={Login} />
          <Route path="/register" component={Register} />
          <Route path="/forgot-password" component={ForgotPassword} />
          <Route path="/password-reset" component={PasswordReset} />
          <Route path="/team-link" component={TeamLink} />
          <Route path="/event-response" component={EventResponse} />
          <Route path="/venue-action" component={VenueAction} />
          <Route path="/social-play" component={SocialPlay} />
          <Route path="/teams" component={Teams} />
          <Route path="/flights" component={Flights} />
          <Route path="/venues" component={Venues} />
          <AuthRoute
            role={ROLES.PLAYER}
            path="/admin/account/:id"
            component={UserAccountContainer}
          />
          <AuthRoute role={ROLES.PLAYER} path="/account" component={Account} />
          <AuthRoute
            role={ROLES.PLAYER}
            path="/welcome"
            component={FirstTimeSetup}
          />
          <AuthRoute
            role={ROLES.ADMINISTRATOR}
            path="/admin"
            component={Admin}
          />
          <AuthRoute role={ROLES.PLAYER} path="/agenda" component={Agenda} />
          <AuthRoute role={ROLES.PLAYER} path="/ranking" component={Ranking} />
          <Route component={FourOhFour} />
        </Switch>
      );
    }

    return (
      <div className="tf">
        <ScrollToTop {...this.props} />
        <Header />
        <ReduxToastr
          timeOut={2000}
          newestOnTop={false}
          preventDuplicates
          position="top-right"
          transitionIn="fadeIn"
          transitionOut="fadeOut"
          progressBar
        />
        <div className="wrapper">
          <Sidebar
            isAdmin={isAdmin}
            isPlayer={isPlayer}
            isTeamCreator={isTeamCreator}
            isClubSuper={isClubSuper}
            isVenue={isVenue}
          />
          <div className={classnames(mainClass)}>
            <SubHeader />
            <div className="main-content">
              {routes}
              <Footer />
            </div>
          </div>
        </div>
        {this.props.redirectTo ? <Redirect to={this.props.redirectTo} /> : null}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  ...state.common,
});

const mapDispatchToProps = {
  onMenuToggle: () => ({ type: MENU_TOGGLE }),
  onLoad: (payload, token, isMobile) => {
    return {
      type: APP_LOAD,
      skipTracking: true,
      payload,
      token,
      isMobile,
    };
  },
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(App)
);
