import React from 'react';
import classNames from 'classnames';

// Cached SVG fragment
const SVGPath = (
  <path d="M16.74766 8.3971c-9.1766 4.71714-12.79195 15.98104-8.0748 25.15763l3.61797-1.8598C8.60097 24.5168 11.4284 15.7054 18.60745 12.0151l-1.8598-3.61798z">
    <animateTransform
      attributeType="xml"
      attributeName="transform"
      type="rotate"
      from="0 25 25"
      to="360 25 25"
      dur="0.6s"
      repeatCount="indefinite"
    />
  </path>
);

/**
 * Animated loading SVG
 * @param {{}} props
 * @param {string=} props.className Loader classes
 * @param {{}=} props.style Loader style properties
 * @return {JSX.Element}
 */
const SVGLoader = ({ className, style }) => (
  <svg
    className={classNames('svg-loader', className)}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 50 50"
    style={{
      width: '60px',
      height: '60px',
      display: 'block',
      margin: '30px auto',
      ...style,
    }}>
    {SVGPath}
  </svg>
);

export default SVGLoader;
