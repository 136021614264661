import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import teamBg from './imgs/teams_square.png';
import socialBg from './imgs/socialplay_square.png';
import scheduleBg from './imgs/schedule_square.png';
import { USERS_DISPLAY } from '../constants';
import { get } from 'lodash';
import agent from '../agent';
import MobileStoreButton from 'react-mobile-store-button';
import { isIOS, isAndroid, isMobile } from 'react-device-detect';
import ROLES from '../data/roles';

export class HomeContainer extends React.Component {
  state = {
    loading: true,
    mobile: isMobile,
    ios: isIOS,
    android: isAndroid,
  };

  componentDidMount() {
    // reset flag for the next time we come back into this components
    // otherwise impersonating can use old data before the async fetch completes
    this.setState({
      loading: true,
    });
    const loadPromise = this.props.onLoad(
      get(this.props, 'currentUser.id', null)
    );
    if (loadPromise) {
      loadPromise
        .then(() => {
          this.setState({ loading: false });
        })
        .catch(() => {
          this.setState({ loading: false });
        });
      // TODO: .finally when fully supported
    }
  }

  get isAdmin() {
    return this.hasRole(ROLES.ADMINISTRATOR);
  }

  get isVenue() {
    return this.hasRole(ROLES.VENUE);
  }

  get isPlayer() {
    return this.hasRole(ROLES.PLAYER);
  }

  get isClubSuper() {
    return this.hasRole(ROLES.VENUE);
  }

  hasRole(roleName) {
    return this.props.roles ? this.props.roles.indexOf(roleName) >= 0 : false;
  }

  render() {
    let content;
    const { mobile, ios, android } = this.state;
    if (!this.props.currentUser) {
      // content = <Redirect to="/plans" />;
      content = <Redirect to="/login" />;
    } else if (this.isAdmin) {
      content = <Redirect to="/admin/user" />;
    } else if (this.isVenue) {
      content = <Redirect to="/venues" />;
    } else {
      content = (
        <div>
          {' '}
          <div className="home">
            <div className="home__section home__section--teams">
              <img src={teamBg} />
              <Link to="/teams">Teams</Link>
            </div>
            <div className="home__section home__section--schedule">
              <img src={scheduleBg} />
              <Link to="/agenda">My Agenda</Link>
            </div>
            <div className="home__section home__section--social">
              <img src={socialBg} />
              <Link to="/ranking">Ladder</Link>
            </div>
            <div className="home__section home__section--social">
              <img src={socialBg} />
              <Link to="/social-play">Social Play</Link>
            </div>
          </div>
          {mobile ? (
            <div className="row">
              <div className="col-sm text-center font-weight-bold">
                Download the App and login to have the best experience!
              </div>
            </div>
          ) : null}
          {ios ? (
            <div className="row">
              <div className="col-sm text-center">
                <MobileStoreButton
                  store="ios"
                  url="https://itunes.apple.com/us/app/tennisfolder-plus/id1360656806"
                />
              </div>
            </div>
          ) : null}
          {android ? (
            <div className="row">
              <div className="col-sm text-center">
                <MobileStoreButton
                  store="android"
                  url="https://play.google.com/store/apps/details?id=com.tennisfolder.tennisfolderv1"
                />
              </div>
            </div>
          ) : null}
          {!this.state.loading &&
            this.props.display &&
            this.props.display.indexOf('setAccountPreferences') !== -1 && (
              <Redirect to="/welcome" />
            )}
        </div>
      );
    }
    return content;
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onLoad: id =>
      id &&
      dispatch({
        type: USERS_DISPLAY,
        payload: agent.Auth.display(id),
      }),
  };
};

const mapStateToProps = state => ({
  ...state.auth,
  ...state.common,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HomeContainer);
