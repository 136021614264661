import React from 'react';
import { connect } from 'react-redux';
import Loader from '../components/UI/Loader/index';
import EventDetail from './components/event-detail';
import {
  getEvent,
  updateEvent,
  setPlayerEventStatus,
  updateGame,
  confirmScores,
} from './components/redux/actions';
import { debounce } from 'throttle-debounce';
import { toastr } from 'react-redux-toastr';

import ROLES from '../data/roles';

class ChallengeContainer extends React.Component {
  state = {};

  timeout = { timeOut: 0 };

  componentDidMount() {
    this.loadEvent();
    this.updateNotes = debounce(350, this.updateNotes);
  }

  loadEvent = () => {
    const { eventId } = this.props.match.params;
    const isAdmin = !!this.props.roles.find(
      role => role === ROLES.ADMINISTRATOR || role === ROLES.VENUE
    );
    this.props.onLoad(eventId, isAdmin).catch(e => {
      toastr.warning(
        'Event Detail',
        `Error showing event detail ${e}`,
        this.timeout
      );
    });
  };

  notesChange = ev => {
    this.updateNotes(ev.target.value);
  };

  finalizeEvent = () => {
    const {
      event: { id },
    } = this.props;
    return this.props.onUpdateEvent(id, {
      status: 'closed',
    });
  };

  unFinalizeEvent = () => {
    const {
      event: { id },
    } = this.props;
    return this.props.onUpdateEvent(id, {
      status: 'open',
    });
  };

  updateNotes(notes) {
    const {
      event: { id },
    } = this.props;
    return this.props.onUpdateEvent(id, {
      notes,
    });
  }

  changePlayerStatus(user, status) {
    const { event } = this.props;
    if (event && user != null) {
      return this.props.onEventStatus(event.id, {
        status,
        user,
      });
    }
  }

  handleUpdateGame = (gameId, body) => {
    const { event } = this.props;
    if (event) {
      this.props.onUpdateGame(event.id, gameId, body);
    }
  };

  handleConfirmScores = () => {
    const { event } = this.props;
    if (event) {
      this.props.onConfirmScores(event.id);
    }
  };

  render() {
    const { event, currentUser, playerOpts } = this.props;

    const isAdmin = !!this.props.roles.find(
      role => role === ROLES.ADMINISTRATOR || role === ROLES.VENUE
    );
    const isCreator =
      event && event.creator && event.creator._id === currentUser._id;
    return (
      <div className="main-outline">
        {event ? (
          <EventDetail
            isAdmin={isAdmin}
            isCreator={isCreator}
            event={event}
            updateNotes={this.notesChange}
            finalizeEvent={this.finalizeEvent}
            unfinalizeEvent={this.unFinalizeEvent}
            updateGame={(gameId, body) => this.handleUpdateGame(gameId, body)}
            confirmScores={() => this.handleConfirmScores()}
            currentUser={currentUser}
            playerOpts={playerOpts}
            setPlayerStatus={(user, status) =>
              this.changePlayerStatus(user, status)
            }
          />
        ) : (
          <Loader />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  ...state.challenge,
  ...state.common,
});

const mapDispatchToProps = dispatch => ({
  onLoad: (id, isAdmin) => dispatch(getEvent(id, isAdmin)),
  onUpdateEvent: (id, body) => dispatch(updateEvent(id, body)),
  onEventStatus: (id, body) => dispatch(setPlayerEventStatus(id, body)),
  onUpdateGame: (id, gameId, body) => dispatch(updateGame(id, gameId, body)),
  onConfirmScores: id => dispatch(confirmScores(id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChallengeContainer);
