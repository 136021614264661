import girlHitting from './girlhitting.png';
import ballOnGround from './IMG_4362.png';
import ballOnNet from './IMG_4363.png';
import hittingBall from './IMG_4364.jpg';
import courts from './IMG_4365.jpg';
import sunsetBall from './IMG_4366.jpg';
import ballsOnCourt from './IMG_4367.png';
import ballAndRacket from './IMG_4368.jpg';

export default [
  {
    id: 1,
    name: 'Girl Hitting Ball',
    src: girlHitting,
  },
  {
    id: 2,
    name: 'Ball On Racket Cover',
    src: ballOnGround,
  },
  {
    id: 3,
    name: 'Ball Hitting Net',
    src: ballOnNet,
  },
  {
    id: 4,
    name: 'Racket Hitting Ball',
    src: hittingBall,
  },
  {
    id: 5,
    name: 'Tennis Courts',
    src: courts,
  },
  {
    id: 6,
    name: 'Ball Against Sunset',
    src: sunsetBall,
  },
  {
    id: 7,
    name: 'Balls on Court',
    src: ballsOnCourt,
  },
  {
    id: 8,
    name: 'Ball and Racket',
    src: ballAndRacket,
  },
];
