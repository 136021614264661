import React from 'react';
import { connect } from 'react-redux';
import agent from '../../agent';
import { Link, Redirect } from 'react-router-dom';
import TeamList from '../../teams/components/team-list.component';
import { teamLoad } from '../../teams/action';
import Loader from '../../components/UI/Loader';
import { VENUE_TEAM_LOAD, VENUE_TEAM_PAST_LOAD } from '../../teams/constants';


export class VenueTeamsContainer extends React.Component {
  componentDidMount() {
    const venueId = this.props.match.params.id;
    if (!this.props.venueTeams) {
      this.props.onLoad(
        agent.Teams.getAll({ venue: venueId, current: true }),
        VENUE_TEAM_LOAD
      );
    }
    if (!this.props.venuePastTeams) {
      this.props.onLoad(
        agent.Teams.getAll({ venue: venueId, current: false }),
        VENUE_TEAM_PAST_LOAD
      );
    }
  }

  hasRole(role) {
    return this.props.roles && this.props.roles.indexOf(role) >= 0;
  }

  render() {
    const isVenueAdmin = this.hasRole('Venue');
    const isAdmin = this.hasRole('Admin');
    const currentTeams = this.props.venueTeams;
    const pastTeams = this.props.venuePastTeams;
    const hasCurrentTeams = currentTeams && currentTeams.length !== 0;
    const hasPastTeams = pastTeams && pastTeams.length !== 0;
    return (
      <div>
        <h2>
          Teams List
          {isVenueAdmin &&
            (isAdmin ? (
              <span className="float-right">
                <Link to="/teams/new" className="btn btn-primary btn-sm">
                  <i className="fa fa-plus fa-fw" /> Add New Team
                </Link>
              </span>
            ) : (
              <span className="float-right">
                <Link to="/teams/request" className="btn btn-primary btn-sm">
                  <i className="fa fa-plus fa-fw" /> Request New Team
                </Link>
              </span>
            ))}
        </h2>
        {currentTeams ? (
          currentTeams.length ? (
            <TeamList teams={currentTeams} />
          ) : null
        ) : (
          <Loader />
        )}
        {hasPastTeams && hasCurrentTeams && <h2>Past Teams List</h2>}
        {hasPastTeams ? <TeamList teams={pastTeams} /> : null}
        {!hasPastTeams && !hasCurrentTeams && <div> No Teams </div>}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  ...state.teams,
  ...state.common,
});

const mapDispatchToProps = dispatch => ({
  onLoad: (payload, context) => dispatch(teamLoad(payload, context)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VenueTeamsContainer);
