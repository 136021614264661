import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import InputUI from '../../UI/Input';
import validations from '../../../data/validations';
import TextAreaUI from '../../UI/TextArea';
import Select from '../../UI/Select/Select';
import TimePicker from '../../UI/DateTimePicker';
import { MaskedSelect } from '../../UI/Select';
import {
  getRatingSum,
  MAX_RATING,
  MIN_RATING,
  playTypes,
} from '../../../social-play/service/social-play';
import { MIXED, SINGLES } from '../../../data/play-type';
import validators from '../../../data/validations';
import { days, eventTypes } from '../../../data/types';
import _memoize from 'lodash/memoize';

const repeatOptions = [
  { name: 'Sunday', id: days.SUNDAY },
  { name: 'Monday', id: days.MONDAY },
  { name: 'Tuesday', id: days.TUESDAY },
  { name: 'Wednesday', id: days.WEDNESDAY },
  { name: 'Thursday', id: days.THURSDAY },
  { name: 'Friday', id: days.FRIDAY },
  { name: 'Saturday', id: days.SATURDAY },
];

const genderOptions = [
  {
    id: 1,
    name: 'Male',
  },
  {
    id: 2,
    name: 'Female',
  },
  {
    id: 4,
    name: 'Any',
  },
];

const isRequired = _memoize(message => value => {
  return validators.required(value, message);
});

export class VenueEventFields extends Component {
  static propTypes = {
    type: PropTypes.string,
    formValues: PropTypes.object,
    groups: PropTypes.array,
  };

  state = {
    playType: SINGLES,
    filterByRating: false,
  };

  isNotSocialOrLesson = () => {
    const { type } = this.props;
    return type !== eventTypes.SOCIAL && type !== eventTypes.LESSON;
  };

  isSocial = () => {
    const { type } = this.props;
    return type === eventTypes.SOCIAL;
  };

  isClinicOrLesson = () => {
    const { type } = this.props;
    return type === eventTypes.CLINIC || type === eventTypes.LESSON;
  };

  toggleFilterByRating = () => {
    this.setState({ filterByRating: !this.state.filterByRating });
  };

  updateTargetRating = val => {
    this.setState({ rating: Number(val.target.value) });
  };

  validateGroup = values => {
    if (!values) {
      return null;
    }
    let invalid = false;
    // Used for multi select
    if (values.length > 0) {
      let count = 0;
      values.forEach(value => {
        count = count + value.users.length;
      });
      invalid = count <= 1;
    }
    // Used for single select
    if (values.users) {
      invalid = values.users.length <= 1;
    }
    return invalid ? 'Not enough players in this circle' : null;
  };

  render() {
    const { formValues, groups } = this.props;
    const { filterByRating, rating } = this.state;
    const isSocial = this.isSocial();
    const isClinicOrLesson = this.isClinicOrLesson();
    const isNotSocialOrLesson = this.isNotSocialOrLesson();
    const circlesOnly = formValues && formValues.groups;
    return (
      <div>
        {!isSocial && (
          <Field
            type="text"
            name="name"
            label="Name"
            component={InputUI}
            validate={validations.required}
          />
        )}
        <Field name="notes" component={TextAreaUI} label="Add Notes" />
        {isClinicOrLesson && (
          <Field name="cost" component={InputUI} label="Add Cost" />
        )}
        {!isSocial && (
          <Field
            name="groups"
            label="Only Invite From Circle (Optional)"
            component={Select}
            options={groups}
            multi={false}
            creatable={false}
            warn={[this.validateGroup]}
            valueKey="groups"
            labelKey="name"
            placeholder="Select a Circle..."
          />
        )}
        {isNotSocialOrLesson && (
          <div>
            <Field
              name="maxPlayers"
              component={InputUI}
              label="Maximum Number of Players"
              type="number"
              min={2}
              step={1}
            />
            <Field
              name="minPlayers"
              component={InputUI}
              label="Minimum Number of Players"
              type="number"
              min={2}
              step={1}
            />
          </div>
        )}
        <Field
          name="eventDate"
          showTime={false}
          showDate={true}
          component={TimePicker}
          label="Select Event Date"
          validate={validations.required}
        />
        <Field
          name="startTime"
          showDate={false}
          component={TimePicker}
          label="Select Event Start Time"
          validate={validations.required}
        />
        <div>
          <Field
            name="repeat"
            component={InputUI}
            label="Select Week Repeat Count"
            type="number"
            min={0}
            max={52}
            step={1}
          />
          <Field
            name="days"
            label="Repeat Days"
            component={Select}
            options={repeatOptions}
            labelKey="name"
            valueKey="id"
            multi={true}
          />
        </div>
        {!isSocial && !circlesOnly && (
          <div>
            <Field
              name="gender"
              label="Gender (optional)"
              component={MaskedSelect}
              options={genderOptions.slice(0, 2)}
              valueKey="id"
              labelKey="name"
              onChange={(_, gender) => {
                this.gender = gender;
              }}
            />
            <div className="form-check">
              <label className="form-check-label">
                <Field
                  name="filterByRating"
                  component="input"
                  type="checkbox"
                  className="form-check-input"
                  checked={filterByRating}
                  onChange={() => this.toggleFilterByRating()}
                />
                Filter by Rating
              </label>
            </div>
            {filterByRating && (
              <div>
                <label className="form-check">
                  Target Rating
                  <input
                    type="number"
                    value={rating}
                    name="rating"
                    className="form-control"
                    min={1}
                    max={7}
                    step={0.5}
                    onChange={value => this.updateTargetRating(value)}
                  />
                </label>
                <div>
                  {' '}
                  Would you like to also invite players half a rating
                  lower/higher: <br />
                  {getRatingSum(rating, -0.5) >= MIN_RATING && (
                    <div className="form-check mt-2 ml-4">
                      <label className="form-check-label">
                        <Field
                          type="checkbox"
                          name="halfUnder"
                          component="input"
                          className="form-check-input"
                        />
                        {getRatingSum(rating, -0.5)}{' '}
                        {_clientFeatures.rating.label
                          ? 'Rating'
                          : 'USTA Rating'}
                      </label>
                    </div>
                  )}
                  {getRatingSum(rating, 0.5) <= MAX_RATING && (
                    <div className="form-check ml-4">
                      <label className="form-check-label">
                        <Field
                          type="checkbox"
                          name="halfOver"
                          component="input"
                          className="form-check-input"
                        />
                        {getRatingSum(rating, 0.5)}{' '}
                        {_clientFeatures.rating.label
                          ? 'Rating'
                          : 'USTA Rating'}
                      </label>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        )}
        {isNotSocialOrLesson && !circlesOnly && (
          <div>
            <div className="form-check">
              <label className="form-check-label">
                <Field
                  name="public"
                  component="input"
                  type="checkbox"
                  className="form-check-input"
                />
                Members Only
              </label>
            </div>
          </div>
        )}
        {isSocial && (
          <div>
            <Field
              name="playType"
              label="Play Type"
              component={MaskedSelect}
              options={playTypes}
              validate={[isRequired('Play type is required')]}
              valueKey="id"
              labelKey="name"
              onChange={(_, playType) => {
                this.playType = playType;
                this.setState(this.state);
              }}
            />
            {this.playType === MIXED && (
              <Field
                name="combinedRating"
                type="number"
                min="1"
                max="10"
                step="0.5"
                validate={[
                  isRequired('Combined Rating is Required'),
                  validators.comboUstaRating,
                ]}
                component={InputUI}
                label="Combined Rating"
              />
            )}
            {this.playType !== MIXED && (
              <div>
                <Field
                  name="gender"
                  label="Gender"
                  component={MaskedSelect}
                  options={genderOptions}
                  validate={[isRequired('Gender is required')]}
                  valueKey="id"
                  labelKey="name"
                  onChange={(_, gender) => {
                    this.gender = gender;
                  }}
                />
                <label className="form-check">
                  Target Rating
                  <input
                    type="number"
                    value={rating}
                    name="rating"
                    className="form-control"
                    min={1}
                    max={7}
                    step={0.5}
                    onChange={value => this.updateTargetRating(value)}
                  />
                </label>
                {rating && (
                  <div>
                    {' '}
                    Would you like to also invite players half a rating
                    lower/higher: <br />
                    {getRatingSum(rating, -0.5) >= MIN_RATING && (
                      <div className="form-check mt-2 ml-4">
                        <label className="form-check-label">
                          <Field
                            type="checkbox"
                            name="halfUnder"
                            component="input"
                            className="form-check-input"
                          />
                          {getRatingSum(rating, -0.5)}{' '}
                          {_clientFeatures.rating.label
                            ? 'Rating'
                            : 'USTA Rating'}
                        </label>
                      </div>
                    )}
                    {getRatingSum(rating, 0.5) <= MAX_RATING && (
                      <div className="form-check ml-4">
                        <label className="form-check-label">
                          <Field
                            type="checkbox"
                            name="halfOver"
                            component="input"
                            className="form-check-input"
                          />
                          {getRatingSum(rating, 0.5)}{' '}
                          {_clientFeatures.rating.label
                            ? 'Rating'
                            : 'USTA Rating'}
                        </label>
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        )}
        <div>
          <div className="form-check">
            <label className="form-check-label">
              <Field
                name="notify"
                component="input"
                type="checkbox"
                className="form-check-input"
              />
              Send Invite Notifications
            </label>
          </div>
        </div>
      </div>
    );
  }
}

export default VenueEventFields;
