import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import Select from '../../UI/Select/Select';
import { SINGLES, DOUBLES, challengePlayTypes } from '../../../data/play-type';
import _memoize from 'lodash/memoize';
import validators from '../../../data/validations';
import { MaskedSelect } from '../../UI/Select';
import InputUI from '../../UI/Input';
import validations from '../../../data/validations';
import TextAreaUI from '../../UI/TextArea';
import TimePicker from '../../UI/DateTimePicker';

const isRequired = _memoize(message => value => {
  return validators.required(value, message);
});

export class ChallengeEventFields extends Component {
  static propTypes = {
    playerOpts: PropTypes.array,
  };

  state = {
    homePlayer1: null,
    homePlayer2: null,
    awayPlayer1: null,
    awayPlayer2: null,
    availablePlayers: [],
    playType: null,
  };

  handleSetPlayType = playType => {
    const { playerOpts } = this.props;
    let { availablePlayers } = this.state;
    const isSingles = playType === SINGLES;
    if (availablePlayers.length === 0 && playerOpts && playerOpts.length > 0) {
      availablePlayers = playerOpts
        .sort((a, b) => {
          if (isSingles) {
            return this.compareEloScore(a.eloSingles, b.eloSingles);
          }
          return this.compareEloScore(a.eloDoubles, b.eloDoubles);
        })
        .map((player, index) => {
          const { eloSingles, eloDoubles, displayName, _id } = player;
          const eloScore = isSingles ? eloSingles : eloDoubles;
          const label = eloScore
            ? `${index + 1} | ${displayName}`
            : displayName;
          return { eloSingles, eloDoubles, label, id: _id };
        });
    }
    this.setState({
      availablePlayers,
      playType,
    });
  };

  handleSetPlayer = (position, player) => {
    const { id } = player;
    const { availablePlayers } = this.state;
    if (availablePlayers.length > 0) {
      this.setState({
        [position]: id,
      });
    }
  };

  compareEloScore = (scoreA, scoreB) => {
    if (scoreA === scoreB) {
      return 0;
    }
    if (!scoreA) {
      return 1;
    }
    if (!scoreB) {
      return -1;
    }
    return scoreB - scoreA;
  };

  getPlayerOptions = () => {
    const {
      availablePlayers,
      homePlayer1,
      homePlayer2,
      awayPlayer1,
      awayPlayer2,
    } = this.state;
    if (availablePlayers.length > 0) {
      return availablePlayers.filter(player => {
        const { id } = player;
        return (
          id !== homePlayer1 &&
          id !== homePlayer2 &&
          id !== awayPlayer1 &&
          id !== awayPlayer2
        );
      });
    }
    return [];
  };

  renderHomePlayers = options => {
    const { playType } = this.state;
    const isDoubles = playType === DOUBLES;
    return (
      <div>
        <h4>Home</h4>
        <Field
          name="homePlayer1"
          label="Player 1"
          component={Select}
          options={options}
          validate={[isRequired('Player 1 is required')]}
          multi={false}
          creatable={false}
          valueKey="id"
          labelKey="label"
          placeholder="Select a player..."
          onChange={playerId => {
            this.handleSetPlayer('homePlayer1', playerId);
          }}
        />
        {isDoubles && (
          <Field
            name="homePlayer2"
            label="Player 2"
            component={Select}
            options={options}
            validate={[isRequired('Player 2 is required')]}
            multi={false}
            creatable={false}
            valueKey="id"
            labelKey="label"
            placeholder="Select a player..."
            onChange={playerId => {
              this.handleSetPlayer('homePlayer2', playerId);
            }}
          />
        )}
      </div>
    );
  };

  renderAwayPlayers = options => {
    const { playType } = this.state;
    const isDoubles = playType === DOUBLES;
    return (
      <div>
        <h4>Away</h4>
        <Field
          name="awayPlayer1"
          label="Player 1"
          component={Select}
          options={options}
          validate={[isRequired('Player 1 is required')]}
          multi={false}
          creatable={false}
          valueKey="id"
          labelKey="label"
          placeholder="Select a player..."
          onChange={playerId => {
            this.handleSetPlayer('awayPlayer1', playerId);
          }}
        />
        {isDoubles && (
          <Field
            name="awayPlayer2"
            label="Player 2"
            component={Select}
            options={options}
            validate={[isRequired('Player 2 is required')]}
            multi={false}
            creatable={false}
            valueKey="id"
            labelKey="label"
            placeholder="Select a player..."
            onChange={playerId => {
              this.handleSetPlayer('awayPlayer2', playerId);
            }}
          />
        )}
      </div>
    );
  };

  render() {
    const { playType } = this.state;
    const options = this.getPlayerOptions();
    const isDoubles = playType === DOUBLES;
    return (
      <div>
        <Field
          type="text"
          name="name"
          label="Name"
          component={InputUI}
          validate={validations.required}
        />
        <Field name="notes" component={TextAreaUI} label="Add Notes" />
        <Field
          name="eventDate"
          showTime={false}
          showDate={true}
          component={TimePicker}
          label="Select Event Date"
          validate={validations.required}
        />
        <Field
          name="startTime"
          showDate={false}
          component={TimePicker}
          label="Select Event Start Time"
          validate={validations.required}
        />
        <Field
          name="playType"
          label="Play Type"
          component={MaskedSelect}
          options={challengePlayTypes}
          validate={[isRequired('Play type is required')]}
          valueKey="id"
          labelKey="name"
          onChange={(_, playType) => {
            this.playType = playType;
            this.handleSetPlayType(playType);
          }}
        />
        {playType && this.renderHomePlayers(options)}
        {playType && this.renderAwayPlayers(options)}
        {isDoubles && (
          <div>
            <div className="form-check">
              <label className="form-check-label">
                <Field
                  name="roundRobin"
                  component="input"
                  type="checkbox"
                  className="form-check-input"
                />
                Round Robin
              </label>
            </div>
          </div>
        )}
      </div>
    );
  }
}
