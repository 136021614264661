import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import PropTypes from 'prop-types';

import InputUI from '../../components/UI/Input';
import ListErrors from '../../common/list-errors';

export class AddGroupModal extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    show: PropTypes.bool.isRequired, // Should be the same state as the one used in the toggle function
    toggle: PropTypes.func.isRequired, // Uses the parents state to control opening/closing of the dialog
  };

  constructor(props) {
    super(props);
    this.state = {
      modal: this.props.show,
    };
  }

  validateGroup = value => {
    if (!value || value.length === 0) {
      return 'invalid';
    }
    return undefined;
  };

  render() {
    const { invalid, submitting, errors, handleSubmit } = this.props;

    return (
      <Modal isOpen={this.state.modal} className={this.props.className}>
        <form onSubmit={handleSubmit}>
          <ModalHeader toggle={this.props.toggle}>
            {this.props.title}
          </ModalHeader>
          <ModalBody>
            <ListErrors errors={errors} />
            {this.props.message}
            <br />
            <br />
            <Field
              name="name"
              component={InputUI}
              validate={[this.validateGroup]}
              label={`New Group Name (required)`}
              placeholder="Add a Group Name..."
            />
          </ModalBody>
          <ModalFooter>
            <a className="btn btn-link" onClick={this.props.toggle}>
              Cancel
            </a>
            <Button disabled={submitting || invalid} color="primary">
              Ok
            </Button>
          </ModalFooter>
        </form>
      </Modal>
    );
  }
}

export default reduxForm({ form: 'addGroupForm' })(AddGroupModal);
