import React from 'react';
import AddVenueModal from './add-venue-modal';
import ConfirmationDialog from '../../components/UI/ConfirmationDialog';

export class VenuesComponent extends React.Component {
  state = {
    showAddVenue: false,
    showRemoveVenue: false,
    selectedVenue: '',
  };

  getSortedVenues = venues => {
    return [].concat(venues).sort((a, b) => {
      return a.name.localeCompare(b.name);
    });
  };

  toggleAddVenueDialog = venue => {
    this.setState({
      showAddVenue: !this.state.showAddVenue,
      selectedVenue: venue,
    });
  };

  toggleRemoveVenueDialog = venue => {
    this.setState({
      showRemoveVenue: !this.state.showRemoveVenue,
      selectedVenue: venue,
    });
  };

  render() {
    const { showAddVenue, showRemoveVenue, selectedVenue } = this.state;
    const { venues, settings, currentUser } = this.props;
    const preferred = settings.venues;
    const availableVenues = venues
      ? venues.filter(
          venue => !preferred.some(preferred => preferred.id === venue._id)
        )
      : [];
    const hasVenues = preferred && preferred.length;

    const venueRows = preferred
      ? this.getSortedVenues(preferred).map(venue => (
          <tr key={venue._id} className="admin-user-table__user">
            <td>
              <div>{venue.name}</div>
            </td>
            <td>
              <div>{venue.displayAddress}</div>
            </td>
            <td>
              <div className="float-right">
                <button
                  title="Remove"
                  onClick={e => {
                    e.preventDefault();
                    this.toggleRemoveVenueDialog(venue);
                  }}
                  className="btn btn-sm btn-outline-danger">
                  <i className="fa fa-trash" />
                </button>
              </div>
            </td>
          </tr>
        ))
      : null;

    return (
      <div className="">
        <table className="table">
          <thead>
            <tr>
              <th>{hasVenues ? 'Name' : ''}</th>
              <th>{hasVenues ? 'Address' : ''}</th>
              <th>
                <div className="float-right">
                  <button
                    title="Add Venue"
                    onClick={e => {
                      e.preventDefault();
                      this.toggleAddVenueDialog();
                    }}
                    className="btn btn-sm btn-outline-primary">
                    <i className="fa fa-plus fa-fw" />
                    Add Venue
                  </button>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>{venueRows}</tbody>
        </table>
        {showAddVenue && (
          <AddVenueModal
            title={'Add Venue'}
            message={`Select a venue to add to your preferences.`}
            show={showAddVenue}
            toggle={this.toggleAddVenueDialog}
            options={availableVenues}
            inputRequired={true}
            onSubmit={values => {
              const venueIds = values.venues
                ? values.venues.map(venue => venue._id)
                : [];
              this.toggleAddVenueDialog();
              this.props.onAddVenues(venueIds);
            }}
            initialValues={currentUser}
          />
        )}
        {showRemoveVenue && (
          <ConfirmationDialog
            show={showRemoveVenue}
            title={'Remove Venue'}
            buttonLabels={{ ok: 'Remove' }}
            message={`Are you sure you want to remove ${
              selectedVenue.name
            } from your preferences?`}
            toggle={this.toggleRemoveVenueDialog}
            onSubmit={() => {
              this.props.onRemoveVenue(selectedVenue._id);
            }}
          />
        )}
      </div>
    );
  }
}
