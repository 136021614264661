import React from 'react';

// Replace spaces w nbsp;
// {title.replace(/ /g, "\u00a0")}

export class MainTitleContainer extends React.Component {
  render() {
    const { selectedVenue, location } = this.props;
    return selectedVenue && location.pathname !== '/venues' ? (
      <div className="main-title-wrapper">
        <h1 className={'main-title'}>
          <span className="text-success main-title-brackets">[</span>
          {selectedVenue.name}
          <span className="text-success">]</span>
          <br />
        </h1>
      </div>
    ) : null;
  }
}
