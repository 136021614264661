import React from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, reduxForm, formValueSelector, getFormValues } from 'redux-form';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';

import InputUI from '../../components/UI/Input';
import Select from '../../components/UI/Select';
import ListErrors from '../../common/list-errors';
import {
  leagueTypes,
  COMBO,
  SINGLES_AND_DOUBLES,
  MIXED,
} from '../../data/play-type';
import genders from '../../data/genders';
import validation from '../../data/validations';

const DEFAULT_LEAGUE = {
  id: null,
  gender: null,
  playType: null,
  gameOrder: null,
  rating: null,
};

const singlesAndDoublesGO = 'S1,D1,S2,D2,D3';
const doublesOrMixedGO = 'D1,D2,D3';

const leagueOpts = leagueTypes.filter(type => type.id !== MIXED);

export class NewLeagueModalContainer extends React.Component {
  state = {
    modal: true,
  };

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  onModalClose = () => {
    this.setState({ redirect: true });
  };

  onGenderChange = (e, newGender) => {
    const ndx = leagueOpts.findIndex(type => type.id === MIXED);
    let playType;
    // This is has the gender 'mixed', assign it a combo
    if (newGender.id === 3) {
      playType = { id: COMBO, name: 'Combo' };
    } else {
      playType = '';
      if (ndx >= 0) {
        leagueOpts.splice(ndx, 1);
      }
    }
    this.props.change('playType', playType);
  };

  gameOrderPH = () => {
    return this.props.genderValue &&
      this.props.genderValue.id !== 0 &&
      this.props.playTypeValue &&
      this.props.playTypeValue.id === SINGLES_AND_DOUBLES
      ? singlesAndDoublesGO
      : doublesOrMixedGO;
  };

  gameOrder = value => {
    const games = value && value.toUpperCase().split(',');
    return games &&
      games.length > 0 &&
      games.filter(game => {
        const first = game[0];
        const second = Number(game[1]);
        const twoCharsLong = game.length === 2;
        const startWithSOrD =
          (this.props.playTypeValue &&
            this.props.playTypeValue.id === SINGLES_AND_DOUBLES &&
            first === 'S') ||
          first === 'D' ||
          games.length === 1;
        const isSecondValidCount = !isNaN(second) && second >= 1 && second <= 6;
        return !(twoCharsLong && startWithSOrD && isSecondValidCount);
      }).length === 0
      ? undefined
      : `Game order must be a similar format to "${this.gameOrderPH()}".`;
  };

  onSubmit = values => {
    values.id = this.props.league && this.props.league.id;
    this.props.onSubmit(values);
    this.setState({ redirect: true });
  };

  render() {
    const {
      handleSubmit,
      invalid,
      errors,
      pristine,
      submitting,
      seasons,
      genderValue,
      playTypeValue,
      league = DEFAULT_LEAGUE,
      formValues,
    } = this.props;

    const isEdit = league.id !== null;
    const isComboType =
      (genderValue && genderValue.id === 0) ||
      (playTypeValue && playTypeValue.id === COMBO);
    const isFlight = formValues && formValues.flight;

    return this.state.redirect ? (
      <Redirect to="." />
    ) : (
      <Modal
        isOpen={this.state.modal}
        onClosed={this.onModalClose}
        className={this.props.className}>
        <form onSubmit={handleSubmit(this.onSubmit)}>
          <ModalHeader toggle={this.toggle}>
            {isEdit ? 'Edit' : 'Create New'} League
          </ModalHeader>
          <ModalBody>
            <ListErrors errors={errors} />

            <Field
              name="season"
              validate={validation.required}
              component={Select}
              options={seasons}
              valueKey="id"
              labelKey="name"
              label="Season"
            />

            <Field
              name="gender"
              validate={validation.required}
              component={Select}
              options={genders}
              valueKey="id"
              labelKey="name"
              label="Gender"
              onChange={this.onGenderChange}
            />

            {genderValue && genderValue.id !== 0 && genderValue.id !== 3 && (
              <Field
                name="playType"
                validate={validation.required}
                component={Select}
                options={leagueOpts}
                valueKey="id"
                labelKey="name"
                label="Play Type"
              />
            )}

            <Field
              name="gameOrder"
              component={InputUI}
              validate={[validation.required, this.gameOrder]}
              label="Game Order"
              placeholder={this.gameOrderPH()}
            />

            <Field
              name="rating"
              validate={[
                validation.required,
                isComboType
                  ? validation.comboUstaRating
                  : validation.ustaRating,
              ]}
              component={InputUI}
              label={
                isComboType
                  ? 'Combined Rating (must be less than or equal to)'
                  : 'Player Rating'
              }
            />
            <div className="form-check">
              <label className="form-check-label">
                <Field
                  type="checkbox"
                  name="flight"
                  component={field => (
                    <input
                      {...field.input}
                      type="checkbox"
                      className="form-check-input"
                    />
                  )}
                />
                Allow Flights
              </label>
            </div>
            {isFlight && (
              <div>
                <div className="form-check">
                  <label className="form-check-label">
                    <Field
                      type="checkbox"
                      name="chargeCaptain"
                      component={field => (
                        <input
                          {...field.input}
                          type="checkbox"
                          className="form-check-input"
                        />
                      )}
                    />
                    Charge Captains?
                  </label>
                </div>
                <Field
                  name="cost"
                  type="number"
                  min="0"
                  step="0.01"
                  validate={validation.number}
                  component={InputUI}
                  label="Price per Player"
                />
                <label className="form-check-label">
                  <Field
                    type="checkbox"
                    name="eloRating"
                    component={field => (
                      <input
                        {...field.input}
                        type="checkbox"
                        className="form-check-input"
                      />
                    )}
                  />
                  Count for ladder
                </label>
              </div>
            )}
          </ModalBody>
          <ModalFooter>
            <a className="btn btn-link" onClick={this.toggle}>
              Cancel
            </a>
            <Button
              disabled={pristine || submitting || errors || invalid}
              color="primary">
              {isEdit ? 'Update' : 'Create'} League
            </Button>
          </ModalFooter>
        </form>
      </Modal>
    );
  }
}

const selector = formValueSelector('newLeagueForm'); // Must be the same as form passed into reduxForm()
const reqFormValues = getFormValues('newLeagueForm');
export default compose(
  connect((state, props) => {
    // TODO: this takes values from the redux form and puts it on props...
    // How would this work with initialValues
    const genderValue = selector(state, 'gender');
    const playTypeValue = selector(state, 'playType');
    return {
      genderValue,
      playTypeValue,
      formValues: reqFormValues(state),
      initialValues: props.league
        ? {
            ...props.league,
            cost:
              props.league.flight && props.league.cost !== undefined
                ? props.league.cost
                : 8.99,
            season: {
              id: props.league.season.id,
              name: props.league.season.name,
            },
          }
        : undefined,
    };
  }),
  reduxForm({
    form: 'newLeagueForm',
    enableReinitialize: true,
  })
)(NewLeagueModalContainer);
