import React from 'react';
import { localTime } from '../../common/services/utils/helpers';
import { connect } from 'react-redux';
import {
  inviteResponse,
  eventTypes,
  eventStatus,
  eventTypesArray,
} from '../../data/types';
import { Link } from 'react-router-dom';

class EventList extends React.Component {
  getEventType = type => {
    const event = eventTypesArray.find(e => e.id === type);
    return event ? event.name : 'Venue';
  };

  getUserResponse = event => {
    let response = event.userResponse;
    if (
      event.type === eventTypes.SOCIAL &&
      response === inviteResponse.CONFIRMED &&
      event.status === eventStatus.OPEN
    ) {
      response = inviteResponse.ACCEPT;
    }
    let text = 'No Response';
    let style = 'secondary';
    switch (response) {
      case inviteResponse.DECLINE:
        text = 'Declined';
        style = 'danger';
        break;
      case inviteResponse.MAYBE:
        text = 'Pending';
        style = 'warning';
        break;
      case inviteResponse.ACCEPT:
        text = 'Accepted';
        style = 'success';
        break;
      case inviteResponse.CONFIRMED:
        text = 'Confirmed';
        style = 'success';
        break;
      default:
        break;
    }
    return <small className={'mt-1 mr-3 text-' + style}>{text}</small>;
  };

  getEventLink = event => {
    let link = null;
    switch (event.type) {
      case eventTypes.SOCIAL:
        link = `social-play/${event._id}`;
        break;
      case eventTypes.MATCH:
      case eventTypes.FLIGHTMATCH:
        link = `teams/${event.team._id}/matches/${event._id}`;
        break;
      case eventTypes.PRACTICE:
        link = `teams/${event.team._id}/practices/${event._id}`;
        break;
      // venue events currently do not work if you are not a venue admin.
      case eventTypes.VENUESOCIAL:
      case eventTypes.DROPIN:
      case eventTypes.CLINIC:
      case eventTypes.LESSON:
      case eventTypes.TOURNAMENT:
      case eventTypes.CHALLENGE:
        link = `venues/${event.venueId}/${event.type}/${event._id}`;
        break;
      default:
        break;
    }
    return link;
  };

  renderEventItem = event => {
    return (
      <div className="row align-items-center">
        {event.status === eventStatus.OPEN ? (
          <div
            title="Open"
            className={'col-sm-1 event-header event-header--' + event.type}>
            <i className=" fa fa-lg fa-folder-open text-success" />
          </div>
        ) : (
          <div
            title={event.type === eventTypes.DROPIN ? 'Filled' : 'Closed'}
            className={'col-sm-1 event-header event-header--' + event.type}>
            <i className=" fa fa-lg fa-folder text-danger" />
          </div>
        )}
        <div className="col-sm-1">
          <small className="mt-1 mr-3">{this.getEventType(event.type)}</small>
        </div>
        <div className="col-sm-3">
          <small className="mt-1 mr-3">
            {localTime(event.start).format('MMM DD, YYYY @ h:mm a')}
          </small>
        </div>
        {event.type === eventTypes.MATCH ||
        event.type === eventTypes.FLIGHTMATCH ? (
          <div className="col-sm-5">
            <small className="mt-1 mr-3">
              {event.team.name} vs. {event.opponent} (
              {event.location === 'home' ? 'Home' : 'Away'})
            </small>
          </div>
        ) : (
          <div className="col-sm-5">
            <small className="mt-1 mr-3">{event.venue}</small>
          </div>
        )}
        <div className="col-sm-2">{this.getUserResponse(event)}</div>
      </div>
    );
  };

  render() {
    const { events } = this.props;

    return (
      <div className="list-group">
        <div className="mt-3">
          {events.map((event, idx) => {
            const link = this.getEventLink(event);
            return link ? (
              <Link to={link} key={idx} className={'list-group-item'}>
                {this.renderEventItem(event)}
              </Link>
            ) : (
              <div key={idx} className={'list-group-item'}>
                {this.renderEventItem(event)}
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default connect()(EventList);
