import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { toastr } from 'react-redux-toastr';
import Button from '../../../components/UI/Button';
import TextArea from '../../../components/UI/TextArea';
import Select, { MaskedSelect } from '../../../components/UI/Select';
import DateTimePicker from '../../../components/UI/DateTimePicker';
import validators from '../../../data/validations';
import InputUI from '../../../components/UI/Input';
import moment from 'moment';
import _memoize from 'lodash/memoize';

import {
  playTypes,
  getRatingSum,
  MAX_RATING,
  MIN_RATING,
} from '../../service/social-play';
import { SINGLES, DOUBLES, MIXED } from '../../../data/play-type';
import { combineDateTime } from '../../../common/services/utils/helpers';

import { connect } from 'react-redux';
import { compose } from 'redux';

const genderOptions = [
  {
    id: 1,
    name: 'Male',
  },
  {
    id: 2,
    name: 'Female',
  },
  {
    id: 4,
    name: 'Any',
  },
];
/**
 * Redux Form doesn't like it when you pass in a
 * new [function] object per render / I don't want
 * to cache all the validators up here.. memoize on
 * the validation messages so we can just define them
 * inline
 * @param {string} message Validation message
 * @return {function(*): ?string}
 */
const isRequired = _memoize(message => value => {
  return validators.required(value, message);
});

const isFutureTime = (_, form) => {
  if (!form.playDate) {
    return "Please select a 'Play Date' first";
  }
  const combinedTime = combineDateTime(form.playDate, form.playTime);
  const now = new moment();
  return combinedTime.isAfter(now)
    ? undefined
    : 'Play time needs to be in the future';
};

class RequestSocialPlayForm extends React.Component {
  playType = SINGLES;
  gender = 0;
  itemCount = 0;
  // TODO: The above is kind of hacky. I can't use the itemCount and playType in state because when I call setState in handleMemberSelect, playerLimit does not get the updated
  // state value until the next time playerLimit is called. This seems similar to angular where we'd have to call an apply or digest on the scope.
  static defaultProps = {
    submitButtonText: 'Submit Request',
  };
  state = {
    venueEvent: false,
    rating: 0,
  };

  componentDidMount() {
    const rating = this.props.currentUser && this.props.currentUser.rating;
    this.setState({ rating: rating });
  }

  playerLimit = () => {
    if (this.playType === SINGLES && this.itemCount > 1) {
      return 'Singles event can only include you and another player';
    } else if (this.playType === DOUBLES && this.itemCount > 3) {
      return 'Doubles event can only include you and 3 other players';
    }
    return undefined;
  };

  handleMemberSelect = values => {
    let count = 0;
    while (values[count]) {
      count++;
    }
    this.itemCount = count;
  };

  get isClubAdmin() {
    return this.props.roles.indexOf('Venue') !== -1;
  }

  toggleVenueEvent() {
    this.setState({ venueEvent: !this.state.venueEvent });
  }

  updateTargetRating = val => {
    this.setState({ rating: Number(val.target.value) });
  };

  handleSubmit = values => {
    const friendsToAdd = [];
    if (values.players) {
      // This is for the case where the user types in an email instead of selecting from the list
      values.players = values.players.map(p => {
        if (!p.email) {
          p.email = p.id;
          friendsToAdd.push(p.email);
        }
        return p;
      });
    }
    let playDateTime = combineDateTime(values.playDate, values.playTime);
    let playTypes = {
      mixed: false,
      singles: false,
      doubles: false,
    };
    playTypes[values.playType] = true;
    const rating =
      this.state.venueEvent && this.state.rating
        ? this.state.rating
        : this.props.currentUser.rating;
    const maxRating = values.halfOver ? getRatingSum(rating, 0.5) : rating;
    const minRating = playTypes.mixed
      ? maxRating
      : values.halfUnder
      ? getRatingSum(rating, -0.5)
      : rating;
    let gender = null;
    if (values.playType !== MIXED) {
      if (this.state.venueEvent) {
        gender = values.gender;
      } else if (values.ignoreGender) {
        gender = 4;
      }
    }
    return this.props
      .onSubmit({
        start: playDateTime,
        public:
          this.state.venueEvent || values.friendsOnly === undefined
            ? true
            : !values.friendsOnly,
        venue: values.venue._id,
        type: 'social',
        playTypes: playTypes,
        notes: values.notes,
        players: values.players
          ? values.players.map(player => player.email)
          : undefined,
        minRating,
        maxRating: values.combinedRating ? values.combinedRating : maxRating,
        venueEvent: this.state.venueEvent,
        gender: gender,
      })
      .then(() => {
        this.props.reset();
        toastr.success(
          'Social Play',
          'Your Social Play Request has been created successfully.'
        );
      })
      .catch(e => {
        this.props.reset();
        if (e.message) {
          let errMsg = e.message;
          if (e.response.body.errors.message) {
            errMsg = e.response.body.errors.message;
          }
          toastr.error('Social Play', errMsg, {
            showCloseButton: true,
            removeOnHover: true,
            progressBar: false,
            timeOut: 30000,
          });
        }
      });
  };

  filterGroupFriends = (opt, filter) =>
    opt.displayName && opt.displayName.includes(filter);

  render() {
    const {
      handleSubmit,
      submitting,
      pristine,
      invalid,
      submitButtonText,
    } = this.props;

    const { venueEvent, rating } = this.state;

    const { isClubAdmin } = this;

    return (
      <div>
        <form
          onSubmit={handleSubmit(this.handleSubmit)}
          style={{ marginTop: 30 }}>
          <div className="row justify-content-center">
            <div className="col-md-6">
              <Field
                name="venue"
                label="Venue"
                component={Select}
                options={this.props.venues}
                validate={isRequired('A venue is required')}
                valueKey="_id"
                labelKey="name"
              />
              <Field
                name="players"
                label="Already have a partner? (Optional)"
                component={Select}
                options={this.props.groupFriends}
                labelKey="displayName"
                valueKey="email"
                multi={true}
                creatable={true}
                filterOption={this.filterGroupFriends}
                placeholder="Add pre-confirmed players here..."
                onChange={this.handleMemberSelect}
                validate={this.playerLimit}
                promptTextCreator={createLabel => createLabel} // Override it so it doesn't say 'Create Options: <label>'. This will just return <label>
              />
              <Field
                name="playDate"
                label="Play Date"
                showDate={true}
                showTime={false}
                validate={[isRequired('Play date is required')]}
                component={DateTimePicker}
                required
              />
              <Field
                name="playTime"
                label="Play Time"
                showDate={false}
                showTime={true}
                validate={[isRequired('Play time is required'), isFutureTime]}
                component={DateTimePicker}
                required
              />
              <Field
                name="playType"
                label="Play Type"
                component={MaskedSelect}
                options={playTypes}
                validate={[isRequired('Play type is required')]}
                valueKey="id"
                labelKey="name"
                onChange={(_, playType) => {
                  this.playType = playType;
                  this.setState(this.state);
                }}
              />
              {!venueEvent && this.playType !== MIXED && (
                <div className="form-check">
                  <label className="form-check-label">
                    <Field
                      type="checkbox"
                      name="ignoreGender"
                      component={field => (
                        <input
                          {...field.input}
                          type="checkbox"
                          className="form-check-input"
                        />
                      )}
                    />
                    Allow Any Gender
                  </label>
                </div>
              )}
              {venueEvent && this.playType !== MIXED && (
                <Field
                  name="gender"
                  label="Gender"
                  component={MaskedSelect}
                  options={genderOptions}
                  validate={[isRequired('Gender is required')]}
                  valueKey="id"
                  labelKey="name"
                  onChange={(_, gender) => {
                    this.gender = gender;
                  }}
                />
              )}
              {this.playType === MIXED && (
                <Field
                  name="combinedRating"
                  type="number"
                  min="1"
                  max="10"
                  step="0.5"
                  validate={[
                    isRequired('Combined Rating is Required'),
                    validators.comboUstaRating,
                  ]}
                  component={InputUI}
                  label="Combined Rating"
                />
              )}
              <Field name="notes" label="Notes" component={TextArea} />
              {venueEvent && this.playType !== MIXED && (
                <label className="form-check">
                  Target Rating
                  <input
                    type="number"
                    value={rating}
                    name="targetrating"
                    className="form-control"
                    min={1}
                    max={7}
                    step={0.5}
                    onChange={value => this.updateTargetRating(value)}
                  />
                </label>
              )}
              {this.playType !== MIXED && (
                <div>
                  {' '}
                  Would you like to also invite players half a rating
                  lower/higher: <br />
                  {getRatingSum(rating, -0.5) >= MIN_RATING && (
                    <div className="form-check mt-2 ml-4">
                      <label className="form-check-label">
                        <Field
                          type="checkbox"
                          name="halfUnder"
                          component={field => (
                            <input
                              {...field.input}
                              type="checkbox"
                              className="form-check-input"
                            />
                          )}
                        />
                        {getRatingSum(rating, -0.5)}{' '}
                        {_clientFeatures.rating.label}
                      </label>
                    </div>
                  )}
                  {getRatingSum(rating, 0.5) <= MAX_RATING && (
                    <div className="form-check ml-4">
                      <label className="form-check-label">
                        <Field
                          type="checkbox"
                          name="halfOver"
                          component={field => (
                            <input
                              {...field.input}
                              type="checkbox"
                              className="form-check-input"
                            />
                          )}
                        />
                        {getRatingSum(rating, 0.5)}{' '}
                        {_clientFeatures.rating.label}
                      </label>
                    </div>
                  )}
                </div>
              )}
              <div>
                <div className="form-check">
                  Other Options:
                  <br />
                  {isClubAdmin && (
                    <div className="form-check mt-2 ml-4">
                      <label className="form-check-label">
                        <input
                          title="Creates event where you are the observer. This is for venue managers."
                          type="checkbox"
                          checked={this.state.venueEvent}
                          name="isEvenOnly"
                          className="form-check-input"
                          onChange={() => this.toggleVenueEvent()}
                        />
                        Venue Social Play Event
                      </label>
                    </div>
                  )}
                  {!venueEvent && (
                    <div className="form-check mt-2 ml-4">
                      <label className="form-check-label">
                        <Field
                          type="checkbox"
                          name="friendsOnly"
                          component={field => (
                            <input
                              {...field.input}
                              type="checkbox"
                              className="form-check-input"
                            />
                          )}
                        />
                        Play friends only
                      </label>
                    </div>
                  )}
                </div>
              </div>
              <div className="clearfix">
                <Field
                  name="submit"
                  type="submit"
                  component={Button}
                  text={submitButtonText}
                  disabled={pristine || invalid || submitting}
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default compose(
  connect(state => ({
    currentUser: state.common.currentUser,
    roles: state.common.roles,
  })),
  reduxForm({
    form: 'requestSocialPlay',
  })
)(RequestSocialPlayForm);
