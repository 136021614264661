import React from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, getFormValues, reduxForm } from 'redux-form';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';

import InputUI from '../../components/UI/Input';
import Select from '../../components/UI/Select';
import ListErrors from '../../common/list-errors';
import validation from '../../data/validations';

const DEFAULT_FLIGHT = {
  id: null,
  name: null,
  league: null,
  teams: null,
};

export class NewFlightModalContainer extends React.Component {
  state = {
    modal: true,
    league: null,
    pristine: true,
  };

  getTeamOptions = () => {
    const { currentTeams, flight } = this.props;
    const { pristine, league } = this.state;
    // We have to use the name here because Teams.getAll() returns league as its name
    let leagueName = null;
    if (pristine && flight) {
      leagueName = flight.league.name;
    } else if (league) {
      leagueName = league.name;
    }
    if (leagueName && currentTeams && currentTeams.length > 0) {
      return currentTeams.filter(
        team =>
          team.league.name === leagueName &&
          (!team.flight || (flight && team.flight === flight.id))
      );
    }
    return null;
  };

  onLeagueChange = (e, newLeague) => {
    if (newLeague) {
      this.setState({ pristine: false, league: newLeague });
    } else {
      this.setState({ pristine: false, league: newLeague });
    }
  };

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  onModalClose = () => {
    this.setState({ redirect: true });
  };

  onSubmit = values => {
    values.id = this.props.league && this.props.league.id;
    this.props.onSubmit(values);
    this.setState({ redirect: true });
  };

  render() {
    const {
      handleSubmit,
      invalid,
      errors,
      pristine,
      submitting,
      leagues,
      flight = DEFAULT_FLIGHT,
      formValues = {},
    } = this.props;
    const { redirect } = this.state;
    const teamOptions = this.getTeamOptions();
    const showTeams = teamOptions && teamOptions.length > 0;
    const isEdit = flight.id !== null;
    const isManual = formValues && formValues.manual;
    return redirect ? (
      <Redirect to="." />
    ) : (
      <Modal
        isOpen={this.state.modal}
        onClosed={this.onModalClose}
        className={this.props.className}>
        <form onSubmit={handleSubmit(this.onSubmit)}>
          <ModalHeader toggle={this.toggle}>
            {isEdit ? 'Edit' : 'Create New'} Flight
          </ModalHeader>
          <ModalBody>
            <ListErrors errors={errors} />
            <Field
              name="name"
              component={InputUI}
              validate={[validation.required]}
              label="Name"
            />
            <Field
              name="league"
              validate={validation.required}
              component={Select}
              options={leagues}
              valueKey="id"
              labelKey="name"
              label="League"
              onChange={this.onLeagueChange}
            />
            {showTeams && (
              <Field
                name="teams"
                component={Select}
                options={teamOptions}
                valueKey="_id"
                labelKey="name"
                label="Teams"
                multi={true}
              />
            )}
            <div className="form-check">
              <label className="form-check-label">
                <Field
                  type="checkbox"
                  name="manual"
                  component={field => (
                    <input
                      {...field.input}
                      type="checkbox"
                      className="form-check-input"
                    />
                  )}
                />
                Auto Generate Matches
              </label>
            </div>
            {!isManual && (
              <div className="form-check">
                <label className="form-check-label">
                  <Field
                    type="checkbox"
                    name="allowDuplicates"
                    component={field => (
                      <input
                        {...field.input}
                        type="checkbox"
                        className="form-check-input"
                      />
                    )}
                  />
                  Allow Duplicates
                </label>
              </div>
            )}
          </ModalBody>
          <ModalFooter>
            <a className="btn btn-link" onClick={this.toggle}>
              Cancel
            </a>
            <Button
              disabled={pristine || submitting || errors || invalid}
              color="primary">
              {isEdit ? 'Update' : 'Create'} Flight
            </Button>
          </ModalFooter>
        </form>
      </Modal>
    );
  }
}

const flightFormSelector = getFormValues('newFlightForm');
export default compose(
  connect((state, props) => {
    // TODO: this takes values from the redux form and puts it on props...
    // How would this work with initialValues

    return {
      formValues: flightFormSelector(state),
      initialValues: props.flight
        ? {
            ...props.flight,
            manual: !props.flight.manual,
            league: {
              id: props.flight.league.id,
              name: props.flight.league.name,
            },
          }
        : undefined,
    };
  }),
  reduxForm({
    form: 'newFlightForm',
    enableReinitialize: true,
  })
)(NewFlightModalContainer);
