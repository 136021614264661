import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import agent from '../../agent';
import { parse } from 'qs';
import {
  CONFIRM_EMAIL_UNLOADED,
  CONFIRM_EMAIL,
  UPDATE_FIELD_AUTH,
} from '../../constants';
import ListErrors from '../../common/list-errors';
import Downloads from '../../downloads';

import RegistrationForm from '../register/registration-form';
import { toastr } from 'react-redux-toastr';

export class ConfirmEmailContainer extends React.Component {
  state = {
    errors: null,
    clubs: null,
    user: null,
  };
  constructor() {
    super();
    this.changePassword = ev => this.props.onChangePassword(ev.target.value);
    this.changeConfirmPassword = ev =>
      this.props.onChangeConfirmPassword(ev.target.value);
    // this.submitForm = (password, confirmPassword) => ev => {
    //   ev.preventDefault();
    //   if (password !== confirmPassword) {
    //     this.props.onValidatePassword('Passwords do not match');
    //   } else {
    //     this.props.onSubmit(this.props.token, password, confirmPassword);
    //     this.setState({ formSubmitted: true });
    //   }
    // };
  }
  UNSAFE_componentWillMount() {
    this.props.onLoad().then(dispatch => {
      const user = dispatch && dispatch.user;
      if (user) {
        agent.Users.getUserSettings().then(settings => {
          const venues = settings.venues ? settings.venues : [];
          const following = settings.following ? settings.following : [];
          const preFilled = {
            firstName: user.name && user.name.first,
            lastName: user.name && user.name.last,
            gender: user.gender,
            ustaRating: user.rating,
            venues: [...venues, ...following],
          };
          this.setState({ user: preFilled });
        });
      }
    });
  }
  componentDidMount() {
    // const playerId = _get(this.props, 'player.id');
    // this.props.loadPreferences(playerId);
    agent.Venues.getList().then(clubs => this.setState({ clubs }));
  }

  componentWillUnmount() {
    this.props.onUnload();
  }
  submitForm = values => {
    const playerObj = {
      email: values.email,
      password: values.password,
      confirmPassword: values.confirmPassword,
      name: { first: values.firstName, last: values.lastName },
      rating: parseFloat(values.ustaRating),
      gender: values.gender.id,
      phone: values.cellPhoneNumber,
      birthYear: parseInt(values.birthYear ? values.birthYear : 0),
      venueId: values.venues.map(function(venue) {
        return venue['id'];
      }),
    };
    return this.props.onSubmit(this.props.token, playerObj).then(
      () => {
        this.setState({
          formSubmitted: true,
        });
      },
      error => {
        const { body = {} } = error.response;
        if (body.errors.message) {
          this.setState({
            error: [
              <div key={1}>
                It looks like you are already a member. Have you forgotten your
                password? <Link to="/forgot-password">Click here</Link> to reset
                it.
              </div>,
            ],
          });
        } else {
          this.setState({ error: body });
        }
      }
    );
  };

  handleConfirmation = () => {
    toastr.success('Success!', 'Your email has been confirmed', {
      timeOut: 5000,
    });
  };

  render() {
    const { password = '', confirmPassword = '' } = this.props;
    const { user } = this.state;
    return (
      <div className="auth-page">
        {this.props.inProgress ? <div>loading...</div> : null}
        {this.props.errors && (
          <div className="alert alert-danger">
            <h3 className="alert-heading">
              {this.props.errors.errors.message}{' '}
            </h3>
            <p>
              If you have already confirmed your email address, then you can can
              can ignore this message.{' '}
            </p>
            <p>
              If you have forgotten your password or need to set your password,{' '}
              <Link to="/forgot-password" className="alert-link">
                click here
              </Link>
            </p>
          </div>
        )}
        {!this.props.confirmed && !this.props.errors && !this.props.inProgress && (
          <div>
            <div className="alert">
              <h3 className="alert-heading">
                Please fill out your profile to confirm your account.
              </h3>
            </div>
            <div>
              <text>A password must be at least six characters long.</text>
            </div>
            <RegistrationForm
              initialValues={{
                email: this.props.email,
                ...user,
              }}
              errors={this.props.errors}
              setPassword={true}
              clubs={this.state.clubs}
              onSubmit={this.submitForm}
            />
          </div>
        )}
        {this.props.invalidPassword && (
          <div className="alert alert-danger mt-5">
            <p>{this.props.invalidPassword}</p>
          </div>
        )}
        {this.props.confirmed && this.handleConfirmation()}
      </div>
    );
  }
}

const mapStateToProps = state => ({ ...state.auth });
const mapDispatchToProps = (dispatch, state) => {
  return {
    onLoad: () => {
      const query = parse(state.location.search.substr(1));
      const token = query.token;
      const pending = query.pending;
      if (!pending) {
        return dispatch({
          type: CONFIRM_EMAIL,
          payload: agent.Auth.confirmEmail(token),
        });
      }
      return dispatch({
        type: UPDATE_FIELD_AUTH,
        key: 'token',
        value: token,
      });
    },
    onUnload: () => dispatch({ type: CONFIRM_EMAIL_UNLOADED }),
    onChangePassword: value =>
      dispatch({ type: UPDATE_FIELD_AUTH, key: 'password', value }),
    onChangeConfirmPassword: value =>
      dispatch({ type: UPDATE_FIELD_AUTH, key: 'confirmPassword', value }),
    onSubmit: (token, body) =>
      dispatch({
        type: CONFIRM_EMAIL,
        payload: agent.Auth.confirmEmail(token, body),
      }),
    onValidatePassword: value =>
      dispatch({
        type: UPDATE_FIELD_AUTH,
        key: 'invalidPassword',
        value,
      }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfirmEmailContainer);
