export const SINGLES = 'singles';
export const DOUBLES = 'doubles';
export const SINGLES_AND_DOUBLES = 'singles_and_doubles';
export const COMBO = 'combo';
export const MIXED = 'mixed';

export const leagueTypes = [
  { id: SINGLES_AND_DOUBLES, name: 'Singles and Doubles' },
  { id: DOUBLES, name: 'Doubles' },
  { id: COMBO, name: 'Combo' },
];

export const socialPlayTypes = [
  {
    id: SINGLES,
    name: 'Singles',
  },
  {
    id: DOUBLES,
    name: 'Doubles',
  },
  {
    id: MIXED,
    name: 'Mixed',
  },
];

export const challengePlayTypes = [
  {
    id: SINGLES,
    name: 'Singles',
  },
  {
    id: DOUBLES,
    name: 'Doubles',
  },
];

export const eventStrings = {
  social: 'Social Play Event',
  dropin: 'Drop-in Event',
  practice: 'Practice',
  match: 'Match',
  flightmatch: 'Flight Match',
  challenge: 'Challenge',
};
