import React from 'react';
import { Field, reduxForm } from 'redux-form';
import SelectUI from '../../../components/UI/Select';
import InputUI from '../../../components/UI/Input';
import ButtonUI from '../../../components/UI/Button';
import ListErrors from '../../../common/list-errors';
import validation from '../../../data/validations';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import agent from '../../../agent';

class VenueConnectForm extends React.Component {
  // NOTE: Both genderOptions and ratingOptions share the same field.
  // None of the options can share the same id.
  state = {
    filterBy: 'gender',
    genderOptions: [
      { name: 'Female', id: 2 },
      { name: 'Male', id: 1 },
      { name: 'Unspecified', id: 0 },
    ],
    ratingOptions: [
      { name: 'Not Ranked', id: 0 },
      { name: '2.5', id: 2.5 },
      { name: '3.0', id: 3 },
      { name: '3.5', id: 3.5 },
      { name: '4.0', id: 4 },
      { name: '4.5', id: 4.5 },
      { name: '5.0', id: 5 },
      { name: '5.5+', id: 5.5 },
    ],
    isGroupsSet: false,
    toCount: 0,
    body: {
      gender: [],
      rating: [],
    },
    toList: [],
    modal: false,
  };

  _onSubmit = values => {
    values['bcc'] = this.state.toList;
    this.props.handleSubmit();
  };

  onSendTOChange = (filters, type) => {
    const venue = this.props.venue;
    if (venue) {
      let body = this.state.body;
      if (type === 'gender') {
        body.gender = filters.map(f => f.id);
      } else {
        body.rating = filters.map(f => f.id);
      }
      this.setState({
        body: body,
      });
      if (body.gender.length > 0 || body.rating.length > 0) {
        agent.Venues.filterMembers(venue._id, body).then(users => {
          this.setState({
            toCount: users.length,
            toList: users.map(u => u.email),
          });
        });
      } else {
        this.setState({
          toCount: 0,
          toList: [],
        });
      }
    }
  };

  toggle = () =>
    this.setState({ modal: !this.state.modal && this.state.toList.length > 0 });

  render() {
    const emailModal = emails => {
      return (
        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>
            <i className="fa fa-users fa-fw" />
            &nbsp;{this.state.toCount}&nbsp; Recipients
          </ModalHeader>
          <ModalBody
            className="mb-4"
            style={{ maxHeight: '400px', overflow: 'scroll' }}>
            <div className="list-group">
              {emails.map((email, idx) => (
                <div key={idx} className="list-group-item">
                  {email}
                </div>
              ))}
            </div>
          </ModalBody>
        </Modal>
      );
    };
    const { errors, handleSubmit, pristine, submitting, invalid } = this.props;
    const { toCount } = this.state;
    return (
      <form onSubmit={handleSubmit(this._onSubmit)}>
        <ListErrors errors={errors} />
        <div className="row justify-content-center">
          <div className="col-md-9 filter-header">
            <a
              href="#"
              className="float-right form-text"
              onClick={e => {
                this.toggle();
                e.preventDefault();
              }}>
              <small id="emailHelp">
                <i className="fa fa-users fa-fw" />
                &nbsp;{this.state.toCount}&nbsp;Recipients
              </small>
            </a>
            <Field
              name="selSendTo"
              component={SelectUI}
              options={this.state.genderOptions}
              valueKey="id"
              labelKey="name"
              label="To"
              placeholder="Select Gender..."
              onChange={e => this.onSendTOChange(e, 'gender')}
              multi={true}
            />
          </div>
          <div className="col-sm-9">
            <Field
              name="ratingSendTo"
              component={SelectUI}
              options={this.state.ratingOptions}
              valueKey="name"
              labelKey="name"
              placeholder="Select Rating..."
              onChange={e => this.onSendTOChange(e, 'rating')}
              multi={true}
            />
          </div>
          <div className="col-md-9">
            <Field
              name="txtSubject"
              component={InputUI}
              type="text"
              min="4"
              label="Subject"
              placeholder="Subject"
              validate={[validation.required]}
            />
          </div>
          <div className="col-md-9">
            <label>Body</label>
            <Field
              component="textarea"
              className="form-control"
              name="txtBody"
              min="4"
              rows="15"
              validate={[validation.required]}
            />
          </div>
          <div className="col-md-9 mt-2">
            <div className="form-group float-right">
              <Field
                disabled={pristine || submitting || invalid || toCount === 0}
                name="btnSendConnect"
                text="Send"
                type="submit"
                component={ButtonUI}
              />
            </div>
          </div>
        </div>
        {emailModal(this.state.toList)}
      </form>
    );
  }
}

export default reduxForm({
  form: 'venueInventoryForm',
})(VenueConnectForm);
