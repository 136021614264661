import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import PropTypes from 'prop-types';

import Select from '../../components/UI/Select';
import ListErrors from '../../common/list-errors';
import InputUI from '../../components/UI/Input';
import validation from '../../data/validations';
import agent from '../../agent';
import Loader from '../../components/UI/Loader';

const zipMin = validation.minLength(5);
const zipMax = validation.maxLength(10); // zip+dash+plusFour

export class AddVenueModalContainer extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    show: PropTypes.bool.isRequired, // Should be the same state as the one used in the toggle function
    toggle: PropTypes.func.isRequired, // Uses the parents state to control opening/closing of the dialog
    inputRequired: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = {
      modal: this.props.show,
      clubs: [],
      loading: false,
      firstTime:true,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  async handleChange(event) {
    if (event.target.value.length > 4 && event.target.value.length < 11) {
      await this.handleFilterVeunes(event.target.value);
    }
  }

  async handleFilterVeunes(zip) {
    await this.setState({ loading: true, firstTime: false });
    await agent.Venues.getList({ zip: zip }).then(clubs =>
      this.setState({ clubs: clubs, loading: false })
    );
  }

  render() {
    const { invalid, submitting, errors, handleSubmit, options } = this.props;

    if (this.props.initialValues && this.state.firstTime) {
      this.handleFilterVeunes(this.props.initialValues.postal);
    }
    let venues;
    venues = options;

    if (this.state.clubs && this.state.clubs.length > 0) {
      venues = this.state.clubs;
    } else {
      venues = options;
    }
    return (
      <Modal isOpen={this.state.modal} className={this.props.className}>
        <form onSubmit={handleSubmit}>
          <ModalHeader toggle={this.props.toggle}>
            {this.props.title}
          </ModalHeader>
          <ModalBody>
            <ListErrors errors={errors} />
            {this.props.message}
            <br />
            <br />
            <Field
              name="postal"
              autoComplete="postal-code"
              component={InputUI}
              validate={[zipMin, zipMax]}
              label="Zip Code"
              onChange={this.handleChange}
            />
            {!this.state.loading ? (
              <Field
                name="venues"
                label="Venues"
                component={Select}
                options={venues}
                labelKey="name"
                valueKey="_id"
                multi={true}
              />
            ) : (
              <Loader />
            )}

            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
          </ModalBody>
          <ModalFooter>
            <a className="btn btn-link" onClick={this.props.toggle}>
              Cancel
            </a>
            <Button disabled={submitting || invalid} color="primary">
              Ok
            </Button>
          </ModalFooter>
        </form>
      </Modal>
    );
  }
}

export default reduxForm({ form: 'addVenueForm', enableReinitialize: true })(
  AddVenueModalContainer
);
