import React from 'react';
import moment from 'moment';
import Toggle from '../../../components/UI/Toggle/index';
import { Link, Redirect } from 'react-router-dom';
import { CONF_TOGGLE, WIN_LOSS } from '../../constants';
import ParticipationLabel from '../participation-label';
import agent from '../../../agent';
import ConfirmationDialog from '../../../components/UI/ConfirmationDialog';
import MailDialog from '../../../components/UI/MailDialog';
import { localTime } from '../../../common/services/utils/helpers';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { eventTypesArray } from '../../../data/types';
import Select from 'react-select';
import classNames from 'classnames';

const ModalEventFinalizationConfirmation = props => (
  <Modal isOpen={props.isOpen}>
    <ModalHeader toggle={props.toggle}>Are you sure?</ModalHeader>
    <ModalBody>
      <div>Finalize this event now?</div>
    </ModalBody>
    <ModalFooter>
      <a className="btn btn-link" onClick={props.toggle}>
        Cancel
      </a>
      <button className="btn btn-primary" onClick={props.onConfirm}>
        Finalize Event
      </button>
    </ModalFooter>
  </Modal>
);

const ModalEventUnfinalizationConfirmation = props => (
  <Modal isOpen={props.isOpen}>
    <ModalHeader toggle={props.toggle}>Are you sure?</ModalHeader>
    <ModalBody>
      <div>Unfinalize this event now?</div>
    </ModalBody>
    <ModalFooter>
      <a className="btn btn-link" onClick={props.toggleUnfinalized}>
        Cancel
      </a>
      <button className="btn btn-primary" onClick={props.onConfirm}>
        Unfinalize Event
      </button>
    </ModalFooter>
  </Modal>
);

const headingClass =
  'header-text d-flex align-items-center justify-content-between';
const notifyBtnClass = 'btn btn-primary btn-sm';

class EventDetail extends React.Component {
  state = {
    toggle: false,
    toggleUnfinalized: false,
    expandedNotes: false,
    showConfirmation: false,
    showEmail: false,
    to: '',
    subject: '',
    editContext: null,
    editGameId: null,
    canConfirmScores: false,
  };

  constructor(props) {
    super(props);
    const {
      event,
      event: { games },
      playerOpts,
    } = props;
    const unavailable = {};
    if (playerOpts && playerOpts.length > 0 && games && games.length > 0) {
      games.forEach(game => {
        const { home, away } = game;
        if (home) {
          const { player1, player2 } = home;
          if (player1) {
            unavailable[player1._id] = true;
          }
          if (player2) {
            unavailable[player2._id] = true;
          }
        }
        if (away) {
          const { player1, player2 } = away;
          if (player1) {
            unavailable[player1._id] = true;
          }
          if (player2) {
            unavailable[player2._id] = true;
          }
        }
      });
    }
    const canConfirmScores = this.canConfirmScores(event);
    this.state = {
      unavailable,
      canConfirmScores,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { event } = this.props;
    const { event: nextPropsEvent } = nextProps;
    if (event && nextPropsEvent) {
      const { updated } = event;
      const { updated: nextPropsUpdated } = nextPropsEvent;
      if (updated !== nextPropsUpdated) {
        const canConfirmScores = this.canConfirmScores(nextPropsEvent);
        this.state = {
          canConfirmScores,
        };
      }
    }
  }

  toggle = () => {
    this.setState({
      toggle: !this.state.toggle,
    });
  };

  toggleNotes = () => {
    this.setState({
      expandedNotes: !this.state.expandedNotes,
    });
  };

  toggleUnfinalized = () => {
    this.setState({
      toggleUnfinalized: !this.state.toggleUnfinalized,
    });
  };

  showEmailDialog = event => {
    const to = event.invites.map(invite => invite.user.email);
    this.setState({ to: to });
    this.toggleEmailDialog();
  };

  toggleEmailDialog = () => {
    this.setState({ showEmail: !this.state.showEmail });
  };

  toggleConfirmationDialog = () =>
    this.setState({ showConfirmation: !this.state.showConfirmation });

  handleFinalizeEventClick = () => {
    // launch modal confirmation window
    this.setState({
      toggle: true,
    });
  };

  handleFinalizeConfirmClick = () => {
    // finalize the event
    this.props.finalizeEvent();
    this.setState({
      toggle: false,
    });
  };

  handleUnfinalizeEventClick = () => {
    this.setState({
      toggleUnfinalized: true,
    });
  };

  handleUnfinalizeConfirmClick = () => {
    // finalize the event
    this.props.unfinalizeEvent();
    this.setState({
      toggleUnfinalized: false,
    });
  };

  handleEventDelete = () => {
    const {
      event: { id },
    } = this.props;
    agent.Challenge.delete(id).then(() => this.setState({ redirect: true }));
  };

  getEventName(type) {
    const event = eventTypesArray.find(e => e.id === type);
    return event ? event.name : 'Venue Event';
  }

  handleUnconfirmedPlayer = () => {
    const {
      event: { venueEvent },
      isCreator,
    } = this.props;
    this.setState({
      toggleDisabled: false,
      redirect: venueEvent && !isCreator,
    });
  };

  renderLineup = (isFinalized, isInPast) => {
    const {
      event: { games },
      isCreator,
      isAdmin,
    } = this.props;
    const isEditable = isCreator || isAdmin;
    return (
      <div>
        {games.map(game => {
          return (
            <div>
              <div className="game">
                {this.renderLineupPosition(game, 'home', isInPast, isEditable)}
              </div>
              <div className="game">
                {this.renderLineupPosition(game, 'away', isInPast, isEditable)}
              </div>
              {isInPast && (
                <div className="game">{this.renderScores(game)}</div>
              )}
            </div>
          );
        })}
      </div>
    );
  };

  handleResultChange = (gameId, body) => {
    const { updateGame } = this.props;
    updateGame(gameId, body);
  };

  handleConfirmScores = () => {
    const { confirmScores } = this.props;
    confirmScores();
  };

  canConfirmScores = event => {
    const { isCreator, currentUser } = this.props;
    const { confirmedScore, finalized, venueEvent, games } = event;
    const isVenueAdmin = venueEvent && isCreator;
    if (finalized || (confirmedScore === currentUser._id && !isVenueAdmin)) {
      return false;
    }
    let confirmedByTeam = '';
    let usersTeam = '';
    let hasScores = true;
    games.forEach(game => {
      const { home, away, winLoss, scores } = game;
      if (home) {
        const { player1, player2 } = home;
        if (
          (player1 && player1._id === confirmedScore) ||
          (player2 && player2._id === confirmedScore)
        ) {
          confirmedByTeam = 'home';
        }
        if (
          (player1 && player1._id === currentUser._id) ||
          (player2 && player2._id === currentUser._id)
        ) {
          usersTeam = 'home';
        }
      }
      if (away) {
        const { player1, player2 } = away;
        if (
          (player1 && player1._id === confirmedScore) ||
          (player2 && player2._id === confirmedScore)
        ) {
          confirmedByTeam = 'away';
        }
        if (
          (player1 && player1._id === currentUser._id) ||
          (player2 && player2._id === currentUser._id)
        ) {
          usersTeam = 'away';
        }
      }
      if (!winLoss || !scores) {
        hasScores = false;
      }
    });
    return (isVenueAdmin || confirmedByTeam !== usersTeam) && hasScores;
  };

  renderScores = game => {
    const {
      event: { finalized },
    } = this.props;
    const { canConfirmScores } = this.state;
    const { winLoss, scores, _id } = game;
    const value = winLoss ? WIN_LOSS.find(obj => obj.id === winLoss) : 0;
    return (
      <div>
        <div className="row">
          <div className="col-md">
            <strong>Winner</strong>
            <Select
              disabled={finalized}
              value={value}
              onChange={value =>
                this.handleResultChange(_id, {
                  winLoss: value ? value.id : 0,
                })
              }
              options={WIN_LOSS}
              valueKey="id"
              labelKey="value"
              placeholder="Winner"
            />
          </div>
          <div className="col-md">
            <strong>Scores</strong>
            <input
              type="text"
              name="score"
              disabled={finalized}
              className="form-control"
              style={{ height: 36, width: 150 }}
              value={scores || ''}
              onChange={event =>
                this.handleResultChange(_id, {
                  scores: event.target.value,
                })
              }
              placeholder={'Ex: 7-5, 4-6, 1-0'}
            />
          </div>
          <button
            className="btn btn-primary confirm-button"
            onClick={this.handleConfirmScores}
            disabled={!canConfirmScores}>
            Confirm Scores
          </button>
        </div>
        {this.renderConfirmedBy()}
      </div>
    );
  };

  renderConfirmedBy = () => {
    const {
      event: { finalized, confirmedScore, invites },
    } = this.props;
    if (!confirmedScore) {
      return null;
    }
    let confirmedBy = '';
    if (invites) {
      const invite = invites.find(invite => invite.user._id === confirmedScore);
      if (invite) {
        confirmedBy = invite.user.displayName;
      }
    }
    return (
      <div className="row confirmed-row">
        <div className="col-md">
          Scores {finalized ? 'confirmed' : 'last entered'} by {confirmedBy}
        </div>
      </div>
    );
  };

  handleLineupChange = (context, newPlayer, prevPlayer) => {
    const { unavailable } = this.state;
    if (prevPlayer) {
      unavailable[prevPlayer._id] = false;
    }
    if (newPlayer) {
      unavailable[newPlayer._id] = true;
    }
    this.setState({
      [context]: newPlayer ? newPlayer : '',
    });
  };

  renderEditLineup = (player, context) => {
    const { playerOpts } = this.props;
    const { unavailable } = this.state;

    return (
      <div>
        <Select
          value={player}
          options={playerOpts}
          placeholder="Default"
          valueKey="_id"
          labelKey="displayName"
          searchable={false}
          optionRenderer={this.renderPlayerOption}
          filterOptions={playerOpts => {
            return playerOpts.filter(opt => !unavailable[opt._id]);
          }}
          onChange={value => {
            this.handleLineupChange(context, value, player);
          }}
        />
      </div>
    );
  };

  renderPlayerOption = player => {
    return (
      <div>
        <div className="mb-1" style={{ color: '#212529' }}>
          {player.displayName}
        </div>
      </div>
    );
  };

  handleUpdateGame = () => {
    const { updateGame } = this.props;
    const { player1Val, player2Val, editContext, editGameId } = this.state;
    const player1 = player1Val ? player1Val._id : player1Val;
    const player2 = player2Val ? player2Val._id : player2Val;

    updateGame(editGameId, {
      [editContext]: { player1, player2 },
    });
    this.setState({
      editGameId: null,
      editContext: null,
      player1Val: null,
      player2Val: null,
    });
  };

  handleSetEditing = (game, context) => {
    const { _id } = game;
    const player1 = game[context] ? game[context].player1 : '';
    const player2 = game[context] ? game[context].player2 : '';
    this.setState({
      player1Val: player1,
      player2Val: player2,
      editGameId: _id,
      editContext: context,
    });
  };

  renderLineupPosition = (game, context, isInPast, isEditable) => {
    const { editContext, editGameId, player1Val, player2Val } = this.state;
    const { position, _id } = game;
    const player1 = game[context] ? game[context].player1 : '';
    const player2 = game[context] ? game[context].player2 : '';
    const isEditing = editContext === context && editGameId === _id;
    const disableEdit = editGameId && !isEditing;
    const isDoubles = position.includes('D');
    const player1Title = `${position} - Player1`;
    const player2Title = `${position} - Player2`;
    return (
      <div>
        <h4>{context === 'home' ? 'Home' : 'Away'}</h4>
        <header className="game__heading">
          <strong>{player1Title}</strong>
          {isEditable && !isInPast && (
            <button
              onClick={() =>
                isEditing
                  ? this.handleUpdateGame()
                  : this.handleSetEditing(game, context)
              }
              disabled={disableEdit}
              className={classNames({
                'btn btn-sm btn-primary': isEditing,
                'btn btn-sm btn-outline-primary': !isEditing,
              })}>
              <i
                className={classNames({
                  'fa fa-save': isEditing,
                  'fa fa-pencil': !isEditing,
                })}
              />
              <span className="ml-2">{isEditing ? 'Save' : 'Edit'}</span>
            </button>
          )}
        </header>
        {isEditing
          ? this.renderEditLineup(player1Val, 'player1Val')
          : this.renderLineupPlayer(player1, isInPast)}
        {isDoubles && (
          <div>
            <strong>{player2Title}</strong>
            {isEditing
              ? this.renderEditLineup(player2Val, 'player2Val')
              : this.renderLineupPlayer(player2, isInPast)}
          </div>
        )}
      </div>
    );
  };

  renderLineupPlayer = (player, isInPast) => {
    const {
      currentUser,
      isCreator,
      event: { invites },
    } = this.props;
    const { toggleDisabled } = this.state;
    const { _id } = player;
    const invite = invites && invites.find(invite => invite.user._id === _id);
    if (!invite) {
      return <div>N/A</div>;
    }
    const { status, user } = invite;

    const isCurrentUser = currentUser._id === user.id;
    const canEditAvailability = !isInPast && (isCreator || isCurrentUser);

    return (
      <div className="game__body">
        <div className="game__deets">
          <div className="game__player__name">{player.displayName}</div>
        </div>
        {canEditAvailability ? (
          <Toggle
            value={status}
            onChange={status => {
              this.setState({ toggleDisabled: true });
              this.props
                .setPlayerStatus(user._id, status)
                .then(() => {
                  this.handleUnconfirmedPlayer();
                })
                .catch(() => {
                  this.setState({ toggleDisabled: false });
                });
            }}
            options={CONF_TOGGLE}
            disabled={toggleDisabled}
          />
        ) : (
          <ParticipationLabel status={status} />
        )}
      </div>
    );
  };

  toggleDeleteConfirmation = () =>
    this.setState({
      showConfirmation: true,
      confirmationTitle: 'Delete Event',
      confirmationMsg: 'Are you sure you want to delete the event?',
      confirmationLabels: { ok: 'Delete Event' },
      confirmationClick: () => {
        this.handleEventDelete();
      },
    });

  render() {
    const {
      event,
      event: {
        type,
        name,
        start,
        minPlayers,
        accepted,
        id,
        notes,
        venue,
        venueEvent,
      },
      isAdmin,
      updateNotes,
      isCreator,
    } = this.props;
    const {
      toggle,
      toggleUnfinalized,
      expandedNotes,
      redirect,
      showConfirmation,
      confirmationTitle,
      confirmationMsg,
      confirmationLabels,
      confirmationClick,
      showEmail,
      to,
      subject,
    } = this.state;
    const redirectTo =
      isCreator && venueEvent ? `/venues/${venue}/events` : `/agenda`;

    const isInPast = localTime(start).isBefore(moment());
    if (redirect) {
      return <Redirect to={redirectTo} />;
    }

    return (
      <div>
        <ModalEventFinalizationConfirmation
          isOpen={toggle}
          toggle={this.toggle}
          onConfirm={this.handleFinalizeConfirmClick}
        />
        <ModalEventUnfinalizationConfirmation
          isOpen={toggleUnfinalized}
          toggle={this.toggleUnfinalized}
          onConfirm={this.handleUnfinalizeConfirmClick}
        />
        <div className="social-header-area">
          <h2 className={headingClass}>
            <Link to={redirectTo}>{this.getEventName(type)}</Link>
            {(isCreator || isAdmin) && (
              <button
                className={notifyBtnClass}
                onClick={() => this.showEmailDialog(event)}>
                Email Participants
              </button>
            )}
          </h2>
        </div>
        <div className="row my-3">
          <div className="col-md">
            <div className={'event-header event-header--' + type}>
              <h2>{name || ''}</h2>
              <h3>
                <small className="text-muted">
                  {localTime(start).format('LLL')}
                </small>
              </h3>
            </div>
          </div>
          <div className="col-md">
            <div className="clearfix my-3 my-md-0">
              {(isCreator || isAdmin) && (
                <div>
                  {isInPast ? null : (
                    <div>
                      <button
                        title="Delete Event"
                        onClick={() => {
                          this.toggleDeleteConfirmation(id);
                        }}
                        className="ml-2 float-md-right btn btn-sm btn-danger">
                        Delete Event
                      </button>
                    </div>
                  )}
                  {event.status === 'closed' ? (
                    <div>
                      <button
                        onClick={this.handleUnfinalizeEventClick}
                        className="float-md-right btn btn-sm btn-danger">
                        Un-finalize Event
                      </button>
                    </div>
                  ) : (
                    <div>
                      <button
                        disabled={accepted < minPlayers}
                        title={
                          accepted < minPlayers
                            ? 'Not enough available players.'
                            : ''
                        }
                        onClick={this.handleFinalizeEventClick}
                        className="ml-2 float-md-right btn btn-sm btn-danger">
                        Finalize Event
                      </button>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="row">
          {/* Notes Section */}
          <div className="col-sm-4">
            <h4>
              Notes{' '}
              {isInPast ? null : (
                <small className="text-muted" onClick={this.toggleNotes}>
                  {expandedNotes ? 'Show less...' : 'Show more...'}
                </small>
              )}
            </h4>
            <div className="form-group">
              {isInPast ? (
                <div>{notes}</div>
              ) : (
                <textarea
                  readOnly={!(isCreator || isAdmin)}
                  className="form-control"
                  style={{
                    height: expandedNotes ? 250 : 'auto',
                  }}
                  defaultValue={notes || ''}
                  onChange={value => updateNotes(value)}
                />
              )}
            </div>
          </div>
          {/* Roster */}
          <div className="col-sm-8">
            <h4>Participants</h4>
            <div>{this.renderLineup(status === 'closed', isInPast)}</div>
          </div>
        </div>
        {showConfirmation && (
          <ConfirmationDialog
            title={confirmationTitle}
            message={confirmationMsg}
            buttonLabels={confirmationLabels}
            show={showConfirmation}
            toggle={this.toggleConfirmationDialog}
            onSubmit={confirmationClick}
          />
        )}
        {showEmail && (
          <MailDialog
            to={to}
            subject={subject}
            show={showEmail}
            toggle={this.toggleEmailDialog}
          />
        )}
      </div>
    );
  }
}

export default EventDetail;
