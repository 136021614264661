import {
  APP_LOAD,
  LOGIN,
  LOGIN_TOKEN,
  IMPERSONATE,
  END_IMPERSONATION,
  LOGOUT,
  REGISTER,
  HOME_PAGE_UNLOADED,
  SETTINGS_PAGE_UNLOADED,
  LOGIN_PAGE_UNLOADED,
  REGISTER_PAGE_UNLOADED,
  ADD_LEAGUE_REQUEST,
  ADD_CLUB_REQUEST,
  MENU_TOGGLE,
  LOCATION_CHANGE,
  CONFIRM_EMAIL,
  CONFIRM_TOKEN,
  UPLOAD_AVATAR,
  UPDATE_PLAYER_INFO,
  ADD_SEASON_REQUEST,
  PAYMENT_TRANSACTION_COMPLETE,
  FIRST_TIME_SETUP,
  FORGOT_PASSWORD_EMAIL_NOT_FOUND,
  LOAD_SETTINGS,
  LOAD_FRIENDS_GROUPS,
  LOAD_FRIENDS_GROUP,
  LOAD_VENUES_LIST,
  UPDATE_USER,
  LOAD_ALL_VENUE_MEMBERS, LOAD_MEMBERS,
} from '../constants';

const defaultState = {
  appName: 'TennisFolder',
  token: null,
  viewChangeCounter: 0,
  menuOpen: false,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case LOAD_SETTINGS:
      return {
        ...state,
        settings: {
          ...state.settings,
          ...action.payload,
        },
      };
    case UPDATE_USER:
      return {
        ...state,
        currentUser: action.payload,
      };
    case PAYMENT_TRANSACTION_COMPLETE:
      return {
        ...state,
        transaction: action.payload,
      };
    case LOCATION_CHANGE:
      if (state.menuOpen || state.redirectTo) {
        return {
          ...state,
          // make sure the menu toggles to closed when navigation changes
          menuOpen: false,
          // Clear the redirect url
          redirectTo: null,
        };
      }
      return state;
    case APP_LOAD:
      return {
        ...state,
        appLoaded: true,
        token: action.token || null,
        currentUser: action.payload,
        // TODO-PBF: Should we just use currentUser.roles?
        roles: action.payload ? action.payload.roles : null,
        isMobile: action.isMobile || false,
      };
    case LOGOUT:
      return {
        ...state,
        roles: null,
        token: null,
        currentUser: null,
        redirectTo: '/login',
      };
    case FIRST_TIME_SETUP:
      return {
        ...state,
        setPassword: false,
        currentUser: {
          ...state.currentUser,
          player: action.payload ? action.payload : state.currentUser.player,
        },
      };
    case LOGIN:
      const user = action.payload && action.payload.user;
      const roles = user && user.roles;
      // TODO: find the correct to do this login restriction...
      if (!user) {
        action.error = true;
        action.payload = ['Invalid credentials'];
      } else if (roles.length === 0) {
        action.error = true;
        action.payload = [
          'You do not have permission. Please use the mobile app.',
        ];
      }
      return {
        ...state,
        token: action.error ? null : action.payload.access_token,
        currentUser: action.error ? null : action.payload.user,
        roles: action.error ? null : action.payload.user.roles, // TODO-PBF: can also have server send up roles
        errors: action.error ? action.payload : null,
      };
    case LOGIN_TOKEN:
      return {
        ...state,
        redirectTo: action.error ? null : action.payload.redirect,
        token: action.error ? null : action.payload.access_token,
        currentUser: action.error ? null : action.payload.user,
        roles: action.error ? null : action.payload.user.roles, // TODO-PBF: can also have server send up roles
        errors: action.error ? action.payload : null,
        message: action.error ? null : action.payload.msg,
        errorToast: action.error ? null : action.payload.err,
      };
    case IMPERSONATE:
      return {
        ...state,
        redirectTo: action.error ? null : '/',
        token: action.error ? null : action.payload.access_token,
        currentUser: action.error ? null : action.payload.user,
        roles: action.error ? null : action.payload.user.roles, // TODO-PBF: can also have server send up roles
        errors: action.error ? action.payload : null,
        impersonator: {
          token: state.token,
          currentUser: state.currentUser,
          roles: state.roles,
        },
      };
    case END_IMPERSONATION:
      return {
        ...state,
        redirectTo: '/admin/users',
        token: state.impersonator.token,
        currentUser: state.impersonator.currentUser,
        roles: state.impersonator.roles,
        impersonator: false,
      };
    case REGISTER:
      return {
        ...state,
        errors: action.error ? action.payload : null,
      };

    case CONFIRM_EMAIL:
      return {
        ...state,
        redirectTo: action.error ? null : '/',
        token: action.error ? null : action.payload.access_token,
        currentUser: action.error ? null : action.payload.user,
        roles: action.error ? null : action.payload.user.roles, // TODO-PBF: can also have server send up roles
        errors: action.error ? action.payload : null,
      };
    case CONFIRM_TOKEN:
      return {
        ...state,
        redirectTo: action.error
          ? null
          : action.payload.match
          ? `/teams/${action.payload.team}/matches/${action.payload.match}`
          : `/teams/${action.payload.team}`,
        token: action.error ? null : action.payload.access_token,
        currentUser: action.error ? null : action.payload.user,
        roles: action.error ? null : action.payload.user.roles,
        errors: action.error ? action.payload : null,
      };
    case FORGOT_PASSWORD_EMAIL_NOT_FOUND:
      return {
        ...state,
        redirectTo: '/register',
      };
    case HOME_PAGE_UNLOADED:
    case SETTINGS_PAGE_UNLOADED:
    case LOGIN_PAGE_UNLOADED:
    case REGISTER_PAGE_UNLOADED:
      return {
        ...state,
        setPassword: false,
        viewChangeCounter: state.viewChangeCounter + 1,
      };
    case ADD_LEAGUE_REQUEST:
      return {
        ...state,
        redirectTo: action.error ? null : '/admin/leagues',
      };
    case ADD_CLUB_REQUEST:
      return {
        ...state,
        redirectTo: action.error ? null : '/admin/venues',
      };
    case ADD_SEASON_REQUEST:
      return {
        ...state,
        redirectTo: action.error ? null : '/admin/seasons',
      };
    case MENU_TOGGLE:
      return { ...state, menuOpen: !state.menuOpen };
    case UPLOAD_AVATAR:
    case UPDATE_PLAYER_INFO:
      return {
        ...state,
        currentUser: {
          ...((action.payload && action.payload.user) || state.currentUser),
          player: action.payload ? action.payload : state.currentUser.player,
        },
      };
    case LOAD_FRIENDS_GROUPS:
      return {
        ...state,
        groups: action.payload,
      };
    case LOAD_FRIENDS_GROUP:
      return {
        ...state,
        friends: action.payload && action.payload.users,
      };
    case LOAD_ALL_VENUE_MEMBERS:
      return {
        ...state,
        membersList: action.payload,
      };
    case LOAD_VENUES_LIST:
      return {
        ...state,
        venues: action.payload,
      };
    case LOAD_MEMBERS:
      return {
        ...state,
        members: action.payload,
      };
    default:
      return state;
  }
};
