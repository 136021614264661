import moment from 'moment';
import { isString, isDate, isNumber, isObject } from 'lodash';

// Currently is expecting the same format for date & time objects this can be expanded
export const combineDateTime = (dt, tm) => {
  let date, time;

  // Is String Parsing out date & Time Combining then wrapping in moment
  if (isString(dt) && isString(tm)) {
    date = dt.indexOf('T') === -1 ? dt : dt.substring(0, dt.indexOf('T'));
    time = tm.indexOf('T') === -1 ? tm : tm.substring(tm.indexOf('T') + 1);
    date = `${date}T${time}`;
    return moment(date);
  }

  // Is Date Object, or Epoch Wrapping in moment & setting the time
  if ((isDate(dt) || isNumber(dt)) && (isDate(tm) || isNumber(tm))) {
    date = moment(dt);
    time = moment(tm);
    date.set('hour', time.get('hour')).set('minute', time.get('minute'));
    return date;
  }
  // Is Moment object just setting time
  if (
    isObject(dt) &&
    dt.hasOwnProperty('_isValid') &&
    isObject(tm) &&
    tm.hasOwnProperty('_isValid')
  ) {
    let newDt = dt
      .clone()
      .set('hour', tm.get('hour'))
      .set('minute', tm.get('minute'));
    return newDt;
  }

  // All else fails send back a date
  return moment();
};

// TODO: Get rid of this localTime function all together and just use moment after the swap.
export const localTime = date => moment(date); // moment(moment.utc(date)).local();

export const getDisplayName = player => {
  console.log(player);
  return player.displayName || player.email;
};
