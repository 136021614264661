import React, { Component } from 'react';
import { Button } from 'reactstrap';
import Loader from '../../../components/UI/Loader';
import agent from '../../../agent';
import { updateEvent } from '../../actions';
import { connect } from 'react-redux';
import { withRouter, Redirect, Link } from 'react-router-dom';
import { localTime } from '../../../common/services/utils/helpers';
import PlayerContactTools from '../../../components/Players/player-contact-tools';
import { debounce } from 'throttle-debounce';
import SocialPlayModal from './social-play-modal';
import ConfirmationDialog from '../../../components/UI/ConfirmationDialog';
import MailDialog from '../../../components/UI/MailDialog';
import {
  inviteResponse,
  eventStatus,
  playTypes,
  eventTypes,
} from '../../../data/types';
import Toggle from '../../../components/UI/Toggle';
import { toastr } from 'react-redux-toastr';

const headingClass =
  'header-text d-flex align-items-center justify-content-between';
const notifyBtnClass = 'btn btn-primary btn-sm';
const gender = ['', 'Male', 'Female', 'Mixed'];

const inviteStatus = [
  { label: '', class: '' },
  { label: 'Confirmed', class: 'badge badge-success text-light' },
  { label: 'Pending', class: 'badge badge-info' },
  { label: 'Declined', class: 'badge badge-danger' },
  { label: 'Received', class: 'badge badge-info' },
  { label: 'Expired', class: 'badge badge-warning' },
];

const TOGGLE_OPTIONS = [
  {
    displayName: <i className="fa fa-times fa-fw fa-lg" />,
    value: false,
  },
  {
    displayName: <i className="fa fa-check fa-fw fa-lg" />,
    value: true,
  },
];
const ADDED_MSG =
  'You are in! When all players have confirmed you will be notified.';
const CONFIRMATION_MSG =
  'This event is confirmed! All players will be notified.';
const REMOVED_MSG = 'You are no longer part of this event.';
const ERR_MSG = `We could not add you to this event at this time. Please try again. If the problem persists, please contact ${
  _clientFeatures.supportURL
}.`;

class SocialPlayEvent extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      open: [],
      confirmed: [],
      showRemoveDialog: false,
      showConfirmationDialog: false,
      curInvite: null,
      redirectTo: null,
      socialPlayMessage: '',
      socialPlayTitle: '',
      socialPlayInputRequired: false,
      showEmail: false,
      replacement: false,
      inviteList: [],
    };
  }

  componentDidMount() {
    this._getGames();
  }

  _handleNotesChange = event => {
    this.changeNotesThrottled(event.target.value);
  };

  changeNotes = notes => {
    updateEvent(this.state.event._id, {
      notes,
    });
  };

  changeNotesThrottled = debounce(350, this.changeNotes);

  toggleEmailDialog = () => this.setState({ showEmail: !this.state.showEmail });
  _getGames = async () => {
    this.setState({
      loading: true,
    });

    const event = await agent.Events.get(this.props.match.params.eventId);
    let inviteList = [];
    if (event.venueEvent) {
      inviteList = await agent.Venues.getMembers(event.venue._id);
    } else {
      inviteList = await agent.Users.getFriendGroup('All Friends');
      inviteList = inviteList.users;
    }
    this.setState({
      loading: false,
      inviteList: inviteList.filter(
        users => !event.invites.find(invite => invite.user._id === users._id)
      ),
      event,
    });
  };

  _getParticipantsEmails = () => {
    const emails = [];
    this.state.event.invites.forEach(i => {
      if (i.status === inviteResponse.CONFIRMED) {
        emails.push(i.user.email);
      }
    });
    return emails;
  };

  _handleDialogClick = (inviteId, playerEmail) => {
    if (!inviteId && playerEmail) {
      agent.Invites.add({
        event: this.state.event.id,
        email: playerEmail,
      })
        .then(() => {
          return this._getGames();
        })
        .then(() => {
          return this.props.onChange();
        });
    } else {
      agent.Invites.remove(inviteId)
        .then(() => {
          if (playerEmail) {
            return agent.Invites.add({
              event: this.state.event.id,
              email: playerEmail,
            });
          }
          return Promise.resolve();
        })
        .then(() => {
          return this._getGames();
        })
        .then(() => {
          return this.props.onChange();
        });
    }
  };

  _handleSocialEventDelete = eventId => {
    this.setState({
      loading: true,
    });
    agent.Events.delete(eventId).then(() => {
      this.props.onChange();
      this.setState({
        redirectTo: `/social-play`,
      });
    });
  };

  _toggleConfirmationDialog = () =>
    this.setState({
      showConfirmationDialog: !this.state.showConfirmationDialog,
    });

  _toggleSocialPlayDialog = () =>
    this.setState({ showRemoveDialog: !this.state.showRemoveDialog });

  getInvitationBadge = status => {
    switch (status) {
      case inviteResponse.ACCEPT:
      case inviteResponse.CONFIRMED:
        return inviteStatus[1];
      case inviteResponse.MAYBE:
        return inviteStatus[2];
      case inviteResponse.DECLINE:
        return inviteStatus[3];
      default:
        return inviteStatus[4];
    }
  };

  getEventDescription = event => {
    if (event.playTypes.mixed) {
      return `Mixed ${event.maxRating.toFixed(1)}`;
    } else {
      let rating = event.creator.rating ? event.creator.rating.toFixed(1) : 0;
      // The actual rating of the social play is not recorded,
      // so we have to determine what it is from min max.
      if (event.venueEvent && event.maxRating) {
        if (event.minRating && event.minRating !== event.maxRating) {
          rating = `${event.minRating.toFixed(1)} - ${event.maxRating.toFixed(
            1
          )}`;
        } else {
          rating = event.maxRating.toFixed(1);
        }
      }
      const playType = event.playTypes.singles ? 'Singles' : 'Doubles';
      const gender =
        event.gender === 1 ? 'Male' : event.gender === 2 ? 'Female' : '';
      return `${playType} ${gender} ${rating}`;
    }
  };
  onChange = (event, available) => {
    if (event.type === eventTypes.SOCIAL) {
      this.setSocialPlayStatus(
        event,
        available ? inviteResponse.CONFIRMED : inviteResponse.DECLINE
      );
    } else {
      this.setPracticeStatus(
        event,
        available ? inviteResponse.ACCEPT : inviteResponse.DECLINE
      );
    }
  };

  setSocialPlayStatus = (event, status) => {
    let eventId = event._id;
    console.log(event);
    if (event.creator === this.props.currentUser._id) {
      this.toggleConfirmationDialog();
      this.setState({ curEvent: event });
    } else {
      agent.Events.setAvailability(eventId, { status: status })
        .then(event => {

          if (status === inviteResponse.CONFIRMED) {
            if (event.status === eventStatus.OPEN) {
              toastr.success('SocialPlay', ADDED_MSG);
            } else {
              toastr.success('SocialPlay', CONFIRMATION_MSG);
            }
          } else {
            toastr.info('SocialPlay', REMOVED_MSG);
            this.props.onChange();
            this.setState({
              redirectTo: '/social-play',
            });
          }
          this._getGames();

        })
        .catch(() => {
          toastr.error('SocialPlay', ERR_MSG);
          this._getGames();
        });
    }
  };
  setPracticeStatus = (event, status) => {
    let eventId = event._id;
    agent.Events.setAvailability(eventId, { status: status })
      .then(() => {
        if (status === inviteResponse.ACCEPT) {
          if (event.isFinalized) {
            toastr.success('Practice', ADDED_MSG);
          } else {
            toastr.success('Practice', CONFIRMATION_MSG);
          }
        } else {
          toastr.info('Practice', REMOVED_MSG);
        }
        this._getGames();
      })
      .catch(() => {
        toastr.error('Practice', ERR_MSG);
        this._getGames();
      });
  };
  render() {
    const { state } = this;
    const { event } = state;

    const isSocialPlayCreator = event
      ? event.creator._id === this.props.currentUser._id
      : undefined;

    return (
      <div>
        {this.state.redirectTo ? <Redirect to={this.state.redirectTo} /> : null}
        <div className="social-header-area">
          <h2 className={headingClass}>
            <Link to="/social-play">Social Play</Link>
            {event && (
              <span onClick={this.toggleEmailDialog} className={notifyBtnClass}>
                Email Participants
              </span>
            )}
          </h2>
          {this.state.showEmail && (
            <MailDialog
              to={this._getParticipantsEmails()}
              subject={`Our SocialPlay Event ${localTime(
                this.state.event.start //this.state.event.dateTime
              ).format('ddd, MM/DD/YY h:mma')}`}
              show={this.state.showEmail}
              toggle={this.toggleEmailDialog}
            />
          )}
        </div>
        <h4 className="text-secondary d-flex justify-content-center">
          Event Details
        </h4>
        <hr />
        {state.loading ? (
          <Loader />
        ) : event ? (
          <div>
            <div className="list-group">
              <div className="list-group-item">
                <h5>
                  {/*{localTime(event.dateTime).format('ddd, MM/DD/YY h:mma')}*/}
                  {localTime(event.start).format('ddd, MM/DD/YY h:mma')}
                </h5>
                <span>
                  {!event.userResponse && (
                    <div className="float-right">
                      <Toggle
                        className={
                          event.userResponse &&
                          event.userResponse !== inviteResponse.ACCEPT &&
                          'toggle--soft-no'
                        }
                        value={
                          event.userResponse &&
                          (event.userResponse === inviteResponse.CONFIRMED ||
                            event.userResponse === inviteResponse.ACCEPT)
                        }
                        options={TOGGLE_OPTIONS}
                        onChange={available => this.onChange(event, available)}
                      />
                    </div>
                  )}

                  {isSocialPlayCreator && (
                    <Button
                      disabled={event.status === eventStatus.CLOSED}
                      className="float-right"
                      color="primary"
                      onClick={() => {
                        this.setState({
                          curInvite: null,
                          socialPlayTitle: 'Add Player',
                          socialPlayMessage:
                            'Enter an email to add a player to your Social Play Event.',
                          socialPlayInputRequired: true,
                          replacement: false,
                        });
                        this._toggleSocialPlayDialog();
                      }}>
                      Add Player
                    </Button>
                  )}
                </span>
                <div>{event.venue.name}</div>
                <div>{this.getEventDescription(event)}</div>
              </div>
              <div className="list-group-item">
                <h5>Notes</h5>{' '}
                <textarea
                  disabled={!isSocialPlayCreator}
                  className="form-control"
                  name="txtNotes"
                  id="txtNotes"
                  cols="30"
                  rows="5"
                  defaultValue={event.notes || ''}
                  onChange={this._handleNotesChange}
                />
              </div>
              {event.invites.map(invite => {
                const isCurrentUser =
                  invite.user._id === this.props.currentUser._id;
                return (
                  <div key={invite.id} className="list-group-item">
                    <PlayerContactTools player={invite} />
                    <b className="pl-3">{invite.user.displayName}</b>
                    <div className="float-right">
                      <span
                        className={
                          this.getInvitationBadge(invite.status).class
                        }>
                        {this.getInvitationBadge(invite.status).label}
                      </span>
                      {isSocialPlayCreator || isCurrentUser ? (
                        <i
                          className="fa fa-fw fa-trash text-danger"
                          onClick={() => {
                            if (isSocialPlayCreator && isCurrentUser) {
                              this._toggleConfirmationDialog();
                            } else {
                              this._toggleSocialPlayDialog();
                            }
                            this.setState({
                              curInvite: invite,
                              socialPlayInputRequired: false,
                              socialPlayTitle: isCurrentUser
                                ? 'Leave Social Play Event'
                                : 'Remove Player',
                              socialPlayMessage: isCurrentUser
                                ? 'Are you sure you want to leave the Social Play event?'
                                : 'Are you sure you want to remove this player from the Social Play?',
                              replacement: true,
                            });
                          }}
                        />
                      ) : (
                        <i className="fa fa-fw fa-trash text-white" />
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        ) : (
          <div>Event not found.</div>
        )}
        {this.state.showRemoveDialog && (
          // TODO: Change this to use InputDialog and get rid of SocialPlay Modal
          <SocialPlayModal
            title={this.state.socialPlayTitle}
            message={this.state.socialPlayMessage}
            show={this.state.showRemoveDialog}
            toggle={this._toggleSocialPlayDialog}
            inputRequired={this.state.socialPlayInputRequired}
            replacement={this.state.replacement}
            inviteList={this.state.inviteList}
            venueEvent={event.venueEvent}
            onSubmit={values => {
              this._toggleSocialPlayDialog();
              this._handleDialogClick(
                this.state.curInvite && this.state.curInvite.id,
                values.playerEmail
              );
            }}
          />
        )}
        {this.state.showConfirmationDialog && (
          <ConfirmationDialog
            show={this.state.showConfirmationDialog}
            title="Delete Social Play Event"
            message="Are you sure you want to delete the social play event? Confirmed players will also be removed."
            toggle={this._toggleConfirmationDialog}
            onSubmit={() => {
              this._toggleConfirmationDialog();
              this._handleSocialEventDelete(event.id);
            }}
          />
        )}
      </div>
    );
  }
}

const stateToProps = state => ({
  ...state.common,
});

export default withRouter(connect(stateToProps)(SocialPlayEvent));
