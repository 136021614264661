import { EVENTS_LOAD } from './constants';

export default function(state = {}, action) {
  switch (action.type) {
    case EVENTS_LOAD:
      const {
        payload: { events, pastEvents },
      } = action;
      return {
        ...state,
        currentEvents: events,
        pastEvents,
      };
    default:
      return state;
  }
}
