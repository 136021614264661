// import Preferences from '../../event/components/friends';
// export default Preferences;
import React from 'react';
import { connect } from 'react-redux';
import { FriendsComponent } from './friends.component';
import { loadGroups, loadGroup, loadAllMembers } from '../../common/action';
import agent from '../../agent';
import { toastr } from 'react-redux-toastr';

const mapStateToProps = state => ({
  ...state.profile,
  ...state.common,
});

const mapDispatchToProps = dispatch => ({
  onGroupsLoad: groups => dispatch(loadGroups(groups)),
  onGroupLoad: group => dispatch(loadGroup(group)),
  onMembersLoad: members => dispatch(loadAllMembers(members)),
});

export class PreferenceContainer extends React.Component {
  componentDidMount() {
    this.getFriendProps('All Friends');
  }

  getFriendProps = group => {
    this.props.onGroupsLoad(agent.Users.getFriendGroups());
    this.onGetGroup(group);
  };

  onGetGroup = values => {
    this.props.onGroupLoad(agent.Users.getFriendGroup(values));
    const { roles } = this.props;
    if (roles.indexOf('Venue') !== -1) {
      this.props.onMembersLoad(agent.Users.getAllMembers({ circle: values }));
    }
  };

  onAddGroup = values => {
    agent.Users.addGroup(values)
      .then(groups => {
        this.getFriendProps(groups[0].name);
      })
      .then(() => {
        toastr.success('Success', 'Group added.');
      })
      .catch(() => {
        toastr.error('Error', 'Could not add the group.');
      });
  };

  onRemoveGroup = values => {
    agent.Users.removeGroup(values.name)
      .then(groups => {
        this.getFriendProps(groups[0].name);
      })
      .then(() => {
        toastr.success('Success', 'Group removed.');
      })
      .catch(() => {
        toastr.error('Error', 'Could not remove group.');
      });
  };

  onAddFriend = values => {
    agent.Users.addFriendToGroup(values)
      .then(() => {
        this.getFriendProps(values.groups[0]);
      })
      .then(() => {
        toastr.success('Success', 'Friend added.');
      })
      .catch(() => {
        toastr.error('Error', 'Could not add friend.');
      });
  };

  onRemoveFriend = values => {
    agent.Users.removeFriendFromGroup(values.group, values.id)
      .then(() => {
        this.getFriendProps(values.group);
      })
      .then(() => {
        toastr.success('Success', 'Friend removed.');
      })
      .catch(() => {
        toastr.error('Error', 'Could not remove friend.');
      });
  };

  render() {
    return (
      <div>
        <FriendsComponent
          groups={this.props.groups}
          membersList={this.props.membersList}
          friends={this.props.friends}
          onGetGroup={this.onGetGroup}
          onAddGroup={this.onAddGroup}
          onRemoveGroup={this.onRemoveGroup}
          onAddFriend={this.onAddFriend}
          onRemoveFriend={this.onRemoveFriend}
        />
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PreferenceContainer);
