import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import Button from '../../components/UI/Button';
import ListErrors from '../../common/list-errors';
import { Redirect } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';

const form = 'captainSettings';

class CaptainSettingsForm extends Component {
  state = {
    redirectTo: null,
    initialPreferences: this.props,
    saved: false,
  };
  static defaultProps = {
    submitButtonText: 'Update Settings',
  };

  handleSubmit = values => {
    this.setState({ saved: true });
    return this.props.onSubmit(values);
  };
  render() {
    const {
      errors,
      handleSubmit,
      submitting,
      invalid,
      pristine,
      submitButtonText,
    } = this.props;

    const { saved } = this.state;

    return this.state.redirectTo ? (
      <Redirect to={this.state.redirectTo} />
    ) : (
      <div className="row">
        <div className="col-sm">
          <form onSubmit={handleSubmit(this.handleSubmit)}>
            {/* TODO: maybe have the viewModel return an array with an object of name and label so we can do a loop here */}
            <div className="row">
              <div className="col-sm-6">
                <label className="form-check-label">
                  <Field
                    type="checkbox"
                    name="availability_notfications"
                    component={field => (
                      <input
                        {...field.input}
                        type="checkbox"
                        className="form-check-input"
                      />
                    )}
                  />
                  Receive availability notifications
                </label>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6">
                <label className="form-check-label">
                  <Field
                    type="checkbox"
                    name="roster_notifications"
                    component={field => (
                      <input
                        {...field.input}
                        type="checkbox"
                        className="form-check-input"
                      />
                    )}
                  />
                  Receive roster notifications
                </label>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6">
                <label className="form-check-label">
                  <Field
                    type="checkbox"
                    name="disable_team_prefs"
                    component={field => (
                      <input
                        {...field.input}
                        type="checkbox"
                        className="form-check-input"
                      />
                    )}
                  />
                  Disable team preferences
                </label>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6">
                <label className="form-check-label">
                  <Field
                    type="checkbox"
                    name="lineup_confirmation_reminders"
                    component={field => (
                      <input
                        {...field.input}
                        type="checkbox"
                        className="form-check-input"
                      />
                    )}
                  />
                  Lineup confirmation reminders
                </label>
              </div>
            </div>
            <div className="clearfix">
              <Field
                name="submit"
                type="submit"
                component={Button}
                text={submitButtonText}
                disabled={pristine || invalid || submitting}
              />
            </div>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  currentUser: state.common.currentUser,
});

export default compose(
  connect(mapStateToProps),
  reduxForm({
    form: 'CaptainSettingsForm',
    enableReinitialize: true,
  })
)(CaptainSettingsForm);
