import { createBrowserHistory } from 'history';
import { applyMiddleware, compose, createStore } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';
import createRootReducer from './reducers';

import { promiseMiddleware, localStorageMiddleware } from './middleware';

export const history = createBrowserHistory();

export default function configureStore(initialState) {
  // Build middleware. These are functions that can process the actions before they reach the store.
  const windowIfDefined =
    typeof window === 'undefined'
      ? { __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: null }
      : window;
  // If devTools is installed, connect to it
  const composeEnhancers =
    windowIfDefined.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const store = createStore(
    createRootReducer(history),
    initialState,
    composeEnhancers(
      applyMiddleware(
        routerMiddleware(history),
        () => next => action => Promise.resolve(next(action)),
        thunk,
        promiseMiddleware,
        localStorageMiddleware
      )
    )
  );

  return store;
}
