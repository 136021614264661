import {
  LOCATION_CHANGE,
  IMPERSONATE,
  END_IMPERSONATION,
} from '../../constants';
import {
  BEFORE_EVENT_CREATE,
  AFTER_EVENT_CREATE,
  GET_EVENT,
  UPDATE_EVENT,
  SET_PLAYER_EVENT_STATUS,
} from './constants';

export default function(state = {}, action) {
  switch (action.type) {
    case IMPERSONATE:
    case END_IMPERSONATION:
      return {};
    case LOCATION_CHANGE:
      if (state.errors || state.redirectTo || state.practice) {
        return {
          ...state,
          errors: null,
          redirectTo: null,
          practice: null,
        };
      }
      return state;
    case UPDATE_EVENT:
      return {
        ...state,
        event: action.payload,
      };
    case BEFORE_EVENT_CREATE:
      return {
        ...state,
        venues: action.payload,
      };
    case AFTER_EVENT_CREATE:
      return {
        ...state,
        errors: action.error ? [action.payload.errors.message] : null,
        redirectTo: action.error ? null : `/venues/${action.payload.venue}`,
      };
    case GET_EVENT:
      return {
        ...state,
        event: action.payload,
      };
    case SET_PLAYER_EVENT_STATUS:
      return {
        ...state,
        event: action.payload,
      };
    default:
      return state;
  }
}
