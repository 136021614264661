import React from 'react';
import moment from 'moment';
import Toggle from '../../../components/UI/Toggle/index';
import { Link, Redirect } from 'react-router-dom';
import PlayerContactTools from '../../../components/Players/player-contact-tools';
import { AVAIL_TOGGLE, CONF_TOGGLE } from '../constants';
import ParticipationLabel from '../../components/participation-label';
import agent from '../../../agent';
import ConfirmationDialog from '../../../components/UI/ConfirmationDialog';
import MailDialog from '../../../components/UI/MailDialog';
import classnames from 'classnames';
import { localTime } from '../../../common/services/utils/helpers';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import {
  eventTypes,
  eventTypesArray,
  inviteResponse,
} from '../../../data/types';
import AddPlayerModal from '../../../social-play/components/event/social-play-modal';

const LAST_NAME = 0;
const FIRST_NAME = 1;
const AVAILABILITY = 3;

const ModalEventFinalizationConfirmation = props => (
  <Modal isOpen={props.isOpen}>
    <ModalHeader toggle={props.toggle}>Are you sure?</ModalHeader>
    <ModalBody>
      <div>Finalize this event now?</div>
    </ModalBody>
    <ModalFooter>
      <a className="btn btn-link" onClick={props.toggle}>
        Cancel
      </a>
      <button className="btn btn-primary" onClick={props.onConfirm}>
        Finalize Event
      </button>
    </ModalFooter>
  </Modal>
);

const ModalEventUnfinalizationConfirmation = props => (
  <Modal isOpen={props.isOpen}>
    <ModalHeader toggle={props.toggle}>Are you sure?</ModalHeader>
    <ModalBody>
      <div>Unfinalize this event now?</div>
    </ModalBody>
    <ModalFooter>
      <a className="btn btn-link" onClick={props.toggleUnfinalized}>
        Cancel
      </a>
      <button className="btn btn-primary" onClick={props.onConfirm}>
        Unfinalize Event
      </button>
    </ModalFooter>
  </Modal>
);

const ModalEventSendReminderConfirmation = props => (
  <Modal isOpen={props.isOpen}>
    <ModalHeader toggle={props.toggle}>Send Reminder?</ModalHeader>
    <ModalBody>
      <div>
        Send an email, reminding those interested about the upcoming event?
      </div>
    </ModalBody>
    <ModalFooter>
      <a className="btn btn-link" onClick={props.toggleSendReminder}>
        Cancel
      </a>
      <button className="btn btn-primary" onClick={props.onConfirm}>
        Send
      </button>
    </ModalFooter>
  </Modal>
);

const ModalEventResendInviteConfirmation = props => (
  <Modal isOpen={props.isOpen}>
    <ModalHeader toggle={props.toggle}>Resend Invites?</ModalHeader>
    <ModalBody>
      <div>
        This will resend email invites to those you have not responded to this
        event.
      </div>
    </ModalBody>
    <ModalFooter>
      <a className="btn btn-link" onClick={props.toggleResendInvite}>
        Cancel
      </a>
      <button className="btn btn-primary" onClick={props.onConfirm}>
        Send
      </button>
    </ModalFooter>
  </Modal>
);

const headingClass =
  'header-text d-flex align-items-center justify-content-between';
const notifyBtnClass = 'btn btn-primary btn-sm';

class EventDetail extends React.Component {
  state = {
    toggle: false,
    toggleUnfinalized: false,
    toggleReminder: false,
    toggleResend: false,
    expandedNotes: false,
    showConfirmation: false,
    showEmail: false,
    showAddPlayer: false,
    to: '',
    subject: '',
    sortRoster: LAST_NAME,
  };

  toggle = () => {
    this.setState({
      toggle: !this.state.toggle,
    });
  };

  toggleNotes = () => {
    this.setState({
      expandedNotes: !this.state.expandedNotes,
    });
  };

  toggleUnfinalized = () => {
    this.setState({
      toggleUnfinalized: !this.state.toggleUnfinalized,
    });
  };

  toggleSendReminder = () => {
    this.setState({
      toggleReminder: !this.state.toggleReminder,
    });
  };

  toggleResendInvite = () => {
    this.setState({
      toggleResend: !this.state.toggleResend,
    });
  };

  showEmailDialog = event => {
    const to = event.invites.map(invite => invite.user.email);
    this.setState({ to: to });
    this.toggleEmailDialog();
  };

  toggleEmailDialog = () => {
    this.setState({ showEmail: !this.state.showEmail });
  };

  toggleAddPlayerDialog = () => {
    this.setState({ showAddPlayer: !this.state.showAddPlayer });
  };

  toggleConfirmationDialog = () =>
    this.setState({ showConfirmation: !this.state.showConfirmation });

  handleFinalizeEventClick = () => {
    // launch modal confirmation window
    this.setState({
      toggle: true,
    });
  };

  handleFinalizeConfirmClick = () => {
    // finalize the event
    this.props.finalizeEvent();
    this.setState({
      toggle: false,
    });
  };

  handleUnfinalizeEventClick = () => {
    this.setState({
      toggleUnfinalized: true,
    });
  };

  handleUnfinalizeConfirmClick = () => {
    // finalize the event
    this.props.unfinalizeEvent();
    this.setState({
      toggleUnfinalized: false,
    });
  };

  handleSendReminderClick = () => {
    this.props.sendReminder();
    this.setState({
      toggleReminder: false,
    });
  };

  handleResendInviteClick = () => {
    this.props.resendInvite();
    this.setState({
      toggleResend: false,
    });
  };

  handleFirstNameClick = () =>
    this.setState({
      sortRoster: FIRST_NAME,
    });

  handleLastNameClick = () =>
    this.setState({
      sortRoster: LAST_NAME,
    });

  handleAvailabilityClick = () =>
    this.setState({
      sortRoster: AVAILABILITY,
    });

  handleEventDelete = () => {
    const {
      event: { id },
    } = this.props;
    agent.Events.delete(id).then(() => this.setState({ redirect: true }));
  };

  handleAddPlayer = email => {
    this.props.addPlayer({ event: this.props.event._id, email: email });
  };

  handleOnAvailabilityChange = (user, status) => {
    const { setPlayerStatus } = this.props;
    this.setState({ toggleDisabled: true });
    setPlayerStatus(user._id, status)
      .then(() => {
        this.setState({
          toggleDisabled: false,
          redirect: status === inviteResponse.DECLINE,
        });
      })
      .catch(() => {
        this.setState({ toggleDisabled: false });
      });
  };

  getListOfParticipant = () => {
    const {
      event: { invites },
      currentUser,
      isCreator,
    } = this.props;
    let participants;
    switch (this.state.sortRoster) {
      case FIRST_NAME:
        participants = []
          .concat(invites)
          .sort((a, b) =>
            getName(a, 'first').localeCompare(getName(b, 'first'))
          );
        break;
      case LAST_NAME:
        participants = []
          .concat(invites)
          .sort((a, b) => getName(a, 'last').localeCompare(getName(b, 'last')));
        break;
      case AVAILABILITY:
        participants = [].concat(invites).sort((a, b) => {
          if (getStatusVal(a.status) > getStatusVal(b.status)) {
            return 1;
          } else if (getStatusVal(a.status) < getStatusVal(b.status)) {
            return -1;
          } else {
            return 0;
          }
        });
        break;
      default:
        participants = [];
    }
    if (
      !isCreator &&
      !participants.find(
        participant => participant.user._id === currentUser._id
      )
    ) {
      participants.push({ user: currentUser });
    }
    return participants;

    function getName(player, type) {
      if (player.user) {
        return player.user.name && player.user.name[type]
          ? player.user.name[type]
          : player.user.email;
      }
      return 'Unknown';
    }
    function getStatusVal(status) {
      switch (status) {
        case 'decline':
          return 2;
        case 'accept':
          return 1;
        default:
          return 0;
      }
    }
  };

  getEventName(type) {
    const event = eventTypesArray.find(e => e.id === type);
    return event ? event.name : 'Venue Event';
  }

  getEventDescription = event => {
    let gender;
    if (event.gender && event.gender !== 0) {
      gender = event.gender === 1 ? 'Male' : 'Female';
    }
    let rating;
    if (event.maxRating) {
      if (event.minRating) {
        rating = `${event.minRating.toFixed(1)} - ${event.maxRating.toFixed(
          1
        )}`;
      } else {
        rating = `${event.maxRating.toFixed(1)}`;
      }
    }
    if (gender && rating) {
      return `${gender} ${rating}`;
    } else if (gender) {
      return `${gender} Only`;
    } else {
      return `${rating} Rating Only`;
    }
  };

  handleUnconfirmedPlayer = isCurrentUser => {
    const {
      event: { venueEvent },
      isCreator,
    } = this.props;
    this.setState({
      toggleDisabled: false,
      redirect: venueEvent && !isCreator,
    });
  };

  getListParticipants(isFinalized, isInPast, event) {
    const { currentUser, isCreator } = this.props;
    const { toggleDisabled } = this.state;
    return (
      <table className="table">
        <thead>
          <tr>
            <th />
            <th>Player</th>
            {event.type === eventTypes.DROPIN ? (
              <th>Interested </th>
            ) : (
              <th>Available </th>
            )}
            {isFinalized && <th>Confirmed</th>}
          </tr>
        </thead>
        <tbody>
          {this.getListOfParticipant().map(participants => {
            const inThisEvent = participants.status === 'confirmed';
            const isCurrentUser = currentUser._id === participants.user.id;
            const availabilityEditable =
              !isInPast && !isFinalized && (isCreator || isCurrentUser);
            const confirmEditable =
              !isInPast && inThisEvent && (isCreator || isCurrentUser);
            return (
              <tr key={participants.id}>
                <td className="player-contact-col">
                  <PlayerContactTools player={participants} />
                </td>
                <td>
                  {participants.user.displayName ||
                    participants.user.email ||
                    ''}
                </td>
                <td>
                  {availabilityEditable ? (
                    <Toggle
                      // the toggle is expecting accept. However, the creator of the event gets auto confirmed in so he has a status of 'confirmed'
                      value={
                        participants.status === 'confirmed'
                          ? 'accept'
                          : participants.status
                      }
                      onChange={status =>
                        this.handleOnAvailabilityChange(
                          participants.user,
                          status
                        )
                      }
                      options={AVAIL_TOGGLE}
                      disabled={toggleDisabled}
                    />
                  ) : (
                    <ParticipationLabel status={participants.status} />
                  )}
                </td>
                {isFinalized && (
                  <td>
                    {confirmEditable ? (
                      <Toggle
                        value={participants.status}
                        onChange={status => {
                          this.setState({ toggleDisabled: true });
                          this.props
                            .setPlayerStatus(participants.user._id, status)
                            .then(() => {
                              this.handleUnconfirmedPlayer(isCurrentUser);
                            })
                            .catch(() => {
                              this.setState({ toggleDisabled: false });
                            });
                        }}
                        options={CONF_TOGGLE}
                        disabled={this.state.toggleDisabled}
                      />
                    ) : (
                      <ParticipationLabel status={participants.status} />
                    )}
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }

  toggleDeleteConfirmation = () =>
    this.setState({
      showConfirmation: true,
      confirmationTitle: 'Delete Event',
      confirmationMsg: 'Are you sure you want to delete the event?',
      confirmationLabels: { ok: 'Delete Event' },
      confirmationClick: () => {
        this.handleEventDelete();
      },
    });

  render() {
    const {
      event,
      event: {
        type,
        name,
        start,
        gender,
        maxRating,
        maxPlayers,
        minPlayers,
        isTemporary,
        status,
        accepted,
        id,
        notes,
        cost,
        venue,
        venueEvent,
      },
      venueMembers,
      inviteList,
      isAdmin,
      updateNotes,
      updateMaxPlayers,
      updateMinPlayers,
      updateCost,
      isCreator,
    } = this.props;
    const {
      toggle,
      toggleUnfinalized,
      toggleReminder,
      toggleResend,
      expandedNotes,
      redirect,
      sortRoster,
      showConfirmation,
      confirmationTitle,
      confirmationMsg,
      confirmationLabels,
      confirmationClick,
      showAddPlayer,
      showEmail,
      to,
      subject,
    } = this.state;
    const redirectTo = isCreator ? `/venues/${venue.id}/events` : `/agenda`;
    const newPlayerTitle = venueEvent && !event.public ? 'Member' : 'Player';

    const isInPast = localTime(start).isBefore(moment());

    return redirect ? (
      <Redirect to={redirectTo} />
    ) : (
      <div>
        <ModalEventFinalizationConfirmation
          isOpen={toggle}
          toggle={this.toggle}
          onConfirm={this.handleFinalizeConfirmClick}
        />
        <ModalEventUnfinalizationConfirmation
          isOpen={toggleUnfinalized}
          toggle={this.toggleUnfinalized}
          onConfirm={this.handleUnfinalizeConfirmClick}
        />
        <ModalEventSendReminderConfirmation
          isOpen={toggleReminder}
          toggle={this.toggleSendReminder}
          onConfirm={this.handleSendReminderClick}
        />
        <ModalEventResendInviteConfirmation
          isOpen={toggleResend}
          toggle={this.toggleResendInvite}
          onConfirm={this.handleResendInviteClick}
        />
        <div className="social-header-area">
          <h2 className={headingClass}>
            <Link to={redirectTo}>{this.getEventName(type)}</Link>
            {isAdmin && (
              <button
                className={notifyBtnClass}
                onClick={() => this.showEmailDialog(event)}>
                Email Participants
              </button>
            )}
          </h2>
        </div>
        <div className="row my-3">
          <div className="col-md">
            <div className={'event-header event-header--' + type}>
              <h2>{name || ''}</h2>
              <h3>
                <small className="text-muted">
                  {localTime(start).format('LLL')}
                </small>
              </h3>
              {(gender || maxRating) && (
                <h4>
                  <small className="text-muted">
                    {this.getEventDescription(event)}
                  </small>
                </h4>
              )}
            </div>
          </div>
          <div className="col-md">
            {event.status === 'closed' ? (
              // TODO: add closed as a constant or update vm
              <div>
                <dl className="row text-md-right">
                  <dt className="col col-md-10">Number Confirmed:</dt>
                  <dd className="col col-md-2">{accepted}</dd>
                </dl>
                {isAdmin && (
                  <div>
                    <button
                      title="Delete Event"
                      onClick={() => {
                        this.toggleDeleteConfirmation();
                      }}
                      className="ml-2 float-md-right btn btn-sm btn-danger">
                      Delete Event
                    </button>
                    {type !== eventTypes.DROPIN ? (
                      <button
                        onClick={this.handleUnfinalizeEventClick}
                        className="float-md-right btn btn-sm btn-danger">
                        Unfinalize Event
                      </button>
                    ) : (
                      <div>
                        {!isInPast && (
                          <button
                            disabled={!accepted || accepted === 0}
                            onClick={this.toggleSendReminder}
                            className="float-md-right btn btn-sm btn-danger">
                            Send Reminder
                          </button>
                        )}
                      </div>
                    )}
                  </div>
                )}
              </div>
            ) : (
              <div className="clearfix my-3 my-md-0">
                <dl className="row text-md-right">
                  {type === eventTypes.DROPIN ? (
                    <dt className="col col-md-10">Number Interested:</dt>
                  ) : (
                    <dt className="col col-md-10">Number Available:</dt>
                  )}
                  {/* event.available are how many spots left to be accepted/confirmed into. event.accepted contains the count of accepted users*/}
                  <dd className="col col-md-2">{accepted}</dd>
                  {maxPlayers ? (
                    <dt className="col col-md-10 text-md-right">
                      Max Participants:
                    </dt>
                  ) : null}
                  {maxPlayers ? (
                    <dd className="col col-md-2">{maxPlayers}</dd>
                  ) : null}
                  {minPlayers ? (
                    <dt className="col col-md-10 text-md-right">
                      Min Participants:
                    </dt>
                  ) : null}
                  {minPlayers ? (
                    <dd className="col col-md-2">{minPlayers}</dd>
                  ) : null}
                </dl>
                {isAdmin && (
                  <div>
                    {isInPast || isTemporary ? null : (
                      <div>
                        <button
                          title="Delete Event"
                          onClick={() => {
                            this.toggleDeleteConfirmation(id);
                          }}
                          className="ml-2 float-md-right btn btn-sm btn-danger">
                          Delete Event
                        </button>
                      </div>
                    )}
                    {type !== eventTypes.DROPIN ? (
                      <div>
                        <button
                          disabled={accepted < minPlayers}
                          title={
                            accepted < minPlayers
                              ? 'Not enough available players.'
                              : ''
                          }
                          onClick={this.handleFinalizeEventClick}
                          className="ml-2 float-md-right btn btn-sm btn-danger">
                          Finalize Event
                        </button>
                      </div>
                    ) : (
                      <div>
                        {!isInPast && (
                          <button
                            disabled={!accepted || accepted === 0}
                            onClick={this.toggleSendReminder}
                            className="ml-2 float-md-right btn btn-sm btn-danger">
                            Send Reminder
                          </button>
                        )}
                      </div>
                    )}
                    {!isInPast && (
                      <div>
                        <button
                          onClick={this.toggleResendInvite}
                          className="float-md-right btn btn-sm btn-danger">
                          Resend Invites
                        </button>
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        <div className="row">
          {/* Notes Section */}
          <div className="col-sm-4">
            <h4>
              Notes{' '}
              {isInPast ? null : (
                <small className="text-muted" onClick={this.toggleNotes}>
                  {expandedNotes ? 'Show less...' : 'Show more...'}
                </small>
              )}
            </h4>
            <div className="form-group">
              {isInPast ? (
                <div>{notes}</div>
              ) : (
                <textarea
                  readOnly={!isAdmin}
                  className="form-control"
                  style={{
                    height: expandedNotes ? 250 : 'auto',
                  }}
                  defaultValue={notes || ''}
                  onChange={value => updateNotes(value)}
                />
              )}
            </div>
            {cost && (
              <div>
                <h4>Cost</h4>
                <div className="form-group">
                  {isInPast ? (
                    <div>{notes}</div>
                  ) : (
                    <textarea
                      readOnly={!isAdmin}
                      className="form-control"
                      defaultValue={cost}
                      onChange={value => updateCost(value)}
                    />
                  )}
                </div>
              </div>
            )}
            {status !== 'closed' && isAdmin ? (
              <div className="form-check">
                <label className="form-control mr-0">
                  Maximum Participants
                  <input
                    type="number"
                    value={maxPlayers !== 0 ? maxPlayers : null}
                    name="maxNumberOfParticipants"
                    className="form-control"
                    min={1}
                    step={1}
                    onChange={value => updateMaxPlayers(value)}
                  />
                </label>
              </div>
            ) : null}
            {status !== 'closed' && isAdmin ? (
              <div className="form-check">
                <label className="form-control mr-0">
                  Minimum Participants
                  <input
                    type="number"
                    value={minPlayers !== 0 ? minPlayers : null}
                    name="minNumberOfParticipants"
                    className="form-control"
                    min={1}
                    step={1}
                    onChange={value => updateMinPlayers(value)}
                  />
                </label>
              </div>
            ) : null}
          </div>
          {/* Roster */}
          <div className="col-sm-8">
            <h4>Participants</h4>
            <div className="lineup-header">
              <div>
                <small className="text-muted mr-2">Order by</small>
                <div className="btn-group btn-group-sm" role="group">
                  {type !== eventTypes.DROPIN && (
                    <button
                      onClick={this.handleAvailabilityClick}
                      className={classnames({
                        'btn btn-secondary': true,
                        'active focus': sortRoster === AVAILABILITY,
                      })}>
                      Availability
                    </button>
                  )}
                  <button
                    onClick={this.handleFirstNameClick}
                    className={classnames({
                      'btn btn-secondary': true,
                      'active focus': sortRoster === FIRST_NAME,
                    })}>
                    First Name
                  </button>
                  <button
                    onClick={this.handleLastNameClick}
                    className={classnames({
                      'btn btn-secondary': true,
                      'active focus': sortRoster === LAST_NAME,
                    })}>
                    Last Name
                  </button>
                </div>
              </div>
              {status !== 'closed' && isAdmin && (
                <button
                  className={notifyBtnClass}
                  onClick={() => this.toggleAddPlayerDialog()}>
                  Add Player
                </button>
              )}
            </div>
            <div>
              {this.getListParticipants(status === 'closed', isInPast, event)}
            </div>
          </div>
        </div>
        {showConfirmation && (
          <ConfirmationDialog
            title={confirmationTitle}
            message={confirmationMsg}
            buttonLabels={confirmationLabels}
            show={showConfirmation}
            toggle={this.toggleConfirmationDialog}
            onSubmit={confirmationClick}
          />
        )}
        {showAddPlayer && (
          <AddPlayerModal
            title={`Add ${newPlayerTitle}`}
            message={`Enter the email of the ${newPlayerTitle.toLowerCase()} you want to add${
              inviteList && inviteList.length > 0
                ? ' or select their name.'
                : '.'
            }`}
            note={`Make sure you have checked with the new ${newPlayerTitle.toLowerCase()} that they are
              available. Adding a player here will automatically confirm
              them.`}
            show={showAddPlayer}
            toggle={this.toggleAddPlayerDialog}
            inputRequired={true}
            replacement={false}
            validatedEmails={venueMembers}
            inviteList={inviteList}
            venueEvent={venueEvent && !event.public}
            warningMsg="This is a member's only event. This email does not match your member records. Clicking 'Ok' will add this user as a new member."
            onSubmit={values => {
              this.toggleAddPlayerDialog();
              this.handleAddPlayer(values.playerEmail);
            }}
          />
        )}
        {showEmail && (
          <MailDialog
            to={to}
            subject={subject}
            show={showEmail}
            toggle={this.toggleEmailDialog}
          />
        )}
      </div>
    );
  }
}

export default EventDetail;
