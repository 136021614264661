import React, { Component } from 'react';
import { reduxForm, Field } from 'redux-form';
import Button from '../../components/UI/Button';
import Select from '../../components/UI/Select';
import { Redirect, Prompt } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';

const form = 'socialPlayPreferences';
export class SocialPlayPreferencesForm extends Component {
  state = {
    redirectTo: null,
    initialPreferences: this.props,
    saved: false,
  };
  static defaultProps = {
    submitButtonText: 'Save Preferences',
  };

  handleSubmit = values => {
    this.setState({ saved: true });
    return this.props.onSubmit(values);
  };

  render() {
    const {
      handleSubmit,
      pristine,
      submitting,
      invalid,
      submitButtonText,
      typeOptions,
      timeOptions,
    } = this.props;
    const { saved } = this.state;
    return this.state.redirectTo ? (
      <Redirect to={this.state.redirectTo} />
    ) : (
      <div>
        <i>(These settings determine what type of invitations you receive)</i>
        <br />
        <br />
        <form onSubmit={handleSubmit(this.handleSubmit)}>
          <div className="row">
            <div className="col-md">
              <Field
                name="playTimes"
                label="Time Slot"
                component={Select}
                options={timeOptions}
                labelKey="name"
                valueKey="id"
                multi={true}
              />
            </div>
            <div className="col-md">
              <Field
                name="playTypes"
                label="Play Type"
                component={Select}
                options={typeOptions}
                labelKey="name"
                valueKey="id"
                multi={true}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md">
              <div className="form-check">
                <label className="form-check-label">
                  <Field
                    type="checkbox"
                    name="friendsOnly"
                    component={field => (
                      <input
                        {...field.input}
                        type="checkbox"
                        className="form-check-input"
                      />
                    )}
                  />
                  Receive Invitations From Friends Only
                </label>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md">
              <div className="form-check">
                <label className="form-check-label">
                  <Field
                    type="checkbox"
                    name="tournament"
                    component={field => (
                      <input
                        {...field.input}
                        type="checkbox"
                        className="form-check-input"
                      />
                    )}
                  />
                  Receive Tournament Invites
                </label>
              </div>
              <div className="form-check">
                <label className="form-check-label">
                  <Field
                    type="checkbox"
                    name="clinic"
                    component={field => (
                      <input
                        {...field.input}
                        type="checkbox"
                        className="form-check-input"
                      />
                    )}
                  />
                  Receive Lesson and Clinic Invites
                </label>
              </div>
            </div>
          </div>
          <div className="clearfix">
            <Field
              name="submit"
              type="submit"
              component={Button}
              text={submitButtonText}
              disabled={pristine || invalid || submitting}
            />
          </div>
        </form>
        <Prompt
          when={!pristine && !saved}
          message="You have unsaved changes. Are you sure you want to leave?"
        />
        ;
      </div>
    );
  }
}
const mapStateToProps = state => ({
  currentUser: state.common.currentUser,
});
export default compose(
  connect(mapStateToProps),
  reduxForm({
    form,
  })
)(SocialPlayPreferencesForm);
