import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

export default class Message extends React.Component {
  render() {
    const { type, text, title } = this.props;
    const classes = ['alert', `alert-${type}`];

    return (
      <div className={classnames(classes)}>
        {title && <h4 className="alert-heading">{title}</h4>}
        {text}
      </div>
    );
  }
}

Message.propTypes = {
  type: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  title: PropTypes.string,
};
