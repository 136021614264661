import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

const mapStateToProps = state => ({
  ...state.auth,
  ...state.common,
});

class UnauthenticatedUserMenuContainer extends React.Component {
  render() {
    return (
      <nav>
        <ul>
          <li>
            <Link to="/login">Log In</Link>
          </li>
          <li>
            <Link to="/register">Register</Link>
          </li>
        </ul>
      </nav>
    );
  }
}

export default connect(
  mapStateToProps,
  {}
)(UnauthenticatedUserMenuContainer);
