import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { toastr } from 'react-redux-toastr';
import { LOGIN_PAGE_UNLOADED, LOGIN_TOKEN } from '../../constants';
import agent from '../../agent';
import ListErrors from '../../common/list-errors';

export class UserAccountToken extends Component {
  state = { redirect: null };

  componentDidMount() {
    this.props.onLoad(this.props.match.params.token);
  }

  render() {
    const redirect = props => {
      if (props.message) {
        toastr.success(props.message);
      } else if (props.errorToast) {
        toastr.error(props.errorToast, '', { timeOut: 0 });
      }
      props.message && toastr.success(props.message);
      if (props.redirectTo.toLowerCase().startsWith('http')) {
        window.location = props.redirectTo;
        return null;
      } else {
        return <Redirect to={props.redirectTo} />;
      }
    };

    return (
      <div className="container page">
        <div className="row d-flex justify-content-center">
          <div className="col-md-6 col-sm-12">
            <ListErrors errors={this.props.errors} />
          </div>
        </div>
        {this.props.redirectTo && redirect(this.props)}
      </div>
    );
  }
}

const stateToProps = state => ({
  ...state.common,
});

const dispatchToProps = dispatch => ({
  onLoad: token => {
    dispatch({ type: LOGIN_TOKEN, payload: agent.Auth.user_token(token) });
  },
  onUnload: () => dispatch({ type: LOGIN_PAGE_UNLOADED }),
});

export default connect(
  stateToProps,
  dispatchToProps
)(UserAccountToken);
