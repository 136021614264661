import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import teamBg from '../../home/imgs/teams_square.png';
import scheduleBg from '../assets/imgs/socialplay_square.png';
import { USERS_DISPLAY } from '../../constants';
import agent from '../../agent';

export class HomeContainer extends React.Component {
  state = {
    loading: true,
  };

  render() {
    const { match } = this.props;
    let content;
    content = (
      <div>
        {' '}
        <div className="home">
          <div className="home__section home__section--teams">
            <img src={teamBg} />
            <Link to={`/venues/${match.params.id}/teams`}>Teams</Link>
          </div>
          <div className="home__section home__section--social">
            <img src={scheduleBg} />
            <Link to={`/venues/${match.params.id}/members`}>Members</Link>
          </div>
        </div>
      </div>
    );
    return content;
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onLoad: id =>
      id &&
      dispatch({
        type: USERS_DISPLAY,
        payload: agent.Auth.display(id),
      }),
  };
};

const mapStateToProps = state => ({
  ...state.auth,
  ...state.common,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HomeContainer);
