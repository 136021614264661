import React from 'react';
import classNames from 'classnames';
import { toastr } from 'react-redux-toastr';
import ConfirmationDialog from '../../components/UI/ConfirmationDialog';
import AddVenueMemberModal from './add-member-modal';
import ImportMemberModal from './import-member-modal';
import EditMemberModal from './edit-member-modal';
import agent from '../../agent';

const ROLES = [
  {
    name: 'Socialite',
    value: 'socialite',
  },
  {
    name: 'Captain',
    value: 'captain',
  },
];
const TYPE_MEMBERS = 'Members';
const TYPE_FOLLOWERS = 'Followers';
const LAST_NAME = 0;
const FIRST_NAME = 1;
const RATING = 2;
const GENDER = 3;

export class VenueMembersContainer extends React.Component {
  state = {
    showConfirmation: false,
    showImportMemberDialog: false,
    showAddMemberDialog: false,
    showEditMember: false,
    showErr: false,
    selectedUser: '',
    errMsg: '',
    filter: '',
    sortUsers: LAST_NAME,
  };

  changeFilter = ev => {
    this.updateUserList(ev.target.value);
  };

  updateUserList(value) {
    this.setState({
      filter: value,
    });
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    // Set the initial fetch of users to state so we can always filter from the state
    // If we don't keep the original list of users the list will continue to get smaller even after erasing the text in the search bar
    if (!prevState.users && nextProps.users) {
      return { users: nextProps.users };
    }
    return null;
  }

  toggleConfirmationDialog = user =>
    this.setState({
      showConfirmation: !this.state.showConfirmation,
      selectedUser: user,
    });

  toggleEditMemberDialog = user =>
    this.setState({
      showEditMember: !this.state.showEditMember,
      selectedUser: user,
    });

  toggleImportMemberDialog = () =>
    this.setState({
      showImportMemberDialog: !this.state.showImportMemberDialog,
      preview: undefined,
      file: undefined,
    });

  toggleAddMemberDialog = () =>
    this.setState({ showAddMemberDialog: !this.state.showAddMemberDialog });

  handleLastNameClick = () =>
    this.setState({
      sortUsers: LAST_NAME,
    });

  handleFirstNameClick = () =>
    this.setState({
      sortUsers: FIRST_NAME,
    });

  handleRatingClick = () =>
    this.setState({
      sortUsers: RATING,
    });

  handleGenderClick = () =>
    this.setState({
      sortUsers: GENDER,
    });

  handleImportPreviewList = csv => {
    const venue = this.props.venue._id;
    agent.Venues.importList(venue, 'prev', csv)
      .then(response => {
        this.setState({ preview: response, file: csv });
      })
      .catch(() => {
        toastr.error(`Error`, 'Can not read csv file.');
      });
  };

  handleImportList = () => {
    const venue = this.props.venue._id;
    if (this.state.file) {
      agent.Venues.importList(venue, 'update', this.state.file)
        .then(() => {
          this.toggleImportMemberDialog();
          this.props.fetchMembers();
        })
        .catch(() => {
          toastr.error(`Error`, 'Can not import users');
        });
    } else {
      toastr.error(`Missing CSV file.`);
    }
  };

  getSortedUsers(users) {
    if (this.state.filter) {
      const filter = this.state.filter.toLowerCase();
      users = users.filter(
        u =>
          u.displayName.toLowerCase().includes(filter) ||
          u.email.toLowerCase().includes(filter)
      );
    }
    return [].concat(users).sort((a, b) => {
      let aSort = a.displayName;
      let bSort = b.displayName;
      switch (this.state.sortUsers) {
        case LAST_NAME:
          aSort = a.name && a.name.last ? a.name.last : aSort;
          bSort = b.name && b.name.last ? b.name.last : bSort;
          return aSort.localeCompare(bSort);
        case FIRST_NAME:
          aSort = a.name && a.name.first ? a.name.first : aSort;
          bSort = b.name && b.name.first ? b.name.first : bSort;
          return aSort.localeCompare(bSort);
        case RATING:
          aSort = a.rating ? a.rating : 0;
          bSort = b.rating ? b.rating : 0;
          return aSort.toString().localeCompare(bSort.toString());
        case GENDER:
          aSort = a.gender ? a.gender : 0;
          bSort = b.gender ? b.gender : 0;
          return aSort.toString().localeCompare(bSort.toString());
        default:
          aSort.localeCompare(bSort);
      }
    });
  }

  render() {
    const { users, isAdmin, venue, opts } = this.props;
    const type = opts.type;
    const dialog = opts.dialog;
    const roleHeaders = () => {
      return ROLES.map(role => <th>{role.name}</th>);
    };

    const userRows = users
      ? this.getSortedUsers(users).map(user => (
          <tr key={user._id} className="admin-user-table__user">
            <td>
              <div>
                <div title={user.email}>{user.displayName}</div>
              </div>
            </td>
            <td>
              <div>{user.email}</div>
            </td>
            <td>
              {user.gender && user.gender !== 0 ? (
                <div>{user.gender === 1 ? 'M' : 'F'}</div>
              ) : (
                <div>{'N/A'}</div>
              )}
            </td>
            <td>
              {user.rating ? <div>{user.rating}</div> : <div>{'N/A'}</div>}
            </td>
            {isAdmin &&
              ROLES.map(role => (
                <td>
                  <label className="tf-checkbox">
                    <input
                      type="checkbox"
                      className="tf-checkbox__input"
                      defaultChecked={user.roles.includes(role.name)}
                      onChange={this.props.onCheckChange(user, role)}
                    />
                  </label>
                </td>
              ))}
            {type === TYPE_MEMBERS && venue.paying && (
              <td>
                <button
                  title="Edit"
                  onClick={e => {
                    e.preventDefault();
                    this.toggleEditMemberDialog(user);
                  }}
                  className="btn btn-sm btn-link">
                  <i className="fa-pencil fa fa-fw" />
                </button>
              </td>
            )}
            {type === TYPE_MEMBERS && venue.paying && (
              <td>
                <button
                  title="Downgrade"
                  onClick={e => {
                    e.preventDefault();
                    this.toggleConfirmationDialog(user);
                  }}
                  className="btn btn-sm btn-outline-danger">
                  <i className="fa fa-chevron-down fa-fw" />
                </button>
              </td>
            )}
            {type === TYPE_FOLLOWERS && venue.paying && (
              <td>
                <button
                  title="Upgrade"
                  onClick={e => {
                    e.preventDefault();
                    this.toggleConfirmationDialog(user);
                  }}
                  className="btn btn-sm btn-outline-primary">
                  <i className="fa fa-chevron-up fa-fw" />
                </button>
              </td>
            )}
          </tr>
        ))
      : null;
    return (
      <div className="">
        <div className="list-group">
          <div className="list-group-item">
            <div>
              <span className="h3">{type} List</span>
              <div className="float-right">
                <input
                  type="search"
                  placeholder="Search"
                  className="form-control"
                  onChange={this.changeFilter}
                />
              </div>
            </div>
          </div>
          <div className="lineup-header">
            <div>
              <small className="text-muted mr-2">Order by</small>
              <div className="btn-group btn-group-sm" role="group">
                <button
                  onClick={this.handleLastNameClick}
                  className={classNames({
                    'btn btn-danger': true,
                    'active focus': this.state.sortUsers === LAST_NAME,
                  })}>
                  Last Name
                </button>
                <button
                  onClick={this.handleFirstNameClick}
                  className={classNames({
                    'btn btn-secondary': true,
                    'active focus': this.state.sortUsers === FIRST_NAME,
                  })}>
                  First Name
                </button>
                <button
                  onClick={this.handleRatingClick}
                  className={classNames({
                    'btn btn-secondary': true,
                    'active focus': this.state.sortUsers === RATING,
                  })}>
                  Rating
                </button>
                <button
                  onClick={this.handleGenderClick}
                  className={classNames({
                    'btn btn-secondary': true,
                    'active focus': this.state.sortUsers === GENDER,
                  })}>
                  Gender
                </button>
              </div>
            </div>
            {(isAdmin || venue.paying) && (
              <div>
                <div className="float-md-right">
                  <button
                    title="Add Member"
                    onClick={e => {
                      e.preventDefault();
                      this.toggleAddMemberDialog();
                    }}
                    className="btn btn-sm btn-outline-primary">
                    <i className="fa fa-plus fa-fw" />
                    Add Member
                  </button>
                </div>
                <div className="float-md-right" style={{ marginRight: '20px' }}>
                  <button
                    title="Import Users from CSV"
                    onClick={e => {
                      e.preventDefault();
                      this.toggleImportMemberDialog();
                    }}
                    className="btn btn-sm btn-outline-primary">
                    <i className="fa fa-plus fa-fw" />
                    Import List
                  </button>
                </div>
              </div>
            )}
          </div>
          {users && users.length ? (
            <table className="table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Gender</th>
                  <th>Rating</th>
                  {isAdmin && roleHeaders()}
                  {type === TYPE_MEMBERS && venue.paying && <th>Edit</th>}
                  {venue.paying && <th />}
                </tr>
              </thead>
              <tbody>{userRows}</tbody>
            </table>
          ) : (
            <div className="list-group-item">No Venue {type}</div>
          )}
          {this.state.showConfirmation && (
            <ConfirmationDialog
              show={this.state.showConfirmation}
              title={dialog.title}
              buttonLabels={dialog.buttons}
              message={dialog.message}
              toggle={this.toggleConfirmationDialog}
              onSubmit={() => {
                this.props.action(this.state.selectedUser);
              }}
            />
          )}
          {this.state.showImportMemberDialog && (
            <ImportMemberModal
              onPreviewList={this.handleImportPreviewList}
              onImportList={this.handleImportList}
              show={this.state.showImportMemberDialog}
              toggle={this.toggleImportMemberDialog}
              inputRequired={true}
              preview={this.state.preview}
            />
          )}
          {this.state.showAddMemberDialog && (
            <AddVenueMemberModal
              title={'Add new member'}
              message={'Enter an email to add as either a member or follower.'}
              show={this.state.showAddMemberDialog}
              toggle={this.toggleAddMemberDialog}
              inputRequired={true}
              onSubmit={values => {
                this.toggleAddMemberDialog();
                this.props.onAddMember(values);
              }}
            />
          )}
          {this.state.showEditMember && (
            <EditMemberModal
              title={`Edit Profile`}
              message={`Edit the profile for ${
                this.state.selectedUser.displayName
              }.`}
              initialValues={this.state.selectedUser}
              show={this.state.selectedUser}
              toggle={this.toggleEditMemberDialog}
              onSubmit={values => {
                this.toggleEditMemberDialog();
                this.props.onEditMember(values);
              }}
            />
          )}
        </div>
      </div>
    );
  }
}
