import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import ListErrors from '../../common/list-errors';
import agent from '../../agent';
import { UPDATE_FIELD_AUTH, LOGIN, LOGIN_PAGE_UNLOADED } from '../../constants';

class LoginContainer extends React.Component {
  state = {
    redirectToReferrer: null,
  };

  changeEmail = ev => this.props.onChangeEmail(ev.target.value);

  changePassword = ev => this.props.onChangePassword(ev.target.value);

  submitForm = (email, password) => ev => {
    ev.preventDefault();
    const props = this.props;
    this.props.onSubmit(email, password).then(() =>
      this.setState({
        redirectToReferrer: true,
      })
    );
  };

  componentWillUnmount() {
    this.props.onUnload();
  }

  render() {
    const { email = '', password = '' } = this.props;

    if (this.state.redirectToReferrer) {
      const { from } = this.props.location.state || { from: { pathname: '/' } };
      return <Redirect to={from} />;
    }

    return (
      <div className="container page">
        <div className="row">
          <div className="col-md-6 offset-md-3 col-xs-12">
            <div className="auth-page">
              <h1 className="text-xs-center">Log In</h1>
              <p className="text-xs-center">
                <Link to="register">Need an account?</Link>
              </p>
              <ListErrors errors={this.props.errors || this.state.errors} />
              <form onSubmit={this.submitForm(email, password)}>
                <fieldset>
                  <fieldset className="form-group">
                    <input
                      className="form-control"
                      type="email"
                      placeholder="Email"
                      value={email}
                      onChange={this.changeEmail}
                    />
                  </fieldset>
                  <fieldset className="form-group">
                    <input
                      className="form-control"
                      type="password"
                      placeholder="Password"
                      value={password}
                      onChange={this.changePassword}
                    />
                  </fieldset>
                  <button
                    className="btn btn-primary"
                    type="submit"
                    disabled={this.props.inProgress}>
                    Log In
                  </button>
                </fieldset>
              </form>
              <Link to="forgot-password">Forgot Password?</Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  ...state.auth,
  ...state.common,
});

const mapDispatchToProps = dispatch => ({
  onChangeEmail: value =>
    dispatch({ type: UPDATE_FIELD_AUTH, key: 'email', value }),
  onChangePassword: value =>
    dispatch({ type: UPDATE_FIELD_AUTH, key: 'password', value }),
  onSubmit: (email, password) =>
    dispatch({
      type: LOGIN,
      payload: agent.Auth.login(email, password),
    }),
  onUnload: () => dispatch({ type: LOGIN_PAGE_UNLOADED }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginContainer);
