import React from 'react';

class Checkbox extends React.Component {
  constructor() {
    super();
  }
  render() {
    const checked = this.props.checked;
    const label = this.props.label;
    return (
      <label className="tf-checkbox">
        <input
          type="checkbox"
          className="tf-checkbox__input"
          defaultChecked={checked}
          onChange={this.props.onCheckChange}
        />
        {label}
      </label>
    );
  }
}

export default Checkbox;
