import {
  ASYNC_START,
  ASYNC_END,
  ADMIN_USERS_LOADED,
  ADMIN_USERS_UNLOADED,
  ADMIN_TEAMS_LOADED,
  ADMIN_TEAMS_UNLOADED,
  TEAMS_LIST,
  ADMIN_LEAGUES_LOADED,
  ADMIN_CURRENT_LEAGUES_LOADED,
  ADMIN_LEAGUES_UNLOADED,
  ADMIN_FLIGHTS_LOADED,
  ADMIN_FLIGHTS_UNLOADED,
  ADD_LEAGUE_REQUEST,
  ADD_FLIGHT_REQUEST,
  UPDATE_FLIGHT_REQUEST,
  ADMIN_VENUES_LOADED,
  ADMIN_VENUES_UNLOADED,
  ADD_VENUE_REQUEST,
  USERS_LIST,
  SET_USER_IN_ROLE,
  ADMIN_SEASONS_LOADED,
  ADMIN_SEASONS_UNLOADED,
  ADD_SEASON_REQUEST,
  UPDATE_VENUE_REQUEST,
  UPDATE_SEASON_REQUEST,
  UPDATE_LEAGUE_REQUEST,
  USER_PROFILE_LOADED,
  UPDATE_USER_PROFILE,
} from '../constants';

export default (state = {}, action) => {
  switch (action.type) {
    case USER_PROFILE_LOADED:
      return {
        ...state,
        userProfile: action.error ? null : action.payload,
        errors: action.error ? action.payload : null,
      };
    case UPDATE_USER_PROFILE:
      return {
        userProfile: {
          ...state.userProfile,
          player: action.payload,
        },
      };
    case ADMIN_USERS_LOADED:
    case USERS_LIST:
      return {
        ...state,
        filter: action.filter,
        users: action.error ? null : action.payload,
        errors: action.error ? action.payload : null,
      };
    case ADMIN_CURRENT_LEAGUES_LOADED:
      return {
        ...state,
        currentLeagues: action.error ? null : action.payload,
        errors: action.error ? action.payload : null,
      };
    case ADMIN_LEAGUES_LOADED:
      return {
        ...state,
        newLeague: null,
        leagues: action.error ? null : action.payload,
        errors: action.error ? action.payload : null,
      };
    case ADMIN_FLIGHTS_LOADED:
      return {
        ...state,
        newFlight: null,
        flights: action.error ? null : action.payload,
        errors: action.error ? action.payload : null,
      };
    case ADMIN_SEASONS_LOADED:
      return {
        ...state,
        seasons: action.error ? null : action.payload,
        errors: action.error ? action.payload : null,
      };
    case ADMIN_VENUES_LOADED:
      return {
        ...state,
        venues: action.error ? null : action.payload,
        errors: action.error ? action.payload : null,
      };
    case ADMIN_TEAMS_LOADED:
    case TEAMS_LIST:
      return {
        ...state,
        teams: action.error ? null : action.payload,
        errors: action.error ? action.payload : null,
      };
    case SET_USER_IN_ROLE:
      return {
        ...state,
        errors: action.error ? action.payload : null,
      };

    case ADMIN_USERS_UNLOADED:
    case ADMIN_LEAGUES_UNLOADED:
    case ADMIN_FLIGHTS_UNLOADED:
    case ADMIN_VENUES_UNLOADED:
    case ADMIN_SEASONS_UNLOADED:
    case ADMIN_TEAMS_UNLOADED:
      return {};
    // LEAGUES
    case ADD_LEAGUE_REQUEST: {
      let leagues = state.leagues;
      if (!action.error) {
        leagues = [...leagues, action.payload];
      }
      return {
        ...state,
        errors: action.error ? action.payload : null,
        newLeague: action.error ? null : action.payload,
        leagues: leagues,
      };
    }
    case UPDATE_LEAGUE_REQUEST: {
      const leagues = state.leagues;
      if (!action.error) {
        leagues.forEach((venue, idx) => {
          if (action.payload.id === venue.id) {
            leagues[idx] = action.payload;
          }
        });
      }
      return {
        ...state,
        errors: action.error ? action.payload : null,
        newLeague: action.error ? null : action.payload,
        leagues: leagues,
      };
    }
    // FLIGHTS
    case ADD_FLIGHT_REQUEST: {
      let flights = state.flights;
      if (!action.error) {
        flights = [...flights, action.payload];
      }
      return {
        ...state,
        errors: action.error ? action.payload : null,
        newFlight: action.error ? null : action.payload,
        flights: flights,
      };
    }
    case UPDATE_FLIGHT_REQUEST: {
      const flights = state.flights;
      if (!action.error) {
        flights.forEach((flight, idx) => {
          if (action.payload.id === flight.id) {
            flights[idx] = action.payload;
          }
        });
      }
      return {
        ...state,
        errors: action.error ? action.payload : null,
        newFlight: action.error ? null : action.payload,
        flights: flights,
      };
    }
    // VENUES
    case ADD_VENUE_REQUEST: {
      let venues = state.venues;
      if (!action.error) {
        venues = [...venues, action.payload];
      }
      return {
        ...state,
        errors: action.error ? action.payload : null,
        newVenue: action.error ? null : action.payload,
        venues: venues,
      };
    }
    case UPDATE_VENUE_REQUEST: {
      const venues = state.venues;
      if (!action.error) {
        venues.forEach((venue, idx) => {
          if (action.payload.id === venue.id) {
            venues[idx] = action.payload;
          }
        });
      }
      return {
        ...state,
        errors: action.error ? action.payload : null,
        newVenue: action.error ? null : action.payload,
        venues: venues,
      };
    }
    // SEASONS
    case ADD_SEASON_REQUEST: {
      let seasons = state.seasons;
      if (!action.error) {
        seasons = [...seasons, action.payload];
      }
      return {
        ...state,
        errors: action.error ? action.payload : null,
        newSeason: action.error ? null : action.payload,
        seasons: seasons,
      };
    }
    case UPDATE_SEASON_REQUEST: {
      const seasons = state.seasons;
      if (!action.error) {
        seasons.forEach((season, idx) => {
          if (action.payload.id === season.id) {
            seasons[idx] = action.payload;
          }
        });
      }
      return {
        ...state,
        errors: action.error ? action.payload : null,
        newSeason: action.error ? null : action.payload,
        seasons: seasons,
      };
    }
    case ASYNC_START:
      if (action.subtype === USERS_LIST) {
        return {
          ...state,
          inProgress: true,
        };
      }
      break;
    case ASYNC_END:
      return {
        ...state,
        inProgress: false,
      };
    default:
      return state;
  }

  return state;
};
