import { Link } from 'react-router-dom';
import React from 'react';
import agent from '../../agent';
import { connect } from 'react-redux';
import { REGISTER, REGISTER_PAGE_UNLOADED } from '../../constants';
import RegistrationForm from './registration-form';
import Message from './message';
const mapStateToProps = state => ({
  ...state.auth,
});

const mapDispatchToProps = dispatch => ({
  onSubmit: body =>
    dispatch({ type: REGISTER, payload: agent.Auth.register(body) }),
  onUnload: () => dispatch({ type: REGISTER_PAGE_UNLOADED }),
});

class Register extends React.Component {
  state = {
    errors: null,
    registerComplete: false,
  };

  componentDidMount() {
    // const playerId = _get(this.props, 'player.id');
    // this.props.loadPreferences(playerId);
    agent.Venues.getList().then(clubs => this.setState({ clubs }));
  }
  componentWillUnmount() {
    this.props.onUnload();
  }

  submitForm = values => {
    const playerObj = {
      email: values.email,
      password: values.password,
      name: { first: values.firstName, last: values.lastName },
      rating: parseFloat(values.ustaRating),
      gender: values.gender.id,
      phone: values.cellPhoneNumber,
      birthYear: parseInt(values.birthYear ? values.birthYear : 0),
      postal: values.postal,
      venueId: values.venues.map(function(venue) {
        return venue['id'];
      }),
    };
    return this.props.onSubmit(playerObj).then(
      () => {
        this.setState({
          registerComplete: true,
        });
      },
      error => {
        const { body = {} } = error.response;
        if (body.errors.message) {
          this.setState({
            error: [
              <div key={1}>
                It looks like you are already a member. Have you forgotten your
                password? <Link to="/forgot-password">Click here</Link> to reset
                it.
              </div>,
            ],
          });
        } else {
          this.setState({ error: body });
        }
      }
    );
  };

  render() {
    return (
      <div className="auth-page">
        {this.state.registerComplete ? (
          <div className="alert alert-success">
            <h1 className="alert-header">
              Welcome to {_clientFeatures.appName}!
            </h1>
            Your account has been created successfully! Please check your email
            to confirm your email address.
          </div>
        ) : (
          <div className="container page">
            {this.props.message && <Message {...this.props.message} />}
            <h1 className="text-xs-center">Sign Up</h1>
            <p className="text-xs-center">
              <Link to="login">Already have an account?</Link>
            </p>
            <RegistrationForm
              initialValues={{
                email: this.props.email,
              }}
              errors={this.props.errors || this.state.error}
              isSignUp={true}
              clubs={this.state.clubs}
              onSubmit={this.submitForm}
            />
          </div>
        )}
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Register);
