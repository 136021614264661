import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import PropTypes from 'prop-types';

import ListErrors from '../../common/list-errors';
import validation from '../../data/validations';
import Select from '../../components/UI/Select/Select';

export class AddFriendModalContainer extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    show: PropTypes.bool.isRequired, // Should be the same state as the one used in the toggle function
    toggle: PropTypes.func.isRequired, // Uses the parents state to control opening/closing of the dialog
    inputRequired: PropTypes.bool,
    membersList: PropTypes.array, // A List of venue members who can be added as a friend. Only for Venue admins.
  };

  constructor(props) {
    super(props);
    this.state = {
      modal: this.props.show,
    };
  }

  onSubmit = values => {
    const emails = values.emails.map(value => value.email);
    this.props.onSubmit({
      emails: emails,
    });
  };

  validateEmail = values => {
    const { inputRequired } = this.props;
    if (inputRequired && (!values || values.length === 0)) {
      return 'invalid';
    }
    if (values) {
      const invalidEmail = values.find(value => {
        const email = value.email;
        return email && validation.email(email);
      });
      return invalidEmail
        ? `Invalid email address: ${invalidEmail.email}`
        : undefined;
    }
  };

  render() {
    const {
      invalid,
      submitting,
      errors,
      handleSubmit,
      membersList,
      inputRequired,
    } = this.props;
    const option = inputRequired ? '(required)' : '(optional)';
    const showMembers = membersList && membersList.length > 0;
    const height = showMembers ? '350px' : 'auto';
    const label = showMembers
      ? `Select A Member or Add By Email ${option}`
      : `Friend's Email Address ${option}`;
    return (
      <Modal isOpen={this.state.modal} className={this.props.className}>
        <form onSubmit={handleSubmit(this.onSubmit)}>
          <ModalHeader toggle={this.props.toggle}>
            {this.props.title}
          </ModalHeader>
          <ModalBody style={{ height: height }}>
            <ListErrors errors={errors} />
            {this.props.message}
            <br />
            <br />
            <Field
              name="emails"
              component={Select}
              validate={[this.validateEmail]}
              label={label}
              placeholder="Email"
              options={membersList}
              labelKey="displayName"
              valueKey="email"
              multi={true}
              creatable={true}
              on
            />
            <br />
            <br />
          </ModalBody>
          <ModalFooter>
            <a className="btn btn-link" onClick={this.props.toggle}>
              Cancel
            </a>
            <Button disabled={submitting || invalid} color="primary">
              Ok
            </Button>
          </ModalFooter>
        </form>
      </Modal>
    );
  }
}

export default reduxForm({ form: 'addFriendForm' })(AddFriendModalContainer);
