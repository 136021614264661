import React from 'react';

export const BEFORE_PRACTICE_CREATE = 'BEFORE_PRACTICE_CREATE';
export const UPDATE_PRACTICE = 'UPDATE_PRACTICE';
export const AFTER_PRACTICE_CREATE = 'AFTER_PRACTICE_CREATE';
export const GET_PRACTICE_BY_SLUG = 'GET_PRACTICE_BY_SLUG';
export const SET_PLAYER_PRACTICE_STATUS = 'SET_PLAYER_PRACTICE_STATUS';

export const PRACTICE_TOGGLE = [
  {
    displayName: <i className="fa fa-check fa-fw fa-lg" />,
    value: `accept`,
  },
  {
    displayName: <i className="fa fa-times fa-fw fa-lg" />,
    value: `decline`,
  },
];
