import {
  LOGIN,
  REGISTER,
  PASSWORD_RESET_LOADED,
  LOGIN_PAGE_UNLOADED,
  REGISTER_PAGE_UNLOADED,
  PASSWORD_RESET_UNLOADED,
  FORGOT_PASSWORD_UNLOADED,
  FORGOT_PASSWORD_EMAIL_NOT_FOUND,
  PASSWORD_RESET,
  ASYNC_START,
  CONFIRM_EMAIL,
  CONFIRM_EMAIL_UNLOADED,
  UPDATE_FIELD_AUTH,
  USERS_DISPLAY,
  EVENT_RESPONSE,
  VENUE_ACTION,
  CONFIRM_TOKEN,
  CONFIRM_TOKEN_UNLOADED,
} from '../constants';

export default (state = {}, action) => {
  let errorMessages = null;

  switch (action.type) {
    case LOGIN:
    case REGISTER:
      return {
        ...state,
        inProgress: false,
      };
    case PASSWORD_RESET:
    case CONFIRM_EMAIL:
      if (action.error && action.payload.errors.message) {
        errorMessages = action.payload.errors.message;
        if (errorMessages === 'Token not found or invalid') {
          errorMessages = 'This link is no longer valid.';
        } else if (errorMessages === 'Token Expired') {
          errorMessages = 'This link has expired.';
        }
      }
      return {
        ...state,
        inProgress: false,
        errors: errorMessages ? [errorMessages] : null,
        confirmed: !action.error,
        invalidPassword: false,
      };
    case CONFIRM_TOKEN:
      if (action.error && action.payload.errors.message) {
        errorMessages = action.payload.errors.message;
        if (errorMessages === 'Token not found or invalid') {
          errorMessages = 'This link is no longer valid.';
        } else if (errorMessages === 'Token Expired') {
          errorMessages = 'This link has expired.';
        }
      }
      return {
        ...state,
        inProgress: false,
        team: action.payload.team,
        confirmed: !action.error,
        errors: errorMessages ? [errorMessages] : null,
      };
    case PASSWORD_RESET_LOADED:
      return {
        ...state,
        token: action.payload.token,
      };
    case FORGOT_PASSWORD_EMAIL_NOT_FOUND:
      const email = action.payload && (action.payload.user || {}).email;
      return {
        ...state,
        email,
        message: {
          type: 'info',
          text:
            'That Email Address was not found in our system. Create a FREE account now!',
        },
      };
    case USERS_DISPLAY:
      return {
        ...state,
        display: action.payload,
      };
    case LOGIN_PAGE_UNLOADED:
    case REGISTER_PAGE_UNLOADED:
    case PASSWORD_RESET_UNLOADED:
    case FORGOT_PASSWORD_UNLOADED:
    case CONFIRM_EMAIL_UNLOADED:
    case CONFIRM_TOKEN_UNLOADED:
      return {};
    case ASYNC_START:
      if (
        action.subtype === LOGIN ||
        action.subtype === REGISTER ||
        action.subtype === CONFIRM_EMAIL ||
        action.subtype === CONFIRM_TOKEN
      ) {
        return { ...state, inProgress: true };
      }
      break;
    case UPDATE_FIELD_AUTH:
      return { ...state, [action.key]: action.value };
    case EVENT_RESPONSE:
      return {
        ...state,
        inProgress: false,
        errors: action.error ? action.payload && action.payload.errors : null,
        event: !action.error ? action.payload : null,
      };
    case VENUE_ACTION:
      return {
        ...state,
        inProgress: false,
        errors: action.error ? action.payload && action.payload.errors : null,
        venue: !action.error ? action.payload : null,
      };
    default:
      return state;
  }

  return state;
};
