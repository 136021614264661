import { Field, reduxForm, getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import SelectUI from '../../components/UI/Select';
import InputUI from '../../components/UI/Input';
import ButtonUI from '../../components/UI/Button';
import React from 'react';
import ListErrors from '../../common/list-errors';
import validations from '../../data/validations';
import { userGenders } from '../../data/genders';
import validation from '../../data/validations';
import agent from '../../agent';
import Loader from '../../components/UI/Loader';

const CURRENT_YEAR = new Date().getFullYear();
const MANY_YEARS_AGO = CURRENT_YEAR - 100;
const PASSWORD_MIN_LENGTH = validations.minLength(6); // TODO: find a better way to do this. Right now 6 is passed to identity's role service in startup.cs

const birthYearValidators = [
  validations.minValue(MANY_YEARS_AGO),
  validations.maxValue(CURRENT_YEAR),
];

const zipMin = validation.minLength(5);
const zipMax = validation.maxLength(10); // zip+dash+plusFour

class RegistrationForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = { clubs: [], loading: false };
    this.handleChange = this.handleChange.bind(this);
  }

  async handleChange(event) {
    if (event.target.value.length > 4 && event.target.value.length < 11) {
      await this.setState({ loading: true });
      await agent.Venues.getList({ zip: event.target.value }).then(clubs =>
        this.setState({ clubs: clubs, loading: false })
      );
    }
  }
  render() {
    const {
      errors,
      isSignUp,
      setPassword,
      handleSubmit,
      clubs,
      submitting,
      pristine,
      invalid,
      initialValues,
    } = this.props;
    let venues;
    venues = clubs;

    if (this.state.clubs && this.state.clubs.length > 0) {
      venues = this.state.clubs;
    } else {
      venues = clubs;
    }
    const hideVenues =
      initialValues && initialValues.venues && initialValues.venues.length > 0;

    return (
      <div>
        <ListErrors errors={errors} />
        <form onSubmit={handleSubmit}>
          {isSignUp && (
            <div className="row">
              <div className="col-sm-6">
                <Field
                  type="email"
                  name="email"
                  label="Email Address"
                  component={InputUI}
                  validate={validations.required}
                />
              </div>
              <div className="col-sm-6">
                <Field
                  type="password"
                  name="password"
                  label="Password"
                  component={InputUI}
                  validate={[validations.required, PASSWORD_MIN_LENGTH]}
                />
              </div>
            </div>
          )}
          {setPassword && (
            <div className="row">
              <div className="col-sm">
                <Field
                  type="password"
                  name="password"
                  label="Password"
                  component={InputUI}
                  validate={[validations.required, PASSWORD_MIN_LENGTH]}
                />
              </div>
              <div className="col-sm">
                <Field
                  type="password"
                  name="confirmPassword"
                  label="Confirm Password"
                  component={InputUI}
                  validate={validations.required}
                />
              </div>
            </div>
          )}

          <div className="row">
            <div className="col-sm">
              <Field
                type="text"
                name="firstName"
                label="First Name"
                component={InputUI}
                validate={validations.required}
              />
            </div>
            <div className="col-sm">
              <Field
                type="text"
                name="lastName"
                label="Last Name"
                component={InputUI}
                validate={validations.required}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-sm">
              <Field
                type="tel"
                name="cellPhoneNumber"
                label="Cell Phone Number"
                component={InputUI}
                validate={[validations.required, validations.phone]}
              />
            </div>
            <div className="col-sm">
              <Field
                name="gender"
                label="Gender"
                valueKey="id"
                labelKey="name"
                validate={validations.required}
                options={userGenders}
                component={SelectUI}
                clearable={false}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-sm">
              <Field
                name="ustaRating"
                type="number"
                min={_clientFeatures.rating.min}
                max="7"
                step="0.5"
                validate={[validations.required, validations.ustaRating]}
                component={InputUI}
                label={_clientFeatures.rating.label}
              />
            </div>
            <div className="col-sm">
              <Field
                label="Birth Year"
                name="birthYear"
                type="number"
                max={CURRENT_YEAR}
                min={MANY_YEARS_AGO}
                validate={birthYearValidators}
                component={InputUI}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-sm">
              <Field
                name="postal"
                autoComplete="postal-code"
                component={InputUI}
                validate={[zipMin, zipMax]}
                label="Your Zip Code"
                onChange={this.handleChange}
              />
            </div>
          </div>
          {!hideVenues && (
            <div>
              {!this.state.loading ? (
                <Field
                  name="venues"
                  label="Preferred Venues"
                  component={SelectUI}
                  options={venues}
                  labelKey="name"
                  valueKey="id"
                  validate={validations.required}
                  multi={true}
                  creatable={true}
                />
              ) : (
                <Loader />
              )}
            </div>
          )}
          <div className="clearfix">
            <Field
              type="submit"
              name="submit"
              disabled={pristine || invalid || submitting}
              text={isSignUp ? 'Sign Up' : 'Verify Profile'}
              component={ButtonUI}
            />
          </div>
        </form>
      </div>
    );
  }
}

let ConnectedRegistrationForm = reduxForm({
  form: 'registrationForm',
  enableReinitialize: true,
  validate: values => {
    const errors = {};
    if (values.password && values.confirmPassword) {
      if (values.password !== values.confirmPassword) {
        errors.confirmPassword = 'Password does not match.';
      }
    }
    return errors;
  },
})(RegistrationForm);

const registrationFormSelector = getFormValues('registrationForm');
ConnectedRegistrationForm = connect(state => ({
  formValues: registrationFormSelector(state),
}))(ConnectedRegistrationForm);

export default ConnectedRegistrationForm;
