import React from 'react';
import ReactSelect, { Creatable } from 'react-select';

/**
 * Check if props contains a boolean attribute. Returns true if props
 * contains the attr and the value is null | undefined, or the value is
 * explicitly set to `true`
 * @param {{}} props
 * @param {string} prop
 * @return {boolean}
 */
function hasBooleanProp(props, prop) {
  return props[prop] === true || (prop in props && props[prop] == null);
}

export const Select = props => {
  const {
    input,
    label,
    meta: { touched, error, warning },
    ...rest
  } = props;

  const SelectComponent = hasBooleanProp(props, 'creatable')
    ? Creatable
    : ReactSelect;

  return (
    <div className="form-group">
      <label>{label}</label>
      <SelectComponent
        {...rest}
        /**
         * Redux Form's Field on* handlers should handle the control's events,
         * calling any on* handlers provided by props as needed. This means we can
         * look at the docs for Field and figure out what the signature of the
         * handler should be.. instead of passing them through to the underlying
         * control, which could/will have varying signatures. This allows us the
         * flexibility to normalize handlers as necessary (eg. `onBlur`), or
         * even swap out the underlying component without the props needing to
         * be changed at the point of implementation
         */
        {...input}
        /**
         * Multi selects should remain open after a selection has been made.
         * If the user intends on selecting many options (eg. players|friends)
         * having to reveal the options after each selection gets tedious
         */
        closeOnSelect={!rest.multi}
        value={
          rest.multi
            ? input.value
            : /**
             * Redux Form provides a default value of `''` which sucks for
             * non multi-selects.. It renders the little ✘ to clear the control
             * which looks weird when a value has not been selected. Returning
             * undefined (when appropriate) fixes it
             */
            input.value === ''
            ? undefined
            : input.value
        }
        /**
         * The onBlur handler provided by Redux Form's Field component
         * clears the field's value if we don't pass it the control's
         * current value. Feeding it's own value back into the onBlur
         * handler does the trick
         */
        onBlur={() => input.onBlur(input.value)}
      />
      {touched &&
        ((error && <small className="text-danger my-1">{error}</small>) ||
          (warning && <small className="text-warning my-1">{warning}</small>))}
    </div>
  );
};

export default Select;
