import React from 'react';
import Datetime from 'react-datetime';
import moment from 'moment';
import { store } from '../../../store/';

import classNames from 'classnames';
import { compose } from 'redux';
import { connect } from 'react-redux';

/**
 * @todo This shouldn't be a default validator.. The components has no way
 * of knowing that the date should be before/after/only today/yesterday/etc.
 * which makes this an opinionated choice and doesn't necessarily make sense.
 * For example, validation of birth dates vs. match dates: Birth date == past,
 * match dates == future. This should be left to the implementor.
 */
let defaultValidDates = function(current) {
  let yesterday = moment().subtract(1, 'day');
  return current.isAfter(yesterday);
};

let adminValidDates = function(current) {
  return current;
};

/**
 * Render a Datetime picker with a field label. Wrapped in a form-group
 * @param {{}} props
 * @param {string} props.label Display label for the Datetime picker
 * @param {string|boolean} [props.showDate]
 *  showDate boolen:
 *   - `true` to show the date part of a Datetime picker
 *   - `false` to hide the date part of a Datetime picker
 * showDate string:
 *   - The display format of selected dates
 * @param {string|boolean} [props.showTime]
 *  showTime boolen:
 *   - `true` to show the time part of a Datetime picker
 *   - `false` to hide the time part of a Datetime picker
 * showTime string:
 *   - The display format of selected times
 * @return {JSX.Element}
 */
const DateTime = ({
  input,
  label,
  validDates,
  showDate,
  showTime,
  meta: { touched, error, warning },
  currentUser,
  isImpersonating,
}) => {
  const isAdmin =
    isImpersonating || (currentUser && currentUser.roles.includes('Admin'));
  return (
    <div className="form-group">
      <label>{label}</label>
      {store.getState().common.isMobile ? (
        <input
          {...input}
          className={classNames('form-control', {
            'is-invalid': touched && error,
          })}
          style={{ height: '42px' }}
          placeholder={showDate ? 'Pick Date' : 'Pick Time'}
          type={showDate ? 'date' : 'time'}
        />
      ) : (
        <Datetime
          {...input}
          dateFormat={showDate}
          timeFormat={showTime}
          isValidDate={
            isAdmin ? adminValidDates : validDates || defaultValidDates
          }
        />
      )}
      {touched &&
        ((error && <small className="text-danger my-1">{error}</small>) ||
          (warning && <small className="text-warning my-1">{warning}</small>))}
    </div>
  );
};
export default compose(
  connect(state => ({
    currentUser: state.common.currentUser,
    isImpersonating: !!state.common.impersonator,
  }))
)(DateTime);
