import React from 'react';
import VenueEventList from '../matches/components/venue-event-list';
import { Link, Switch, Route, withRouter } from 'react-router-dom';
import Loader from '../../components/UI/Loader/index';

class VenueDetail extends React.Component {
  render() {
    const {
      user,
      venue,
      events,
      isVenue,
      isAdmin,
      match,
      pastEvents,
    } = this.props;
    return (
      <div>
        <div className="header-area">
          <h1 className="header-text text-secondary">{'Venue Events'}</h1>
        </div>
        <Switch>
          <Route
            render={() => (
              <div>
                <div>
                  <h4 className="events-style clearfix">
                    {(isVenue || isAdmin) && (
                      <div className="float-right">
                        <Link
                          className="btn btn-outline-primary btn-sm"
                          to={`/venues/${venue.id}/events/new`}>
                          <i className="fa fa-plus fa-fw" /> Add Event
                        </Link>
                      </div>
                    )}
                  </h4>
                  {pastEvents && (
                    <div>
                      <h3>Past</h3>
                      <VenueEventList
                        venue={venue}
                        events={pastEvents}
                        match={match}
                        userAvailableStatus={this.props.eventStatus}
                        user={user}
                        isCaptain={this.props.isCaptain}
                      />
                    </div>
                  )}
                  {events ? (
                    <div>
                      <h3>Upcoming</h3>
                      <VenueEventList
                        venue={venue}
                        events={events}
                        match={match}
                        userAvailableStatus={this.props.eventStatus}
                        user={user}
                        isCaptain={this.props.isCaptain}
                      />
                    </div>
                  ) : (
                    <Loader />
                  )}
                </div>
              </div>
            )}
          />
        </Switch>
      </div>
    );
  }
}

export default withRouter(VenueDetail);
