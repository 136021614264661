import _timeSlots from '../../data/time-slots';
import { socialPlayTypes } from '../../data/play-type';

// Kick out None
export const timeSlots = _timeSlots.slice(1);

export const playTypes = socialPlayTypes;

// Used for +- 0.5 rating feature

export const MAX_RATING = 7;
export const MIN_RATING = 1;
export const getRatingSum = (a, b) => (a + b).toFixed(1);
