import {
  LOCATION_CHANGE,
  IMPERSONATE,
  END_IMPERSONATION,
} from '../../constants';

import {
  BEFORE_MATCH_CREATE,
  AFTER_MATCH_CREATE,
  SINGLE_MATCH_DETAIL,
  LEAGUE_DETAIL,
  MATCH_DATA_ON_LOAD,
  UPDATE_PLAYER_STATUS,
  UPDATE_MATCH,
  UPDATE_MATCH_SCORECARD,
  UPDATE_MATCH_VISIBILITY,
} from './constants';

import { map as _map, reduce as _reduce } from 'lodash';

export default function(state = {}, action) {
  switch (action.type) {
    case IMPERSONATE:
    case END_IMPERSONATION:
      return {};
    case LOCATION_CHANGE:
      if (state.errors || state.redirectTo || state.lineUp) {
        return {
          ...state,
          errors: null,
          redirectTo: null,
          lineUp: null,
        };
      }
      return state;
    case BEFORE_MATCH_CREATE:
      return {
        ...state,
        teams: action.payload,
      };
    case SINGLE_MATCH_DETAIL:
    case MATCH_DATA_ON_LOAD:
      return {
        ...state,
        teamMatch: action.payload,
        errors: action.error ? action.payload : null,
      };
    case UPDATE_PLAYER_STATUS:
      return {
        ...state,
        errors: action.error ? action.payload : null,
        teamMatch: updateTeamMatch(
          state.teamMatch,
          action.error ? state.teamMatch : action.payload
        ),
      };
    case LEAGUE_DETAIL:
      return {
        ...state,
        teamLeague: action.payload,
        errors: action.error ? action.payload : null,
      };
    case AFTER_MATCH_CREATE:
    case UPDATE_MATCH:
      return {
        ...state,
        teamMatch: updateTeamMatch(
          state.teamMatch,
          action.error ? state.teamMatch : action.payload
        ),
        redirectTo:
          action.error || !(action.payload.team && action.payload.team._id)
            ? null
            : `/teams/${action.payload.team._id}`,
        batchMatches: action.error ? state.batchMatches : null,
        errors: action.error ? action.payload : null,
      };
    case UPDATE_MATCH_SCORECARD:
      return {
        ...state,
        errors: action.error ? action.payload : null,
        teamMatch: updateTeamMatch(
          state.teamMatch,
          action.error
            ? state.teamMatch
            : {
                ...state.teamMatch,
                games: action.payload,
              }
        ),
      };
    case UPDATE_MATCH_VISIBILITY:
      return {
        ...state,
        errors: action.error ? action.payload : null,
        teamMatch: updateTeamMatch(
          state.teamMatch,
          action.error ? state.teamMatch : action.payload
        ),
      };
    default:
      return state;
  }
}

function updateTeamMatch(prev, teamMatch) {
  // No previous data, or there was an error and
  // we're reusing the existing teamMatch
  if (!prev || prev === teamMatch) {
    return teamMatch;
  }

  const { participation, ...patch } = teamMatch;

  const participationPatchById = _reduce(
    participation,
    (ids, participation) => {
      ids[participation.id] = participation;
      return ids;
    },
    {}
  );

  return {
    ...prev,
    ...patch,
    participation: _map(prev.participation, participation => {
      const participationPatch = participationPatchById[participation.id];
      return participationPatch
        ? {
            ...participation,
            ...participationPatch,
          }
        : participation;
    }),
  };
}
