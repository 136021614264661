import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import RequestPlayForm from './form';
import agent from '../../../agent';
import { Redirect } from 'react-router-dom';

const headingClass =
  'header-text d-flex align-items-center justify-content-between';

export class RequestSocialPlay extends Component {
  state = {
    redirectTo: null,
  };

  componentDidMount() {
    agent.Users.getUserSettings().then(userSettings => {
      let venues = userSettings.venues;
      this.setState({
        venues,
      });
    });
    agent.Users.getFriendGroup('All Friends').then(group => {
      let groupFriends = group.users;
      this.setState({
        groupFriends,
      });
    });
  }

  handleSubmit = data => {
    return agent.Events.add(data).then(() => {
      this.props.onRequest();
      this.setState({ redirectTo: true });
    });
  };

  render() {
    return (
      <div>
        {this.state.redirectTo ? (
          <Redirect to="/social-play" />
        ) : (
          <div>
            <div className="social-header-area">
              <h2 className={headingClass}>
                <Link to="/social-play">Social Play</Link>
              </h2>
            </div>
            <h4 className="text-secondary d-flex justify-content-center">
              Request Social Play
            </h4>
            <hr />
            <RequestPlayForm
              venues={this.state.venues}
              groupFriends={this.state.groupFriends}
              onSubmit={this.handleSubmit}
            />
          </div>
        )}
      </div>
    );
  }
}

export default RequestSocialPlay;
