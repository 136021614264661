import React from 'react';
import { connect } from 'react-redux';
import agent from '../agent';
import { Redirect } from 'react-router-dom';
import VenuesList from './components/venue-list.component';
import { venuesLoad } from './action'; //TODO: venuesLoad

export class Venues extends React.Component {
  componentDidMount() {
    this.props.onLoad(
      agent.Venues.getList({ show: 'all' }).then(venues => {
        // If this user is an admin, show the list of all clubs.
        if (this.props.roles.indexOf('Admin') !== -1) {
          return venues;
        } else {
          return venues.filter(v =>
            v.admins.find(a => a._id === this.props.currentUser._id)
          );
        }
      })
    );
  }

  processVenues = () => {
    const { venues } = this.props;
    if (this.props.roles.indexOf('Admin') !== -1) {
      return venues;
    } else if (
      venues &&
      venues.length > 0 &&
      this.props.roles.indexOf('Admin') === -1
    ) {
      return venues.filter(v =>
        v.admins.find(a => a._id === this.props.currentUser._id)
      );
    }
  };

  render() {
    const venues = this.processVenues();
    return venues && venues.length === 1 ? (
      <Redirect to={'venues/' + venues[0].id} />
    ) : (
      <div>
        <h2>My Venues</h2>
        <VenuesList venues={venues} />
        {/* TODO: change^ to venues */}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  ...state.venues,
  ...state.common,
});

const mapDispatchToProps = dispatch => ({
  onLoad: payload => dispatch(venuesLoad(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Venues);
