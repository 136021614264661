import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import PropTypes from 'prop-types';

import InputUI from '../../../components/UI/Input';
import Select from '../../../components/UI/Select';
import ListErrors from '../../../common/list-errors';
import Loader from '../../../components/UI/Loader/index';
import classNames from 'classnames';

export class InputDialog extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    message: PropTypes.string,
    notes: PropTypes.string,
    show: PropTypes.bool.isRequired, // Should be the same state as the one used in the toggle function
    toggle: PropTypes.func.isRequired, // Uses the parents state to control opening/closing of the dialog
    inputs: PropTypes.array,
    buttonLabels: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      modal: this.props.show,
      loading: false,
    };
  }
  getComponent = opts => {
    switch (opts.type) {
      case 'textarea':
        return 'textarea';
      case 'checkbox':
        // field is passed in from reduxForm's field components
        return field => (
          <div>
            <label className="form-check-label">
              <input
                {...field.input}
                type="checkbox"
                className="form-check-input"
              />
              {opts.label}
            </label>
          </div>
        );
      case 'radio':
        return 'input';
      case 'select':
        return Select;
      case 'number':
      default:
        return InputUI;
    }
  };
  loading = doLoad => this.setState({ loading: doLoad });

  render() {
    const {
      invalid,
      submitting,
      errors,
      handleSubmit,
      buttonLabels = {},
    } = this.props;

    return (
      <Modal isOpen={this.state.modal} className={this.props.className}>
        <form
          onSubmit={val => {
            this.loading(true);
            handleSubmit(val);
          }}>
          <ModalHeader toggle={this.props.toggle}>
            {this.props.title}
          </ModalHeader>
          {/* Overflow visible so select components can come out of the dialog */}
          <ModalBody
            className={classNames({
              [this.props.modalBodyClass]: !!this.props.modalBodyClass,
            })}>
            <ListErrors errors={errors} />
            {this.props.message}
            <br />
            <br />
            {this.props.inputs &&
              this.props.inputs.map(i =>
                i.type === 'radio' ? (
                  <label className="mr-5">
                    <Field
                      key={i.name}
                      name={i.name}
                      component={this.getComponent(i)}
                      validate={i.validators}
                      placeholder={i.placeholder}
                      type={i.type ? i.type : 'text'}
                      value={i.value}
                    />{' '}
                    {i.label}
                  </label>
                ) : (
                  <Field
                    {...i} // handle any other components specific property
                    key={i.name}
                    name={i.name}
                    component={this.getComponent(i)}
                    validate={i.validators}
                    placeholder={i.placeholder}
                    type={i.type ? i.type : 'text'}
                  />
                )
              )}
            {this.props.note && (
              <div>
                <br />
                <strong>Note:</strong>
                &nbsp;
                <small>{this.props.note}</small>
                &nbsp;
              </div>
            )}
          </ModalBody>
          <ModalFooter>
            {this.state.loading && (
              <div style={{ position: 'absolute', left: 0 }}>
                <Loader />
              </div>
            )}
            <a className="btn btn-link" onClick={this.props.toggle}>
              {buttonLabels['cancel'] || 'Cancel'}
            </a>
            <Button
              disabled={submitting || invalid || this.state.loading}
              color="primary">
              {buttonLabels['ok'] || 'Ok'}
            </Button>
          </ModalFooter>
        </form>
      </Modal>
    );
  }
}

export default reduxForm({ form: 'inputDialog' })(InputDialog);
