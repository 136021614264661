import {
  GET_EVENT,
  UPDATE_EVENT,
  UPDATE_GAME,
  CONFIRM_SCORES,
  DELETE_EVENT,
  SET_PLAYER_EVENT_STATUS,
} from '../../constants';

export default function(state = {}, action) {
  const { payload, type } = action;
  switch (type) {
    case GET_EVENT: {
      const { event, playerOpts } = payload;
      return {
        ...state,
        event,
        playerOpts,
      };
    }
    case UPDATE_GAME:
    case UPDATE_EVENT:
    case CONFIRM_SCORES: {
      return {
        ...state,
        event: payload,
      };
    }
    case DELETE_EVENT: {
      return {
        ...state,
        event: null,
      };
    }
    case SET_PLAYER_EVENT_STATUS: {
      return {
        ...state,
        event: payload,
      };
    }
    default:
      return state;
  }
}
