import React from 'react';
const ButtonUI = props => {
  const { meta, disabled, text, type } = props;

  return (
    <div className="pull-right">
      <button
        className="btn btn-success"
        type={type}
        title={text}
        disabled={disabled}>
        {meta && meta.submitting && <i className="fa fa-spinner fa-pulse mr-2" />}
        {text}
      </button>
    </div>
  );
};

export default ButtonUI;
