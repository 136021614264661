// import Preferences from '../../event/components/friends';
// export default Preferences;
import React from 'react';
import { connect } from 'react-redux';
import { loadSettings } from '../../common/action';
import agent from '../../agent';
import { toastr } from 'react-redux-toastr';
import SocialPlayComponent from './event.component';
import { playTimes } from '../../data/types';
import { DOUBLES, MIXED, SINGLES } from '../../data/play-type';
import { Redirect } from "react-router-dom";

const mapStateToProps = state => ({
  ...state.profile,
  ...state.common,
});

const timeSlots = [
  { name: 'Weekday Morning', id: playTimes.WEEKDAY_MORN },
  { name: 'Weekday Afternoon', id: playTimes.WEEKDAY_MID },
  { name: 'Weekday Evening', id: playTimes.WEEKDAY_EVE },
  { name: 'Weekends (Before 12 pm)', id: playTimes.WEEKEND_AM },
  { name: 'Weekends (After 12 pm)', id: playTimes.WEEKEND_PM },
];

const playTypes = [
  {
    id: SINGLES,
    name: 'Singles',
  },
  {
    id: DOUBLES,
    name: 'Doubles',
  },
  {
    id: MIXED,
    name: 'Mixed',
  },
];

const mapDispatchToProps = dispatch => ({
  onLoadSettings: settings => dispatch(loadSettings(settings)),
});

export class PreferenceContainer extends React.Component {
  getSettings = () => {
    this.props.onLoadSettings(agent.Users.getUserSettings());
  };

  onUpdateSettings = values => {
    values = this.handleSocialPlaySettings(values);
    agent.Users.updateSettings(values)
      .then(() => {
        this.getSettings();
      })
      .then(() => {
        toastr.success('Success', 'Preferences Updated.');
      })
      .catch(() => {
        toastr.error('Error', 'Could not update friends.');
      });
  };

  handleSocialPlaySettings = values => {
    const playTypes = {
      singles: false,
      doubles: false,
      mixed: false,
    };
    const playTimes = {
      we_am: false,
      we_pm: false,
      wd_morn: false,
      wd_mid: false,
      wd_eve: false,
    };
    const eventTypes = this.props.settings.playPrefs.eventTypes;

    const friendsOnly = values.friendsOnly;
    if (values.playTypes) {
      values.playTypes.forEach(type => {
        playTypes[type.id] = true;
      });
    }
    if (values.playTimes) {
      values.playTimes.forEach(time => {
        playTimes[time.id] = true;
      });
    }
    eventTypes.tournament = values.tournament;
    eventTypes.clinic = eventTypes.lesson = values.clinic;
    return { playPrefs: { playTypes, playTimes, friendsOnly, eventTypes } };
  };

  getInitialSocialPlaySettings = () => {
    const initialValues = {
      playTypes: [],
      playTimes: [],
    };
    if (this.props.settings) {
      const settingPlayTypes = this.props.settings.playPrefs.playTypes;
      const settingPlayTimes = this.props.settings.playPrefs.playTimes;

      playTypes.forEach(type => {
        if (settingPlayTypes[type.id]) {
          initialValues.playTypes.push(type);
        }
      });
      timeSlots.forEach(slot => {
        if (settingPlayTimes[slot.id]) {
          initialValues.playTimes.push(slot);
        }
      });
      initialValues.friendsOnly = this.props.settings.playPrefs.friendsOnly;
      initialValues.tournament = this.props.settings.playPrefs.eventTypes.tournament;
      initialValues.clinic = this.props.settings.playPrefs.eventTypes.clinic;
    }
    return initialValues;
  };

  render() {
    const socialPlayPrefs = this.getInitialSocialPlaySettings();
    return (
      <div>
        {this.props.settings ? (
          <div>
            <h1>Event Preferences</h1>
            <SocialPlayComponent
              initialValues={socialPlayPrefs}
              typeOptions={playTypes}
              timeOptions={timeSlots}
              onSubmit={values => this.onUpdateSettings(values)}
            />
          </div>
        ) : (
          <Redirect to="/account/profile" />
        )}
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PreferenceContainer);
