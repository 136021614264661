const required = (value, message) =>
  value ? undefined : typeof message === 'string' ? message : 'Required';

const maxLength = max => value =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined;

const minLength = min => value =>
  value && value.length < min ? `Must be ${min} characters or more` : undefined;

const number = value =>
  value && isNaN(Number(value)) ? 'Must be a number' : undefined;

const minValue = min => value =>
  value && value < min ? `Must be at least ${min}` : undefined;

const maxValue = max => value =>
  value && value > max ? `Must be at most ${max}` : undefined;

const email = value =>
  value && !/^\S+@\S+\.[a-z]{2,}$/i.test(value)
    ? 'Invalid email address'
    : undefined;

const ustaRating = value =>
  value === undefined || (value % 0.5 === 0 && value >= 1 && value <= 7)
    ? undefined
    : 'Invalid rating (must be between 1.0 and 7.0 in increments of 0.5)';
const comboUstaRating = value =>
  value % 0.5 === 0 && value >= 2 && value <= 14
    ? undefined
    : 'Invalid combo rating (must be between 2.0 and 14.0 in increments of 0.5)';
//Temporarily disabling eslint because it complains about the phone regex
/* eslint-disable */
const phone = value =>
  value &&
  /^[(]{0,1}[0-9]{3}[)]{0,1}[-\s\.]{0,1}[0-9]{3}[-\s\.]{0,1}[0-9]{4}$/.test(
    value
  )
    ? undefined
    : 'Invalid Phone number format';
/* eslint-enable */


export default {
  required,
  minLength,
  maxLength,
  number,
  minValue,
  maxValue,
  email,
  ustaRating,
  comboUstaRating,
  phone,
};
