import {
  ASYNC_START,
  ASYNC_END,
  LOGIN,
  LOGIN_TOKEN,
  LOGOUT,
  IMPERSONATE,
  END_IMPERSONATION,
  CONFIRM_EMAIL,
  CONFIRM_TOKEN,
} from './constants';
import {
  setToken,
  setImpersonationToken,
} from './common/services/requests/requests';
import { toastr } from 'react-redux-toastr';

const INTERNAL_SERVER_ERROR = 500;
const UNAUTHORIZED = 401;

let browserStorage =
  typeof window === 'undefined' && typeof localStorage === 'undefined'
    ? {
        getItem: function() {
          return null;
        },
        setItem: function() {
          return;
        },
      }
    : localStorage;

const promiseMiddleware = store => next => action => {
  if (isPromise(action.payload)) {
    store.dispatch({ type: ASYNC_START, subtype: action.type });

    const currentView = store.getState().viewChangeCounter;
    const skipTracking = action.skipTracking;

    return action.payload.then(
      res => {
        const currentState = store.getState();
        if (!skipTracking && currentState.viewChangeCounter !== currentView) {
          return;
        }
        action.payload = res;
        store.dispatch({
          type: ASYNC_END,
          subtype: action.type,
          payload: action.payload,
        });
        store.dispatch(action);
        return res;
      },
      error => {
        const currentState = store.getState();
        if (!skipTracking && currentState.viewChangeCounter !== currentView) {
          return;
        }
        action.error = true;
        action.payload = error.response.body;
        if (!action.skipTracking) {
          store.dispatch({
            type: ASYNC_END,
            subtype: action.type,
            payload: action.payload,
            error: true,
          });
        }
        store.dispatch(action);

        if (error.status === INTERNAL_SERVER_ERROR) {
          toastr.error(
            'Server Error',
            `Error loading page: ${
              error.message
            } || Please try again. If the problem persists, please contact ${
              _clientFeatures.supportURL
            }.`,
            { timeOut: 0 }
          );
          return;
        }
        if (error.status === UNAUTHORIZED) {
          store.dispatch({
            type: LOGOUT,
            subtype: action.type,
            payload: action.payload,
            error: true,
          });
          store.dispatch(action);
        }
        return Promise.reject(error);
      }
    );
  }

  return next(action);
};

const localStorageMiddleware = () => next => action => {
  // switch (action.type){
  //   case LOGIN:
  //   case CONFIRM_EMAIL:
  //   case LOGIN_TOKEN:
  //     if (!action.error) {
  //       browserStorage.setItem('jwt', action.payload.access_token);
  //       setToken(action.payload.access_token);
  //     }
  //     break;
  //   case IMPERSONATE:
  //   case END_IMPERSONATION:
  //     if (!action.error) {
  //       browserStorage.setItem('i_jwt', action.payload.access_token);
  //       setImpersonationToken(action.payload.access_token);
  //     }
  //     break;
  //   case LOGOUT:
  //     browserStorage.setItem('jwt', '');
  //     browserStorage.setItem('i_jwt', '');
  //     setToken(null);
  //     setImpersonationToken(null);
  //
  //     // Do a hard reload on logout so we get a clean slate
  //     // for the next user session
  //     window.location.href = '/';
  //     break;
  // }
  if (
    action.type === LOGIN ||
    action.type === CONFIRM_EMAIL ||
    action.type === LOGIN_TOKEN ||
    action.type === CONFIRM_TOKEN
  ) {
    if (!action.error) {
      browserStorage.setItem('jwt', action.payload.access_token);
      setToken(action.payload.access_token);
    }
  } else if (action.type === IMPERSONATE) {
    if (!action.error) {
      browserStorage.setItem('i_jwt', action.payload.access_token);
      setImpersonationToken(action.payload.access_token);
    }
  } else if (action.type === END_IMPERSONATION) {
    browserStorage.setItem('i_jwt', '');
    setImpersonationToken(null);
  } else if (action.type === LOGOUT) {
    browserStorage.setItem('jwt', '');
    browserStorage.setItem('i_jwt', '');
    setToken(null);
    setImpersonationToken(null);

    // Do a hard reload on logout so we get a clean slate
    // for the next user session
    window.location.href = '/';
  }
  return next(action);
};

function isPromise(v) {
  return v && typeof v.then === 'function';
}

export { promiseMiddleware, localStorageMiddleware };
