import React from 'react';

const TextAreaUI = ({ input, label, meta: { touched, error } }) => (
  <div className="form-group">
    <label>{label}</label>
    <textarea {...input} className="form-control" type="text" rows="3" />
    {touched && error && <span className="error">{error}</span>}
  </div>
);

export default TextAreaUI;
