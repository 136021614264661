import React from 'react';
import EventList from './components/event-list';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import agent from '../agent';
import { loadEvents } from './action';
import Loader from '../components/UI/Loader/index';

class Agenda extends React.Component {
  async componentDidMount() {
    const events = await agent.Events.getCurrent();
    const pastEvents = await agent.Events.getCurrent({
      types: 'challenge',
      past: true,
    });
    this.props.onEventsLoad({ events, pastEvents });
  }

  render() {
    const { user, match, currentEvents, pastEvents } = this.props;
    return (
      <div>
        <div className="header-area">
          <h1 className="header-text text-secondary">{'Events'}</h1>
        </div>
        <Switch>
          <Route
            render={() => (
              <div>
                {pastEvents && pastEvents.length > 0 && (
                  <div>
                    <h3>Past Challenges</h3>
                    <EventList
                      match={match}
                      user={user}
                      events={pastEvents}
                      isCaptain={this.props.isCaptain}
                    />
                  </div>
                )}
                {currentEvents ? (
                  currentEvents.length > 0 ? (
                    <div>
                      <h3>Upcoming</h3>
                      <EventList
                        match={match}
                        user={user}
                        events={currentEvents}
                        isCaptain={this.props.isCaptain}
                      />
                    </div>
                  ) : (
                    <div> No Current Events.</div>
                  )
                ) : (
                  <Loader />
                )}
              </div>
            )}
          />
        </Switch>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  ...state.common,
  ...state.agenda,
});

const mapDispatchToProps = dispatch => ({
  onEventsLoad: events => dispatch(loadEvents(events)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Agenda);
