import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { LOGOUT, MENU_TOGGLE, END_IMPERSONATION } from '../../constants';
import defaultImg from '../sidebar/imgs/default-user-icon.png';

class HeaderContainer extends React.Component {
  state = {};
  onError = () => {
    this.setState({
      src: defaultImg,
    });
  };
  render() {
    const user = this.props.currentUser;
    const src = this.state.src ? this.state.src : null;
    return (
      <header
        className={
          'tf-site-header' + (this.props.impersonator ? ' tf-impersonated' : '')
        }>
        {this.props.impersonator && (
          <div
            className="tf-impersonated"
            title={
              this.props.impersonator.currentUser.email +
              ' impersonating as ' +
              this.props.currentUser.email
            }>
            <span>
              <i className="fa fa-chevron-left" />
              <i className="fa fa-user" />
              <i className="fa fa-chevron-right" />
            </span>
            <span className="tf-impersonated__name">
              {this.props.currentUser.email}
            </span>
            <button
              className="float-right btn btn-sm btn-outline-danger"
              onClick={this.props.onEndImpersonation}>
              End
            </button>
          </div>
        )}
        <Link className="tf-brand" to="/">
          <img className="header-logo" alt="Sports Folder" width="120" />
        </Link>
        <div className="d-none d-lg-block">
          {user ? (
            <span className="header__with-user">
              <Link to="/account">
                <img
                  className="sidebar__player__avatar_sm"
                  src={src ? src : user.avatar}
                  alt={src}
                  onError={this.onError}
                />
              </Link>
              <Link to="/account">
                <span className="header__user-identity">{user.email}</span>
              </Link>
              <a
                href={
                  _clientFeatures.isPBF
                    ? 'https://pickleballfolder.com/web-help/'
                    : 'https://tennisfolder.com/web-help/'
                }>
                <span className="header__help-identity">Help</span>
              </a>
              <button
                type="button"
                className="btn btn-link logout-link"
                onClick={this.props.onClickLogout}>
                Log Out
              </button>
            </span>
          ) : (
            <Link to="/login" className="btn btn-primary">
              Log In
            </Link>
          )}
        </div>
        <button
          type="button"
          className="navbar-toggler d-lg-none"
          aria-label="Toggle navigation"
          onClick={this.props.onClickMenu}>
          <span className="navbar-toggler-icon" />
        </button>
      </header>
    );
  }
}

const mapStateToProps = state => ({
  ...state.common,
});

const mapDispatchToProps = dispatch => ({
  onClickLogout: () => {
    return dispatch({ type: LOGOUT });
  },
  onClickMenu: () => {
    return dispatch({ type: MENU_TOGGLE });
  },
  onEndImpersonation: () => {
    return dispatch({ type: END_IMPERSONATION });
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HeaderContainer);
