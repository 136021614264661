import React from 'react';
import agent from '../../agent';
import { toastr } from 'react-redux-toastr';
import ConfirmationDialog from '../../components/UI/ConfirmationDialog';
import MailDialog from '../../components/UI/MailDialog';
import { connect } from 'react-redux';

const mailIcon = <i className="fa fa-fw fa-envelope-o" />;
const phoneIcon = <i className="fa fa-fw fa-phone" />;
const mobileIcon = <i className="fa fa-fw fa-commenting-o" />;
const friendIcon = <i className="fa fa-fw fa-user-plus" />; // TODO: Consider moving addFriend to its own components
const notVerifiedIcon = (
  <i className="fa fa-fw fa-exclamation-triangle mr-1 ml-1 text-warning" />
);

class PlayerContactTools extends React.Component {
  noOp = () => {};
  state = {
    showEmail: false,
    showConfirmation: false,
    confirmationTitle: '',
    confirmationMsg: '',
    onSubmit: this.noOp,
  };
  sentEmail = [];

  addFriend = player =>
    agent.SocialPlay.addFriends([
      {
        ...player,
      },
    ]).then(addCount => {
      this.setState({
        confirmationTitle:
          addCount > 0 ? 'Successfully Added Friend' : 'Already A Friend',
        confirmationMsg:
          addCount > 0
            ? `${player.email} is now your Social Play Friend. `
            : `${player.email} is already your friend`,
        onSubmit: this.noOp,
      });
      this.toggleConfirmationDialog();
    });

  addEmail = email => {
    this.sentEmail.push(email);
    setTimeout(
      () => this.sentEmail.splice(this.sentEmail.indexOf(email), 1),
      5000
    );
  };

  resend = email => {
    let encodedEmail = encodeURIComponent(email);
    if (this.sentEmail.indexOf(encodedEmail) === -1) {
      this.addEmail(encodedEmail);
      agent.Auth.resendConfirmEmail(encodedEmail).then(() => {
        toastr.success('Resent email confirmation');
      });
    }
  };

  toggleConfirmationDialog = () =>
    this.setState({ showConfirmation: !this.state.showConfirmation });

  toggleEmailDialog = () => this.setState({ showEmail: !this.state.showEmail });

  render() {
    const { player = { email: '', phone: '' } } = this.props;
    return (
      <span>
        <table
          style={{ display: 'inline' }}
          className="m-0 p-0 border-0"
          cellSpacing="0">
          <tbody>
            <tr className="m-0 p-0 border-0">
              <td className="m-0 p-0 border-0">
                {/*{this.props.currentUser.email !== player.email ? (*/}
                {/*<span*/}
                {/*className="player-contact-icon"*/}
                {/*title="Add Friend"*/}
                {/*onClick={() => {*/}
                {/*this.setState({*/}
                {/*onSubmit: this.addFriend.bind(this, player),*/}
                {/*confirmationTitle: 'Add Friend',*/}
                {/*confirmationMsg: `Are you sure you want to add ${*/}
                {/*player.email*/}
                {/*}?`,*/}
                {/*});*/}
                {/*this.toggleConfirmationDialog();*/}
                {/*}}>*/}
                {/*{friendIcon}*/}
                {/*</span>*/}
                {/*) : (*/}
                {/*<span className="text-white player-contact-icon">*/}
                {/*{friendIcon}*/}
                {/*</span>*/}
                {/*)}*/}
                {player.email && (
                  <span
                    className="player-contact-icon"
                    onClick={this.toggleEmailDialog}>
                    {mailIcon}
                  </span>
                )}
                {player.phone && (
                  <a
                    href={`tel:${player.phone}`}
                    className="player-contact-icon">
                    {phoneIcon}
                  </a>
                )}
                {player.phone && (
                  <a
                    href={`sms:${player.phone}`}
                    className="player-contact-icon">
                    {mobileIcon}
                  </a>
                )}
              </td>
            </tr>
            {player.hasOwnProperty('phoneValidated') && !player.phoneValidated && (
              <tr className="m-0 p-0 border-0">
                <td className="m-0 p-0 border-0">
                  <span>
                    <small>
                      <a
                        title="Unconfirmed account. Resend confirmation"
                        href="#"
                        className="text-muted"
                        onClick={e => {
                          e.preventDefault();
                          this.resend(player.email);
                        }}>
                        <small>
                          {notVerifiedIcon}
                          Unconfirmed
                        </small>
                      </a>
                    </small>
                  </span>
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {this.state.showConfirmation && (
          <ConfirmationDialog
            show={this.state.showConfirmation}
            title={this.state.confirmationTitle}
            message={this.state.confirmationMsg}
            toggle={this.toggleConfirmationDialog}
            onSubmit={this.state.onSubmit}
          />
        )}
        {this.state.showEmail && (
          <MailDialog
            to={player.email}
            show={this.state.showEmail}
            toggle={this.toggleEmailDialog}
          />
        )}
      </span>
    );
  }
}
const mapStateToProps = state => ({
  ...state.common,
});

export default connect(mapStateToProps)(PlayerContactTools);
