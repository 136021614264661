import React from 'react';
import agent from '../../../agent';
import PropTypes from 'prop-types';
import InputDialog from '../InputDialog';
import { toastr } from 'react-redux-toastr';

export class MailDialog extends React.Component {
  static propTypes = {
    // to: a string or a list of strings
    subject: PropTypes.string,
    message: PropTypes.string, // A specific message to indicate the purpose of these emails.
    show: PropTypes.bool.isRequired, // Should be the same state as the one used in the toggle function
    toggle: PropTypes.func.isRequired, // Uses the parents state to control opening/closing of the dialog
  };
  state = {
    mailInputs: null,
  };
  componentDidMount() {
    const emailList =
      this.props.to && this.props.to.constructor === String
        ? [{ email: this.props.to }]
        : this.props.to.map(t => {
            return { email: t };
          });
    const mailInputs = [
      {
        name: 'to',
        label: 'To',
        type: 'select',
        multi: true,
        creatable: true,
        options: emailList,
        labelKey: 'email',
        valueKey: 'email',
        promptTextCreator: createLabel => `Add: ${createLabel}`,
      },
      {
        name: 'subject',
        type: 'text',
        min: '4',
        label: 'Subject',
        placeholder: 'Subject',
      },
      {
        className: 'form-control email-body',
        name: 'body',
        label: 'Body',
        type: 'textarea',
        min: '4',
      },
    ];
    this.setState({
      mailInputs,
      initialValues: {
        to: [...emailList], // need to use new array here since we're using the same list in the input options. If they share the same list, we'll have duplicates when we add new emails.
        subject: this.props.subject,
      },
    });
  }

  render() {
    const { message } = this.props;
    return (
      this.state.mailInputs && (
        <InputDialog
          title="Contact"
          message={
            message ? message : 'Send an E-mail to other Tennis Folder users:'
          }
          inputs={this.state.mailInputs}
          show={this.props.show}
          toggle={this.props.toggle}
          initialValues={this.state.initialValues}
          buttonLabels={{ ok: 'Send' }}
          onSubmit={val => {
            const email = {
              ...val,
              to: val.to.map(t => t.email),
            };
            agent.Mail.send(email)
              .then(() => {
                toastr.success('Email', `${val.to.length} sucessfully sent!`);
                this.props.toggle();
              })
              .catch(e => {
                toastr.error(
                  'Error',
                  `errMessage: ${
                    e.message
                  } Please try again. If the problem persists, please contact ${
                    _clientFeatures.supportURL
                  }.`
                );
              });
          }}
        />
      )
    );
  }
}

export default MailDialog;
