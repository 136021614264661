import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { LOGOUT } from '../../constants';
import defaultImg from './imgs/default-user-icon.png';

const mapStateToProps = state => ({
  ...state.auth,
  ...state.common,
});

class UserMenuContainer extends React.Component {
  state = {
    src: null,
  };
  onError = () => {
    this.setState({
      src: defaultImg,
    });
  };
  renderMenuHeader() {
    const user = this.props.currentUser;
    const src = this.state.src;

    return (
      <Link className="sidebar__player" to="/account">
        <img
          className="sidebar__player__avatar"
          src={src ? src : user.avatar}
          alt={defaultImg}
          onError={this.onError}
        />
        <div className="sidebar__player__name">{user.email}</div>
      </Link>
    );
  }

  renderUserNav() {
    const { isAdmin, isPlayer, isTeamCreator, isVenue } = this.props;
    const navItems = [];

    if (isAdmin) {
      navItems.push(
        <li key="admin">
          <Link className="sidebar__nav-section__link" to="/admin/users">
            Admin
          </Link>
        </li>
      );
    }
    if (isVenue || isAdmin) {
      navItems.push(
        <li key="venues">
          <Link className="sidebar__nav-section__link" to="/venues">
            My Venues
          </Link>
        </li>
      );
    }

    if (isPlayer) {
      navItems.push(
        <li key="teams">
          <Link className="sidebar__nav-section__link" to="/teams">
            My Teams
          </Link>
        </li>
      );
    }

    navItems.push(
      <li key="social-play">
        <Link className="sidebar__nav-section__link" to="/social-play">
          Social Play
        </Link>
      </li>
    );
    navItems.push(
      <li key="ranking">
        <Link className="sidebar__nav-section__link" to="/ranking">
          Ladder
        </Link>
      </li>
    );

    // TODO-PBF: add back
    // if (isClubSuper) {
    //   navItems.push(
    //     <li key="clubs">
    //       <Link className="sidebar__nav-section__link" to="/clubs/dashboard">
    //         My Clubs
    //       </Link>
    //     </li>
    //   );
    // }
    if (isTeamCreator) {
      navItems.push(
        <li key="create-team">
          <Link className="sidebar__nav-section__link" to="/teams/new">
            Create Team
          </Link>
        </li>
      );
    }
    //
    // if (isPlayer) {
    //   navItems.push(
    //     <li key="my-teams">
    //       <Link className="sidebar__nav-section__link" to="/teams">
    //         My Teams
    //       </Link>
    //     </li>,
    //     <li key="schedule">
    //       <Link className="sidebar__nav-section__link" to="/schedule">
    //         Schedule
    //       </Link>
    //     </li>,
    //   );
    // }

    return <ul>{navItems}</ul>;
  }

  render() {
    return (
      <nav>
        {this.renderMenuHeader()}
        {this.renderUserNav()}
        {/* Plus a lonely section for the logout Link */}
        <ul>
          <li>
            <a
              className="sidebar__nav-section__link"
              onClick={this.props.onClickLogout}>
              Log Out
            </a>
          </li>
        </ul>
      </nav>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  onClickLogout: () => {
    return dispatch({ type: LOGOUT });
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserMenuContainer);
