import React from 'react';
import { connect } from 'react-redux';
import VenueSettingsForm from './venue-settings-form';
import agent from '../../agent';
import { toastr } from 'react-redux-toastr';
import { LOAD_SETTINGS } from '../../constants';

const mapStateToProps = state => ({
  ...state.profile,
  ...state.common,
});

const mapDispatchToProps = dispatch => ({
  onUpdate: settings => dispatch({ type: LOAD_SETTINGS, payload: settings }),
});

export class VenueContainer extends React.Component {
  handleSubmit = values => {
    return agent.Users.setVenueAdminSettings(values)
      .then(results => {
        this.props.onUpdate({ venueAdmin: results });
        toastr.success(
          'Venue Manager Settings',
          'Your manager settings have been updated successfully.'
        );
      })
      .catch(() => {
        toastr.error(
          'Venue Manager Settings',
          'Error saving the admin settings.'
        );
      });
  };
  render() {
    return (
      <div>
        <h1>Venue Admin Settings</h1>
        <br />
        <br />
        <VenueSettingsForm
          initialValues={this.props.settings && this.props.settings.venueAdmin}
          onSubmit={this.handleSubmit}
          buttonText="Update Settings"
        />
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VenueContainer);
