import React from 'react';
import { connect } from 'react-redux';
import agent from '../../agent';
import Loader from '../../components/UI/Loader/index';
import EventDetail from './components/event-detail.component';
import { getEvent, updateEvent, setPlayerEventStatus } from './action';
import { PLAYER } from '../../data/team-member-type';
import { debounce } from 'throttle-debounce';
import InputDialog from '../../components/UI/InputDialog';
import { toastr } from 'react-redux-toastr';
import { COMBO, MIXED } from '../../data/play-type';
import validators from '../../data/validations';
import {
  getRatingSum,
  MAX_RATING,
  MIN_RATING,
} from '../../social-play/service/social-play';

import ROLES from '../../data/roles';

class EventDetailContainer extends React.Component {
  state = {
    showTeamInviteDlg: false,
    showSocialPlayersDlg: false,
    clubTeams: [],
    venueMembers: [],
    inviteList: [],
  };

  timeout = { timeOut: 0 };

  componentDidMount() {
    this.loadEvent();
    this.updateNotes = debounce(350, this.updateNotes);
    this.updateCost = debounce(350, this.updateCost);
  }

  loadEvent = () => {
    const { eventId } = this.props.match.params;
    const isAdmin = !!this.props.roles.find(
      role => role === ROLES.ADMINISTRATOR || role === ROLES.VENUE
    );
    this.props
      .onLoad(agent.Events.get(eventId))
      .then(event => {
        if (event.venueEvent && event.venue && !event.public && isAdmin) {
          // If this is a private venue event, get the list of members to validate players against.
          agent.Venues.getMembers(event.venue._id).then(members => {
            this.setState({
              venueMembers: members.map(member => {
                return member.email;
              }),
              inviteList: members.filter(
                member =>
                  !event.invites.find(invite => invite.user._id === member._id)
              ),
            });
          });
        }
      })
      .catch(e => {
        toastr.warning(
          'Event Detail',
          `Error showing event detail ${e}`,
          this.timeout
        );
      });
    // }
  };

  addPlayer = body => {
    const { event } = this.props;
    const { venueMembers } = this.state;
    agent.Invites.add(body)
      .then(() => {
        toastr.success('Success', `Player Added`);
      })
      .then(() => {
        this.props.onUpdateEvent(agent.Events.get(this.props.event.id));
      })
      .then(() => {
        // If this is a members only venue event, add the non-member to the venue.
        if (
          event.venueEvent &&
          !event.public &&
          venueMembers &&
          venueMembers.length > 0 &&
          !venueMembers.find(member => member.email === body.email)
        ) {
          agent.Venues.addMember(event.venue._id, {
            email: body.email,
            member: true,
          });
        }
      });
  };

  notesChange = ev => {
    this.updateNotes(ev.target.value);
  };

  costChange = ev => {
    this.updateCost(ev.target.value);
  };

  componentWillUnmount() {
    this.props.onLoad(null);
  }

  maxPlayersChange = ev => {
    return this.updateMaxPlayers(ev.target.value);
  };

  minPlayersChange = ev => {
    return this.updateMinPlayers(ev.target.value);
  };

  evenOnlyChange = ev => {
    return this.updateEvenOnly(ev.target.checked);
  };

  requestSocialPlayers = (val, opts) => {
    let promise = Promise.resolve();
    if (val.maxParticipant != null) {
      promise = this.updateMaxParticipants(val.maxParticipant);
    }
    return promise
      .then(() => this.fillPractice(this.props.practice.id, opts))
      .catch(e => {
        toastr.error(
          'Practice Detail',
          `Error requesting for social players ${e}`,
          this.timeout
        );
      });
  };

  fillPractice = (id, opts) => {
    let added = 0;
    return agent.Practices.fillPractice(id, opts).then(count => {
      added = count;
      return this.props
        .onLoad(
          agent.Practices.getPracticeBySlug(
            this.props.practice.team.slug,
            this.props.practice.slug
          )
        )
        .then(() => {
          if (added > 0) {
            toastr.success(
              'Social Players',
              `${added} social player(s) invited to practice. They will show up as a participant if they opt in.`,
              this.timeout
            );
          } else {
            toastr.warning(
              'Social Players',
              "There weren't any Social Players that matched the team practice's requirements.",
              this.timeout
            );
          }
        })
        .catch(e => {
          toastr.error(
            'Social Players',
            `Error requesting players: ${e.message ||
              (e.reason && e.reason.message)}`,
            this.timeout
          );
        });
    });
  };

  finalizeEvent = () => {
    return this.props.onUpdateEvent(
      agent.Events.update(this.props.event.id, {
        status: 'closed', // TODO: update with constant
      })
    );
  };

  unfinalizeEvent = () => {
    return this.props.onUpdateEvent(
      agent.Events.update(this.props.event.id, {
        status: 'open',
      })
    );
  };

  sendReminder = () => {
    agent.Events.sendReminder(this.props.event.id)
      .then(() => {
        toastr.success('Success', `Reminder Email Sent!`);
      })
      .catch(e => {
        toastr.error(
          'Error',
          `An error occurred while sending out the email reminders.`
        );
      });
  };

  resendInvite = () => {
    agent.Events.resendInvitations(this.props.event.id)
      .then(() => {
        toastr.success('Success', `Resent Invites!`);
      })
      .catch(e => {
        toastr.error('Error', `An error occurred while resending invites.`);
      });
  };

  updateNotes(notes) {
    return this.props.onUpdateEvent(
      agent.Events.update(this.props.event.id, {
        notes,
      })
    );
  }

  updateCost(cost) {
    return this.props.onUpdateEvent(
      agent.Events.update(this.props.event.id, {
        cost,
      })
    );
  }

  updateMaxPlayers(maxPlayers) {
    return this.props.onUpdateEvent(
      agent.Events.update(this.props.event.id, {
        maxPlayers,
      })
    );
  }

  updateMinPlayers(minPlayers) {
    return this.props.onUpdateEvent(
      agent.Events.update(this.props.event.id, {
        minPlayers,
      })
    );
  }

  updateEvenOnly(isEvenOnly) {
    return this.props.onUpdateEvent(
      agent.Events.update(this.props.event.id, {
        isEvenOnly,
      })
    );
  }

  changePlayerStatus(user, status) {
    if (this.props.event && user != null) {
      return this.props.onEventStatus(
        agent.Events.setAvailability(this.props.event.id, {
          status,
          user,
        }).then(this.loadPractice)
      );
    }
    return new Promise().resolve();
  }

  isCaptainOrCoCaptain = () => {
    return (
      (this.props.practice &&
        this.props.practice.userTeamSettings &&
        this.props.practice.userTeamSettings.type > PLAYER) ||
      this.props.roles.indexOf('Admin') >= 0
    );
  };

  showRequestPlayersDialog = type => {
    if (type === 'teams') {
      this.setState({ loadingTeams: true });
      return agent.Practices.getInviteableTeams(this.props.practice.id)
        .then(teams => {
          this.setState({
            clubTeams: teams.filter(
              t => !t.isInPast && t.id !== this.props.practice.team.id
            ),
            loadingTeams: false,
          });
          this.toggleTeamInviteDialog();
        })
        .catch(e => {
          console.error(e);
          this.setState({ loadingTeams: false });
          toastr.error(
            'Practice Detail',
            `Error requesting Club Teams ${e}`,
            this.timeout
          );
        });
    } else {
      this.toggleSocialPlayersDialog();
    }
  };

  toggleSocialPlayersDialog = () =>
    this.setState({
      showSocialPlayersDlg: !this.state.showSocialPlayersDlg,
    });

  toggleTeamInviteDialog = () =>
    this.setState({
      showTeamInviteDlg: !this.state.showTeamInviteDlg,
    });

  isMixedOrCombo = practice =>
    practice.playType == MIXED || practice.playType == COMBO;

  render() {
    const { event, currentUser } = this.props;
    const maxPartInput = [];
    const teamInviteInput = [];
    const curRating = this.props.currentUser.rating;
    const minRating = getRatingSum(curRating, -0.5);
    const maxRating = getRatingSum(curRating, 0.5);

    maxPartInput.push({
      name: 'friendsOnly',
      label: 'All Matching Club Players',
      type: 'radio',
      value: 'false',
      checked: 'checked',
    });
    maxPartInput.push({
      name: 'friendsOnly',
      label: 'Friends Only',
      type: 'radio',
      value: 'true',
    });

    if (this.props.practice && !this.props.practice.maxNumberOfParticipants) {
      const maxPart = {
        name: 'maxParticipant',
        label: 'Maximum Participants (Required)',
        type: 'number',
        min: '1',
        validators: validators.required,
      };
      maxPartInput.push(maxPart);
      teamInviteInput.push(maxPart);
    }
    if (minRating >= MIN_RATING) {
      maxPartInput.push({
        name: 'minRating',
        label: `Invite ${minRating} USTA Rated players?`,
        type: 'checkbox',
      });
    }
    if (maxRating <= MAX_RATING) {
      maxPartInput.push({
        name: 'maxRating',
        label: `Invite ${maxRating} USTA Rated players?`,
        type: 'checkbox',
      });
    }
    teamInviteInput.push({
      name: 'teams',
      label: `Invite teams from ${(this.props.practice &&
        this.props.practice.club.name) ||
        'club'}:`,
      type: 'select',
      multi: true,
      options: this.state.clubTeams.map(t => {
        return { name: t.name, players: t.players, id: t.id };
      }),
      labelKey: 'name',
      valueKey: 'id',
    });
    const isAdmin = !!this.props.roles.find(
      role => role === ROLES.ADMINISTRATOR || role === ROLES.VENUE
    );
    const isCreator = event && event.creator._id === currentUser._id;
    return (
      <div className="main-outline">
        {event ? (
          <EventDetail
            isAdmin={isAdmin}
            isCreator={isCreator}
            event={event}
            isCaptain={this.isCaptainOrCoCaptain()}
            addPlayer={this.addPlayer}
            updateNotes={this.notesChange}
            updateCost={this.costChange}
            updateMaxPlayers={this.maxPlayersChange}
            updateMinPlayers={this.minPlayersChange}
            updateEvenOnly={this.evenOnlyChange}
            socialPlayersRequest={this.showRequestPlayersDialog}
            finalizeEvent={this.finalizeEvent}
            unfinalizeEvent={this.unfinalizeEvent}
            sendReminder={this.sendReminder}
            resendInvite={this.resendInvite}
            currentUser={currentUser}
            venueMembers={this.state.venueMembers}
            inviteList={this.state.inviteList}
            setPlayerStatus={(user, status) =>
              this.changePlayerStatus(user, status)
            }
          />
        ) : (
          <Loader />
        )}
        {this.state.loadingTeams && <Loader className="loader" />}
        {this.state.showSocialPlayersDlg && (
          <InputDialog
            modalBodyClass="modal-body-scroll-visible"
            title="Request Social Players"
            message="Social Players, who may not be team-members, will be requested to participate in the practice."
            inputs={maxPartInput}
            show={this.state.showSocialPlayersDlg}
            toggle={this.toggleSocialPlayersDialog}
            onSubmit={val => {
              const opts = {
                friendsOnly: val.friendsOnly === 'true',
                minRating: val.minRating ? getRatingSum(curRating, -0.5) : null,
                maxRating: val.maxRating ? getRatingSum(curRating, 0.5) : null,
              };
              return this.requestSocialPlayers(val, opts).then(
                this.toggleSocialPlayersDialog
              );
            }}
          />
        )}
        {this.state.showTeamInviteDlg && (
          <InputDialog
            modalBodyClass="modal-body-scroll-visible"
            title="Request Teams"
            message="Requesting a Team will invite all its members regardless of gender and rating."
            inputs={teamInviteInput}
            show={this.state.showTeamInviteDlg}
            toggle={this.toggleTeamInviteDialog}
            onSubmit={val => {
              const players = [];
              val.teams.forEach(t => {
                t.players.forEach(p => {
                  players.push(p.id);
                });
              });
              const pTest = players.concat(players);
              const opts = {
                players: pTest,
              };
              return this.requestSocialPlayers(val, opts).then(
                this.toggleTeamInviteDialog
              );
            }}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  ...state.events,
  ...state.common,
});

const mapDispatchToProps = dispatch => ({
  onLoad: data => dispatch(getEvent(data)),
  onUpdateEvent: data => dispatch(updateEvent(data)),
  onEventStatus: data => dispatch(setPlayerEventStatus(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EventDetailContainer);
