import React from 'react';

export default function Loading(props) {
  if (props.isLoading) {
    if (props.timedOut) {
      return <div>Loader timed out!</div>;
    } else if (props.pastDelay) {
      return <div>Loading...</div>;
    } else {
      return null;
    }
  } else if (props.error) {
    const { appInsights } = window;
    if (appInsights && appInsights.trackException) {
      appInsights.trackException(props.error);
    }
    return <div>Error! Component failed to load.</div>;
  } else {
    return null;
  }
}
