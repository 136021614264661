import { LOCATION_CHANGE, IMPERSONATE, END_IMPERSONATION } from '../constants';
import {
  TEAM_LOAD,
  TEAM_PAST_LOAD,
  VENUE_TEAM_LOAD,
  VENUE_TEAM_PAST_LOAD,
  BEFORE_TEAM_CREATE,
  GET_TEAM_DATA,
  GET_TEAM_MATCHES,
  GET_TEAM_PRACTICES,
  GET_LEAGUES_DATA,
  UPDATE_TEAM,
  ADD_TEAM_REQUEST,
  SET_PLAYER_PREFERENCE,
  GET_PLAYER_TEAM_DATA,
  TEAM_REQUESTED,
  GET_VENUES_DATA,
  GET_SEASONS_DATA,
  GET_SEASON_DATA,
} from './constants';

export default function(state = {}, action) {
  switch (action.type) {
    case IMPERSONATE:
    case END_IMPERSONATION:
      return {};
    case LOCATION_CHANGE:
      if (state.errors || state.redirectTo || state.team) {
        return {
          ...state,
          errors: null,
          redirectTo: null,
        };
      }
      return state;
    case TEAM_REQUESTED:
      return {
        ...state,
        errors: action.error ? action.payload : null,
        redirectTo: action.error ? null : '/teams',
      };
    case TEAM_LOAD:
      return {
        ...state,
        teams: action.payload,
      };
    case TEAM_PAST_LOAD:
      return {
        ...state,
        pastTeams: action.payload,
      };
    case VENUE_TEAM_LOAD:
      return {
        ...state,
        venueTeams: action.payload,
      };
    case VENUE_TEAM_PAST_LOAD:
      return {
        ...state,
        venuePastTeams: action.payload,
      };
    case BEFORE_TEAM_CREATE:
      return {
        ...state,
        venues: action.payload.venues.filter(c => c.name != null),
        players: action.payload.players,
        leagues: action.payload.leagues.filter(l => l.name != null),
        foods: action.payload.foods,
        // seasons: action.payload.seasons.filter(s => s.name != null),
      };
    case GET_LEAGUES_DATA:
      return {
        ...state,
        leagues: action.payload,
      };
    case GET_TEAM_DATA:
      return {
        ...state,
        team: action.payload,
      };
    case GET_TEAM_MATCHES:
      return {
        ...state,
        matches: action.payload,
      };
    case GET_TEAM_PRACTICES:
      return {
        ...state,
        practices: action.payload,
      };
    case GET_PLAYER_TEAM_DATA:
      return {
        ...state,
        playerTeam: action.payload,
      };
    case GET_VENUES_DATA:
      return {
        ...state,
        venues: action.payload.venues,
      };
    case GET_SEASONS_DATA:
      return {
        ...state,
        seasons: action.payload,
      };
    case GET_SEASON_DATA:
      return {
        ...state,
        season: action.payload,
      };
    case ADD_TEAM_REQUEST:
      return {
        ...state,
        errors: action.error ? action.payload : null,
        redirectTo: action.error ? null : '/teams',
        teams: undefined,
        currentTeams: undefined,
      };
    case UPDATE_TEAM:
      return {
        ...state,
        errors: action.error ? action.payload : null,
        teams: null,
        redirectTo: action.error ? null : '/teams',
      };
    case SET_PLAYER_PREFERENCE:
      return {
        ...state,
        team: {
          ...state.team,
          userTeamSettings:
            action.payload.id === state.team.userTeamSettings.id
              ? action.payload
              : state.team.userTeamSettings,
          players: state.team.players.map(player =>
            player.id === action.payload.id ? action.payload : player
          ),
        },
      };
    default:
      return state;
  }
}
