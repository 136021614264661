import React from 'react';
import { connect } from 'react-redux';
import agent from '../../agent';
import {
  ADMIN_TEAMS_LOADED,
  ADMIN_TEAMS_UNLOADED,
  TEAMS_LIST,
} from '../../constants';
import { Link } from 'react-router-dom';
import { debounce } from 'throttle-debounce';
import { localTime } from '../../common/services/utils/helpers';
import ConfirmationDialog from '../../components/UI/ConfirmationDialog';
import { toastr } from 'react-redux-toastr';

export class TeamsContainer extends React.Component {
  constructor() {
    super();
    this.updateTeamList = debounce(350, this.updateTeamList);
  }

  state = {
    showConfirmation: false,
    team: null,
  };

  changeFilter(ev) {
    this.updateTeamList(ev.target.value);
  }

  updateTeamList(value) {
    this.props.onChangeFilter(this.props.match.params.id, value);
  }

  UNSAFE_componentWillMount() {
    this.props.onLoad(this.props.match.params.id);
  }

  componentWillUnmount() {
    this.props.onUnload();
  }

  handleDeleteTeam = teamId =>
    agent.Teams.deleteTeam(teamId)
      .then(() => {
        toastr.success('Delete Team', 'This team has been removed.');
        this.props.onLoad(this.props.match.params.id);
      })
      .catch(() =>
        toastr.error(
          'Delete Team',
          'An error occurred while trying to remove this team.'
        )
      );
  toggleConfirmationDialog = () =>
    this.setState({ showConfirmation: !this.state.showConfirmation });

  render() {
    const { teams } = this.props;

    return (
      <div className="">
        <div className="list-group">
          <div className="list-group-item">
            <div>
              <span className="h3">Teams List</span>
              <div className="float-right">
                <input
                  type="search"
                  placeholder="Search"
                  className="form-control"
                  onChange={this.changeFilter.bind(this)}
                />
              </div>
            </div>
          </div>
          {teams && teams.length ? (
            <table className="table">
              <thead>
                <tr>
                  <th>Team</th>
                  <th>Last Sync</th>
                  <th>League</th>
                </tr>
              </thead>
              <tbody>
                {teams.map((team, idx) => (
                  <tr key={idx}>
                    <td>
                      <Link to={`/teams/${team._id}`}>{team.name}</Link>
                    </td>
                    <td>
                      {!!team.lastSync &&
                        localTime(team.lastSync).format('llll')}
                    </td>
                    <td>{team.league && team.league.name}</td>
                    <td>
                      <button
                        title="Delete"
                        onClick={e => {
                          e.preventDefault();
                          this.setState({ team: team });
                          this.toggleConfirmationDialog();
                        }}
                        className="btn btn-sm btn-outline-danger">
                        <i className="fa fa-trash" />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div className="list-group-item">No teams</div>
          )}
        </div>
        {this.state.showConfirmation && (
          <ConfirmationDialog
            show={this.state.showConfirmation}
            title="Delete Team"
            buttonLabels={{ ok: 'Delete Team' }}
            message="Are you sure you want to delete this team?
            This will remove all records of the team, including any
            participations, any win/loss, schedule and food records"
            toggle={this.toggleConfirmationDialog}
            onSubmit={() => {
              this.handleDeleteTeam(this.state.team._id);
            }}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  ...state.common,
  ...state.admin,
});

const mapDispatchToProps = (dispatch, state) => {
  return {
    onLoad: id => {
      dispatch({
        type: ADMIN_TEAMS_LOADED,
        payload: agent.Admin.listTeams(id, state.filter),
      });
    },
    onUnload: () => dispatch({ type: ADMIN_TEAMS_UNLOADED }),
    onChangeFilter: (id, value) =>
      dispatch({
        type: TEAMS_LIST,
        payload: agent.Admin.listTeams(id, value),
      }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TeamsContainer);
