import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import InputUI from '../../components/UI/Input';
import ButtonUI from '../../components/UI/Button';
import ListErrors from '../../common/list-errors';
import validation from '../../data/validations';

class PasswordUpdateForm extends Component {
  handleSubmit = values => {
    this.props.onSubmit(values);
    this.props.reset();
  };
  render() {
    const {
      errors,
      handleSubmit,
      submitting,
      pristine,
      buttonText,
    } = this.props;
    return (
      <div className="row">
        <div className="col">
          <ListErrors errors={errors} />
          <form onSubmit={handleSubmit(this.handleSubmit)}>
            <Field
              name="currentPassword"
              type="password"
              validate={validation.required}
              component={InputUI}
              label="Current Password"
            />

            <Field
              name="newPassword"
              type="password"
              validate={validation.required}
              component={InputUI}
              label="New Password"
            />

            <Field
              name="newPasswordConfirm"
              type="password"
              validate={[validation.required]}
              component={InputUI}
              label="New Password Confirmation"
            />
            <div className="clearfix">
              <Field
                disabled={pristine || submitting}
                text={buttonText}
                component={ButtonUI}
                name="userPreferenceSubmit"
              />
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default reduxForm({
  form: 'passwordUpdateForm',
})(PasswordUpdateForm);
