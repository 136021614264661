import React from 'react';
import { connect } from 'react-redux';
import agent from '../../agent';
import {
  loadVenueMembers,
  loadVenueFollowers,
  updateVenueLists,
} from '../action';
import { SET_USER_IN_ROLE } from '../../constants';
import classNames from 'classnames';
import { VenueMembersContainer } from './venue-members';
import { toastr } from 'react-redux-toastr';

const MEMBERS = 0;
const FOLLOWERS = 1;
const LIST_OPTS = {
  membersList: {
    type: 'Members',
    dialog: {
      title: 'Downgrade Member',
      message:
        'Are you sure you want to downgrade this member? This will move the user from your members list into your followers list.',
      buttons: { ok: 'Downgrade' },
    },
  },
  followersList: {
    type: 'Followers',
    dialog: {
      title: 'Upgrade Member',
      message:
        'Are you sure you want to upgrade this user to a member? This will move the user from your followers list into your members list.',
      buttons: { ok: 'Upgrade' },
    },
  },
};

export class UsersContainer extends React.Component {
  state = {
    tabs: ['Members', 'Followers'],
    activeTab: MEMBERS,
  };

  componentDidMount() {
    this.handleLoadLists();
  }

  handleLoadLists = () => {
    this.props.onMembersLoad(
      agent.Venues.getMembers(this.props.match.params.id)
    );
    this.props.onFollowersLoad(
      agent.Venues.getFollowers(this.props.match.params.id)
    );
  };

  onCheckChange = (user, role) => ev => {
    this.props.setUserRole(user, role, ev.target.checked);
  };

  onAddMember = values => {
    this.props.onUpdateMembers(
      agent.Venues.addMember(this.props.venue._id, {
        email: values.email,
        member: values.member,
      }).then(data => {
        toastr.success('Added Member', 'This user has been added as a member.');
        return data;
      })
    );
  };

  onEditMember = values => {
    this.props.onUpdateMembers(
      agent.Venues.updateMemberProfile(
        this.props.venue._id,
        values._id,
        values
      ).then(data => {
        toastr.success(
          'Member Updated',
          "The member's Profile has been updated"
        );
        return data;
      })
    );
  };

  onUpgradeMember = user => {
    this.props.onUpdateMembers(
      agent.Venues.updateMemberStatus(this.props.venue._id, user._id, {
        upgrade: true,
      }).then(data => {
        toastr.success(
          'Added Member',
          'This user has been added as a club member.'
        );
        return data;
      })
    );
  };

  onDowngradeMember = user => {
    this.props.onUpdateMembers(
      agent.Venues.updateMemberStatus(this.props.venue._id, user._id, {
        upgrade: false,
      }).then(data => {
        toastr.success(
          'Removed Member',
          'This user is no longer a member of this club.'
        );
        return data;
      })
    );
  };

  render() {
    const { activeTab, tabs } = this.state;
    const isAdmin = this.props.roles.includes('Admin');
    return (
      <div>
        <ul className="nav nav-tabs mb-3">
          {tabs.map((tab, idx) => {
            return (
              <li key={idx} className="nav-item">
                <a
                  onClick={() => {
                    this.setState({
                      activeTab: idx,
                    });
                  }}
                  className={classNames('nav-link', {
                    active: idx === activeTab,
                  })}>
                  {tab}
                </a>
              </li>
            );
          })}
        </ul>
        {activeTab === MEMBERS && (
          <VenueMembersContainer
            users={this.props.members}
            isAdmin={isAdmin}
            opts={LIST_OPTS.membersList}
            action={this.onDowngradeMember}
            onAddMember={this.onAddMember}
            onEditMember={this.onEditMember}
            venue={this.props.venue}
            onCheckChange={this.onCheckChange}
            fetchMembers={this.handleLoadLists}
          />
        )}
        {activeTab === FOLLOWERS && (
          <VenueMembersContainer
            users={this.props.followers}
            isAdmin={isAdmin}
            opts={LIST_OPTS.followersList}
            action={this.onUpgradeMember}
            onAddMember={this.onAddMember}
            onEditMember={this.onEditMember}
            venue={this.props.venue}
            onCheckChange={this.onCheckChange}
            fetchMembers={this.handleLoadLists}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  ...state.common,
  ...state.venues,
});

const mapDispatchToProps = dispatch => ({
  onMembersLoad: users => dispatch(loadVenueMembers(users)),
  onFollowersLoad: users => dispatch(loadVenueFollowers(users)),
  onUpdateMembers: users => dispatch(updateVenueLists(users)),
  setUserRole: (user, role, addToRole) =>
    dispatch({
      type: SET_USER_IN_ROLE,
      payload: addToRole
        ? agent.Admin.addUserToRole(user, role)
        : agent.Admin.removeUserFromRole(user, role),
    }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UsersContainer);
