import React from 'react';

export const BEFORE_EVENT_CREATE = 'BEFORE_EVENT_CREATE';
export const UPDATE_EVENT = 'UPDATE_EVENT';
export const AFTER_EVENT_CREATE = 'AFTER_EVENT_CREATE';
export const GET_EVENT = 'GET_EVENT';
export const SET_PLAYER_EVENT_STATUS = 'SET_PLAYER_EVENT_STATUS';

export const AVAIL_TOGGLE = [
  {
    displayName: <i className="fa fa-check fa-fw fa-lg" />,
    value: 'accept',
  },
  {
    displayName: <i className="fa fa-times fa-fw fa-lg" />,
    value: 'decline',
  },
];

export const CONF_TOGGLE = [
  {
    displayName: <i className="fa fa-check fa-fw fa-lg" />,
    value: 'confirmed',
  },
  {
    displayName: <i className="fa fa-times fa-fw fa-lg" />,
    value: 'decline',
  },
];
