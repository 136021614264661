import connectGrey from './connect_grey.png';
import connectWhite from './connect_white.png';
import homeGrey from './home_grey.png';
import homeWhite from './home_white.png';
import playGrey from './play_grey.png';
import playWhite from './play_white.png';
import scheduleGrey from './schedule_grey.png';
import scheduleWhite from './schedule_white.png';
import setupGrey from './setup_grey.png';
import setupWhite from './setup_white.png';
import socialplayGrey from './socialplay_grey.png';
import socialplayWhite from './socialplay_white.png';
import teamsGrey from './teams_grey.png';
import teamsWhite from './teams_white.png';

export default [
  {
    name: 'connect',
    images: [
      {
        type: 'grey',
        src: connectGrey,
      },
      {
        type: 'white',
        src: connectWhite,
      },
    ],
  },
  {
    name: 'home',
    images: [
      {
        type: 'grey',
        src: homeGrey,
      },
      {
        type: 'white',
        src: homeWhite,
      },
    ],
  },
  {
    name: 'play',
    images: [
      {
        type: 'grey',
        src: playGrey,
      },
      {
        type: 'white',
        src: playWhite,
      },
    ],
  },
  {
    name: 'schedule',
    images: [
      {
        type: 'grey',
        src: scheduleGrey,
      },
      {
        type: 'white',
        src: scheduleWhite,
      },
    ],
  },
  {
    name: 'setup',
    images: [
      {
        type: 'grey',
        src: setupGrey,
      },
      {
        type: 'white',
        src: setupWhite,
      },
    ],
  },
  {
    name: 'event',
    images: [
      {
        type: 'grey',
        src: socialplayGrey,
      },
      {
        type: 'white',
        src: socialplayWhite,
      },
    ],
  },
  {
    name: 'teams',
    images: [
      {
        type: 'grey',
        src: teamsGrey,
      },
      {
        type: 'white',
        src: teamsWhite,
      },
    ],
  },
];
