export const IMPERSONATE = 'IMPERSONATE';
export const END_IMPERSONATION = 'END_IMPERSONATION';
export const USER_LOGGING_IN = 'USER_LOGGING_IN';
export const USER_LOGGED_IN = 'USER_LOGGED_IN';
export const USER_LOGGED_OUT = 'USER_LOGGED_OUT';
export const USER_LOGIN_ERROR = 'USER_LOGIN_ERROR';
export const USER_LOGIN_FAILED = 'USER_LOGIN_FAILED';
export const PASSWORD_RESET_LOADED = 'PASSWORD_RESET_LOADED';
export const PASSWORD_RESET_UNLOADED = 'PASSWORD_RESET_UNLOADED';
export const PASSWORD_RESET = 'PASSWORD_RESET';
export const APP_LOAD = 'APP_LOAD';
export const SETTINGS_SAVED = 'SETTINGS_SAVED';
export const SETTINGS_PAGE_UNLOADED = 'SETTINGS_PAGE_UNLOADED';
export const HOME_PAGE_LOADED = 'HOME_PAGE_LOADED';
export const HOME_PAGE_UNLOADED = 'HOME_PAGE_UNLOADED';
export const PROFILE_PAGE_LOADED = 'PROFILE_PAGE_LOADED';
export const PROFILE_PAGE_UNLOADED = 'PROFILE_PAGE_UNLOADED';
export const LOGIN = 'LOGIN';
export const LOGIN_TOKEN = 'LOGIN_TOKEN';
export const LOGOUT = 'LOGOUT';
export const REGISTER = 'REGISTER';
export const LOGIN_PAGE_UNLOADED = 'LOGIN_PAGE_UNLOADED';
export const REGISTER_PAGE_UNLOADED = 'REGISTER_PAGE_UNLOADED';
export const ASYNC_START = 'ASYNC_START';
export const ASYNC_END = 'ASYNC_END';
export const UPDATE_FIELD_AUTH = 'UPDATE_FIELD_AUTH';
export const UPDATE_FIELD_EDITOR = 'UPDATE_FIELD_EDITOR';
export const ADMIN_HOME_LOADED = 'ADMIN_HOME_LOADED';
export const ADMIN_HOME_UNLOADED = 'ADMIN_HOME_UNLOADED';
export const ADMIN_USERS_LOADED = 'ADMIN_USERS_LOADED';
export const ADMIN_USERS_UNLOADED = 'ADMIN_USERS_UNLOADED';
export const ADMIN_LEAGUES_LOADED = 'ADMIN_LEAGUES_LOADED';
export const ADMIN_CURRENT_LEAGUES_LOADED = 'ADMIN_CURRENT_LEAGUES_LOADED';
export const ADMIN_LEAGUES_UNLOADED = 'ADMIN_LEAGUES_UNLOADED';
export const ADMIN_FLIGHTS_LOADED = 'ADMIN_FLIGHTS_LOADED';
export const ADMIN_FLIGHTS_UNLOADED = 'ADMIN_FLIGHTS_UNLOADED';
export const ADD_LEAGUE_REQUEST = 'ADD_LEAGUE_REQUEST';
export const UPDATE_LEAGUE_REQUEST = 'UPDATE_LEAGUE_REQUEST';
export const ADD_FLIGHT_REQUEST = 'ADD_FLIGHT_REQUEST';
export const UPDATE_FLIGHT_REQUEST = 'UPDATE_FLIGHT_REQUEST';
export const ADMIN_VENUES_LOADED = 'ADMIN_VENUES_LOADED';
export const ADMIN_VENUES_UNLOADED = 'ADMIN_VENUES_UNLOADED';
export const ADD_VENUE_REQUEST = 'ADD_VENUE_REQUEST';
export const UPDATE_VENUE_REQUEST = 'UPDATE_VENUE_REQUEST';
export const ADD_SEASON_REQUEST = 'ADD_SEASON_REQUEST';
export const UPDATE_SEASON_REQUEST = 'UPDATE_SEASON_REQUEST';
export const ADMIN_SEASONS_LOADED = 'ADMIN_SEASONS_LOADED';
export const ADMIN_SEASONS_UNLOADED = 'ADMIN_SEASONS_UNLOADED';
export const ADMIN_TEAMS_LOADED = 'ADMIN_TEAMS_LOADED';
export const ADMIN_TEAMS_UNLOADED = 'ADMIN_TEAMS_UNLOADED';
export const USER_PROFILE_LOADED = 'USER_PROFILE_LOADED';
export const UPDATE_USER_PROFILE = 'UPDATE_USER_PROFILE';
export const TEAMS_LIST = 'TEAMS_LIST';
export const USERS_LIST = 'USERS_LIST';
export const USERS_DISPLAY = 'USERS_DISPLAY';
export const UPDATE_FIELD_ADMIN = 'UPDATE_FIELD_ADMIN';
export const SET_USER_IN_ROLE = 'SET_USER_IN_ROLE';
export const MENU_TOGGLE = 'MENU_TOGGLE';
export const CONFIRM_EMAIL_UNLOADED = 'CONFIRM_EMAIL_UNLOADED';
export const CONFIRM_EMAIL = 'CONFIRM_EMAIL';
export const CONFIRM_TOKEN = 'CONFIRM_TOKEN';
export const CONFIRM_TOKEN_UNLOADED = 'CONFIRM_TOKEN_UNLOADED';
export const EVENT_RESPONSE = 'EVENT_RESPONSE';
export const EVENT_RESPONSE_UNLOADED = 'EVENT_RESPONSE_UNLOADED';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const FORGOT_PASSWORD_PAGE_UNLOADED = 'FORGOT_PASSWORD_PAGE_UNLOADED';
export const FORGOT_PASSWORD_UNLOADED = 'FORGOT_PASSWORD_UNLOADED';
export const FORGOT_PASSWORD_EMAIL_NOT_FOUND =
  'FORGOT_PASSWORD_EMAIL_NOT_FOUND';
export const LOCATION_CHANGE = '@@router/LOCATION_CHANGE';
export const UPDATE_PLAYER_INFO = 'UPDATE_PLAYER_INFO';
export const UPLOAD_AVATAR = 'UPLOAD_AVATAR';
export const UPDATE_USER_PASSWORD = 'UPDATE_USER_PASSWORD';
export const CLUBS_LOADED = 'CLUBS_LOADED';
export const CONTACT_FORM_SUBMIT = 'CONTACT_FORM_SUBMIT';
export const FIRST_TIME_SETUP = 'FIRST_TIME_SETUP';
export const PAYMENT_FORM_SUBMIT = 'PAYMENT_FORM_SUBMIT';
export const PAYMENT_TRANSACTION_COMPLETE = 'PAYMENT_TRANSACTION_COMPLETE';
export const LOAD_SETTINGS = 'LOAD_SETTINGS';
export const LOAD_FRIENDS_GROUPS = 'LOAD_FRIENDS_GROUPS';
export const LOAD_FRIENDS_GROUP = 'LOAD_FRIENDS_GROUP';
export const LOAD_ALL_VENUE_MEMBERS = 'LOAD_ALL_VENUE_MEMBERS';
export const LOAD_VENUES_LIST = 'LOAD_VENUES_LIST';
export const LOAD_MEMBERS = 'LOAD_MEMBERS';
export const UPDATE_USER = 'UPDATE_USER';
export const VENUE_ACTION = 'VENUE_ACTION';
export const VENUE_ACTION_UNLOADED = 'VENUE_ACTION_UNLOADED';
// API
export const API_ROOT = '/v1';
