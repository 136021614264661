import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import adminReducer from './admin/reducers';
import clubReducer from './clubs/reducers';
import authReducer from './auth/reducers';
import commonReducer from './common/reducers';
import venuesReducer from './venues/reducers';
import eventsReducer from './venues/events/reducers';
import agendaReducer from './agenda/reducers';
import teamsReducer from './teams/reducers';
import practicesReducer from './teams/practices/reducers';
import matchesReducer from './teams/matches/reducers';
import flightsReducer from './flights/reducers';
import rankingReducer from './ranking/components/redux/reducers';
import challengeReducer from './challenge/components/redux/reducers';
import { reducer as formReducer } from 'redux-form';
import { reducer as toastrReducer } from 'react-redux-toastr';

export default history =>
  combineReducers({
    router: connectRouter(history),
    admin: adminReducer,
    club: clubReducer,
    auth: authReducer,
    common: commonReducer,
    toastr: toastrReducer,
    form: formReducer,
    venues: venuesReducer,
    events: eventsReducer,
    agenda: agendaReducer,
    teams: teamsReducer,
    practices: practicesReducer,
    matches: matchesReducer,
    flights: flightsReducer,
    ranking: rankingReducer,
    challenge: challengeReducer,
  });
