import React from 'react';
import { connect } from 'react-redux';
import EventForm from './components/add-event-form';
import { Link, Redirect } from 'react-router-dom';
import { toastr } from 'react-redux-toastr';
import agent from '../../agent';
import { afterEventCreate, beforeEventCreate } from './action';
import { combineDateTime } from '../../common/services/utils/helpers';
import { eventTypes, playTypes } from '../../data/types';
import { loadGroups, loadMembers } from '../../common/action';
import {DOUBLES} from "../../data/play-type";

class AddNewVenueEvent extends React.Component {
  componentDidMount() {
    const { id } = this.props.match.params;
    this.props.onLoadGroups(agent.Users.getFriendGroups());
    this.props.onLoadMembers(agent.Venues.getMembers(id));
  }

  onSubmit(values) {
    const { id } = this.props.match.params;
    const type = values.type;
    let event = {
      start: values.startTime
        ? combineDateTime(values.eventDate, values.startTime).format()
        : null,
      notes: values.notes ? values.notes : undefined,
      venueEvent: true,
      venue: id,
      gender: values.gender,
      repeat: values.repeat,
      days: values.days,
      type,
      notify: !!values.notify,
    };
    if (type === eventTypes.CHALLENGE) {
      const {
        homePlayer1,
        homePlayer2,
        awayPlayer1,
        awayPlayer2,
        name,
        playType,
        roundRobin,
      } = values;
      const isDoubles = playType === DOUBLES;
      event['name'] = name;
      const game = {
        position: isDoubles ? 'D1' : 'S1',
        home: {
          player1: homePlayer1 && homePlayer1.id,
          player2: homePlayer2 && homePlayer2.id,
        },
        away: {
          player1: awayPlayer1 && awayPlayer1.id,
          player2: awayPlayer2 && awayPlayer2.id,
        },
      };
      event['games'] = [game];
      event['roundRobin'] = isDoubles ? roundRobin : false;
      return this.props
        .onEventCreate(agent.Challenge.add(event))
        .then(() => toastr.success('Event', 'Your event has been created!'));
    }
    if (type === eventTypes.SOCIAL) {
      event['playTypes'] = values.playTypes;
      event['gender'] = values.gender;
      event['maxRating'] = values.playTypes.mixed
        ? values.combinedRating
        : values.maxRating;
      if (values.minRating && !values.playTypes.mixed) {
        event['minRating'] = values.minRating;
      }
    } else {
      event['name'] = values.name;
      event['maxPlayers'] = type === eventTypes.LESSON ? 1 : values.maxPlayers;
      event['minPlayers'] = type === eventTypes.LESSON ? 1 : values.minPlayers;
      if (type !== eventTypes.LESSON) {
        event['public'] = !values.public;
      }
      if (type === eventTypes.CLINIC || type === eventTypes.LESSON) {
        event['cost'] = values.cost;
      }
      if (values.groups) {
        // For multi
        if (values.groups.length > 0) {
          event['groups'] = values.groups.map(group => group.name);
        } else {
          event['groups'] = [values.groups.name];
        }
      } else {
        event['gender'] = values.gender;
        if (values.filterByRating) {
          event['maxRating'] = values.maxRating;
          if (values.minRating) {
            event['minRating'] = values.minRating;
          }
        }
      }
    }
    return this.props
      .onEventCreate(agent.Events.add(event))
      .then(() => toastr.success('Event', 'Your event has been created!'));
  }

  render() {
    const {
      match,
      groups,
      initialValues,
      errors,
      members,
      redirectTo,
    } = this.props;
    return (
      <div className="main-outline">
        {redirectTo ? <Redirect to={redirectTo} /> : null}
        <div className="social-header-area">
          <h2 className="header-text d-flex align-items-center justify-content-between">
            <Link to={`/venues/${match.params.id}/events`}>
              Create New Event
            </Link>
          </h2>
        </div>
        <EventForm
          onSubmit={values => this.onSubmit(values)}
          groups={groups}
          initialValues={initialValues}
          enableReinitialize={true}
          errors={errors}
          isEdit={false}
          members={members}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  /**
   * Don't provide an object here unless we know we can hand off some defaults
   * as we only get one chance to do it. `initialValues` can be set _one time_
   */
  let initialValues = {
    maxPlayers: 99,
    minPlayers: 4,
    repeat: 0,
    public: true,
  };
  return {
    ...state.common,
    ...state.events,
    initialValues,
  };
};

const mapDispatchToProps = dispatch => ({
  onLoadGroups: data => dispatch(loadGroups(data)),
  onLoadMembers: data => dispatch(loadMembers(data)),
  onEventCreate: body => dispatch(afterEventCreate(body)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddNewVenueEvent);
