export const inviteResponse = {
  ACCEPT: 'accept',
  DECLINE: 'decline',
  MAYBE: 'maybe',
  CONFIRMED: 'confirmed',
};

export const eventTypes = {
  SOCIAL: 'social',
  VENUESOCIAL: 'venuesocial',
  CLINIC: 'clinic',
  LESSON: 'lesson',
  TOURNAMENT: 'tournament',
  DROPIN: 'dropin',
  PRACTICE: 'practice',
  SOCIALPRACTICE: 'social-practice',
  MATCH: 'match',
  FLIGHTMATCH: 'flightmatch',
  CHALLENGE: 'challenge',
};

export const eventTypesArray = [
  {
    id: eventTypes.SOCIAL,
    name: 'Social Play',
    description: '',
    display: ['venue'],
  },
  {
    id: eventTypes.VENUESOCIAL,
    name: 'Venue Social',
    description: '',
    display: ['venue'],
  },
  {
    id: eventTypes.DROPIN,
    name: 'Drop In',
    description: '',
    display: ['venue'],
  },
  {
    id: eventTypes.CLINIC,
    name: 'Clinic',
    description: '',
    display: ['venue'],
  },
  {
    id: eventTypes.LESSON,
    name: 'Lesson',
    description: '',
    display: ['venue'],
  },
  {
    id: eventTypes.TOURNAMENT,
    name: 'Tournament',
    description: '',
    display: ['venue'],
  },
  {
    id: eventTypes.CHALLENGE,
    name: 'Ladder Match',
    description: '',
    display: ['venue'],
  },
  {
    id: eventTypes.MATCH,
    name: 'Match',
    description: '',
    display: [],
  },
  {
    id: eventTypes.FLIGHTMATCH,
    name: 'Match',
    description: '',
    display: [],
  },
  {
    id: eventTypes.PRACTICE,
    name: 'Practice',
    description: '',
    display: [],
  },
];

export const eventStatus = {
  OPEN: 'open',
  CLOSED: 'closed',
};

export const days = {
  SUNDAY: 'sunday',
  MONDAY: 'monday',
  TUESDAY: 'tuesday',
  WEDNESDAY: 'wednesday',
  THURSDAY: 'thursday',
  FRIDAY: 'friday',
  SATURDAY: 'saturday',
};

export const playTimes = {
  WEEKEND_AM: 'we_am',
  WEEKEND_PM: 'we_pm',
  WEEKDAY_MORN: 'wd_morn',
  WEEKDAY_MID: 'wd_mid',
  WEEKDAY_EVE: 'wd_eve',
};

export const playTypes = {
  SINGLES: 'singles',
  DOUBLES: 'doubles',
  MIXED: 'mixed',
};

export const winLoss = {
  WIN: '1',
  LOSS: '2',
  DRAW: '0',
};

export const teamRoles = {
  PLAYER: 'player',
  SUBSTITUTE: 'substitute',
  CoCaptain: 'co_captain',
  Captain: 'captain',
};

export const shiftTypes = {
  BOTH: 'both',
  FIRST: 'first',
  SECOND: 'second',
};

export const leagueTypes = {
  SINGLES_AND_DOUBLES: 'singles_and_doubles',
  DOUBLES: 'doubles',
  COMBO: 'combo',
};
