import React from 'react';

export const BEFORE_MATCH_CREATE = 'BEFORE_MATCH_CREATE';
export const AFTER_MATCH_CREATE = 'AFTER_MATCH_CREATE';
export const SINGLE_MATCH_DETAIL = 'SINGLE_MATCH_DETAIL';
export const LEAGUE_DETAIL = 'LEAGUE_DETAIL';
export const MATCH_DATA_ON_LOAD = 'MATCH_DATA_ON_LOAD';
export const UPDATE_PLAYER_STATUS = 'UPDATE_PLAYER_STATUS';
export const UPDATE_MATCH = 'UPDATE_MATCH';
export const UPDATE_MATCH_SCORECARD = 'UPDATE_MATCH_SCORECARD';
export const UPDATE_MATCH_VISIBILITY = 'UPDATE_MATCH_VISIBILITY';
export const MATCH_PARTNERS = 'MATCH_PARTNERS';
export const ADD_PLAYER_TO_LINEUP = 'ADD_PLAYER_TO_LINEUP';

export const MATCH_TOGGLE = [
  {
    displayName: <i className="fa fa-check fa-fw fa-lg" />,
    value: 'accept',
  },
  {
    displayName: <i className="fa fa-question fa-fw fa-lg" />,
    value: 'maybe',
  },
  {
    displayName: <i className="fa fa-times fa-fw fa-lg" />,
    value: 'decline',
  },
];
