import React from 'react';
import { connect } from 'react-redux';
import CaptainSettingsForm from './captain-settings-form';
import agent from '../../agent';
import { toastr } from 'react-redux-toastr';
import { LOAD_SETTINGS } from '../../constants';
import { loadSettings } from '../../common/action';
import { Redirect } from 'react-router-dom';

const mapStateToProps = state => ({
  ...state.profile,
  ...state.common,
});

const mapDispatchToProps = dispatch => ({
  onLoadSettings: settings => dispatch(loadSettings(settings)),
  onUpdate: settings => dispatch({ type: LOAD_SETTINGS, payload: settings }),
});

export class CaptainsContainer extends React.Component {
  getSettings = () => {
    this.props.onLoadSettings(agent.Users.getUserSettings());
  };
  handleSubmit = values => {
    values = this.handleCaptainSettings(values);
    agent.Users.updateSettings(values)
      .then(() => {
        this.getSettings();
      })
      .then(() => {
        toastr.success('Success', 'Captain Settings Updated.');
      })
      .catch(() => {
        toastr.error('Error', 'Could not update Settings.');
      });
  };
  handleCaptainSettings = values => {
    return { captain: values };
  };

  getInitialCaptainSettings = () => {
    let initialValues = {};
    if (this.props.settings) {
      if (this.props.settings.captain) {
        initialValues = { ...this.props.settings.captain };
      }
    }

    return initialValues;
  };
  render() {
    const captainPrefs = this.getInitialCaptainSettings();
    return (
      <div>
        {this.props.settings ? (
          <div>
            <h1>Captain Settings</h1>
            <i>
              (These settings are only available when you are a captain or
              co-captain of a team.)
            </i>
            <br />
            <br />
            <CaptainSettingsForm
              initialValues={captainPrefs}
              onSubmit={values => this.handleSubmit(values)}
              submitButtonText="Update Settings"
            />
          </div>
        ) : (
          <Redirect to="/account/profile" />
        )}
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CaptainsContainer);
