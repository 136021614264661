import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import PropTypes from 'prop-types';

import InputUI from '../../components/UI/Input';
import ListErrors from '../../common/list-errors';
import validation from '../../data/validations';

export class AddVenueMemberModalContainer extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    show: PropTypes.bool.isRequired, // Should be the same state as the one used in the toggle function
    toggle: PropTypes.func.isRequired, // Uses the parents state to control opening/closing of the dialog
    inputRequired: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = {
      modal: this.props.show,
    };
  }

  validateEmail = value => {
    if (this.props.inputRequired && !value) {
      return 'invalid';
    }
    return !value ? undefined : validation.email(value);
  };

  render() {
    const { invalid, submitting, errors, handleSubmit } = this.props;

    return (
      <Modal isOpen={this.state.modal} className={this.props.className}>
        <form onSubmit={handleSubmit}>
          <ModalHeader toggle={this.props.toggle}>
            {this.props.title}
          </ModalHeader>
          <ModalBody>
            <ListErrors errors={errors} />
            {this.props.message}
            <br />
            <br />
            <Field
              name="email"
              component={InputUI}
              validate={[this.validateEmail]}
              label={`Player's Email Address (required)`}
              placeholder="Player Email"
            />
            <label className="form-check-label">
              <Field
                type="checkbox"
                name="member"
                component={field => (
                  <input
                    {...field.input}
                    type="checkbox"
                    className="form-check-input"
                  />
                )}
              />
              Add as a Member
            </label>
          </ModalBody>
          <ModalFooter>
            <a className="btn btn-link" onClick={this.props.toggle}>
              Cancel
            </a>
            <Button disabled={submitting || invalid} color="primary">
              Ok
            </Button>
          </ModalFooter>
        </form>
      </Modal>
    );
  }
}

export default reduxForm({ form: 'addMemberForm' })(
  AddVenueMemberModalContainer
);
